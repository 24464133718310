@import './mixins';

.image-editor-navigation {
	background-color: var(--background-color);
	height: 84px;
	border-top: solid 1px #2b2a30;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: 16px;
	padding-right: 16px;
	&__button {
		margin-left: 8px;
		margin-right: 8px;

		@include small-mobile() {
			margin-left: 4px;
			margin-right: 4px;
		}
	}
	&__buttons {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&__upload-input {
		display: none;
	}

	@include small-mobile() {
		padding-left: 8px;
		padding-right: 8px;
	}
}