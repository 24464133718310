@tailwind base;
@tailwind components;
@tailwind utilities;

/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
  scroll-behavior: smooth;
}

a
input,select{
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*---------------CUSTOM CSS VARIABLES---------------*/

:root {
  /* Primary colors */
  --primary-color: #36d300; 
  --primary-light: #6fa840; 
  --primary-dark: #2c4600; 

  /* Primary color hover */
  --primary-color-hover: #218838; 
  --primary-light-hover: #6fa840; 
  --primary-dark-hover: #2c4600; 

  /* Secondary colors */
  --secondary-color: #FD8309; 
  --secondary-light: #ffcc80; 
  --secondary-dark: #cc8400; 

  /* Background colors */
  --background-color: rgba(250,250,250,0.1); 
  --background-light: rgba(250,250,250,0.1); 
  --background-dark: rgba(0,0,0,0.1);

  --body-background: rgba(12, 17, 23, 1.0);

  /* Header Background */
  --header-background: rgba(0,0,0,0.9); 
  --header-background-light: rgba(250, 250, 250, 0.9); 
  --header-background-dark: rgba(0,0,0,0.9); 

  /* Sidebar Background */
  --sidebar-background: rgba(0,0,0,0.7); 
  --sidebar-background-light: rgba(250, 250, 250, 0.7); 
  --sidebar-background-dark: rgba(0,0,0,0.7);

  /* Sidebar icons */
  --sidebar-icons: rgba(250,250,250,0.8); 
  --sidebar-icons-light: rgba(0,0,0,0.8); 
  --sidebar-icons-dark: rgba(250,250,250,0.8);

  /* Icons */
  --icon-color: rgba(250,250,250,1); 
  --icon-color-light: rgba(0,0,0,1); 
  --icon-color-dark: rgba(250,250,250,1);

  /* Toggles */
  --toggle-color: rgba(250,250,250,0.2); 
  --toggle-color-light: rgba(0,0,0,0.2); 
  --toggle-color-dark: rgba(250,250,250,0.2);

  /* Discover DropDown Background */
  --discover-background: rgba(0,0,0,0.6); 
  --discover-background-light: rgba(250, 250, 250, 0.6); 
  --discover-background-dark: rgba(0,0,0,0.6);

  /* Text colors */
  --text-color: #fff; 
  --text-light: #000000; 
  --text-dark: #fff; 

  /* Border colors */
  --border-color: rgba(250,250,250,0.3); 
  --border-light: rgba(0,0,0,0.3);
  --border-dark: rgba(250,250,250,0.3); 

  /* Strong Border colors */
  --strong-border-color: rgba(250,250,250,1); 
  --strong-border-light: rgba(0,0,0,1);
  --strong-border-dark: rgba(250,250,250,1); 


  /* Border Styles */
  --border-dashed: 2px dashed var(--border-color);
  --border-dotted: 2px dotted var(--border-dark);

  /* Border Radius */
  --border-radius-sm: 10px;
  --border-radius-md: 20px;
  --border-radius-lg: 25px;
  --border-radius-full: 50px;

  /* Button colors */
  --button-color: #36d300; 
  --button-hover-color: #2c4600;
  --button-text-color: #ffffff; 

  /* Alert colors */
  --success-color: #28a745; 
  --warning-color: #ffc107; 
  --danger-color: #dc3545; 
  --info-color: #17a2b8; 

  /* Shadows */
  --box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
  --box-shadow-hover: 0 6px 10px rgba(0, 0, 0, 0.15); 

   /* Spacing */
   --spacing-xxs: 4px;
   --spacing-xs: 8px;
   --spacing-sm: 12px;
   --spacing-md: 16px;
   --spacing-lg: 24px;
   --spacing-xl: 32px;
   --spacing-xxl: 40px;
 
   /* Font Sizes */
   --font-size-xxs: 10px;
   --font-size-xs: 12px;
   --font-size-sm: 14px;
   --font-size-md: 16px;
   --font-size-lg: 18px;
   --font-size-xl: 24px;
   --font-size-xxl: 32px;

   /* Modal Content Overlay */
   --modal-content-background: rgba(0, 0, 0, 0.8);
   --modal-content-background-light: rgba(250, 250, 250, 0.8);
   --modal-content-background-dark: rgba(0, 0, 0, 0.8);

   /* Modal Overlay */
  --modal-overlay-background: rgba(0, 0, 0, 0.75);
  --modal-overlay-backdrop-filter: blur(10px);
  --modal-overlay-z-index: 1000;

    /* Transitions */
  --transition-default: all 0.3s ease;
  --transition-fast: all 0.2s ease-in;
  --transition-slow: all 0.5s ease-out;
  --transition-fade-in: opacity 0.3s ease-in;
  --transition-fade-out: opacity 0.3s ease-out;

  /* Animations */
  --animation-fade-in: fadeIn 0.3s ease-in forwards;
  --animation-fade-out: fadeOut 0.3s ease-out forwards;
  --animation-scale-up: scaleUp 0.4s ease-in-out forwards;
  --animation-scale-down: scaleDown 0.4s ease-in-out forwards;

   /* Site Logo */
   --site-logo-url: url('https://res.cloudinary.com/dzlopmfj8/image/upload/v1724797193/BuzzVibe-TM-Gradient-outline_jfxvg6.png');

   --image-invert-filter: invert(0);


    /* Dark Mode Styles */
    body.dark-mode {
      --background-color: var(--background-dark);
      --text-color: var(--text-dark);
      --body-background:rgba(12, 17, 23, 1.0);
      --header-background: var(--header-background-dark);
      --sidebar-background: var(--sidebar-background-dark);
      --border-color: var(--border-dark);
      --strong-border-color: var(--strong-border-dark);
      --sidebar-icons: var(--sidebar-icons-dark);
      --discover-background: var(--discover-background-dark);
      --modal-content-background: var(--modal-content-background-dark);
      --icon-color: var(--icon-color-dark);
      --toggle-color: var(--toggle-color-dark);
      --image-invert-filter: invert(0);
    }

    /* Light Mode Styles */
    body.light-mode {
      --background-color: var(--background-light);
      --text-color: var(--text-light);
      --body-background: #f5f5f5;
      --header-background: var(--header-background-light);
      --sidebar-background: var(--sidebar-background-light);
      --border-color: var(--border-light);
      --strong-border-color: var(--strong-border-light);
      --sidebar-icons: var(--sidebar-icons-light);
      --discover-background: var(--discover-background-light);
      --modal-content-background: var(--modal-content-background-light);
      --icon-color: var(--icon-color-light);
      --toggle-color: var(--toggle-color-light);
      --image-invert-filter: invert(1);
    }
}

@keyframes fadeIn {
  from {
      opacity: 0;
      transform: scale(0.95);
  }
  to {
      opacity: 1;
      transform: scale(1);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Fade In from Right */
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Fade In from Bottom */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Fade In from Top */
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
      opacity: 0;
      transform: translateY(-20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

@keyframes flicker {
  from {
    opacity: 1;
    filter: drop-shadow(0 0 8px yellow);
  }
  to {
    opacity: 0.8;
    filter: drop-shadow(0 0 12px white);
  }
}

@keyframes shake {
  0%, 100% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(1px, -1px) rotate(-1deg);
  }
}

@keyframes radiate {
  to {
    transform: scale(1.5);
    opacity: 0;
  }
  }

  @keyframes pulse {
    0% {
      background-color: #e0e0e0;
    }
    50% {
      background-color: #cfcfcf;
    }
    100% {
      background-color: #e0e0e0;
    }
  }

  body {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      background: rgba(12, 17, 23, 1.0);
      background: var(--body-background);
      scroll-behavior: smooth;
      color: var(--text-color)
  }
  body {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  }

/* Memberships */

.membership-start-container {
    position: relative;
    width: 100%;
    height: 100vh;
    background: url('https://res.cloudinary.com/dzlopmfj8/image/upload/v1719146372/membershipbackground.webp') no-repeat center center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.membership-start-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
}

.membership-start-content {
    text-align: center;
    color: var(--text-color);
}

.membership-start-logo {
    max-height: 240px;
    margin-bottom: 20px;
}

.membership-start-button {
    padding: 10px 20px;
    font-size: 18px;
    color: var(--text-color);
    background-color: var(--primary-color);
    border: none;
    border-radius: var(--border-radius-full);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.membership-start-button:hover {
  background-color: var(--primary-color-hover);; 
}
.membership-agreement-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: rgba(0,0,0,0.5);
    padding: 25px;
}

.membership-agreement-content {
    background-color: var(--background-color);
    padding: 25px;
    border-radius: var(--border-radius-md);
    border: 1px solid var(--border-color);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 900px;
    width: 100%;
    text-align: left;
}

.membership-agreement-title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
}

.membership-agreement-text {
    margin-bottom: 25px;
}
.membership-agreement-text.h3 {
  margin-bottom: 25px;
  text-align: center;
}
.membership-agreement-text h2 {
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.membership-tos-text h2 {
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.membership-agreement-text.li {
  display: block;
}

.membership-agreement-signature {
    margin-bottom: 25px;
}

.membership-agreement-label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
}

.signature-canvas {
    width: 100%;
    height: 150px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
}

.membership-agreement-clear-button {
    margin-top: 15px;
    padding: 15px 25px;
    font-size: 14px;
    color: var(--text-color);
    background-color: #ff0000;
    border: none;
    border-radius: var(--border-radius-full);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.membership-agreement-clear-button:hover {
    background-color: #cc0000;
}

.membership-agreement-error {
    color: red;
    margin-top: 10px;
}

.membership-agreement-buttons {
    display: flex;
    justify-content: space-between;
}

.membership-agreement-button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: var(--border-radius-full);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.membership-agreement-button-back {
    background-color: rgba(250, 250, 250, 0.2);
    color: var(--text-color);
}

.membership-agreement-button-next {
    background-color: var(--primary-color);
    color: var(--text-color);
}

.membership-agreement-button-back:hover {
    background-color: rgba(250, 250, 250, 0.3);
}

.membership-agreement-button-next:hover {
    background-color: #32b202;
}


.membership-tos-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: rgba(0,0,0,0.5);
    padding: 25px;
}

.membership-tos-content {
    background-color: var(--background-color);
    padding: 40px;
    border-radius: var(--border-radius-md);
    border: 1px solid var(--border-color);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 900px;
    width: 100%;
    text-align: left;
}

.membership-tos-text.h4 {
  text-align: center;
}

.membership-tos-title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center
}

.membership-tos-text {
    margin-bottom: 25px;
}

.membership-tos-signature {
    margin-bottom: 25px;
}

.membership-tos-label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
}

.signature-canvas {
    width: 100%;
    height: 150px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
}

.membership-tos-clear-button {
    margin-top: 15px;
    padding: 15px 25px;
    font-size: 14px;
    font-weight: 500;
     color: var(--text-color);
    background-color: #ff0000;
    border: none;
    border-radius: var(--border-radius-full);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.membership-tos-clear-button:hover {
    background-color: #cc0000;
}

.membership-tos-error {
    color: red;
    margin-top: 10px;
}

.membership-tos-buttons {
    display: flex;
    justify-content: space-between;
}

.membership-tos-button {
    padding: 15px 25px;
    font-size: 16px;
    border: none;
    border-radius: var(--border-radius-full);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.membership-tos-button-back {
    background-color: var(--background-color);
     color: var(--text-color);
}

.membership-tos-button-next {
    background-color: var(--primary-color);
     color: var(--text-color);
}

.membership-tos-button-back:hover {
    background-color: #5a6268;
}

.membership-tos-button-next:hover {
    background-color: var(--primary-color);
}

.progress-indicator {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.progress-dot {
    width: 12px;
    height: 12px;
    margin: 0 5px;
    border-radius: 50%;
    background-color: #ccc;
    transition: background-color 0.3s ease;
}

.progress-dot.active {
    background-color: var(--primary-color);
}

.membership-personal-data-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: rgba(0,0,0,0.5);
    padding: 20px;
}

.membership-personal-data-content {
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    padding: 40px;
    border-radius: var(--border-radius-md);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 100%;
    text-align: center;
}

.membership-personal-data-title {
    font-size: 24px;
    margin-bottom: 20px;
}

.membership-personal-data-field {
    margin-bottom: 20px;
}

.membership-personal-data-field label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.membership-personal-data-field input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid var(--border-color);
    border-radius: 15px;
    background: rgba(0,0,0,0.5);
}

.membership-personal-data-button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    background-color: var(--primary-color);
    border-radius: var(--border-radius-full);
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px;
}

.membership-personal-data-button:hover {
    background-color: var(--primary-color);
    color: var(--text-color);
}

.membership-personal-data-error {
    color: red;
    margin-top: 10px;
}

.membership-personal-data-photo {
    margin-top: 20px;
    max-width: 100%;
    border-radius: var(--border-radius-sm);
}

.membership-personal-data-webcam {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.membership-summary {
    background-color: rgba(0,0,0,0.5);
    padding: 20px;
    border-radius: 8px;
    margin-top: 20px;
}

.membership-summary h3 {
    margin-bottom: 10px;
}

.membership-summary p {
    margin: 5px 0;
}

.membership-photo-preview,
.membership-signature-preview {
    max-width: 100px;
    display: block;
    margin-top: 10px;
}

/* Membership checkout */
.membership-checkout-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.membership-checkout-grid {
  display: grid;
  grid-template-columns: 2fr 1fr; 
  gap: 20px;
}

@media only screen and (max-width: 1000px) {
  .membership-checkout-grid {
    display: block;
  }
}

.membership-checkout-info {
  padding: 20px;
  background-color: var(--background-color);
  border-radius: var(--border-radius-md);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--border-color);
}

.membership-checkout-details {
  margin-bottom: 20px;
}

.membership-checkout-details h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.membership-checkout-details p {
  margin-bottom: 5px;
}

.membership-checkout-summary-container {
  padding: 20px;
  border: 1px solid var(--border-color); 
  border-radius: var(--border-radius-sm);
  margin-top: 20px;
  background-color: var(--background-color);
}

.membership-checkout-summary-title {
  font-size: 1.125rem;
  font-weight: 600;
  color: #111827;
  margin-bottom: 5px;
}

.membership-checkout-summary-description {
  margin-top: 8px;
  max-width: 640px;
  font-size: 0.875rem;
  color: #6b7280; 
}

.membership-checkout-summary-section {
  margin-top: 20px;
  border-top: 1px solid #e5e7eb;
  padding-top: 15px;
}

.membership-checkout-summary-row {
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid #e5e7eb;
}

.membership-checkout-summary-row dt {
  font-size: 0.875rem;
  font-weight: 500;
 color: var(--text-color);;
}

.membership-checkout-summary-row dd {
  margin-top: 0;
  font-size: 0.875rem;
  color: #4b5563; 
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.membership-checkout-summary-photo {
  max-width: 100px;
  border-radius: 4px;
  margin-top: 10px;
}

.membership-checkout-summary-button {
  font-weight: 500;
  color: #6366f1; /* Indigo */
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.membership-checkout-summary-button:hover {
  color: #4f46e5; /* Darker Indigo */
}


.membership-checkout-photo {
  max-width: 100px;
  margin-top: 10px;
  border-radius: 4px;
}

.membership-checkout-signature {
  margin-top: 20px;
}

.membership-checkout-signature-canvas {
  width: 100%;
  height: 150px;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-sm);
  margin-top: 10px;
}

.membership-checkout-clear-signature {
  margin-top: 10px;
  background-color: #ff4d4f;
   color: var(--text-color);
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: var(--border-radius-lg);
}

.membership-checkout-clear-signature:hover {
  background-color: #d9363e;
}

.membership-checkout-form {
  margin-top: 20px;
}

.membership-checkout-form div {
  margin-bottom: 15px;
}

.membership-checkout-input {
  width: 100%;
  padding: 10px;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-sm);
  font-size: 1rem;
}

.membership-checkout-submit-button {
  background-color: #007bff;
   color: var(--text-color);
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: var(--border-radius-full);
  font-size: 1rem;
}

.membership-checkout-submit-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.membership-checkout-back-button {
  margin-top: 10px;
  background-color: #6c757d;
   color: var(--text-color);
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: var(--border-radius-full);
  font-size: 1rem;
}

.membership-checkout-payment {
  padding: 20px;
  background-color: var(--background-color);
  border-radius: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}



@media (max-width: 799px) {
/*------LOGO BANNER------*/
    .w-full.flex.items-center.justify-between {
    height: 85px !important;
}

    .site-logo {
    height: auto;
    width: 100px !important;
}

    .bg-\[\#fff\] {
    background-color: rgba(0, 0, 0, 0.7) !important;
    border-bottom: 1px solid rgba(250, 250, 250, 0.3);
}
.sponsored-shops-widget-name {
  font-size: 75% !important;
  font-weight: 700 !important;
}
}

@media (min-width: 800px) and (max-width: 1281px) {

.mx-auto.sticky.px-4.py-1.top-0.z-50.bg-black {
    display: block !important;
}

.site-logo {
    height: auto;
    max-width: 300px !important;
}

aside.w-full.lg\:w-1\/4.p-2.bg-\[\#f5f5f5\].p-2.rounded-lg.lg\:sticky.lg\:top-24 {
    margin-bottom: 25px !important;
}

.sidebar-bottom-nav {
    background-color: rgba(0, 0, 0, 0.8) !important;
    bottom: 0;
    box-shadow: 0 -2px 10px #0000001a;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 50;
    border-top: 1px solid rgba(250, 250, 250, 0.3) !important;
}

/*------LOGO BANNER------*/
    .w-full.flex.items-center.justify-between {
    height: 85px !important;
}

    .site-logo {
    height: auto;
    width: 100px !important;
}

    .bg-\[\#fff\] {
    background-color: rgba(0, 0, 0, 0.7) !important;
    border-bottom: 1px solid rgba(250, 250, 250, 0.3);
}
	
}
	

@media (max-width: 768px) {
    .grid {
        grid-template-columns: 1fr;
    }

    .membership-summary,
    .membership-details {
        margin-top: 20px;
    }

    .btn-secondary {
        margin-top: 20px;
    }
}









.membership-photo-preview,
.membership-signature-preview {
    max-width: 100px;
    margin-top: 10px;
}

.membership-success-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: rgba(0,0,0,0.5);
   color: var(--text-color);;
    padding: 20px;
    box-sizing: border-box;
}

.membership-success-content {
    text-align: center;
    max-width: 600px;
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

.membership-success-title {
    font-size: 2em;
    margin-bottom: 20px;
}

.membership-success-message {
    font-size: 1.2em;
    margin-bottom: 10px;
}

.membership-success-email {
    font-size: 1em;
    margin-bottom: 20px;
}

.membership-success-footer {
    font-size: 0.9em;
}

.membership-success-animation {
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .membership-success-content {
        padding: 20px;
    }

    .membership-success-title {
        font-size: 1.5em;
    }

    .membership-success-message {
        font-size: 1em;
    }

    .membership-success-email {
        font-size: 0.9em;
    }
}



.qr-reader {
    width: 425px;
    height: 100vh;
    margin: 0 auto;
    position: relative;
  }
  
  .qr-reader video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .qr-reader .qr-box {
    width: 100% !important;
    left: 0 !important;
  }
  
  .qr-reader .qr-frame {
    position: absolute;
    fill: none;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  
  /* Media Queries for mobile screens */
  @media (max-width: 426px) {
    .qr-reader {
      width: 100%;
    }
  }

/* StudioAdminDashboard.css */
.membership-admin-dashboard {
    padding: 20px;
    background-color: rgba(0,0,0,0.5);
   color: var(--text-color);;
    font-family: Arial, sans-serif;
}

.membership-admin-dashboard h1 {
    text-align: center;
    margin-bottom: 20px;
   color: var(--text-color);;
}

.membership-qr-scanner-button {
    display: block;
    margin: 0 auto 20px;
    padding: 10px 20px;
    font-size: 16px;
   color: var(--text-color);;
    background-color: var(--primary-color);
    border: none;
    border-radius: var(--border-radius-full);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.membership-qr-scanner-button:hover {
    background-color: #3d6000;
}

.membership-members-table {
  width: 100%;
  border-collapse: collapse;
  border-radius: var(--border-radius-lg);
  margin: 0 auto;
  background-color: var(--background-color);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.membership-members-table th,
.membership-members-table td {
  padding: 16px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.membership-members-table th {
  background-color: var(--background-color);
  font-weight: bold;
 color: var(--text-color);;
}

.membership-members-table td {
  font-size: 14px;
 color: var(--text-color);;
}

/* Flexbox layout for member info */
.member-info {
  display: flex;
  align-items: center;
}

.member-details {
  display: flex;
  align-items: center;
}

.member-photo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 12px;
}

.member-text {
  display: flex;
  flex-direction: column;
}

.member-name {
  font-weight: 600;
  font-size: 20px;
 color: var(--text-color);;
}

.member-email {
 color: var(--text-color);;
  font-size: 12px;
  margin-top: 4px;
}

/* Status Colors */
.membership-member-signed-in {
  background-color: var(--primary-color);
}

.membership-member-canceled {
  background-color: #fe3a3a47;
}

/* Hover effect */
.membership-members-table tr:hover {
  background-color: var(--background-color);
  transition: background-color 0.2s ease;
}

.membership-admin-logo {
    max-height: 140px;
    margin-bottom: 20px;
}

/* Card-like layout for small screens */
@media (max-width: 768px) {
  .membership-members-table {
      display: none; 
  }
  .membership-member-signed-in {
    background-color: #28a745 !important; 
}

.membership-member-canceled {
    background-color: #e23333 !important; 
}

  .membership-card-list {
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;
  }

  .membership-card {
      background-color: rgba(255, 255, 255, 0.1);
      border: 1px solid var(--border-color);
      border-radius: var(--border-radius-md);
      padding: 20px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
     color: var(--text-color);;
      display: flex; 
      align-items: center;
      transition: background-color 0.3s ease;
  }

  .membership-card img {
      width: 90px;
      height: 90px; 
      border-radius: 50%;
      border: 1px solid var(--border-color);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      margin-right: 20px;
  }

  .membership-card-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 5px; 
  }

  .membership-card h3 {
      font-size: 16px;
      margin: 0;
  }

  .membership-card span {
      font-size: 14px;
      color: #ccc;
  }
}

/* Hide card layout on larger screens */
@media (min-width: 769px) {
  .membership-card-list {
      display: none; /* Hide the card layout on larger screens */
  }
}



/* MemberDetails.css */

.member-details-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 20px;
    box-sizing: border-box;
}

.member-details-modal {
    background: #2c2c2c;
   color: var(--text-color);;
    padding: 25px;
    border-radius: 15px;
    max-width: 600px;
    width: 100%;
    position: relative;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    animation: fadeIn 0.3s ease-in-out;
}

.member-close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
   color: var(--text-color);;
    font-size: 24px;
    cursor: pointer;
    transition: color 0.3s;
}

.member-close-button:hover {
    color: #ff6b6b;
}

.member-photo-large {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
    border: 3px solid #fff;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

li {
    margin-bottom: 12px;
    font-size: 16px;
    justify-content: space-between;
    border-bottom: 1px solid #444;
    padding-bottom: 6px;
}

li strong {
    color: #ff6b6b;
}

.cancel-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.cancel-modal {
    background: rgba(0,0,0,0.5);
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 400px;
    width: 100%;
}

.cancel-confirm-button, .cancel-close-button {
    margin: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.cancel-confirm-button {
    background-color: #ff4d4d;
     color: var(--text-color);
}

.cancel-confirm-button:hover {
    background-color: #ff1a1a;
}

.cancel-close-button {
    background-color: var(--background-color);
     color: var(--text-color);
}

.cancel-close-button:hover {
    background-color: #5a6268;
}
.member-reinstate-button {
    background-color: var(--primary-color);
     color: var(--text-color);
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
}

.member-reinstate-button:hover {
    background-color: #45a049;
}
.membership-search-bar {
    width: 100%;
    padding: 12px 20px;
    margin-bottom: 20px;
    font-size: 16px;
    background-color: rgba(0,0,0,0.5);
   color: var(--text-color);;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius-lg);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.membership-search-bar:focus {
    border-color: var(--primary-color);
    box-shadow: 0 2px 8px rgba(0, 123, 255, 0.2);
    outline: none;
}

.membership-agreement-checkbox {
    display: flex;
    align-items: center;
    margin: 20px 0; 
}

.membership-agreement-checkbox label {
    font-size: 16px;
    color: var(--text-color); 
    cursor: pointer;
    user-select: none;
}

.membership-agreement-checkbox input[type="checkbox"] {
    margin-right: 10px; 
    width: 20px;
    height: 20px; 
    cursor: pointer;
}




@media (max-width: 768px) {
    .member-details-modal {
        padding: 20px;
    }

    .member-photo-large {
        width: 100px;
        height: 100px;
    }

    li {
        font-size: 14px;
    }
}


/* SignInModal.css */
/* Overlay */
.member-sign-in-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal Container */
.member-sign-in-modal {
    background: #2c2c2c;
   color: var(--text-color);;
    padding: 25px;
    border-radius: 15px;
    max-width: 400px;
    width: 100%;
    text-align: center;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    animation: fadeIn 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center; 
}



/* Image Styling */
.member-sign-in-modal img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Button Styling */
.member-sign-in-modal button {
    background: linear-gradient(135deg, #ff6b6b, #ff4b4b);
   color: var(--text-color);;
    border: none;
    padding: 10px 20px;
    border-radius: var(--border-radius-full);
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
    font-size: 16px;
    font-weight: 600;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.member-sign-in-modal button:hover {
    background: linear-gradient(135deg, #ff4b4b, #ff2b2b);
    transform: translateY(-2px);
}

/* Heading Styling */
.member-sign-in-modal h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

/* Paragraph Styling */
.member-sign-in-modal p {
    font-size: 18px;
    margin-bottom: 20px;
}


.membership-agreement-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin-top: 20px;
    font-family: 'Arial', sans-serif;
    background-color: rgba(0,0,0,0.5);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    overflow-x: auto; 
}

.membership-agreement-table th,
.membership-agreement-table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
}

.membership-agreement-table th {
    background-color: rgba(0,0,0,0.5);
   color: var(--text-color);;
    font-weight: 600;
}

.membership-agreement-table tr {
    transition: background-color 0.3s;
}

.membership-agreement-table tr:hover {
    background-color: green;
}

.membership-agreement-table tbody tr:last-child td {
    border-bottom: none;
}

@media screen and (max-width: 600px) {
    .membership-agreement-table thead {
        display: none; 
    }

    .membership-agreement-table, 
    .membership-agreement-table tbody, 
    .membership-agreement-table tr, 
    .membership-agreement-table td {
        display: block; 
        width: 100%; 
    }

    .membership-agreement-table tr {
        margin-bottom: 15px; 
    }

    .membership-agreement-table td {
        text-align: right;
        padding-left: 50%;
        position: relative;
    }

    .membership-agreement-table td::before {
        content: attr(data-label); 
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        font-weight: bold;
        text-align: left;
    }
}

.member-cancel-button {
    background-color: #ff4d4d;
    color: var(--text-color);
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
}

.member-cancel-button:hover {
    background-color: #ff1a1a;
}




/* Responsive Design */
@media (max-width: 768px) {
    .membership-members-table th, .membership-members-table td {
        padding: 8px;
        font-size: 14px;
    }

    .membership-qr-scanner-button {
        font-size: 16px;
        padding: 10px 20px;
    }
}

@media (max-width: 480px) {
    .membership-admin-dashboard h1 {
        font-size: 24px;
    }

    .membership-members-table th, .membership-members-table td {
        padding: 6px;
        font-size: 12px;
    }
}







/* Ryan's CSS additions */

.widget-wrapper {
  padding: 10px;
  border-radius: var(--border-radius-sm);
  background-color: transparent;
}

/* User sign up page */
.user-sign-up-container {
    min-height: 100vh;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3rem 1.5rem;
  }
  
  .user-sign-up-header {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .user-sign-up-title {
    font-size: 2rem;
    font-weight: 800;
   color: var(--text-color);;
  }
  
  .user-sign-up-form-container {
    max-width: 32rem;
    width: 100%;
    margin: 0 auto;
  }
  
  .user-sign-up-form {
    background-color: var(--background-color);
    padding: 2rem;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    border-radius: var(--border-radius-md);
  }
  
  .user-sign-up-form-elements {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .user-sign-up-label {
    font-size: 0.875rem;
    font-weight: 500;
   color: var(--text-color);;
  }
  
  .user-sign-up-input-container {
    margin-top: 0.25rem;
  }
  
  .user-sign-up-input {
    width: 100%;
    padding: 0.5rem 0.75rem;
    border: 1px solid #d1d5db;
    border-radius: var(--border-radius-lg);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    font-size: 0.875rem;
   color: var(--text-color);;
    background-color: var(--background-color);
    transition: border-color 0.2s ease, box-shadow 0.2s ease;
  }
  
  .user-sign-up-input:focus {
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.2);
    outline: none;
  }
  
  .user-sign-up-relative {
    position: relative;
  }
  
  .user-sign-up-icon {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    cursor: pointer;
    color: #9ca3af;
  }
  
  .user-sign-up-file-container {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .user-sign-up-avatar-preview,
  .user-sign-up-banner-preview {
    display: inline-block;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    overflow: hidden;
    background-color: #e5e7eb;
  }
  
  .user-sign-up-avatar {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  
  .user-sign-up-avatar-icon {
    height: 2rem;
    width: 2rem;
    color: #9ca3af;
  }
  
  .user-sign-up-file-label {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    border: 1px solid var(--primary-color);
    border-radius: var(--border-radius-lg);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    font-size: 0.875rem;
    font-weight: 500;
   color: var(--text-color);;
    background-color: var(--background-color);
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .user-sign-up-file-label:hover {
    background-color: var(--primary-color-hover);
  }
  
  .user-sign-up-file-input {
    display: none;
  }
  
  .user-sign-up-banner-placeholder {
    height: 2rem;
    width: 2rem;
    background-color: #e5e7eb;
    border-radius: 50%;
  }
  
  .user-sign-up-submit-button {
    width: 100%;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: var(--border-radius-full);
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--text-color);
    background-color: var(--primary-color);
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .user-sign-up-submit-button:hover {
    background-color: var(--primary-color-hover);
  }
  
  .user-sign-up-footer {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 1.5rem;
    font-size: 0.875rem;
    color: #6b7280;
  }
  
  .user-sign-up-link {
    color: var(--primary-color);
    text-decoration: none;
  }
  
  .user-sign-up-link:hover {
    text-decoration: underline;
  }

.user-sign-up-tos-container {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 0.875rem; 
  color: var(--text-color); 
}

.user-sign-up-tos-label {
  display: flex;
  align-items: center;
  gap: 0.5rem; 
  cursor: pointer;
}

.user-sign-up-tos-checkbox {
  width: 1rem; 
  height: 1rem; 
  accent-color: var(--primary-color); 
  border: 1px solid var(--border-color);
  border-radius: 0.25rem; 
  cursor: pointer;
}

.user-sign-up-tos-text {
 color: var(--text-color);; 
  line-height: 1.2;
}

.user-sign-up-tos-link {
  color: var(--primary-color); 
  text-decoration: none;
  font-weight: 600; 
}

.user-sign-up-tos-link:hover {
  text-decoration: underline;
}

/* Submit button disabled state */
.user-sign-up-submit-button:disabled {
  background-color: #ccc; 
  color: #6c757d;
  cursor: not-allowed;
  border: 1px solid #ccc;
}

  

/* Activation page */
.activation-page {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.5);
   color: var(--text-color);;
    font-family: Arial, sans-serif;
  }
  
  .activation-signin-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: var(--primary-color);
     color: var(--text-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .activation-signin-button:hover {
    background-color: var(--primary-color-hover);
  }
  

/* Switcheroo */
.switcheroo-avatar-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 42px;
  height: 42px;
  position: relative;
}

.switcheroo-current-profile-avatar {
  height: 42px;
  width: 42px;
  max-width: 42px;
  border-radius: 100%;
  border: 2px solid var(--strong-border-color);
}


.switcheroo-swap-icon {
  position: absolute;
  right: -5px;
  bottom: -5px;
  border-radius: 50%;
  padding: 3px;
  font-size: 14px;
   color: var(--text-color);
  background-color: var(--primary-color);
  border: 1px solid #00000080 !important;
}

.switcheroo-modal-overlay {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.8) 100%); 
  backdrop-filter: var(--modal-overlay-backdrop-filter); 
}

.switcheroo-modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 420px; 
  max-width: 90%; 
  background-color: var(--sidebar-background); 
  font-size: 16px !important;
  font-weight: 700;
  box-shadow: 5px 25px 25px 25px rgba(0, 0, 0, 0.9); 
  padding: 24px;
  border-radius: 24px;
  overflow: hidden;
  border: 1px solid var(--border-color);
}


.switcheroo-close-icon {
  background: none;
  border: none;
 color: var(--text-color);;
  cursor: pointer;
  font-size: 20px;
  position: absolute;
  right: 15px;
  top: 8px;
  font-weight: 100;
  height: 25px;
  width: 25px;
  background-color: var(--background-color);
  border-radius: 50%;
}


.switcheroo-modal-item {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  margin: 5px 0;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
   color: var(--text-color);
}

.switcheroo-modal-item:hover {
  background-color: var(--background-color);
}

.switcheroo-modal-item-image {
  height: 25px;
  width: 25px;
  border-radius: 100%;
  object-fit: cover;
  margin-right: 10px;
}

.switcheroo-check-icon {
  height: 24px;
  width: 24px;
  margin-left: auto;
  color: var(--primary-color);
}

/* avatar for social */
  .avatar-component-relative {
    position: relative;
  }
  
  .avatar-component-inline-block {
    display: inline-block;
  }
  
  .avatar-component-text-left {
    text-align: left;
  }

  .avatar-component-avatar {
    width: 45px ;
    height: 45px;
    margin-top: 2px;
    margin-left: 10px;
    border-radius: 100%;
    border: 2px solid rgba(250, 250, 250, 0.7);
    cursor: pointer;
    max-width: none;
}
@media (max-width: 480px) {
  .avatar-component-avatar {
    width: 45px ;
    height: 45px;
    margin-top: 2px;
    margin-left: 10px;
}
}
  
  .avatar-component-subscription-icon {
    position: absolute;
    top: 0;
    right: 0;
  }
  
  .avatar-component-subscription-img {
    height: 24px; 
    width: 24px;
  }
  

  /* Sidebar */
  .sidebar-relative {
    position: relative;
  }
  
  .sidebar-icon {
    height: 1.5rem;
    width: 1.5rem;
  }
  
  .sidebar-badge {
    position: absolute;
    top: -0.25rem;
    right: -0.25rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem;
    font-size: 0.75rem;
    font-weight: bold;
    color: #d1fae5;
    background-color: #059669;
    border-radius: 9999px;
  }
  
  .sidebar-bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 50;
    background-color: #1f2937;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .sidebar-nav {
    display: flex;
    justify-content: space-around;
    padding: 0.5rem;
  }
  
  .sidebar-nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 0.75rem;
    text-align: center;
    width: 100%;
  }
  
  .sidebar-text-current {
    color: #059669;
  }
  
  .sidebar-text-default {
    color: #6b7280;
  }
  
  .sidebar-icon-current {
    color: #047857;
  }
  
  .sidebar-icon-default {
    color: #9ca3af;
  }
  
  .sidebar-profile-switch {
    padding: 0.5rem;
  }
  
  .sidebar-desktop {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 50;
    align-items: center;
  }
  
  @media (min-width: 1024px) {
    .sidebar-desktop {
      display: flex;
    }

    .lg\:px-8 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
    }

    .lg\:mx-auto {
        margin-left: 4.5rem !important;
        margin-right: 4.5rem !important;
    }

    .text-white.font-bold {
    font-size: 20px !important;
    }
    .lg\:mx-auto {
        margin-left: 4.5rem !important;
        margin-right: 4.5rem !important;
    }

    .lg\:max-w-7xl {
        max-width: 100% !important;
    }
	  
    .lg\:px-24 {
        padding: 25px 7% !important;
    }
}
  

  .sidebar-container {
    background-color: var(--sidebar-background);
    border-radius: 0 .5rem .5rem 0;
    box-shadow: 2px 0 10px #0000001a;
    width: 4rem;
}
  
.sidebar-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
  border: 1px solid var(--border-color);
  border-left: 0 !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

  
  .sidebar-nav-large {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    padding-top: 1rem;
  }
  
  .sidebar-nav-item-large {
    display: flex;
    align-items: center;
    text-decoration: none;
    width: 100%;
    padding: 0.5rem;
    border-left-width: 4px;
    color: var(--sidebar-icons);
  }

  .sidebar-nav-item-current {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: var(--text-color);
}
  
  .sidebar-nav-item-default {
    border-color: transparent;
  }
  
  .sidebar-nav-item-large:hover {
    background-color: #374151;
  }
  
  .sidebar-icon-large {
    margin-right: 0.75rem;
    height: 1.5rem;
    width: 1.5rem;
    color: var(--sidebar-icons);
  }
  
  /* Ensure bottom nav is hidden on larger screens */
  @media (min-width: 1024px) {
    .lg-hidden {
      display: none;
    }
  }

  .notification-sidebar-dialog {
  position: relative;
  z-index: 30;
}

.notification-sidebar-overlay {
  position: fixed;
  inset: 0;
}

.notification-sidebar-container {
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.notification-sidebar-inner-container {
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.notification-sidebar-panel-container {
    pointer-events: none;
    position: fixed;
    top: 10vh;
    bottom: 0;
    right: 0;
    display: flex;
    max-width: 100%;
    padding-left: 10px;
  }

.notification-sidebar-enter {
  transition: transform ease-in-out 0.5s;
}

.notification-sidebar-enterFrom {
  transform: translateX(100%);
}

.notification-sidebar-enterTo {
  transform: translateX(0);
}

.notification-sidebar-leave {
  transition: transform ease-in-out 0.5s;
}

.notification-sidebar-leaveFrom {
  transform: translateX(0);
}

.notification-sidebar-leaveTo {
  transform: translateX(100%);
}

.notification-sidebar-panel {
  background-color: var(--sidebar-background);
  backdrop-filter: var(--modal-overlay-backdrop-filter);
  border-left: 1px solid var(--border-color);
  box-shadow: none;
  height: 100%;
  max-width: 28rem;
  overflow-y: auto;
  padding: 0px;
  position: fixed;
  right: 0;
  top: 10%;
  margin-top: 5px;
  pointer-events: auto;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .notification-sidebar-panel {
      width: 50%; 
      padding: 15px;
  }
}

@media (max-width: 768px) {
  .notification-sidebar-panel {
      width: 100%;
      padding: 10px;
      top: 7%;
      border: none;
  }
}


.notification-sidebar-panel-content {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  background-color: rgb(250, 250, 250, 0.2);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.notification-sidebar-header {
  padding: 24px 16px;
}

.notification-sidebar-header-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notification-sidebar-title {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
   color: var(--text-color);
}

.notification-sidebar-close-container {
  margin-left: 12px;
  display: flex;
  height: 1.75rem;
  align-items: center;
}

.notification-sidebar-close-button {
  background-color: rgba(250, 250, 250, 0.2) !important;
  border: 1px solid rgba(250, 250, 250, 0.5) !important;
 color: var(--text-color);;
  border-radius: var(--border-radius-full) !important;
  padding: 7px !important;
  position: absolute !important;
  right: 15px;
  top: 10px;
  cursor: pointer !important;
  transition: background-color 0.3s ease;
}

@media (max-width: 768px) {
  .notification-sidebar-close-button {
    position: absolute;
    cursor: pointer !important;
  }
}

.notification-sidebar-close-button:hover {
   color: var(--text-color);
}

.notification-sidebar-close-button:focus {
  outline: none;
}

.notification-sidebar-close-overlay {
  position: absolute;
  inset: -10px;
}

.notification-sidebar-close-icon {
  height: 1.5rem;
  width: 1.5rem;
}

.notification-sidebar-header-spacer {
  margin-top: 4px;
}

.notification-sidebar-body {
  position: relative;
  flex: 1;
  padding: 24px 16px;
}

.notification-feed-flow-root {
    display: flow-root;
}

.notification-feed-list {
    margin-bottom: -2rem;
}

.notification-feed-item {
    position: relative;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
}

.notification-feed-connector {
    position: absolute;
    left: 1.25rem;
    top: 1.25rem;
    margin-left: -1px;
    height: 100%;
    width: 0.125rem;
    background-color: #e5e7eb; 
}

.notification-feed-icon {
  color: #6b7280;
  height: 1.25rem;
  width: 1.25rem;
  float: left !important;
  margin-left: 0px !important;
  margin-right: 15px !important;
}

.notification-feed-icon-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 80px;
}

.notification-feed-avatar {
    display: flex;
    height: 2.5rem; 
    width: 2.5rem; 
    align-items: center;
    justify-content: center;
    border-radius: 9999px; 
}

.notification-feed-content-wrapper {
    display: flex;
    align-items: start;
    padding-left: 0.75rem;
    padding-right: 0.75rem; 
}

.notification-feed-text-wrapper {
    display: flex;
    min-width: 0;
    flex: 1 1;
    min-width: 0;
    padding-left: 10px;
    font-size: 12px;
}

.notification-feed-text {
  max-width: 150px;
  display: flex;
  flex-direction: column;
}

.friend-list-panel-root {
    position: relative;
    z-index: 10;
  }
  
  .friend-list-panel-fixed-inset {
    position: fixed;
    inset: 0;
  }
  
  .friend-list-panel-overflow-hidden {
    overflow: hidden;
  }
  
  .friend-list-panel-absolute-inset {
    position: absolute;
    inset: 0;
    overflow: hidden;
  }
  
  .friend-list-panel-pointer-events-none {
    pointer-events: none;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    max-width: 100%;
    padding-left: 10px;
  }
  
  .friend-list-panel-transition-enter {
    transform: translateX(100%);
    transition: transform 500ms ease-in-out;
  }
  
  .friend-list-panel-transition-enter-active {
    transform: translateX(0);
  }
  
  .friend-list-panel-transition-exit {
    transform: translateX(0);
    transition: transform 500ms ease-in-out;
  }
  
  .friend-list-panel-transition-exit-active {
    transform: translateX(100%);
  }
  
  .friend-list-panel-panel {
    pointer-events: auto;
    width: 100%;
    max-width: 28rem;
  }
  
  .friend-list-panel-panel-content {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    background-color: rgba(250, 250, 250, 0.5);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height: 100%;
  }
  
  .friend-list-panel-header {
    padding: 1.5rem;
  }
  
  .friend-list-panel-header-content {
    display: flex;
    align-items: start;
    justify-content: space-between;
  }
  
  .friend-list-panel-title {
    font-size: 1.125rem;
    font-weight: 500;
    color: #1a202c;
  }
  
  .friend-list-panel-close-button-container {
    margin-left: 0.75rem;
    display: flex;
    height: 1.75rem;
    align-items: center;
  }
  
  .friend-list-panel-close-button {
    border-radius: 0.375rem;
    color: #a0aec0;
    transition: color 0.15s ease-in;
  }
  
  .friend-list-panel-close-button:hover {
    color: #718096;
  }
  
  .friend-list-panel-close-button:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(99, 102, 241, 0.5);
  }
  .friend-list-widget-wrapper {
    margin-bottom: 1rem;
  }
  
  .friend-list-widget-search {
    margin-bottom: 1.5rem;
  }
  
  .friend-list-widget-box {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .friend-list-widget-title {
    color: #2c4a2e;
    font-weight: 500;
  }

  .profile-page {
    background-color: black;
  }
  
  .profile-header {
    background-color: var(--primary-color);
    height: 0.3rem;
  }
  
  .profile-avatar-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.profile-banner-wrapper {
  position: relative; 
}



.profile-friends {
  display: flex;
  width: 100%;
  justify-content: center;
}

.profile-avatar {
  border-radius: 50%; 
  width: 150px; 
  height: auto !important; 
  border: 3px solid #fff !important; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3) !important; 
  margin-bottom: 15px; 
}

@media (max-width: 1200px) { /* Large desktops */
  .profile-avatar {
    width: 140px;
    margin-bottom: 10px;
  }
}

@media (max-width: 1024px) { /* Tablets in landscape */
  .profile-avatar {
    width: 130px;
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) { /* Tablets in portrait */
  .profile-avatar-wrapper {
    align-items: center;
    margin-right: 0rem;
    margin-top: -20px;
  }
  .profile-avatar {
    width: 120px;
    margin-bottom: 8px;
  }
}

@media (max-width: 480px) { /* Small mobile devices */
  .profile-avatar-wrapper {
    align-items: center;
    margin-right: 0rem;
    margin-top: -30px;
  }
  .profile-avatar {
    margin-bottom: 5px;
    width: 100px;
  }
  .profile-friends {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 52px;
  }
}

@media (max-width: 320px) { /* Extra small mobile devices */
  .profile-avatar {
    width: 80px;
    margin-bottom: 5px;
  }
}

  
  .profile-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }

  .profile-info-container {
    position: absolute;
    top: 5%; 
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    grid-column:  2 / 3;
    z-index: 10;
    width: 100%;
  }

  @media (max-width: 1024px) {
    .profile-info-container {
      position: absolute;
      top: 5%; 
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      grid-column:  2 / 3;
      z-index: 10;
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    .profile-info-container {
      position: absolute;
      top: 15%; 
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      grid-column:  2 / 3;
      z-index: 10;
      width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .profile-info-container {
      position: absolute;
      top: 25%; 
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      grid-column:  2 / 3;
      z-index: 10;
      width: 100%;
    }
  }
  
  .profile-name {
    font-size: 2rem;
    font-weight: bold;
    color: #48bb78;
  }
  
  .profile-handle {
    color: var(--text-color);
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 10px;
    margin-left: 0px;
    margin-top: 0;
    cursor: pointer ;
  }  
  
  .profile-followers {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .profile-followers-add-button {
    padding-bottom: 1rem;
  }

  @media (max-width: 480px) {
    .profile-followers-add-button {
      margin-bottom: 10px;
    }
    .profile-handle {
      margin-bottom: 0px;
    }  
  }
  
  /* Profile Main Content */

  .profile-main-content {
    display: flex;
    gap: 15px;
    margin-bottom: 25px ;
    margin-top: 25px;
    padding-left: 7% ;
    padding-right: 7% ;
    width: 100%;
    justify-content: center;
}
  
  .profile-sidebar-left {
    order: 2; 
    width: 25% !important; 
    padding: 0px 15px !important;
    border-radius: 0px !important; 
    background-color: transparent !important; 
    border: none !important; 
    height: inherit !important; 
}

.shop-profile-sidebar-left {
  order: 2; 
  width: 25% !important; 
  padding: 0px 15px !important;
  border-radius: 0px !important; 
  background-color: transparent !important; 
  border: none !important; 
  height: inherit !important; 
}
@media (max-width: 768px) {
  .shop-profile-sidebar-left {
    display: none;
  }
  .shop-profile-sidebar-right {
    display: none;
  }
  .profile-main-content {
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 0px;
  }
}

  .profile-main-area {
    order: 2;
    flex-grow: 1;
    border-left: 1px solid var(--border-color);
    border-right: 1px solid var(--border-color);
  }

  .shop-profile-main-area {
    order: 2;
    flex-grow: 1;
    overflow: hidden;
    border-left: 1px solid var(--border-color);
    border-right: 1px solid var(--border-color);
  }
  @media (max-width: 768px) {
    .shop-profile-main-area {
      order: 2;
      flex-grow: 1;
      padding: 0rem;
      overflow: hidden;
    }
}
  
  .profile-sidebar-right {
    order: 3; 
    width: 100%; 
    background-color: transparent !important; 
    border-radius: 0px !important; 
    margin: 0 !important; 
    max-width: 25% !important; 
    
}

  .shop-profile-sidebar-right {
    order: 3; 
    width: 100%; 
    padding: 0px 15px !important;
    background-color: transparent !important; 
    border-radius: 0px !important; 
    margin: 0 !important; 
    max-width: 25% !important; 
    border-left: 1px solid var(--border-color) !important;
    
  }

  
  @media (min-width: 1024px) {
  
    .profile-sidebar-left {
      order: 1;
      width: 25%;
    }
  
    .profile-main-area {
      order: 2;
      width: 40%;
    }
  
    .profile-sidebar-right {
      order: 3;
      width: 25%;
    }
  }

    
  @media (max-width: 480px) {
  
    .profile-sidebar-left {
      display: none;
    }
  
    .profile-main-area {
      margin-top: 35px;
      width: 100%;
    }
  
    .profile-sidebar-right {
      display: none;
  }
}
  

  /* UserProfileData.css */
.user-profile-data-content-container {
    padding: 0px;
  }
  
  .user-profile-data-tab-navigation {
    border: 1px solid var(--border-color) !important;
    border-radius: 10px !important;
    padding: 0px !important;
    overflow: hidden;
  }
  
  .user-profile-data-tabs {
    display: flex;
    gap: 20px;
    overflow-x: auto;
  }
  
  .user-profile-data-tab-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 20px;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 2px solid transparent;
  }
  
  .user-profile-data-tab-active {
    color: var(--primary-color);
    border-bottom-color: var(--primary-color);
  }

  .user-profile-data-tab-inactive {
    color: #6b7280;
  }
  
  .user-profile-data-tab-icon {
    width: 24px;
    height: 24px;
    margin-bottom: 5px;
  }
  
  .user-profile-data-tab-content {
    margin-top: 20px;
  }

  /* Shop Profile Data  */
.shop-profile-data-content-container {
    padding: 20px;
  }
  
  .shop-profile-data-tab-navigation {
    border-radius: 10px ;
    padding: 15px 25px ;
    display: inline-block ;
    overflow: scroll;
    width: 100%;
}
@media (max-width: 480px) {
  .shop-profile-data-tab-navigation {
    border-radius: 0px ;
}
}
  
  .shop-profile-data-tabs {
    display: flex;
    gap: 20px;
  }
  
  .shop-profile-data-tab-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 20px;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 2px solid transparent;
  }
  
  .shop-profile-data-tab-active {
    border-bottom: none;
    color: var(--primary-color);
    font-weight: 700;
}
  
  .shop-profile-data-tab-inactive {
    color: var(--text-color);
  }
  
  .shop-profile-data-tab-icon {
    width: 24px;
    height: 24px;
    margin-bottom: 5px;
  }
  
  .shop-profile-data-events-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 25px;
    margin-bottom: 12px;
  }
  
  .shop-profile-data-no-events,
  .shop-profile-data-no-reviews {
    width: 100%;
    text-align: center;
    padding: 5px;
    font-size: 18px;
  }
  
  .shop-profile-data-review {
    display: flex;
    margin: 10px 0;
  }
  
  .shop-profile-data-review-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  
  .shop-profile-data-review-content {
    padding-left: 10px;
  }
  
  .shop-profile-data-review-header {
    display: flex;
    align-items: center;
  }
  
  .shop-profile-data-review-user {
    font-weight: 600;
    padding-right: 10px;
  }
  
  .shop-profile-data-review-comment {
    font-weight: 400;
    color: #000000a7;
  }
  
  .shop-profile-data-review-date {
    color: #000000a7;
    font-size: 14px;
  }
  
  .shop-profile-data-dashboard-link {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    border: 1px solid transparent;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
    color: var(--text-color);
    background-color: var(--primary-color);
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .shop-profile-data-dashboard-link:hover {
    background-color: var(--primary-color-hover);
  }

  /* ShopInfoSidebar.css */
.shop-info-sidebar-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    background-color: rgba(0,0,0,0.5);
    border-radius: var(--border-radius-sm);
    border-bottom: 1px solid #fff;
  }
  
  .shop-info-sidebar-top-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border-radius: var(--border-radius-sm);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    padding: 20px;
  }
  
  .shop-info-sidebar-avatar-container {
    position: relative;
    width: 150px;
    height: 150px;
    margin-bottom: 10px;
  }

  @media (max-width: 1024px){
    .shop-info-sidebar-avatar-container {
      height: 120px;
      margin-bottom: 10px;
      position: relative;
      width: 120px;
  }
  }


  @media (max-width: 768px) {
    .shop-info-sidebar-avatar-container {
      width: 120px;
      height: 120px;
    }
  }
  
  /* Small screens (mobile devices) */
  @media (max-width: 480px) {
    .shop-info-sidebar-avatar-container {
      width: 150px;
      height: 150px;
    }
  }
  
  .shop-info-sidebar-avatar {
    border-radius: 50%;
    height: 100%;
    object-fit: cover;
    width: 100%;
    border: 3px solid rgba(250, 250, 250, 0.7) !important;
}
.shop-info-sidebar-avatar-edit {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 1px solid rgba(250, 250, 250, 0.5) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

  
  .shop-info-sidebar-avatar-input {
    display: none;
  }
  
  .shop-info-sidebar-avatar-label {
    color: #fff;
    font-size: 24px;
  }
  
  .shop-info-sidebar-name {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: #fff;
  }

  @media (max-width: 768px) {
    .shop-info-sidebar-name {
      font-size: 15px;
      font-weight: bold;
      text-align: center;
    }
  }
  @media (max-width: 480px) {
    .shop-info-sidebar-name {
      font-size: 15px;
      font-weight: bold;
      text-align: center;
      margin-top: 5px;
    }
  }
  
  .shop-info-sidebar-follow-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    z-index: 1;
  }

  @media (max-width: 768px) {
    .shop-info-sidebar-follow-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
    }
  }
  @media (max-width: 480px) {
    .shop-info-sidebar-follow-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0;
      justify-content: space-between;
      margin-top: 50px;
      width: 100%;
    }
  }
  
  .shop-info-sidebar-middle-row {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .shop-info-sidebar-description {
    font-size: 16px;
    color: #000000a6;
  }
  
  .shop-info-sidebar-detail {
    display: flex;
    flex-direction: column;
  }
  
  .shop-info-sidebar-detail h5 {
    font-size: 16px;
    font-weight: 600;
  }
  
  .shop-info-sidebar-detail h4 {
    font-size: 14px;
    color: #000000a6;
  }
  
  .shop-info-sidebar-bottom-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  .shop-info-sidebar-bottom-row h5 {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  }
  
  .shop-info-sidebar-actions {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .shop-info-sidebar-action-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    background-color: #4f46e5;
    color: var(--text-color);
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
  }
  
  .shop-info-sidebar-action-button:hover {
    background-color: var(--primary-color-hover);
  }
  
  
  
    /* Main Feed  */
  .main-feed-page {
    height: 100%; 
    touch-action: auto;
  }
  
  .main-feed-page-scrollable {
    overflow-y: auto;
    height: 85vh; 
  }
  
  
  .main-feed-page-scrollable::-webkit-scrollbar {
    width: 0px;
    background: transparent; 
  }
  
  
  .main-feed-page-scrollable {
    -ms-overflow-style: none;  
    scrollbar-width: none; 
  }
  
  .main-feed-page-main-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 600px;
    order: 2;
    border-right: 1px solid var(--border-color);
    border-left: 1px solid var(--border-color);
  }
  .main-feed-page-scrollable {
    overflow-y: auto;
  }
  
  .main-feed-page-sticky-sidebar {
    height: 85vh;
    overflow-y: auto;
  }
  
  
    


  .Hero-all-products-container {
    position: relative;
    height: 20vh;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden; /* Ensure the gradient and content fit nicely */
  }
  
  .Hero-all-products-container::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 24%; 
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
    z-index: 1; /* Keep the gradient above the background */
  }
  
  @media (min-width: 800px) {
    .Hero-all-products-container {
      height: 25vh;
    }
  }
  
  .Hero-all-products-section {
    position: relative; 
    z-index: 2; 
    width: 90%;
    animation: fadeIn 1s ease-out; 
  }
  
  @media (min-width: 800px) {
    .Hero-all-products-section {
      width: 60%;
    }
  }
  
  .Hero-all-products-title {
    font-size: 35px;
    line-height: 1.2;
    color: var(--text-color);
    font-weight: 600;
    text-transform: capitalize;
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); 
    opacity: 0;
    animation: fadeInUp 1.5s ease-out forwards;
  }
  
  @media (min-width: 800px) {
    .Hero-all-products-title {
      font-size: 60px;
    }
  }
  
  .Hero-all-products-subtitle {
    font-size: 18px;
    color: var(--text-color);
    margin-top: 10px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    opacity: 0;
    animation: fadeInUp 1.8s ease-out forwards;
  }
  
  
  
  
  @media (min-width: 800px) {

.\38 00px\:my-\[20px\] {
        margin-bottom: 18px !important;
        margin-top: 20px;
    }
  }

  /* Link Post Preview*/

  .link-post-preview-container {
    background-color: rgba(0,0,0,0.5);
    border: 1px solid #d3d3d3;
    border-radius: var(--border-radius-sm);
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 1rem;
    transition: transform 0.3s ease;
  }
  
  .link-post-preview-container:hover {
    transform: scale(1.02);
  }
  
  .link-post-preview-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .link-post-preview-content {
    padding: 1rem;
    background-color: rgba(0,0,0,0.5);
  }
  
  .link-post-preview-title {
    font-size: 1.2em;
    margin-bottom: 0.5rem;
   color: var(--text-color);;
  }
  
  .link-post-preview-description {
    font-size: 0.9em;
    color: #666666;
    margin-bottom: 0.5rem;
  }
  
  .link-post-preview-url {
    font-size: 0.9em;
    color: var(--primary-color);
    text-decoration: none;
    word-break: break-all;
    margin-top: 0.5rem;
  }
  
  
  .categories-dropdown-header-container {
    position: absolute;
    z-index: 50;
    top: 3.5rem;
    width: 100%;
    max-width: 100%;
    min-width: 690px;
    max-width: 1000px;
    box-shadow: 0 4px 8px #0000001a; 
    border-radius: 0.375rem;
    background-color: rgba(0, 0, 0, 0.9) !important; 
    border: 1px solid var(--border-color) !important; 
    border-top: none !important; 
    margin-top: 4px !important; 
    border-top-right-radius: 0px !important; 
    border-top-left-radius: 0px !important; 
}

  
  .categories-dropdown-header-inner {
    display: flex;
  }
  
  .categories-dropdown-header-section {
    padding: 1rem;
  }
  
  .categories-dropdown-header-categories {
    flex-grow: 1;
    flex-basis: 40%;
  }
  
  .categories-dropdown-header-feature-products {
    flex-grow: 1;
    flex-basis: 60%;
  }
  
  .categories-dropdown-header-title {
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--primary-color);
    margin-bottom: 1rem;
  }
  
  .categories-dropdown-header-item {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 0.375rem;
  }
  
  .categories-dropdown-header-item:hover {
    background-color: var(--primary-color);
  }
  
  .categories-dropdown-header-item-image {
    width: 3rem;
    height: 3rem;
    border-radius: 0.125rem;
    object-fit: cover;
  }
  
  .categories-dropdown-header-item-text {
    font-size: 0.875rem;
    font-weight: 600;
  }
  
  .categories-dropdown-header-product {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    border-radius: 0.375rem;
  }
  
  .categories-dropdown-header-product:hover {
    background-color: black;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .categories-dropdown-header-product-image {
    width: 150px;
    height: 150px;
    border-radius: 0.375rem;
    object-fit: cover;
  }
  
  .categories-dropdown-header-product-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--text-color);
  }
  
  .categories-dropdown-header-product-shop {
    font-size: 0.75rem;
    color: var(--primary-color);
  }
  
  .categories-dropdown-header-featured-shop {
    padding: 1rem;
  }
  
  .categories-dropdown-header-featured-shop-inner {
    position: relative;
  }
  
  .categories-dropdown-header-featured-shop-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 0.375rem;
  }
  
  .categories-dropdown-header-featured-shop-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: var(--modal-overlay-background);
    padding: 0.5rem;
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }
  
  .categories-dropdown-header-featured-shop-text {
    font-size: 1rem;
    font-weight: 600;
    color: var(--text-color);
    text-align: center;
  }
  
  .home-page-content {
    padding-left: 20px;
    padding-right: 20px;
  }
  
  @media (min-width: 768px) {
    .home-page-content {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
  
  @media (min-width: 1024px) {
    .home-page-content {
      padding-left: 120px;
      padding-right: 120px;
    }
  }
  

.site-logo {
    width: 260px; 
    height: auto; 
  }
.reshare-container {
    margin: 10px 0;
  }
  
  .reshare-textarea {
    width: 100%;
    min-height: 80px;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: var(--border-radius-sm); 
    background-color: rgba(0,0,0,0.5); 
     color: var(--text-color); 
    resize: vertical; 
  }
  
  .reshare-textarea:focus {
    border-color: var(--primary-color); 
  }
  
  .reshare-button {
    padding: 8px 15px;
    border: none;
    border-radius: 4px;
    background-color: var(--primary-color);
     color: var(--text-color);
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .reshare-button:hover {
    background-color: var(--primary-color-hover);
  }

  .cancel-button {
    padding: 8px 15px;
    border: none;
    border-radius: 4px;
    background-color: #a0a0a0; 
     color: var(--text-color);
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cancel-button:hover {
    background-color: #808080; 
  }
  
  .cart-popup {
    position: fixed;
    top: 98px !important; /* Added margin with !important */
    right: 0;
    height: calc(100% - 20px); /* Adjusted for the margin */
    width: 80%;
    background-color: #2a2a2ad1 !important;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06); /* Adjust shadow-sm as needed */
  }
  
  /* Media query for screens wider than 800px */
  @media (min-width: 800px) {
    .cart-popup {
      width: 25%;
    }
  }

   /* Comments */

   .comment-container {
    display: flex;
    align-items: start;
    gap: 1rem;
    padding: 1rem;
    border-bottom: 1px solid rgba(0,0,0,0.5);
  }
  
  .comment-avatar-container {
    flex-shrink: 0;
  }
  
  .comment-avatar {
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
  
  .comment-content {
    flex: 1;
  }
  
  .comment-author {
    display: block;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.25rem;
   color: var(--text-color);;
  }
  
  .comment-text-box {
    background-color: rgba(255, 255, 255, 0.2);
    padding: 0.75rem;
    border-radius: 0.5rem;
  }
  
  .comment-edit-input {
    width: 100%;
    background-color: #424242;
     color: var(--text-color);
    border: 1px solid rgba(0,0,0,0.5);
    padding: 0.5rem;
    border-radius: 0.5rem;
  }
  
  .comment-text {
    font-size: 0.875rem;
     color: var(--text-color);
  }
  
  .comment-options-icon {
    height: 1.25rem;
    width: 1.25rem;
    cursor: pointer;
  }
  
  .comment-options-panel {
    position: absolute;
    z-index: 10;
    margin-top: 0.5rem;
    background-color: rgba(250, 250, 250, 0.5);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
  }
  
  .comment-options-container {
    padding: 0.5rem;
  }
  
  .comment-option-button {
    display: block;
    width: 100%;
    text-align: left;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    color: rgba(0,0,0,0.5);
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .comment-option-button:hover {
    background-color: #f0f0f0;
  }
  
  .comment-save-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color);
     color: var(--text-color);
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    margin-top: 0.5rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .comment-save-button:hover {
    background-color: var(--primary-color-hover);
  }

/* Overall Layout */
.reported-posts-admin-container {
  display: flex;
  min-height: 100vh;
}

.reported-posts-admin-sidebar {
  width: 250px;
}

.reported-posts-admin-content {
  flex: 1;
  padding: 24px;
  color: var(--text-color, #e0e0e0);
}

/* Titles & Loader */
.reported-posts-admin-title {
  font-size: 2rem;
  margin-bottom: 16px;
  text-align: center;
}

.reported-posts-admin-loader {
  text-align: center;
  font-size: 1.2rem;
  padding: 40px 0;
}

/* Table Styles */
.reported-posts-admin-table-container {
  overflow-x: auto;
  margin-bottom: 24px;
}

.reported-posts-admin-table {
  width: 100%;
  border-collapse: collapse;
  background-color: var(--background-color);
}

.reported-posts-admin-table-head {
  background-color:  var(--background-color);
}

.reported-posts-admin-table th,
.reported-posts-admin-table td {
  padding: 12px 8px;
  border-bottom: 1px solid  var(--border-color);
  text-align: left;
}

.reported-posts-admin-table-body-row {
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.reported-posts-admin-table-body-row:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.reported-posts-admin-removed {
  opacity: 0.6;
}

/* Main Post Modal (Detail) */
.reported-posts-admin-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--modal-overlay-background);
  backdrop-filter: var(--modal-overlay-backdrop-filter);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 16px;
}

.reported-posts-admin-modal-box {
  background-color: var(--modal-content-background);
  color: var(--text-color, #e0e0e0);
  padding: 24px;
  border-radius: var(--border-radius-md);
  width: 90%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  border: 1px solid var(--border-color);
}

.reported-posts-admin-modal-close {
  position: absolute;
  top: 12px;
  right: 12px;
  background: transparent;
  border: none;
  font-size: 2rem;
  color: var(--text-color, #e0e0e0);
  cursor: pointer;
  line-height: 1;
}

.reported-posts-admin-modal-content {
  margin-top: 16px;
}

.reported-posts-admin-modal-title {
  font-size: 1.75rem;
  margin-bottom: 8px;
}

.reported-posts-admin-modal-date {
  color: #aaa;
  margin-bottom: 16px;
}

.reported-posts-admin-modal-description p {
  line-height: 1.6;
  margin-bottom: 16px;
}

.reported-posts-admin-modal-media {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 16px;
}

.reported-posts-admin-modal-image,
.reported-posts-admin-modal-video {
  border-radius: 6px;
  max-width: 100%;
  height: auto;
}

.reported-posts-admin-modal-comments {
  margin-top: 16px;
  font-style: italic;
}

.reported-posts-admin-modal-actions {
  margin-top: 24px;
  text-align: center;
}

.reported-posts-admin-modal-remove-button {
  background-color: var(--primary-color, #ff4d4f);
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: var(--border-radius-full);
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.reported-posts-admin-modal-remove-button:hover {
  background-color: #e04344;
}

/* Confirm Removal Modal */
.reported-posts-admin-confirm-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--modal-overlay-background);
  backdrop-filter: var(--modal-overlay-backdrop-filter);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
  padding: 16px;
}

.reported-posts-admin-confirm-modal {
  background-color: var(--modal-content-background);
  color: var(--text-color, #e0e0e0);
  padding: 24px;
  border-radius: var(--border-radius-md);
  width: 90%;
  max-width: 400px;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  border: 1px solid var(--border-color);
}

.reported-posts-admin-confirm-text {
  font-size: 1.2rem;
  margin-bottom: 24px;
}

.reported-posts-admin-confirm-actions {
  display: flex;
  justify-content: space-around;
}

.reported-posts-admin-confirm-button {
  padding: 10px 24px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.reported-posts-admin-confirm-yes {
  background-color: var(--primary-color, #ff4d4f);
  color: #fff;
}

.reported-posts-admin-confirm-yes:hover {
  background-color: #e04344;
}

.reported-posts-admin-confirm-no {
  background-color: #777;
  color: #fff;
}

.reported-posts-admin-confirm-no:hover {
  background-color: #666;
}


/* Report modal */

/* Backdrop covers the entire viewport */
.report-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--modal-overlay-background);
  backdrop-filter: var(--modal-overlay-backdrop-filter);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  /* Optional fade-in animation */
  opacity: 1;
  transition: opacity 0.3s ease;
}

/* Container for the modal content */
.report-modal-container {
  background-color: var(--modal-content-background);
  padding: 20px;
  border: 1px solid var(--border-color);
  width: 400px;
  border-radius: var(--border-radius-md);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

/* Title styling */
.report-modal-title {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  text-align: center;
}

/* Field grouping */
.report-modal-field {
  margin-bottom: 1rem;
}

/* Label styling */
.report-modal-label {
  display: block;
  margin-bottom: 0.5rem;
}

/* Select and textarea styling */
.report-modal-select,
.report-modal-textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-md);
  background-color: var(--toggle-color);
}

/* Button styling */
.report-modal-button {
  width: 100%;
  padding: 10px;
  background-color: var(--primary-color);
  border: none;
  border-radius: var(--border-radius-md);
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.report-modal-button:hover {
  background-color: var(--primary-color-hover);
}



  /* Cart sidebar*/

  .cart-sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.29);
    z-index: 10;
  }
  
  .cart-sidebar-popup {
    background-color: var(--sidebar-background);
    backdrop-filter: var(--modal-overlay-backdrop-filter);
    border-left: 1px solid var(--border-color);
    box-shadow: none ;
    height: 100%;
    max-width: 35% ;
    overflow-y: auto;
    padding: 20px;
    position: fixed;
    right: 0;
    width: 25% ;
    top: 10.4% ;
  }

  /* Tablet and smaller screens */
@media (max-width: 1024px) {
  .cart-sidebar-popup {
    max-width: 50% ;
    width: 50%;
    top: 10.4%;
  }
}

/* Mobile devices (phones) */
@media (max-width: 768px) {
  .cart-sidebar-popup {
    max-width: 100%;
    width: 100%;
    top: 7%;
    height: 100vh;
  }
}
  
  
  .cart-sidebar-close-btn-container {
    display: flex;
    justify-content: flex-end;
  }
  
  .cart-sidebar-close-btn {
    cursor: pointer;
  }
  
  .cart-sidebar-header {
    display: flex;
    align-items: center;
    padding: 16px 0;
  }
  
  .cart-sidebar-item-count {
    padding-left: 8px;
    font-size: 20px;
    font-weight: 500;
  }
  
  .cart-sidebar-items {
    border-top: 1px solid #ddd;
  }
  
  .cart-sidebar-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
  }
  
  .cart-sidebar-item {
    border-bottom: 1px solid #ddd;
    padding: 16px 0;
  }
  
  .cart-sidebar-item-content {
    display: flex;
    align-items: center;
  }
  
  .cart-sidebar-item-controls {
    display: flex;
    align-items: center;
  }
  
  .cart-sidebar-item-control {
    background: rgba(167, 171, 177, 0.31);
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .cart-sidebar-item-quantity {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .cart-sidebar-item-image {
    width: 125px;
    height: auto;
    margin: 0 16px;
    border-radius: 5px;
  }


@media (max-width: 480px) {
  .cart-sidebar-item-image {
    width: 100px; 
    margin: 0 8px; 
  }
}
  
  .cart-sidebar-item-details {
    flex-grow: 1;
    padding-left: 8px;
  }
  
  .cart-sidebar-item-details h4 {
    margin: 4px 0;
    color: rgba(0, 0, 0, 0.51);
    font-weight: 400;
    font-size: 15px;
  }
  
  .cart-sidebar-item-total-price {
    font-weight: 600;
    font-size: 17px;
    color: #d02222;
  }
  
  .cart-sidebar-remove-item {
    cursor: pointer;
  }
  
  .cart-sidebar-checkout {
    padding: 16px 0;
  }
  
  .cart-sidebar-checkout-btn-disabled,
  .cart-sidebar-checkout-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 100%;
    border-radius: 5px;
   color: var(--text-color);;
    font-size: 18px;
    font-weight: 600;
  }
  
  .cart-sidebar-checkout-btn-disabled {
    background-color: grey;
    cursor: not-allowed;
  }
  
  .cart-sidebar-checkout-btn {
    background-color: #e44343;
    cursor: pointer;
    text-decoration: none;
  }
  
  .cart-sidebar-checkout-btn:hover {
    background-color: #d02222;
  }
  

  /* Sponsored Shops widget*/
  
  .sponsored-shops-widget-container {
    padding: 20px;
    background-color: var(--background-color);
    border: 1px solid rgba(250, 250, 250, 0.5);
    border-radius: var(--border-radius-sm);
    margin: 10px auto;
    max-width: 1000px;
}

.sponsored-shops-widget-title {
    text-align: center;
    margin-bottom: 20px;
     color: var(--text-color);
    font-size: 1em;
}

.sponsored-shops-widget-list {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sponsored-shops-widget-item {
    width: 100%;
    aspect-ratio: 1 / 1;
    text-align: center;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius-sm);
    overflow: hidden;
    background-color: #444;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.sponsored-shops-widget-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.sponsored-shops-widget-link {
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.sponsored-shops-widget-banner-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.sponsored-shops-widget-image-container {
  position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
}

.sponsored-shops-widget-banner {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: filter 0.3s ease;
}

.sponsored-shops-widget-item:hover .sponsored-shops-widget-banner {
    filter: brightness(0.7);
}

.sponsored-shops-widget-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    color: var(--text-color);
    opacity: 1;
    transition: opacity 0.3s ease;
}

.sponsored-shops-widget-avatar {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 10px;
    border: 2px solid #fff;
}

.sponsored-shops-widget-name {
    font-size: 1.5em;
    margin: 0;
}

.sponsored-shops-widget-button {
    margin-top: 15px;
    border-radius: var(--border-radius-full);
    padding: 5px 25px;
    background: var(--primary-color);
    color: #fff;
    border: none;
    cursor: pointer;
    text-decoration: none;
    transition: background 0.3s ease;
    font-weight: 700;
}

.sponsored-shops-widget-button:hover {
  background-color: var(--primary-color-hover);
}




  /* Sponsored brands widget*/

  .sponsored-brands-widget-container {
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius-sm);
    margin: 0 0 25px;
    max-width: 1000px;
    padding: 10px;
    padding-bottom: 0px;
    padding-top: 15px;
}


.sponsored-brands-widget-title {
    text-align: center;
    margin-bottom: 10px;
    color: var(--text-color);
    font-size: 1em;
    font-weight: 300;
    letter-spacing: 1px;
}

.sponsored-brands-widget-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.sponsored-brands-widget-item {
  align-items: center;
  background-color: transparent;
  border: 1px solid var(--border-color);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  margin: 0px;
  overflow: hidden;
  position: relative;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100% ;
  padding: 0px ;
  aspect-ratio: 1 / 1;
}


.sponsored-brands-widget-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.sponsored-brands-widget-link {
    text-decoration: none;
    color: inherit;
    display: block;
    width: 100%;
}

.sponsored-brands-widget-images {
  width: 100%;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  position: relative;
}

.sponsored-brands-widget-avatar {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
    border: 3px solid #1f9c29;
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: var(--text-light);
}

.sponsored-brands-widget-cover {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.sponsored-brands-widget-details {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
 color: var(--text-color);;
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: 100%;
  position: absolute;
  bottom: 0;
}


.sponsored-brands-widget-name {
    font-size: 1.5em;
    margin: 10px 0;
    font-weight: 500;
}

.sponsored-brands-widget-button {
  background: #fd8309 !important;
  border: none;
  border-radius: var(--border-radius-full) !important;
 color: var(--text-color);;
  cursor: pointer;
  padding: 5px 25px !important;
  text-decoration: none;
  transition: background .3s ease, transform .3s ease;
  font-weight: 700 
}

.sponsored-brands-widget-button:hover {
    background: #fd8309;
    transform: scale(1.05);
}


/* Daily Ad Graph Button */
.daily-ad-graph-button {
    padding: 10px 20px;
    margin-right: 5px; 
    font-size: 16px;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
    background-color: var(--primary-color); 
     color: var(--text-color); 
}

/* Hover and Active States for Daily Button */
.daily-ad-graph-button:hover, .daily-ad-graph-button:focus {
  background-color: var(--primary-color-hover); 
}

/* Hourly Ad Graph Button */
.hourly-ad-graph-button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
    background-color: #008CBA; 
     color: var(--text-color);
}

/* Hover and Active States for Hourly Button */
.hourly-ad-graph-button:hover, .hourly-ad-graph-button:focus {
    background-color: #007bb5; 
}

/* Disabled State for Both Buttons */
.daily-ad-graph-button:disabled,
.hourly-ad-graph-button:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
    border-color: var(--border-color);
}

/* Filter graph Buttons */
.filter-button {
    padding: 10px 20px;
    margin-right: 10px;
    background-color: var(--primary-color);
     color: var(--text-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
}

.filter-button:hover {
    background-color: var(--primary-color);
}

.filter-button:active {
    background-color: #004085;
    transform: translateY(2px);
}

.filter-button:focus {
    box-shadow: 0 0 0 3px rgba(0,123,255,.5);
}



/* Brand */

/* BrandCreate.module.css */
.brand-formContainer {
    max-width: 500px;
    margin: 2rem auto;
    padding: 1.5rem;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    background: #f9f9f9;
  }
  
  .brand-label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .brand-input,
  .brand-textarea,
  .brand-fileInput {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid var(--border-color);
    border-radius: 4px;
  }
  
  .brand-textarea {
    height: 100px;
  }
  
  .brand-button {
    display: block;
    width: 100%;
    padding: 0.5rem 1rem;
    background-color: var(--primary-color);
     color: var(--text-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .brand-button:hover {
    background-color: var(--primary-color);
  }

  .catalog-create-modal-style {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 700px;
    background-color: #353535de;
    border: 2px solid #000;
    max-height: 90vh; 
    overflow-y: auto; 
    box-shadow: 24px;
    padding: 4px;
}
  

.locator-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .locator-modal-content {
    background-color: rgb(55, 55, 55);
    padding: 20px;
    border-radius: var(--border-radius-sm);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: center;
  }
  
  .locator-button {
    margin: 10px;
    padding: 10px 20px;
    border: none;
    background-color: var(--primary-color);
    color: var(--text-color);
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .locator-button:hover {
    background-color: var(--primary-color);
  }
  /* Shipping */
  .shipping-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.region-select-field {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius-lg);
}

.shipping-form label {
    display: block;
   color: var(--text-color);;
    margin-bottom: 5px;
}

.shipping-form input {
    width: 100%;
    padding: 10px;
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius-lg);
    transition: border-color 0.3s;
   color: var(--text-color);;
}

.shipping-form input:focus {
    border-color: var(--primary-color); 
}

.shipping-form button {
    padding: 10px 15px;
    border: none;
    border-radius: var(--border-radius-full);
     color: var(--text-color);
    background-color: var(--primary-color); 
    cursor: pointer;
    transition: background-color 0.3s;
}

.shipping-form button:hover {
  background-color: var(--primary-color-hover); 
}

.shipping-form button[type="button"] {
    background-color: #888; 
    border-radius: var(--border-radius-full);
}

.shipping-form button[type="button"]:hover {
    background-color: #666; 
}

.shipping-container {
    padding: 20px;
    background-color: #323232;
    min-height: 100vh;
    min-width: 80vw;
}

.shipping-rules-list {
    margin-top: 20px;
    border-top: 1px solid #e1e1e1;
    padding-top: 20px;
}

.shipping-rule-item {
    display: flex;
    flex-direction: column;
    background-color: #444444;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-bottom: 10px;
    padding: 10px;
}

.shipping-rule-item > div {
    margin-bottom: 5px;
}

.shipping-rate-conditions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding: 10px;
    background-color: #444444;
    border-radius: 5px;
}

.shipping-rate-conditions div {
    margin: 0 10px;
}

.shipping-rate-conditions h4, .shipping-rate-conditions h5 {
    margin-top: 0;
}

.rule-actions {
    display: flex;
    justify-content: flex-end;
}

.rule-actions button {
    margin-left: 10px;
}


.add-rule-btn, .edit-rule-btn, .delete-rule-btn {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
   color: var(--text-color);;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s;
}

.add-rule-btn {
    background-color: var(--primary-color);
}

.edit-rule-btn {
    background-color: var(--primary-color);
}

.delete-rule-btn {
    background-color: #f44336;
}

.add-rule-btn:hover {
  background-color: var(--primary-color-hover);
}

.edit-rule-btn:hover, .delete-rule-btn:hover {
    opacity: 0.9;
}

.region-form {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    max-width: 400px;
    margin: auto;
}

.region-form input[type="text"], .region-form button, .region-form .select__control {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: var(--border-radius-lg);
    border: 1px solid var(--border-color);
    background-color: var(--background-color);
}

/* Styling for React Select */
.region-form .select__control {
    display: flex;
    border-color: var(--border-color);
    box-shadow: none; 
    background-color: var(--background-color);
}

.region-form .select__control--is-focused {
    border-color: var(--primary-color);
    box-shadow: none; /* Consistent focus styling */
}

.region-form .select__menu {
    background-color: #535353;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.region-form .select__option--is-focused {
    background-color: #323232;
     color: var(--text-color);
}

.region-form .select__option--is-selected {
    background-color: #323232;
     color: var(--text-color);
}

.region-form .select__multi-value {
    background-color: #323232;
     color: var(--text-color);
}

.region-form .select__multi-value__remove:hover {
    background-color: #323232;
     color: var(--text-color);
}

.region-form button {
    background-color: var(--primary-color);
     color: var(--text-color);
    border: none;
    cursor: pointer;
}

.region-form button.cancel-button {
    background-color: #f44336;
}

.region-form input[type="text"]:focus {
    border-color: var(--primary-color);
    outline: none;
}

.region-form button:hover {
    opacity: 0.9;
}

.regions-button-add {
  background-color: var(--background-color);
  border: 1px solid var(--primary-color);
   color: var(--text-color);
  padding: 5px 10px;
  border-radius: var(--border-radius-full);
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
  margin-bottom: 10px;
  align-items: center;
}

.regions-button-add:hover {
  background-color: #5a6268;
}

.regions-container {
  padding: 24px;
  background-color: var(--background-color);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--border-color);
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
}

.regions-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.regions-item {
  background-color: var(--background-color);
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 16px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.regions-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.regions-actions {
  display: flex;
  gap: 12px;
}

.regions-button {
  padding: 8px 16px;
  border: none;
  border-radius: var(--border-radius-full);
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.regions-button-edit {
  background-color: var(--primary-color);
  color: var(--text-color);
}

.regions-button-edit:hover {
  background-color: var(--primary-color-hover);
  transform: scale(1.05);
}

.regions-button-delete {
  background-color: #dc3545;
   color: var(--text-color);
}

.regions-button-delete:hover {
  background-color: #c82333;
  transform: scale(1.05);
}

.regions-button-add-icon {
  height: 20px;
  width: 20px;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 2px;
}

.regions-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: var(--modal-overlay-backdrop-filter);
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.regions-modal-content {
  background-color: var(--background-color);
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  position: relative;
  width: 450px;
  max-width: 90%;
}

.regions-modal-close-button {
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #333;
  transition: color 0.3s ease;
  height: 24px;
  width: 24px;
}

.regions-modal-close-button:hover {
  color: #000;
}


/* subscription checkout section */
.subscription-details {
    background-color: #015e07; 
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    margin-top: 20px;
  }
  
  .subscription-details h2 {
    color: var(--text-color); 
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .subscription-details p {
    color: var(--text-color); 
    font-size: 16px;
    line-height: 1.5;
  }

  .change-subscription-modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

.change-subscription-modal-content {
    background-color: #3b3b3b;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 50%;
}

.change-subscription-close-btn {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

.change-subscription-close-btn:hover,
.change-subscription-close-btn:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.change-subscription-plan {
    padding: 10px;
    cursor: pointer;
}

.change-subscription-plan-current {
    background-color: lightgreen;
}

.upgrade-plan-btn {
    background-color: var(--primary-color); 
    color: var(--text-color);
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s ease;
  }
  
  .upgrade-plan-btn:hover {
    background-color: var(--primary-color-hover); 
  }

  .user-subscriptions-overview {
    padding: 20px;
    background-color: var(--background-color);
    border-radius: var(--border-radius-md);
}

.user-subscriptions-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.user-subscriptions-loading,
.user-subscriptions-error {
    text-align: center;
    margin-top: 20px;
    font-size: 18px;
}

.user-subscriptions-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--modal-overlay-background);
    backdrop-filter: var(--modal-overlay-backdrop-filter);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.user-subscriptions-modal {
    width: 300px;
    background-color: var(--text-light);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative;
}

.user-subscriptions-confirm-btn,
.user-subscriptions-cancel-btn {
    margin-right: 10px;
    background-color: var(--primary-color);
     color: var(--text-color);
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 16px;
}

.user-subscriptions-cancel-btn {
    background-color: #f44336;
}

.user-subscriptions-list {
    list-style-type: none;
    padding: 0;
}

.user-subscriptions-item {
    background-color: var(--text-light);
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 10px;
    transition: background-color 0.3s;
}

.user-subscriptions-item-active {
    background-color: #e6ffe6;
}

.user-subscriptions-detail {
    margin: 5px 0;
}

.user-subscriptions-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}

.user-subscriptions-empty {
    text-align: center;
    margin-top: 20px;
}

.user-subscriptions-view-plans-btn {
    background-color: var(--primary-color);
     color: var(--text-color);
    border: none;
    border-radius: var(--border-radius-full);
    padding: 10px 15px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
}

/* Shop subscriptions overview */

/* General Styles */
.shop-subscriptions-overview-container {
  padding: 20px;
  background-color: rgba(250, 250, 250, 0.2);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.shop-subscriptions-overview-container h2 {
  font-size: 24px;
 color: var(--text-color);;
  margin-bottom: 20px;
}

/* Modal Styles */
.shop-subscriptions-overview-modal-overlay {
  position: fixed; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); 
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.shop-subscriptions-overview-modal-content {
  width: 300px;
  background-color: rgba(250, 250, 250, 0.2); 
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  text-align: center; 
}

.shop-subscriptions-overview-modal-confirm-btn,
.shop-subscriptions-overview-modal-cancel-btn {
  margin-top: 15px;
  background-color: var(--primary-color);
   color: var(--text-color);
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  width: 100px;
  margin-right: 10px;
}

.shop-subscriptions-overview-modal-cancel-btn {
  background-color: #f44336;
}

/* Subscription List Styles */
.shop-subscriptions-overview-list {
  list-style-type: none;
  padding: 0;
}

.shop-subscriptions-overview-item {
  background-color: var(--background-color);
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  transition: background-color 0.3s;
}

.shop-subscriptions-overview-item-active {
  background-color: var(--background-color);
}

.shop-subscriptions-overview-detail {
  margin: 5px 0;
}

.shop-subscriptions-overview-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.shop-subscriptions-overview-cancel-btn {
  background-color: #f44336;
   color: var(--text-color);
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
}
.shop-change-subscription-upgrade-plan-btn {
  background-color: var(--primary-color);
   color: var(--text-color);
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
}

.shop-subscriptions-overview-empty {
  text-align: center;
  margin-top: 20px;
}

.shop-subscriptions-overview-view-plans-btn {
  background-color: var(--primary-color);
  color: var(--text-color);
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

/* Loading and Error Styles */
.shop-subscriptions-overview-loading {
  text-align: center;
  font-size: 18px;
  color: #333;
}

.shop-subscriptions-overview-error-message {
  text-align: center;
  color: #f44336;
  font-size: 18px;
}


/* ShopPaymentSettingsPage.css */
.shop-onboarding-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    text-align: center;
    padding: 20px;
  }

  .shop-onboarding-banner {
    background-color: var(--background-color) !important;
    border: 1px solid var(--border-color) !important;
    font-weight: 700 !important;
    border-radius: var(--border-radius-md);
   color: var(--text-color);;
    font-size: 30px;
    margin-bottom: 10px;
    max-width: 600px;
    padding: 20px;
    text-align: center;
    width: 100%;
  }
  
  .shop-onboarding-content {
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    padding: 40px;
    border-radius: var(--border-radius-md);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
    max-height: 80vh;
    overflow: hidden;
    overflow-y: auto;
  }
  
  @media (max-width: 480px) {
    .shop-onboarding-content {
      padding: 10px;
    }
    .shop-onboarding-container {
      padding: 0px;
    }
    
  }
  
  .shop-onboarding-onboarding-container {
    display: flex;
    justify-content: center;
    align-items: center; 
  }
  
  .shop-onboarding-error {
    color: red;
    margin-top: 20px;
  }
  

/* Container for payment method details and button */
.save-payment-method-container {
  text-align: center;
  padding: 20px;
  background-color: var(--background-color);
}

/* Styling for payment details */
.save-payment-method-details h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.save-payment-method-details p {
  font-size: 1rem;
  margin-bottom: 5px;
}

.save-payment-method-open-btn {
  background-color: var(--background-color);
   color: var(--text-color);
  padding: 10px 20px;
  border: 1px solid var(--primary-color);
  border-radius: var(--border-radius-full);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.save-payment-method-open-btn:hover {
  background-color: rgba(250, 250, 250, 0.3);
}

/* Modal styling */
.save-payment-method-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.save-payment-method-modal-content {
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
 color: var(--text-color);;
  padding: 20px;
  border-radius: var(--border-radius-md);
  width: 400px;
  text-align: center;
  position: relative;
}

.save-payment-method-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  cursor: pointer;
}

.save-payment-method-close-btn:hover {
  color: #ff0000;
}

/* Form styling */
.save-payment-method-form {
  margin-top: 20px;
}

.save-payment-method-card-element {
  border: 1px solid var(--border-color);
  background-color: rgba(250, 250, 250, 0.2);
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 20px;
}

.save-payment-method-submit-btn {
  background-color: var(--primary-color);
   color: var(--text-color);
  padding: 10px 20px;
  border: none;
  border-radius: var(--border-radius-full);
  cursor: pointer;
}

.save-payment-method-submit-btn:disabled {
  background-color: #6c757d;
}

.payment-method-page {
  display:flex;
  flex-direction: column;
  justify-content: center;
}

.next-step-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: var(--primary-color);
   color: var(--text-color);
  border: none;
  border-radius: var(--border-radius-full);
  cursor: pointer;
  width: 100%;
}



.boost-animation-container {
    position: fixed; 
    top: 50%;       
    left: 50%;      
    transform: translate(-50%, -50%); 
    z-index: 1000;  
    width: 100%;    
    height: 100%;   
    display: flex;  
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
}
 /* Tag manager */

/* Container Styles */
.admin-tag-management {
  padding: 30px;
  background-color: var(--background-color);
  border-radius: 10px;
  width: 80vw;
  color: var(--text-color);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

/* Heading */
.admin-heading {
  text-align: center;
  color: var(--text-color);
  margin-bottom: 25px;
  font-size: 2rem;
  font-weight: 600;
}

/* Form Styles */
.admin-tag-form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
  margin-bottom: 35px;
}

.admin-tag-input {
  flex: 1 1 200px;
  padding: 12px 16px;
  border: 1px solid var(--border-color);
  background-color: var(--background-color);
  border-radius: 6px;
  font-size: 1rem;
  color: var(--text-color);
  transition: border-color 0.3s, box-shadow 0.3s;
}

.admin-tag-input:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 8px rgba(54, 211, 0, 0.5);
}

.admin-tagbtn {
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
  border: none;
  font-size: 1rem;
  transition: background-color 0.3s, transform 0.2s;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin-tagbtn-add-tag {
  background-color: var(--primary-color, #007bff);
  color: var(--text-color);
  flex: 0 0 auto;
}

.admin-tagbtn-add-tag:hover {
  background-color: var(--primary-color-hover);
  transform: translateY(-2px);
}

.admin-tagbtn-add-tag:active {
  background-color: var(--primary-color-hover);
  transform: translateY(0);
}

.admin-tagbtn-add-tag:focus {
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

.admin-tagbtn-approve {
  background-color: var(--primary-color);
  color: var(--text-color);
  padding: 8px 16px;
  border-radius: var(--border-radius-full);
  cursor: pointer;
  transition: background-color 0.3s;
}

.admin-tagbtn-approve:hover {
  background-color: var(--primary-color-hover);
}

.admin-tagbtn-approve:active {
  background-color: var(--primary-color);
}

.admin-tagbtn-delete {
  background-color: #dc3545;
  color: var(--text-color);
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.admin-tagbtn-delete:hover {
  background-color: #c82333;
}

.admin-tagbtn-delete:active {
  background-color: #bd2130;
}

.admin-tagbtn-delete:focus,
.admin-tagbtn-approve:focus {
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

/* Loading Text */
.admin-loading-text {
  text-align: center;
  font-size: 1.2rem;
  color: var(--text-color);
}

/* Tags Container */
.admin-tags-container {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* Tag Section */
.admin-tag-section {
  flex: 1 1 45%;
  background-color: var(--background-color);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.admin-tag-section-title {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: var(--text-color);
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 5px;
}

.admin-no-tags-text {
  color: var(--text-color);
  font-style: italic;
  text-align: center;
}

/* Tag List */
.admin-tag-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.admin-tag-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background-color: var(--background-color);
  border-radius: 6px;
  margin-bottom: 12px;
  transition: background-color 0.3s;
}

.admin-tag-item:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

.admin-tag-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.admin-tag-name {
  font-size: 1rem;
  font-weight: 500;
  padding: 6px 12px;
  border-radius: 12px;
  color: var(--text-color);
}

.admin-tag-name.approved {
  background-color: var(--primary-color);
}

.admin-tag-name.unapproved {
  background-color: #ffc107;
}

.admin-tag-description {
  font-size: 0.875rem;
  color: var(--text-color);
}

/* Tag Actions */
.admin-tag-actions {
  display: flex;
  gap: 10px;
}

/* Responsive Design */
@media (max-width: 992px) {
  .admin-tags-container {
      flex-direction: column;
      gap: 20px;
  }

  .admin-tag-section {
      flex: 1 1 100%;
  }

  .admin-tag-form {
      flex-direction: column;
      align-items: stretch;
  }

  .admin-tagbtn-add-tag {
      width: 100%;
  }
}

@media (max-width: 600px) {
  .admin-tag-form {
      flex-direction: column;
      gap: 10px;
  }

  .admin-tag-input {
      flex: 1 1 100%;
  }

  .admin-tagbtn-add-tag {
      width: 100%;
  }

  .admin-tag-actions {
      flex-direction: column;
      gap: 5px;
  }

  .admin-tag-item {
      flex-direction: column;
      align-items: flex-start;
  }
}


/* Coupon code form*/

.coupon-code-create-form {
    max-height: 80vh;
    overflow-y: auto;
  }
  
  .coupon-code-create-form__form {
    display: flex;
    flex-direction: column;
  }
  
  .coupon-code-create-form__input-group {
    margin-bottom: 1rem;
  }
  
  .coupon-code-create-form__input-group label {
    font-weight: bold;
  }
  
  .coupon-code-create-form__input-group input,
  .coupon-code-create-form__input-group select,
  .coupon-code-create-form__input-group .basic-multi-select {
    width: 100%;
    padding: 0.5rem;
    border-radius: 5px;
    border: 1px solid var(--border-color);
  }
  
  .coupon-code-create-form__input-group input:focus,
  .coupon-code-create-form__input-group select:focus,
  .coupon-code-create-form__input-group .basic-multi-select:focus {
    border-color: var(--primary-color);
    outline: none;
  }
  
  .coupon-code-create-form__input-group input[type="submit"] {
    background-color: var(--primary-color);
     color: var(--text-color);
    cursor: pointer;
    border: none;
    transition: background-color 0.3s ease;
  }
  
  .coupon-code-create-form__input-group input[type="submit"]:hover {
    background-color: var(--primary-color-hover);
  }

  /* Hashtag Suggestions */
.hashtag-suggestions-container {
    position: absolute;
    background-color: rgba(0,0,0,0.5);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: 1000;
    width: 50%;
    max-height: 200px;
    overflow-y: auto;
    margin-top: 0.5rem; 
    margin-left: 5rem;
  }
  
  .hashtag-suggestions-loading {
    padding: 8px;
    text-align: center;
    color: #888;
  }
  
  .hashtag-suggestion-item {
    padding: 8px;
    cursor: pointer;
  }
  
  .hashtag-suggestion-item:hover {
    background-color: var(--primary-color);
  }
  
  .hashtag-suggestion-item .MuiTypography-root {
    color: var(--primary-color);
    font-weight: bold;
  }
  
  
/* Suggested Friend Card */

.friend-card {
    background-color: var(--background-color) !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
    border-radius: 8px;
    box-shadow: 0 4px 8px #0000001a;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px;
    padding: 10px;
    transition: transform 0.2s, box-shadow 0.2s;
    width: 180px;
}


.friend-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.friend-card-image img {
    border: 1px solid var(--border-color) !important;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-radius: 10px !important;
    height: 120px;
    object-fit: cover;
    width: 100%;
}


.friend-card-content {
    padding: 12px;
    text-align: center;
}

.friend-card-name {
    margin: 0;
    font-size: 18px;
   color: var(--text-color);;
}

.friend-card-actions {
    display: flex;
    justify-content: center;
    padding: 5px;
}

.add-friend-button-button {
    background-color: var(--primary-color) !important;
    border-radius: var(--border-radius-full) !important;
    padding: 5px 25px !important;
    padding-bottom: 1rem;
    color: #fff !important;
    gap: 8px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 700;
}

.recommended-friends-container {
    margin: 0 auto;
    max-width: 85vw;
    padding: 20px;
    padding-left: 0px !important;
    padding-right: 0px !important;
}


.recommended-friends-title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
    color: rgba(0,0,0,0.5);
}

.suggested-friend-list {
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;
    padding: 20px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    justify-content: center;
}

.suggested-friend-list::-webkit-scrollbar {
    height: 8px;
}

.suggested-friend-list::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
}

/* Homepage hero slider */
.homepage-hero-slider {
  height: 50vh;
  position: relative;
  margin-top: 25px;
  border-radius: var(--border-radius-sm);
}
  
  .homepage-hero-slide {
    position: absolute;
    inset: 0;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    transition: opacity 0.5s ease-in-out;
  }
  
  .homepage-hero-slide-active {
    opacity: 1;
  }
  
  .homepage-hero-slide-inactive {
    opacity: 0;
  }
  
  .homepage-hero-slide-content {
    height: 100%;
    padding: 6em;
    background: linear-gradient(to left, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.5) 100%);
     color: var(--text-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .homepage-hero-slide-title {
    font-size: 7rem;
    font-weight: bold;
  }
  
  .homepage-hero-slide-button {
    margin-top: 1rem;
    display: inline-block;
    background-color: var(--primary-color);
     color: var(--text-color);
    font-size: 2rem;
    font-weight: bold;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    text-decoration: none;
    transition: background-color 0.3s ease-in-out;
  }
  
  .homepage-hero-slide-button:hover {
    background-color: var(--primary-color-hover);
  }
  
  .homepage-hero-slider-button {
    position: absolute;
    top: 50%;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
     color: var(--text-color);
    border: none;
    border-radius: 50%;
    padding: 0.75rem;
    cursor: pointer;
    transform: translateY(-50%);
  }
  
  .homepage-hero-slider-button-left {
    left: 1rem;
  }
  
  .homepage-hero-slider-button-right {
    right: 1rem;
  }
  
  .homepage-hero-icon {
    height: 1.5rem;
    width: 1.5rem;
  }

  @media (max-width: 768px) {
    .homepage-hero-slide-title {
      font-size: 3rem;
      font-weight: bold;
    }
    .homepage-hero-slide-button {
      font-size: 1rem;
    }
    .home-page-content {
      padding-left: 10px;
     padding-right: 10px;
     margin-top: 40px
    }
    .homepage-hero-slider {
      margin-top: 0px;
    }
    .add-friend-button-button {
     margin-bottom: 10px;
  }
    
  }

/* Homepage category overview */
  .homepage-category-component-section {
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .homepage-category-component-branding {
    margin: 3rem 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    background-color: rgba(0,0,0,0.5);
    padding: 1.25rem;
    border-radius: 0.375rem;
  }
  
  .homepage-category-component-branding-item {
    display: flex;
    align-items: start;
  }
  
  .homepage-category-component-branding-text {
    padding-left: 0.75rem;
  }
  
  .homepage-category-component-branding-title {
    font-weight: bold;
    font-size: 1rem;
   color: var(--text-color);;
  }
  
  .homepage-category-component-branding-description {
    font-size: 0.875rem;
    color: #666;
  }
  
  .homepage-category-component-categories-section {
    background-color: rgba(0,0,0,0.5);
    padding: 1.5rem;
    border-radius: 0.5rem;
    margin-bottom: 3rem;
  }
  
  .homepage-category-component-categories-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 5px;
  }
  
  @media (min-width: 768px) {
    .homepage-category-component-categories-grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }
  }
  
  @media (min-width: 1024px) {
    .homepage-category-component-categories-grid {
      grid-template-columns: repeat(4, 1fr);
      gap: 20px;
    }
  }
  
  @media (min-width: 1280px) {
    .homepage-category-component-categories-grid {
      grid-template-columns: repeat(5, 1fr);
      gap: 25px;
    }

    .xl\:px-8 {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }

    .lg\:px-8 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
    }

    .lg\:mx-auto {
        margin-left: 4.5rem !important;
        margin-right: 4.5rem !important;
    }

    .text-white.font-bold {
    font-size: 20px !important;
    }

	  /*----EDITS FOR WIDTHS TEST----*/
    .xl\:mx-auto {
        margin-left: 7% !important;
        margin-right: auto !important;
    }

    .xl\:px-8 {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .py-16.sm\:py-24.xl\:mx-auto.xl\:max-w-7xl.xl\:px-8 {
    margin: 0px 7% !important;
}

    .lg\:px-8 {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

        .lg\:mx-auto {
        margin-left: 7% !important;
        margin-right: 7% !important;
    }
    .xl\:max-w-7xl {
        max-width: 100% !important;
    }
  }
  
  .homepage-category-component-category-item {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    overflow: hidden;
    transition: background-color 0.3s, box-shadow 0.3s;
  }
  
  .homepage-category-component-category-item:hover {
    background-color: var(--primary-color);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  }
  
  .homepage-category-component-category-title {
    font-size: 1.125rem;
    line-height: 1.3;
   color: var(--text-color);;
  }
  
  .homepage-category-component-category-image {
    width: 120px;
    height: 100%;
    object-fit: cover;
  }

  .best-deals-homepage-container {
    width: 100%;
    padding: 0 20px;
  }
  
  .best-deals-homepage-section {
    width: 100%;
    margin: 0 auto;
  }
  
  .best-deals-homepage-heading {
    text-align: center;
    font-size: 27px;
   color: var(--text-color);;
    font-weight: 600;
    font-family: Roboto, sans-serif;
    padding-bottom: 20px;
  }
  
  .best-deals-homepage-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin-bottom: 3rem;
  }
  
  @media (min-width: 768px) {
    .best-deals-homepage-grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 25px;
    }
  }
  
  @media (min-width: 1024px) {
    .best-deals-homepage-grid {
      grid-template-columns: repeat(4, 1fr);
      gap: 25px;
    }
  }
  
  @media (min-width: 1280px) {
    .best-deals-homepage-grid {
      grid-template-columns: repeat(5, 1fr);
      gap: 25px;
    }
  }
  
  
  


 /* Responsive design for smaller screens */
 @media (max-width: 768px) {
    .subscription-details {
      background-color: #015e07; 
      padding: 15px;
      border-radius: 5px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    }
  }

/* Styling for the list containing subscription details */
.user-subscriptions-list {
    list-style: none; 
    padding: 0;
    margin: 0;
}

.user-subscriptions-item {
    background-color: #3f3f3f; 
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 10px; 
    border-radius: 5px; 
}

.user-subscriptions-detail {
    font-size: 16px;
    color: var(--text-color); 
    margin: 5px 0; 
}

.user-subscriptions-cancel-btn {
    background-color: #f44336; 
     color: var(--text-color);
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 16px;
    display: inline-block; 
    margin-top: 10px; 
}

.user-subscriptions-cancel-btn:hover {
    background-color: #d32f2f; 
}

/* Additional styling for active subscription management */
.active-subscription-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px; 
}

.active-subscription-button {
    background-color: var(--primary-color); 
     color: var(--text-color);
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 16px;
}

.active-subscription-button:hover {
  background-color: var(--primary-color-hover);
}

/* Order Confirmation page */

.order-confirmation-container {
    display: flex;
    justify-content: center;
    align-items: start;
    padding: 20px;
  }
  
  .order-confirmation-side-image {
    flex: 1;
    max-width: 40%;
  }
  
  .order-confirmation-hero-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .order-confirmation-details {
    flex: 2;
    padding: 20px;
    max-width: 60%;
  }

  @media (max-width: 480px) {
    .order-confirmation-container {
      display: flex;
      flex-direction: column;
      padding: 10px;
    }
    .order-confirmation-details {
      flex: 2;
      padding: 20px;
      max-width: 100%;
    }
  }
  
  .order-confirmation-title {
    font-size: 2.5em;
    color: var(--text-color);
  }
  
  .order-confirmation-id {
    margin-top: 10px;
    font-size: 1.5em;
    color: #e0e0e0;
  }
  
  .order-confirmation-customer {
    color: #488bf0;
  }
  
  .order-confirmation-product-item {
    display: flex;
    align-items: center;
    border-top: 1px solid #e2e8f0;
    padding: 20px 0;
  }
  
  .order-confirmation-product-image {
    width: 100px;
    height: 100px;
    margin-right: 20px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .order-confirmation-product-info {
    flex-grow: 1;
  }
  
  .order-confirmation-shipping {
    margin-top: 20px;
  }
  
  .order-confirmation-loading {
    text-align: center;
    font-size: 18px;
    color: #4a5568;
  }

  .order-confirmation-payment-info {
    background-color: rgba(255, 255, 255, 0.1); 
    border: 1px solid var(--border-color); 
    padding: 20px; 
    margin-top: 20px; 
    border-radius: var(--border-radius-md); 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
}

.order-confirmation-payment-info h3 {
    color: #1a202c; 
    font-size: 18px; 
    margin-bottom: 10px; 
    font-weight: 600; 
}

.order-confirmation-payment-info p {
    color: #4a5568; 
    font-size: 16px; 
    margin-bottom: 5px; 
    line-height: 1.6; 
}
.order-confirmation-animation {
    text-align: center;
    margin-bottom: 20px;
  }

.continue-shopping-button {
    background-color: var(--primary-color);
     color: var(--text-color);
    padding: 10px 20px;
    border: none;
    border-radius: var(--border-radius-full);
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    margin-top: 10px;
  }
  
  .continue-shopping-button:hover {
    background-color: var(--primary-color);
  } 

/* -------------------------------------------------------------------------- */
/* Orders List - Improved CSS                                                 */
/* -------------------------------------------------------------------------- */

/* Container */
.shop-all-orders-container {
  background-color: var(--background-color);           
  padding: 20px;
  width: 100%;
  border-radius: var(--border-radius-md);       
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 1px solid var(--border-color);
  margin-bottom: 20px;
}

/* Responsive: on smaller screens, remove margin-left & extra padding */
@media (max-width: 480px) {
  .shop-all-orders-container {
    min-height: 100vh;
    margin-left: 0;
    padding: 16px;
    width: 100%;
  }
}

/* Header */
.shop-all-orders-header {
  width: 100%;
  margin: auto;
  margin-bottom: 16px;
}

/* Title */
.shop-all-orders-title {
  font-size: 1.5rem; 
  font-weight: 600;
  margin: 0;
  color: var(--text-color);
  margin-bottom: 10px;
  text-transform: uppercase;
  padding-left: 10px;
  border-left: 4px solid var(--primary-color);
}

.shop-all-orders-title-shipping {
  font-size: 16px; 
  font-weight: 600;
  margin: 0;
  color: var(--text-color);
  margin-bottom: 10px;
  text-transform: uppercase;
  padding-left: 10px;
  border-left: 4px solid var(--primary-color);
}

/* Table Container (Desktop Only) */
.shop-all-orders-table {
  margin-top: 24px;
  width: 100%;
  overflow-x: auto;   
  border-radius: 8px;
  border: 1px solid var(--border-color); 
}

/* Table */
.table {
  width: 100%;
  border-collapse: collapse;
  background-color: var(--background-color);
  border-radius: inherit;
}

/* Table Head */
.table thead tr {
  background-color: var(--background-color);
}

.table th {
  padding: 12px;
  text-align: left;
  font-weight: 600;
  background-color: var(--background-color);
  border-bottom: 2px solid var(--border-color); 
}

/* Table Body */
.table tbody tr {
  transition: background-color 0.2s;
  border-bottom: 1px solid var(--border-color); 
}

/* Hover effect for rows */
.table tbody tr:hover {
  background-color: var(--border-color); 
}

/* Table Cells */
.table td {
  padding: 12px;
  border: none;
}

/* Utility Colors */
.table .greenColor {
  color: #48BB78; 
  font-weight: 500;
}

.table .redColor {
  color: #F56565; 
  font-weight: 500;
}

.table .yellowColor {
  color: #ECC94B;
  font-weight: 500;
}

/* Pending row highlight */
.shop-all-orders-pending-row {
  background-color: var(--background-color)
}

/* Action cells */
.shop-all-orders-actions {
  text-align: center;
}

.shop-all-orders-actions a {
  color: var(--primary-color);      
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s, transform 0.2s;
}

.shop-all-orders-actions a:hover {
  color: #63B3ED;            /* Darker blue on hover */
  transform: scale(1.05);
}

/* Mobile list (if you have a .mobile-only class) */
.shop-all-orders-mobile-list {
  list-style: none;
  padding: 0;
  margin-top: 24px;
}

.shop-all-orders-mobile-list-item {
  background-color: #2D3748;
  margin-bottom: 16px;
  border: 1px solid #4A5568;
  border-radius: 8px;
  transition: background-color 0.2s;
}

.shop-all-orders-mobile-list-item:hover {
  background-color: #384158;
}

.shop-all-orders-mobile-content {
  padding: 16px;
}

.shop-all-orders-mobile-header h3 {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 600;
}

.shop-all-orders-mobile-action {
  text-align: right;
  padding: 8px 16px;
  border-top: 1px solid #4A5568;
}



  /* Shop Purchases list */
  .shop-purchases-container {
    width: 100%;
    padding: 16px;
    margin-top: 20px;
    background-color: rgba(0,0,0,0.5);
    border-radius: 6px;
    color: #F7FAFC;
  }
  
  .shop-purchases-title {
    font-size: 24px;
    font-weight: bold;
    color: #F7FAFC;
    margin-bottom: 16px;
  }
  
  .shop-purchases-status-delivered {
    color: green;
  }
  
  .shop-purchases-status-pending {
    color: red;
  }
  
  .shop-purchases-button {
    color: #F7FAFC;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .shop-purchases-button:hover {
    color: var(--primary-color);
  }
  
  .shop-purchases-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .shop-purchases-table th,
  .shop-purchases-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #2D3748;
  }
  
  .shop-purchases-table th {
    background-color: #146e10;
    color: #F7FAFC;
  }
  
  .shop-purchases-table tr:hover {
    background-color: #555;
  }
  
  

  

/* CSS Document */

.bg-gray-900 {
    --tw-bg-opacity: .2;
    background-color: rgb(250 250 250/var(--tw-bg-opacity));
    background-color: rgba(250, 250, 250, 0.2) !important;
}

.text-green-600 {
    --tw-text-opacity: .2;
    color: rgb(22 163 74/var(--tw-text-opacity));
    background-color: rgba(250, 250, 250, 0.2) !important;
}

.bg {
    --tw-bg-opacity: .2;
    background-color: rgb(250 250 250/var(--tw-bg-opacity)) !important;
    color: #ffffff !important;
	background-color: rgba(250, 250, 250, 0.2) !important;
}

.bg-white {
    --tw-bg-opacity: .2;
    background-color: rgb(255 255 255/var(--tw-bg-opacity));
    background-color: rgb(250, 250, 250, 0.2) !important;
    color: #ffffff !important;
}

.lg\:block {
    display: block;
    width: 25% !important;
}

.flex {
    display: flex;
}

.xl\:block {
    display: block;
    width: 25% !important;
    vertical-align: top !important;
}

.xl\:gap-\[20px\] {
    gap: 20px;
    background-color: rgba(250, 250, 250, 0.2) !important;
    border-radius: 10px !important;
    padding: 15px !important;
}

.css-1ug174f {
    padding: 1.5rem 1.5rem 0.75rem;
    background-color: rgb(255, 255, 255);
    border-radius: 0.75rem;
    background-color: rgba(250, 250, 250, 0.2) !important;
    color: rgb(250, 250, 250) !important;
}

.top-24 {
    top: 0 !important;
}

.css-snk24v {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: .7rem;
    line-height: 1.334;
    letter-spacing: 0em;
    color: rgb(250, 250, 250);
    font-weight: 500;
}

.css-1pqb849 {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: rgb(250, 250, 250) !important;
}

.css-zevtcw {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: rgb(70, 211, 0) !important;
}

.buzzweed-green {
    color: rgb(70, 211, 0) !important;
}

.css-pg6ig3 {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: rgb(70, 211, 0) !important;
    margin: 0.5rem 0px;
}

.text-green-700 {
    --tw-text-opacity: 1;
    color: rgb(21 128 61/var(--tw-text-opacity));
    color: rgb(70, 211, 0) !important;
    /* border-bottom: rgb(70, 211, 0) !important; */
}

.text-green-600 {
    --tw-text-opacity: 1;
    color: rgb(22 163 74/var(--tw-text-opacity));
    background-color: rgba(250, 250, 250, 0.2);
}

.cart-icon {
    color: rgb(250, 250, 250) !important;
}

.text-\[\#68d284\] {
    --tw-text-opacity: 1;
    color: rgb(250 250 250/var(--tw-text-opacity));
    color: rgb(250, 250, 250) !important;
}

.text-green-700 {
    --tw-text-opacity: 1;
    color: rgb(21 128 61/var(--tw-text-opacity));
    color: rgb(70, 211, 0) !important;
    border-bottom: 3px solid rgb(70, 211, 0) !important;
}

.text-sm {
    font-size: 1.0rem !important;
    line-height: 1.25rem;
}

.text-gray-600 {
    --tw-text-opacity: 1;
    color: rgb(75 85 99/var(--tw-text-opacity));
    color: rgb(250, 250, 250) !important;
}

.text-gray-400 {
    --tw-text-opacity: 1;
    color: rgb(156 163 175/var(--tw-text-opacity));
    color: rgb(250, 250, 250) !important;
}

svg.text-green-700.mr-3.h-6.w-6.flex-shrink-0 {
    color: rgb(70, 211, 0) !important;
    border-bottom: none !important;
}

.border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgb(22 163 74/var(--tw-border-opacity));
    border-color: rgb(70, 211, 0) !important;
}

.bg-\[\#3bc177\] {
    --tw-bg-opacity: 1;
    background-color: rgb(59 193 119/var(--tw-bg-opacity));
    background-color: rgb(70, 211, 0) !important;
}

.hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251/var(--tw-bg-opacity));
    background-color: rgb(70, 211, 0) !important;
}

svg.absolute.right-2.top-4.cursor-pointer {
    color: rgb(250, 250, 250) !important;
}

.border-\[\#3957db\] {
    --tw-border-opacity: 1;
    border-color: rgb(57 87 219/var(--tw-border-opacity));
    border-radius: var(--border-radius-full) !important;
    background-color: rgba(250, 250, 250, 0.2) !important;
    border-color: rgba(250, 250, 250, 0.3) !important;
}

.border-\[\#3957db\] {
    --tw-border-opacity: 1;
    border-color: rgb(57 87 219/var(--tw-border-opacity));
    border-radius: var(--border-radius-full) !important;
    background-color: rgba(250, 250, 250, 0.2) !important;
    border-color: rgba(250, 250, 250, 0.3) !important;
    color: rgb(250, 250, 250) !important;
}

svg.absolute.right-2.top-1\.5.cursor-pointer {
    width: 25px !important;
    height: auto !important;
}

.bg-\[\#fff\] {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255/var(--tw-bg-opacity));
    background-color: rgba(250, 250, 250, 0.2) !important;
}

.relative.h-\[60px\].mt-\[10px\].w-\[270px\].hidden.\31 000px\:block {
    bottom: 0;
    margin-bottom: -25px !important;
}

h3.m-3.cursor-pointer.select-none {
    color: rgb(250, 250, 250) !important;
}

.pb-4.w-\[270px\].bg-\[\#fff\].absolute.z-30.rounded-b-md.shadow-sm {
    background-color: rgba(0,0,0,0.5) !important;
}

.fixed.top-0.right-0.h-full.w-\[80\%\].\38 00px\:w-\[25\%\].bg-white.flex.flex-col.overflow-y-scroll.justify-between.shadow-sm {
    background-color: rgba(0,0,0,0.8) !important;
}



/*--------------2nd Update--------------*/

/* Comment Box */
.css-qiux0o {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(250, 250, 250, 0.87) !important;
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5) !important;
    border-radius: 2rem;
    padding: 1rem 2rem;
}

/* Post Button */

.css-cda2fy.Mui-disabled {
    color: rgba(250, 250, 250, 1.0);
}

.css-cda2fy {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 8px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: rgb(40, 158, 49);
    background-color: rgb(70, 211, 49) !important;
    border-radius: 3rem;
}

/* Add Friend Button */
.css-ozo1r7 {
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentcolor;
    flex-shrink: 0;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 1.5rem;
    color: rgb(250, 250, 250) !important;
}

.css-11gap41 {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    text-align: center;
    flex: 0 0 auto;
    font-size: 1.5rem;
    border-radius: 50%;
    overflow: visible;
    color: rgba(0, 0, 0, 0.54);
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: rgb(70, 211, 0) !important;
    padding: 0.6rem;
}

/* Posted Content Blocks */

.css-1l1ah9e {
    margin: 2rem 0px;
    padding: 1.5rem 1.5rem 0.75rem;
    background-color: rgba(255, 255, 255, 0.2) !important;
    border-radius: 0.75rem;
}


.css-1ug174f {
    padding: 1.5rem 1.5rem 0.75rem;
    background-color: rgb(255, 255, 255);
    border-radius: 0.75rem;
    background-color: rgba(250, 250, 250, 0.2) !important;
    color: rgb(250, 250, 250) !important;
}

.bg-black {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0/var(--tw-bg-opacity));
    background-color: var(--background-color) !important;
}

.top-8 {
    top: 0 !important;
}

.text-\[\#3a24db\] {
    --tw-text-opacity: 1;
    color: rgb(58 36 219/var(--tw-text-opacity));
    color: rgb(250, 250, 250) !important;
}

.border-\[\#3a24db\] {
    --tw-border-opacity: 1;
    border-color: rgb(58 36 219/var(--tw-border-opacity));
    border: none !important;
    background-color: rgb(70, 211, 0) !important;
    border-radius: var(--border-radius-full) !important;
    margin-left: auto !important;
    margin-right: auto !important;
    text-align: center !important;
}

.rounded-\[5px\] {
    border-radius: var(--border-radius-full) !important;
}

input.w-full.border.p-1.rounded-\[5px\].\!w-\[95\%\].mb-4.\38 00px\:mb-0 {
    background-color: rgba(250, 250, 250, 0.2) !important;
    border: none !important;
    padding: 10px !important;
}

input.w-full.border.p-1.rounded-\[5px\].\!w-\[95\%\].mb-1.\38 00px\:mb-0 {
    background-color: rgba(250, 250, 250, 0.2) !important;
    border: none !important;
    padding: 10px !important;
}

.bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(21 128 61/var(--tw-bg-opacity));
    background-color: rgb(70, 211, 0) !important;
    border-radius: var(--border-radius-full) !important;
}

.text-\[red\] {
    --tw-text-opacity: 1;
    color: rgb(255 0 0/var(--tw-text-opacity));
    color: rgb(70, 211, 0) !important;
    font-weight: 500 !important;
}

.css-1rt5dsz {
    margin: 0px 0px 1.5rem;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 1.5rem;
    line-height: 1.334;
    letter-spacing: 0em;
    color: rgba(250, 250, 250, 0.7) !important;
    font-weight: 500;
}

label.block.pb-2 {
    color: rgba(250, 250, 250, 0.7) !important;
}



/*---------UPDATE 3--------*/

.css-1fzwgfs {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: rgba(250, 250, 250, 0.7) !important;
}

.css-76kev2 {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: rgba(250, 250, 250, 0.7) !important;
}

.w-\[35px\] {
    width: 35px;
    height: auto;
}




/*------------Update 4----------------*/

.w-\[90\%\].\38 00px\:w-\[60\%\].h-\[90vh\].overflow-y-scroll.\38 00px\:h-\[75vh\].bg-white.rounded-md.shadow-sm.relative.p-4 {
    background-color: rgba(0,0,0,0.8) !important;
    padding: 25px !important;
    color: rgb(250, 250, 250) !important;
}

.w-full.\38 00px\:w-\[50\%\].pt-5.pl-\[5px\].pr-\[5px\] {
    padding: 25px !important;
}

aside.sticky.top-24.hidden.w-44.shrink-0.lg\:block.bg-\[\#f5f5f5\].p-2.rounded-lg {
    background-color: rgba(250, 250, 250, 0.2) !important;
}

.bg-\[\#f5f5f5\] {
    --tw-bg-opacity: .2;
    background-color: rgb(250 250 250/var(--tw-bg-opacity));
    background-color: rgba(250, 250, 250, 0.2) !important;
}

.bg-black {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0/var(--tw-bg-opacity));
    background-color: rgba(0, 0, 0, 0.8) !important;
    border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.css-10yzw17 {
    gap: 0.25rem;
    display: inline-block !important;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    width: 22%;
}

.css-cda2fy.Mui-disabled {
    color: rgba(250, 250, 250, 1.0) !important;
}

.css-1sb2j9v {
    gap: 1rem;
    display: inline-block;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
}

.css-11gap41 {
    display: inline-block;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    text-align: center;
    flex: 0 0 auto;
    font-size: 1.5rem;
    border-radius: 50%;
    overflow: visible;
    color: rgba(0, 0, 0, 0.54);
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: rgb(27, 242, 73);
    padding: 0.6rem;
    position: relative;
    width: 45px !important;
    height: 45px !important;
    float: right;
}

.css-1sb2j9v {
    gap: 1rem;
    display: inline-block;
    position: relative !important;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    /* float: left; */
}

.css-1g4v7g4 {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 1.5rem;
    line-height: 1.334;
    letter-spacing: 0em;
    color: rgb(250, 250, 250) !important;
    font-weight: 500;
}

.border-\[2px\] {
    border-width: 1px !important;
}

.css-qiux0o {
    border: 1px solid var(--border-color) !important;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-ei7csf {
    width: 45px !important;
    height: auto !important;
    color: rgb(250, 250, 250) !important;
    padding-top: 15px !important;
}

hr.MuiDivider-root.MuiDivider-fullWidth.css-1drwh4k {
    margin-top: 25px !important;
    border-color: rgba(250, 250, 250, 0.3) !important;
}


/*-------Update 5----------*/

button.text-gray-500.border-transparent.hover\:text-gray-700.whitespace-nowrap.py-4.px-1.border-b-2.font-medium.text-sm {
    text-align: center !important;
    margin: auto !important;
}

input.MuiInputBase-input.css-mnn31 {
    background: transparent !important;
}

.MuiBox-root.css-dlj3h8 {
    display: inline-block !important;
}

.md\:flex.md\:justify-between.md\:items-center.sm\:px-12.px-4.bg-green-700.py-7 {
    border-radius: 0 !important;
}

.md\:flex.md\:justify-between.md\:items-center.sm\:px-12.px-4.bg-green-700.py-7 {
    border-radius: 0 !important;
    background-color: rgba(250, 250, 250, 0.3) !important;
}

.bg-\[\#56d879\] {
    --tw-bg-opacity: 1;
    background-color: rgb(86 216 121/var(--tw-bg-opacity));
    background-color: rgb(70, 211, 0) !important;
}

.text-\[\#56d879\] {
    --tw-text-opacity: 1;
    color: rgb(86 216 121/var(--tw-text-opacity));
    color: rgb(70, 211, 0) !important;
}

input.text-gray-800.sm\:w-72.w-full.sm\:mr-5.mr-1.lg\:mb-0.mb-4.py-2\.5.rounded.px-2.focus\:outline-none {
    border-radius: var(--border-radius-full) !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    padding-left: 25px !important;
}

input.text-gray-800.sm\:w-72.w-full.sm\:mr-5.mr-1.lg\:mb-0.mb-4.py-2\.5.rounded.px-2.focus\:outline-none {
    border-radius: var(--border-radius-full) !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    padding-left: 25px !important;
    border: 1px solid var(--border-color) !important;
}

button.bg-\[\#56d879\].hover\:bg-teal-500.duration-300.px-5.py-2\.5.rounded-md.text-whie.md\:w-auto.w-full {
    border-radius: var(--border-radius-full) !important;
}

input.h-\[40px\].w-full.px-2.border-\[\#3957db\].border-\[2px\].rounded-md {
    padding-left: 25px !important;
}

h5.pt-3.text-\[15px\].text-blue-400.pb-3 {
    border-top: 1px solid rgba(250, 250, 250, 0.2) !important;
    margin-top: 15px !important;
}

.bg-\[\#f5f6fb\].px-3.\38 00px\:px-10.py-2.rounded {
    background-color: rgba(250, 250, 250, 0.2) !important;
    color: rgb(250, 250, 250) !important;
    border-radius: 15px !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.text-\[\#000\] {
    --tw-text-opacity: 1;
    color: rgb(0 0 0/var(--tw-text-opacity));
    color: rgb(250, 250, 250) !important;
}

svg.absolute.right-2.top-1\.5.cursor-pointer {
    width: 25px !important;
    height: auto !important;
    margin-right: 10px !important;
}

.pb-4.w-\[270px\].bg-\[\#fff\].absolute.z-30.rounded-b-md.shadow-sm {
    background-color: rgba(0,0,0,0.8) !important;
}

.bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(21 128 61/var(--tw-bg-opacity));
    border-radius: var(--border-radius-full) !important;
}

button.bg-gradient-to-r.from-teal-400.to-teal-500.text-white.font-bold.rounded-l.px-4.py-2.shadow-lg.hover\:opacity-75.transition.duration-300.ease-in-out {
    border-radius: 0px !important;
    /* background-color: rgb(0,0,0,0.7) !important; */
    border: 1px solid rgb(70, 211, 0) !important;
}

.text-blue-400 {
    --tw-text-opacity: 1;
    color: rgb(250 250 250/var(--tw-text-opacity));
    color: rgb(250, 250, 250) !important;
    font-size: 20px !important;
    text-transform: capitalize !important;
}

.css-5vij8j {
    color: rgb(250, 250, 250) !important;
}


/*------Update 6----------*/

.bg-white {
    --tw-bg-opacity: .2;
    background-color: rgb(255 255 255/var(--tw-bg-opacity));
    background-color: rgb(250, 250, 250, 0.2) !important;
    color: #ffffff !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.relative.h-\[60px\].mt-\[10px\].w-\[270px\].hidden.\31 000px\:block {
    bottom: 0;
    margin-bottom: -29px !important;
}

.MuiBox-root.css-1ug174f {
    background-color: rgba(250, 250, 250, 0.2) !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.w-\[50\%\].relative {
    width: 25% !important;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-ei7csf {
    width: 25px !important;
    height: auto !important;
    color: rgb(250, 250, 250) !important;
    padding-top: 15px !important;
    text-align: center !important;
}

aside.sticky.top-24.hidden.w-44.shrink-0.lg\:block.bg-\[\#f5f5f5\].p-2.rounded-lg {
    background-color: rgba(250, 250, 250, 0.2) !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.bg-\[\#f5f5f5\] {
    --tw-bg-opacity: .2;
    background-color: rgb(250 250 250/var(--tw-bg-opacity));
    background-color: rgba(250, 250, 250, 0.2) !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.css-1l1ah9e {
    margin: 2rem 0px;
    padding: 1.5rem 1.5rem 0.75rem;
    background-color: rgb(255, 255, 255);
    border-radius: 0.75rem;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.bg-gray-900 {
    --tw-bg-opacity: .2;
    background-color: rgb(250 250 250/var(--tw-bg-opacity));
    background-color: rgba(250, 250, 250, 0.2) !important;
    border: 1px solid var(--border-color) !important;
    border-left: 0px !important;
}

.share-button {
    align-items: center;
    border-radius: var(--border-radius-full);
    display: flex;
    font-size: 18px !important;
    padding: 20px;
    text-decoration: none;
    transition: background-color .3s ease;
    width: 45px !important;
    height: 45px !important;
    text-align: center !important;
    margin-top: 25px !important;
}

h4.text-\[\#000000a6\] {
    text-transform: capitalize !important;
}

.md\:justify-between {
    justify-content: space-evenly !important;
}

h1.lg\:text-4xl.text-3xl.md\:mb-0.mb-6.lg\:leading-normal.font-semibold.md\:w-2\/5 {
    float: right !important;
    display: inline-block !important;
    position: relative !important;
    margin-right: 0px !important;
    padding-right: 0px !important;
    text-align: right !important;
}

/*Profile Pic*/
img.w-\[150px\].h-\[150px\].object-cover.rounded-full {
    border: 2px solid rgba(2500, 250, 250, 1.0) !important;
}

button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.css-11gap41 {
    display: none !important;
}

/*.pointer-events-none.fixed.inset-y-0.right-0.flex.max-w-full.pl-10.sm\:pl-16 {
    top: 100px !important;
}*/

.bg-\[\#E3E9EE\] {
    --tw-bg-opacity: .7 !important;
    background-color: rgb(0 0 0/var(--tw-bg-opacity)) !important;
    border: 1px solid rgba(250, 250, 250, 0.5) !important;
}

.relative.h-\[60px\].mt-\[10px\].w-\[270px\].hidden.\31 000px\:block {
    bottom: 0;
    margin-bottom: -29px!important;
    margin-left: 15px !important;
    margin-right: 15px !important;
}

.w-\[42\%\] {
    width: 25%;
}
.pb-4.pt-16.w-\[270px\].absolute.z-30.rounded-b-md.shadow-sm {
    background-color: rgba(0,0,0,0.8) !important;
}

button.h-full.w-full.flex.justify-between.items-center.pl-10.bg-white.font-sans.text-lg.font-\[500\].select-none.rounded-t-md {
    z-index: 1000000 !important;
}

.border-\[\#3957db\], svg.absolute.right-2.top-4.cursor-pointer {
    color: #fafafa!important;
    z-index: 100000000 !important;
}

.py-5 {
    padding-bottom: 3.25rem !important;
    padding-top: 3.25rem !important;
}

.border.cursor-pointer {
    margin-top: 25px !important;
    border: 1px solid #ffffff !important;
    padding: 15px !important;
}

.mt-3 {
    margin-top: 0rem;
}
.mr-3 {
    margin-right: 0rem;
}

.border.cursor-pointer {
    margin-top: 45px !important;
    margin-right: 15px !important;
    border: 1px solid #ffffff !important;
    padding-top: 0px !important;
    padding: 15px !important;
}

.relative.h-\[60px\].mt-\[10px\].w-\[270px\].hidden.\31 000px\:block {
    bottom: 0;
    margin-bottom: -25px!important;
    margin-left: 15px!important;
    margin-right: 15px!important;
    border-bottom: none !important;
}

/*Add Prod Button*/



.h-\[200px\] {
    height: 100px !important;
}

.w-\[150px\].bg-green-700.h-\[50px\].my-3.flex.items-center.justify-center.rounded-xl.cursor-pointer.bg-\[\#6443d1\].mt-4.\!rounded.\!h-11 {
    background-color: #777777 !important;
    border: 1px solid var(--border-color) !important;
}

button.bg-gradient-to-r.from-teal-400.to-teal-500.text-white.font-bold.rounded-l.px-4.py-2.shadow-lg.hover\:opacity-75.transition.duration-300.ease-in-out {
    border-radius: 24px;
    margin-left: 15px !important;
    margin-right: 15px !important;
    font-size: 16px !important;
    background-color: rgba(250, 250, 250, 0.2) !important;
    background-image: none !important;
    border-color: rgba(250, 250, 250, 0.2) !important;
    border: 2px solid rgba(250, 250, 250, 0.2) !important;
}

.ml-1 {
    margin-left: 0.25rem;
    font-size: 25px !important;
}

.bg-\[\#e44343\] {
    --tw-bg-opacity: 1;
    background-color: rgb(228 67 67/var(--tw-bg-opacity));
    background-color: rgb(70,211,0) !important;
}

.text-\[16px\] {
    font-size: 16px;
    color: #ffffff !important;
    text-transform: capitalize !important;
}

.bg-\[\#f63b60\] {
    --tw-bg-opacity: 1;
    background-color: rgb(70 211 0/var(--tw-bg-opacity));
}

.\!bg-\[\#FDE1E6\] {
    --tw-bg-opacity: .5 !important;
    background-color: rgb(225 225 225/var(--tw-bg-opacity))!important;
}

.\!text-\[\#f63b60\] {
    --tw-text-opacity: 1!important;
    color: rgb(225 225 225/var(--tw-text-opacity))!important;
}

input.text-gray-800.sm\:w-72.w-full.sm\:mr-5.mr-1.lg\:mb-0.mb-4.py-2\.5.rounded.px-2.focus\:outline-none {
    background-color: rgba(0,0,0,.5)!important;
    border: 1px solid hsla(0,0%,98%,.3)!important;
    border-radius: var(--border-radius-full)!important;
    padding-left: 25px!important;
    color: #ffffff !important;
}

svg.text-green-700.mr-3.h-6.w-6.flex-shrink-0 path {
    color: rgb(70, 211, 0) !important;
    border-bottom: none !important;
}

svg.text-green-700.mr-3.h-6.w-6.flex-shrink-0 > path {
    border-bottom: none!important;
    color: var(--primary-color)!important;
}

.css-snk24v {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 0.9rem;
    line-height: 1.334;
    letter-spacing: 0em;
    color: rgba(250, 250, 250, 0.5) !important;
    font-weight: 500;
}

/*-----Dashboard-------*/

/*.w-full.h-\[80px\].bg-white.shadow.sticky.top-0.left-0.z-30.flex.items-center.justify-between.px-4 div a > img {
    width: 37% !important;
    height: auto !important;
}*/

.text-\[\#555\] {
    --tw-text-opacity: 1;
    color: rgb(85 85 85/var(--tw-text-opacity));
    color: rgba(250, 250, 250, 0.9) !important;
}

.text-\[crimson\] {
    --tw-text-opacity: 1;
    color: rgb(220 20 60/var(--tw-text-opacity));
    color: rgb(70, 211, 0) !important;
}

/*Ellipsis*/

/*.css-mh3zap {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: var(--unstable_DataGrid-headWeight);
}*/

.MuiDataGrid-root.MuiDataGrid-autoHeight.MuiDataGrid-root--densityStandard.MuiDataGrid-withBorderColor.css-78d1bb {
    color: #ffffff !important;
}

.css-78d1bb {
    --unstable_DataGrid-radius: 4px;
    --unstable_DataGrid-headWeight: 500;
    --unstable_DataGrid-overlayBackground: rgba(255, 255, 255, 0.38);
    --DataGrid-cellOffsetMultiplier: 2;
    flex: 1 1 0%;
    box-sizing: border-box;
    position: relative;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(224, 224, 224);
    border-radius: var(--unstable_DataGrid-radius);
    color: rgba(250, 250, 250, 1.0) !important;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    outline: none;
    height: 100%;
    display: flex;
    min-width: 0px;
    min-height: 0px;
    flex-direction: column;
    overflow-anchor: none;
}

p#\:r4p\: {
    color: rgba(250, 250, 250, 0.9) !important;
}

blockquote, dd, dl, figure, h1, h2, h3, h4, h5, h6, hr, p, pre {
    margin: 0;
    color: inherit;
}

.css-78d1bb.MuiDataGrid-autoHeight {
    height: auto;
}
.css-78d1bb {
    --unstable_DataGrid-radius: 4px;
    --unstable_DataGrid-headWeight: 500;
    --unstable_DataGrid-overlayBackground: rgba(255, 255, 255, 0.38);
    --DataGrid-cellOffsetMultiplier: 2;
    flex: 1 1 0%;
    box-sizing: border-box;
    position: relative;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(224, 224, 224);
    border-radius: var(--unstable_DataGrid-radius);
    color: rgba(250, 250, 250, 1.0) !important;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    outline: none;
    height: 100%;
    display: flex;
    min-width: 0px;
    min-height: 0px;
    flex-direction: column;
    overflow-anchor: none;
    padding: 25px !important;
}

.w-full.mx-8.pt-1.mt-10.bg-white {
    padding: 25px !important;
}

.w-full.min-h-\[45vh\].bg-white.rounded {
    padding: 25px !important;
}

.share-buttons {
    display: flex;
    justify-content: space-evenly !important;
}

.share-button {
    align-items: center;
    border-radius: var(--border-radius-full) !important;
    display: flex;
    font-size: 18px !important;
    padding: 15px !important;
    text-decoration: none;
    transition: background-color .3s ease;
}

.inner-container {
    background-color: var(--background-color) !important;
    border: 1px solid rgba(250, 250, 250, 0.1) !important;
}

.outer-container {
    background-color: rgba(250, 250, 250, 0.2) !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.relative.min-h-\[70vh\].\38 00px\:min-h-\[80vh\].w-full.bg-no-repeat.flex.items-center {
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.w-full.h-\[420px\].rounded-lg.shadow-sm.p-3.relative.cursor-pointer {
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
    padding-top: 25px !important;
}


.inner-container {
background-color: rgba(250, 250, 250, 0.0) !important;
	border: 0px !important;
}

input.w-full.border.p-1.rounded-\[5px\] {
    background-color: rgb(45,45,45) !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.bg-slate-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(226 232 240/var(--tw-bg-opacity));
    background-color: rgba(250, 250, 250, 0.3) !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}


/*------Messaging------*/
/*.text-\[\#fff\], .text-\[\#ffffff\] {
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity));
    display: block !important;
    position: relative !important;
    padding: 25px !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
    background-color: rgba(250, 250, 250, 0.5) !important;
}

.text-\[\#fff\], .text-\[\#ffffff\] {
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity));
    display: block !important;
    position: relative !important;
    padding: 25px !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
    background-color: rgba(250, 250, 250, 0.5) !important;
    border-radius: 14px !important;
}*/

.w-full.flex.p-3.px-3.bg-\[\#00000010\].cursor-pointer {
    border-bottom: 1px solid rgba(250, 250, 250, 0.3) !important;
}

.w-full.flex.p-3.px-3.bg-transparent.cursor-pointer {
    border-bottom: 1px solid rgba(250, 250, 250, 0.3) !important;
}

aside.outer-container div .p-3 {
    border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
}

aside.outer-container div .p-3 h5 {
    font-size: 12px !important;
}

h5.font-\[600\] {
    font-size: .2em !important;
	color: rgba(250, 250, 250, 0.4) !important;
}

.profile-dropdown {
    z-index: 1000000 !important;
    overflow: inherit !important;
}

.hover\:bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(70 211 0/var(--tw-bg-opacity)) !important;
}

div#headlessui-menu-items-\:rq\: {
    display: block;
    position: fixed !important;
    margin-top: 25px !important;
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
    background-color: rgba(0, 0, 0, 0.8) !important;
    color: rgb(250, 250, 250) !important;
}

.text-gray-700 {
    --tw-text-opacity: 1;
    color: rgb(55 65 81/var(--tw-text-opacity));
    color: rgb(250, 250, 250) !important;
}

a#headlessui-menu-item-\:rr\: {
    border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
}

a#headlessui-menu-item-\:rs\: {
    border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
}

a#headlessui-menu-item-\:rt\: {
    border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
}

/*body {
    background: rgba(14, 17, 22, 0.7) !important;
}*/
/*----rgba(14, 17, 22, 0.7)-----*/

.css-1r1fc2u {
    background-color: rgba(250, 250, 250, 0.7) !important;
}

.css-1r1fc2u {
    padding: 1.5rem 1.5rem 0.75rem;
    background-color: rgb(74, 74, 74);
    border-radius: 0.75rem;
}

.css-elpoka {
    margin: 2rem 0px;
    padding: 1.5rem 1.5rem 0.75rem;
    background-color: var(--background-color) !important;
    border-radius: 0.75rem;
}


/*-----NUMBER SOLD---------*/
span.font-\[400\].text-\[17px\].text-\[\#68d284\] {
    display: none !important;
}


/*-----Cart Titles---------*/
h4.pb-3.font-\[500\].items-center {
    text-transform: capitalize !important;
}

.md\:flex.md\:justify-between.md\:items-center.sm\:px-12.px-4.bg-green-700.py-7 {
    background-color: rgba(14, 17, 22, 0.7) !important;
    border-top: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.grid.grid-cols-1.sm\:gird-cols-3.lg\:grid-cols-4.gap-6.sm\:px-8.px-5.py-16.sm\:text-center {
    border-top: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.pb-4.pt-16.w-\[270px\].absolute.z-30.rounded-b-md.shadow-sm {
    border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
    border-left: 1px solid rgba(250, 250, 250, 0.2) !important;
    border-right: 1px solid rgba(250, 250, 250, 0.2) !important;
}

h1.text-\[\#fff\].text-\[18px\].font-\[600\] {
    background-color: transparent !important;
    border: 0px !important;
}

.text-\[\#475ad2\] {
    --tw-text-opacity: 1;
    color: rgb(70 211 0/var(--tw-text-opacity)) !important;
}


.bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(70 211 0/var(--tw-bg-opacity));
}

a.bg-indigo-500.text-white.shadow-sm.hover\:bg-green-400.focus-visible\:outline-green-500.mt-6.block.rounded-md.py-2.px-3.text-center.text-sm.font-semibold.leading-6.focus-visible\:outline.focus-visible\:outline-2.focus-visible\:outline-offset-2 {
    background-color: rgb(70, 211, 0) !important;
    border-radius: var(--border-radius-full) !important;
}

.ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    border: 1px solid rgb(70, 211, 0) !important;
}

span.pr-3.font-\[400\].text-\[17px\].text-\[\#44a55e\] {
    display: none !important;
}

div#headlessui-menu-items-\:rb\: {
	position: fixed !important;
}

.profile-header.bg-green-700.h-24.sm\:h-20.lg\:h-28 {
    border-radius: 0px !important;
    background-color: var(--background-color) !important;
    border-top: 1px solid rgba(250, 250, 250, 0.2) !important;
    border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
    margin-bottom: 25px !important;
}

@media (min-width: 1024px) {
    .lg\:px-24 {
      padding-left: 6rem;
      padding-right: 6rem;
      padding-top: 45px !important;
    }
  }


.min-h-screen.bg-gray-50.flex.flex-col.justify-center.py-12.sm\:px-6.lg\:px-8 {
    background-color: rgba(15, 17, 22, 1.0) !important;
}


h2.mt-6.text-center.text-3xl.font-extrabold.text-gray-900 {
    text-transform: capitalize !important;
}

label.block.text-sm.font-medium.text-gray-700 {
    text-transform: capitalize !important;
}

.bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(37 99 235/var(--tw-bg-opacity));
    background-color: rgb(70, 211, 0) !important;
    border-radius: var(--border-radius-full) !important;
    width: 100% !important;
    max-width: 50% !important;
    margin: auto !important;
}

.text-blue-600 {
    --tw-text-opacity: 1;
    color: rgb(70 211 0/var(--tw-text-opacity)) !important;
}

.mt-8.sm\:mx-auto.sm\:w-full.sm\:max-w-\[35rem\] .flex.items-center.w-full {
    text-align: center !important;
    margin: auto !important;
    display: block !important;
    position: relative !important;
    margin-top: 25px !important;
    margin-bottom: 15px !important;
}

input.appearance-none.block.w-full.px-3.py-2.border.border-gray-300.rounded-md.shadow-sm.placeholder-gray-400.focus\:outline-none.focus\:ring-blue-500.focus\:border-blue-500.sm\:text-sm {
    background-color: rgba(250, 250, 250, 0.5) !important;
    color: rgb(14, 17, 22) !important;
    border-radius: var(--border-radius-full) !important;
    border: 1px solid var(--border-color) !important;
}

.text-gray-900 {
    --tw-text-opacity: 1;
    color: rgb(17 24 39/var(--tw-text-opacity));
    color: rgba(250, 250, 250, 0.7) !important;
}

.relative.inline-block {
    float: right !important;
}

div#headlessui-popover-panel-\:r6\: {
    margin-top: 35px !important;
}

.text-green-500 {
    --tw-text-opacity: 1;
    color: rgb(70 211 0/var(--tw-text-opacity)) !important;
}

button.text-green-500.border-green-500.whitespace-nowrap.py-4.px-1.border-b-2.font-medium.text-sm {
    border-bottom: 2px solid rgb(70, 211, 0) !important;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.Mui-disabled.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.Posts_css-cda2fy__xgrti.css-1ujsas3 {
    background-color: rgb(70, 211, 0) !important;
    border-radius: var(--border-radius-full) !important;
    color: rgb(250, 250, 250) !important;
    width: 20% !important;
}

.profile-dropdown {
    overflow: inherit!important;
    z-index: 1000000!important;
    display: inline-flex !important;
    position: fixed !important;
    margin-top: 25px !important;
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
    border-top: 0px !important;
    border-bottom-right-radius: 0px !important;
    width: auto !important;
}

a#headlessui-menu-item-\:r1\:,a#headlessui-menu-item-\:r2\:,a#headlessui-menu-item-\:r3\:,a#headlessui-menu-item-\:r4\: {
    background-color: rgba(0, 0, 0, 0.2) !important;
	border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
}

a:hover#headlessui-menu-item-\:r1\:,a:hover#headlessui-menu-item-\:r2\:,a:hover#headlessui-menu-item-\:r3\:,a:hover#headlessui-menu-item-\:r4\: {
    background-color: rgba(0, 0, 0, 0.2) !important;
	border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.css-snk24v {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 0.9rem !important;
    line-height: 1.334;
    letter-spacing: 0em;
    color: rgb(18, 66, 22);
    font-weight: 500;
}


/*Posts Top Title*/
h3.px-4.py-2.text-xl.font-semibold.text-gray-900 {
    display: none !important;
}


h3.text-center.py-2.text-\[20px\] {
    overflow: hidden;
    text-overflow: ellipsis;
}

.profile-header.bg-green-700.h-24.sm\:h-20.lg\:h-28 {
    background-color: hsla(0,0%,98%,.1)!important;
    border-bottom: 1px solid hsla(0,0%,98%,.2)!important;
    border-radius: 0!important;
    border-top: 1px solid hsla(0,0%,98%,.2)!important;
    margin-bottom: 25px!important;
    display: none !important;
}


/*Profile Header*/
.profile-header.bg-green-700.h-24.sm\:h-20.lg\:h-28 {
    background-color: hsla(0,0%,98%,.1)!important;
    border-bottom: 1px solid hsla(0,0%,98%,.2)!important;
    border-radius: 0!important;
    border-top: 1px solid hsla(0,0%,98%,.2)!important;
    margin-bottom: 25px!important;
    display: none !important;
}

img.w-\[150px\].h-\[150px\].object-cover.rounded-full {
    border: 2px solid snow!important;
    display: block!important;
    height: 95px!important;
    width: 95px!important;
}

#root .profile-page img.w-full.object-cover {
    height: 450px !important;
}


/* Cart Image */

img.w-\[125px\].h-min.ml-2.mr-2.rounded-\[5px\] {
    border-radius: 0px !important;
    border: 1px solid rgba(250, 250, 250, 0.7) !important;
}

svg.cursor-pointer.\38 00px\:mb-\[\'unset\'\].\38 00px\:ml-\[\'unset\'\].mb-2.ml-2 {
    margin-left: 0 !important;
}

.w-full.\38 00px\:flex.items-center {
    width: 100% !important;
}

.product-description {
	font-size: 16px;
	color: rgba(250, 250, 250, 0.8) !important;
    text-transform: capitalize !important;
}

.content-bg {
    background-color: rgba(14,17,22,.7) !important;
}

.bg-drk {
	background-color: rgba(14,17,22,.2) !important;
}

/*Search Dropdown*/
.search-drop {
    background-color: rgba(0, 0, 0, 0.4) !important;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    border-left: 1px solid var(--border-color);
    border-right: 1px solid var(--border-color);
    border-bottom: 1px solid rgba(250, 250, 250, 0.3) !important;
    width: 100% !important;
    border-top: 1px solid rgba(250, 250, 250, 0.3) !important;
    border-radius: 10px !important;
}


/*Categories Drop Down*/
button.h-full.w-full.flex.justify-between.items-center.pl-10.font-sans.text-lg.font-\[500\].select-none.rounded-t-md {
    z-index: 2000000 !important;
}

.css-miw4ts {
    background-color: var(--background-color) !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.bg-\[\#f5f5f5\] {
    --tw-bg-opacity: .2;
    background-color: rgb(250 250 250/var(--tw-bg-opacity));
    background-color: hsla(0,0%,98%,.2)!important;
    border: 1px solid hsla(0,0%,98%,.2)!important;
    background-color: var(--background-color) !important;
}

.fixed.top-0.right-0.h-full.w-\[80\%\].\38 00px\:w-\[25\%\].bg-white.flex.flex-col.overflow-y-scroll.justify-between.shadow-sm {
    background-color: rgba(0,0,0,.8)!important;
    top: 98px !important;
}

h3.text-xl.font-bold.text-green-500.sm\:text-2xl {
    color: #ffffff !important;
    line-height: 35px !important;
}

p.text-sm.text-green-500 {
    padding-bottom: 15px !important;
}

.profile-page .w-full {
    border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.css-mnn31 {
    font: inherit;
    letter-spacing: inherit;
    color: rgb(250, 250, 250) !important;
    padding: 4px 0px 5px;
    border: 0px;
    box-sizing: content-box;
    background: none;
    height: 1.4375em;
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0px;
    width: 100%;
    animation-name: mui-auto-fill-cancel;
    animation-duration: 10ms;
}

.css-1r4ju6z {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    background-color: var(--background-color);
    border-radius: 2rem;
    padding: 1rem 2rem;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.css-dejdd {
    width: 55px;
    height: 55px;
    border: 1px solid rgba(250, 250, 250, 0.7) !important;
    border-radius: 100% !important;
}

img.h-10.w-10.rounded-full {
    border: 1px solid rgba(250, 250, 250, 0.5) !important;
}

.css-1sb2j9v {
    gap: 1rem;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    text-align: center !important;
}

.css-10yzw17 {
    gap: 0.25rem;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    text-align: center !important;
}

.MuiBox-root.css-dejdd>img {
    height: 55px !important;
    width: 55px !important;
}

.user-info.px-4.py-5.sm\:px-6 {
    width: 86% !important;
    height: 100px !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
    margin: auto !important;
    border-radius: 10px !important;
    margin-top: 35px !important;
    vertical-align: middle !important;
}

.mx-auto.flex.w-full.items-start.gap-x-8.px-4.py-10.sm\:px-6.lg\:px-24 {
    padding-top: 25px !important;
}

button.inline-flex.items-center.rounded-md.bg-green-600.px-3.py-2.text-sm.font-semibold.text-white.shadow-sm.hover\:bg-green-500 {
    background-color: rgb(70, 211, 0) !important;
    border-radius: var(--border-radius-full) !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    margin-bottom: 0px !important;
}

textarea.block.w-full.resize-none.border-0.bg-transparent.px-1\.5.py-1\.5.text-white.placeholder\:text-white.focus\:border-green-500.focus\:ring-0.sm\:text-sm {
    width: 100% !important;
}


.h-\[200px\] {
    height: 100px!important;
    width: 100px !important;
}




/*-----MOBILE--------*/

/* hi-res laptops and desktops */ 
@media (max-width:1281px) { 
	
	.py-1 {
    display: none !important;
}
	
	.null.w-full.h-\[60px\].bg-\[\#fff\].z-50.top-0.left-0.shadow-sm.\38 00px\:hidden {
    padding-top: 10px !important;
}
	
.fixed.w-\[70\%\].bg-\[\#fff\].h-screen.top-0.left-0.z-10.overflow-y-scroll {
    background-color: rgba(0, 0, 0, 0.7) !important;
    border-right: 1px solid rgba(250, 250, 250, 0.2) !important;
    color: rgb(250, 250, 250) !important;
}	
	.text-black {
    --tw-text-opacity: 1;
    color: rgb(0 0 0/var(--tw-text-opacity));
    color-interpolation: linearRGB;
    color: rgb(250, 250, 250) !important;
}
}

/* big landscape tablets, laptops, and desktops */
@media (max-width:1025px) {  }

/* tablet, landscape iPad, lo-res laptops ands desktops */ 
@media (max-width:961px)  { 
	
	.px-\[20px\].h-\[38px\].rounded-\[20px\].bg-\[\#f63b60\].flex.items-center.justify-center.cursor-pointer .text-\[16px\] {
    color: #fff!important;
    font-size: 14px;
    text-transform: capitalize !important;
    padding-bottom: 15px !important;
    padding-top: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 10px !important;
}
	.px-\[20px\].h-\[38px\].rounded-\[20px\].bg-\[\#f63b60\].flex.items-center.justify-center.cursor-pointer {
    display: inline-block!important;
    padding-left: 10px;
    padding-right: 10px!important;
    padding-top: 5px!important;
    position: relative!important;
    text-align: center!important;
    width: 6.5em !important;
}
	.profile-main.-mt-12.px-4.sm\:-mt-8.sm\:flex.sm\:items-end.sm\:px-6.lg\:-mt-16.lg\:px-24 {
    text-align: center !important;
}
	.inline-flex.overflow-hidden.border-4.border-white {
    margin: auto !important;
}
	
	h3.text-xl.font-bold.text-green-500.sm\:text-2xl {
    color: #fff!important;
    line-height: 35px!important;
    text-align: center !important;
    margin: auto !important;
}
	
	.fixed.w-\[70\%\].bg-\[\#fff\].h-screen.top-0.left-0.z-10.overflow-y-scroll {
    background-color: rgba(0, 0, 0, 0.7) !important;
    border-right: 1px solid rgba(250, 250, 250, 0.2) !important;
    color: rgb(250, 250, 250) !important;
}
}

@media (min-width:800px) and (max-width:995px)  { 
	.px-\[20px\].h-\[38px\].rounded-\[20px\].bg-\[\#f63b60\].flex.items-center.justify-center.cursor-pointer {
    display: inline-block!important;
    padding-left: 10px;
    padding-right: 10px!important;
    padding-top: 5px!important;
    position: relative!important;
    text-align: center!important;
    width: 6.0em !important;
}
	
	.px-\[20px\].h-\[38px\].rounded-\[20px\].bg-\[\#f63b60\].flex.items-center.justify-center.cursor-pointer .text-\[16px\] {
    color: #fff!important;
    font-size: 14px;
    text-transform: capitalize !important;
    padding-bottom: 15px !important;
    padding-top: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 10px !important;
}
	.fixed.w-\[70\%\].bg-\[\#fff\].h-screen.top-0.left-0.z-10.overflow-y-scroll {
    background-color: rgba(0, 0, 0, 0.7) !important;
    border-right: 1px solid rgba(250, 250, 250, 0.2) !important;
    color: rgb(250, 250, 250) !important;
}
	
	.fixed.top-0.right-0.h-full.w-\[80\%\].\38 00px\:w-\[25\%\].bg-white.flex.flex-col.overflow-y-scroll.justify-between.shadow-sm {
    background-color: rgba(0,0,0,.8)!important;
    top: 60px !important;
}
}

/* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ 
@media (max-width:641px)  { 
	
	.px-\[20px\].h-\[38px\].rounded-\[20px\].bg-\[\#f63b60\].flex.items-center.justify-center.cursor-pointer .text-\[16px\] {
    color: #fff!important;
    font-size: 11px;
    text-transform: capitalize !important;
    padding-bottom: 15px !important;
    padding-top: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 10px !important;
}
	.px-\[20px\].h-\[38px\].rounded-\[20px\].bg-\[\#f63b60\].flex.items-center.justify-center.cursor-pointer {
    display: inline-block!important;
    padding-left: 10px;
    padding-right: 10px!important;
    padding-top: 5px!important;
    position: relative!important;
    text-align: center!important;
    width: 5.5em !important;
}
	
	.fixed.top-0.right-0.h-full.w-\[80\%\].\38 00px\:w-\[25\%\].bg-white.flex.flex-col.overflow-y-scroll.justify-between.shadow-sm {
    background-color: rgba(0,0,0,.8)!important;
    top: 60px !important;
}
}


 /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ 
@media (max-width:481px)  {
	
	.px-\[20px\].h-\[38px\].rounded-\[20px\].bg-\[\#f63b60\].flex.items-center.justify-center.cursor-pointer .text-\[16px\] {
    color: #fff!important;
    font-size: 10px;
    text-transform: capitalize !important;
    padding-bottom: 10px !important;
    padding-top: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 10px !important;
}
	
	.px-\[20px\].h-\[38px\].rounded-\[20px\].bg-\[\#f63b60\].flex.items-center.justify-center.cursor-pointer {
    display: inline-block!important;
    padding-left: 10px;
    padding-right: 10px!important;
    padding-top: 5px!important;
    position: relative!important;
    text-align: center!important;
    width: 5.5em !important;
}
	
	.fixed.top-0.right-0.h-full.w-\[80\%\].\38 00px\:w-\[25\%\].bg-white.flex.flex-col.overflow-y-scroll.justify-between.shadow-sm {
    background-color: rgba(0,0,0,.8)!important;
    top: 60px !important;
}
}



/* hi-res iphone */ 
@media only screen and (max-width: 480px){ 

}


/* hi-res iphone */ 
@media only screen and (max-width: 386px){ 
.px-\[20px\].h-\[38px\].rounded-\[20px\].bg-\[\#f63b60\].flex.items-center.justify-center.cursor-pointer {
    display: inline-block!important;
    padding-left: 10px;
    padding-right: 10px!important;
    padding-top: 5px!important;
    position: relative!important;
    text-align: center!important;
    width: 5.3em!important;
}
	
.px-\[20px\].h-\[38px\].rounded-\[20px\].bg-\[\#f63b60\].flex.items-center.justify-center.cursor-pointer .text-\[16px\] {
    color: #fff!important;
    font-size: 10px !important;
    margin-bottom: 10px!important;
    margin-top: 0!important;
    padding-bottom: 15px!important;
    padding-top: 0!important;
    text-transform: capitalize!important;
}
	
	.py-1 {
    padding-bottom: 0rem !important;
    padding-top: 0rem !important;
}
	
	.fixed.top-0.right-0.h-full.w-\[80\%\].\38 00px\:w-\[25\%\].bg-white.flex.flex-col.overflow-y-scroll.justify-between.shadow-sm {
    background-color: rgba(0,0,0,.8)!important;
    top: 60px !important;
}
	
/*.null.w-full.h-\[60px\].bg-\[\#fff\].z-50.top-0.left-0.shadow-sm.\38 00px\:hidden {
    padding-top: 10px !important;
}*/
	
}


/* smartphones, iPhone, portrait 480x320 phones */ 
@media (max-width:320px)  { 
.px-\[20px\].h-\[38px\].rounded-\[20px\].bg-\[\#f63b60\].flex.items-center.justify-center.cursor-pointer {
    display: inline-block!important;
    padding-left: 10px;
    padding-right: 10px!important;
    padding-top: 5px!important;
    position: relative!important;
    text-align: center!important;
    width: 5.0em!important;
}
	
	.px-\[20px\].h-\[38px\].rounded-\[20px\].bg-\[\#f63b60\].flex.items-center.justify-center.cursor-pointer .text-\[16px\] {
    color: #fff!important;
    font-size: 10px !important;
    margin-bottom: 10px !important;
    margin-top: 0!important;
    padding-bottom: 15px!important;
    padding-top: 0!important;
    text-transform: capitalize!important;
}
	
.fixed.top-0.right-0.h-full.w-\[80\%\].\38 00px\:w-\[25\%\].bg-white.flex.flex-col.overflow-y-scroll.justify-between.shadow-sm {
    background-color: rgba(0,0,0,.8)!important;
    top: 45px !important;
}
}


.profile-dropdown {
    z-index: 1000000 !important;
    overflow: inherit !important;
}

.hover\:bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(70 211 0/var(--tw-bg-opacity));
}

div#headlessui-menu-items-\:rq\: {
    display: block !important;
    position: fixed !important;
    margin-top: 25px !important;
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
    background-color: rgba(0, 0, 0, 0.8) !important;
    color: rgb(250, 250, 250) !important;
}

.text-gray-700 {
    --tw-text-opacity: 1;
    color: rgb(55 65 81/var(--tw-text-opacity));
    color: rgb(250, 250, 250) !important;
}

a#headlessui-menu-item-\:rr\: {
    border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
}

a#headlessui-menu-item-\:rs\: {
    border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
}

a#headlessui-menu-item-\:rt\: {
    border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
}


.bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, 0.8) !important;
    border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
}

div#headlessui-menu-items-\:rb\: {
	position: fixed !important;
}

/*---- TEMP HIDE-----*/
.profile-header.bg-green-700.h-24.sm\:h-20.lg\:h-28 {
    background-color: hsla(0,0%,98%,.1)!important;
    border-bottom: 1px solid hsla(0,0%,98%,.2)!important;
    border-radius: 0!important;
    border-top: 1px solid hsla(0,0%,98%,.2)!important;
    margin-bottom: 25px!important;
    /*display: none !important;*/
}

.css-1r1fc2u {
    padding: 1.5rem 1.5rem 0.75rem;
    background-color: rgb(74, 74, 74);
    border-radius: 0.75rem;
    background-color: rgba(0, 0, 0, 0.5) !important;
    border: 1px solid var(--border-color) !important;
}

/*----Profile Header--------*/
.profile-header.bg-green-700.h-24.sm\:h-20.lg\:h-28 {
    background-color: hsla(0,0%,98%,.1)!important;
    border-bottom: 1px solid hsla(0,0%,98%,.2)!important;
    border-radius: 0!important;
    border-top: 1px solid hsla(0,0%,98%,.2)!important;
    margin-bottom: 25px!important;
    /*display: none !important;*/
}

.flex.h-full.flex-col.overflow-y-scroll.bg-white.shadow-xl {
    background-color: rgba(0, 0, 0, 0.9) !important;
}

.relative.MuiBox-root.css-b8gmgq {
    border: 1px solid var(--border-color) !important;
    background-color: var(--background-color) !important;
}

.relative.MuiBox-root.css-b8gmgq>img {
    border: 2px solid rgba(0, 0, 0, 0.3);
}

.profile-page .w-full {
    border-bottom: none !important;
}

.relative.cursor-pointer.mr-\[15px\] {
    z-index: 2000000 !important;
}

/*Login-*/

button.group.relative.w-full.h-\[40px\].flex.justify-center.py-2.px-4.border.border-transparent.text-sm.font-medium.rounded-md.text-white.bg-green-600.hover\:bg-green-700 {
    background-color: rgb(70, 211, 0) !important;
    border-radius: var(--border-radius-full) !important;
    width: 50% !important;
    margin: auto !important;
}

.text-green-700 {
    color: rgb(70, 211, 0) !important;
    line-height: 55px !important;
    border-bottom: none !important;
}

.mt-1 input.appearance-none.block.w-full.px-3.py-2.border.border-gray-300.rounded-md.shadow-sm.placeholder-gray-400.focus\:outline-none.focus\:ring-blue-500.focus\:border-green-500.sm\:text-sm {
    border-radius: var(--border-radius-full) !important;
}

.mt-8.sm\:mx-auto.sm\:w-full.sm\:max-w-md {
    border-radius: 15px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 15px !important;
}

.profile-dropdown {
    border-bottom-right-radius: 0!important;
    border-top: 0!important;
    border-top-left-radius: 0!important;
    border-top-right-radius: 0!important;
    display: inline-flex!important;
    margin-top: 25px!important;
    position: fixed!important;
    width: auto!important;
    background-color: rgba(0, 0, 0, 0.7) !important;
    border-left: 1px solid var(--border-color);
    border-right: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
}

.fixed.inset-x-0.bottom-0.z-100.bg-gray-700 {
    z-index: 10000 !important;
}

button.rounded-md.bg-green-600.px-3\.5.py-2\.5.text-sm.font-semibold.text-white.shadow-sm.hover\:bg-green-500.focus-visible\:outline.focus-visible\:outline-2.focus-visible\:outline-offset-2.focus-visible\:outline-indigo-600 {
    background-color: rgb(70, 211, 0) !important;
    border-radius: var(--border-radius-full) !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
}

a.flex.justify-center.items-center.px-6.py-2.border.border-transparent.text-base.font-medium.rounded-md.text-white.bg-indigo-600.hover\:bg-indigo-700.md\:py-2.md\:text-lg.md\:px-10 {
    background-color: rgb(70, 211, 0) !important;
    border-radius: var(--border-radius-full) !important;
    max-width: 50% !important;
    margin: auto !important;
    margin-bottom: 25px !important;
}


input.mt-2.appearance-none.block.w-full.px-3.h-\[35px\].border.border-gray-300.rounded-\[3px\].placeholder-gray-400.focus\:outline-none.focus\:ring-blue-500.focus\:border-blue-500.sm\:text-sm {
    color: rgba(0,0,0,0.5)333 !important;
}

textarea.mt-2.appearance-none.block.w-full.pt-2.px-3.border.border-gray-300.rounded-\[3px\].placeholder-gray-400.focus\:outline-none.focus\:ring-blue-500.focus\:border-blue-500.sm\:text-sm {
    color: rgba(0,0,0,0.5)333 !important;
}

.bg-\[\#099910\] {
    background-color: rgba(0, 0, 0, 0.5) !important;
    border: 1px solid rgba(250, 250, 250, 0.5) !important;
}

#root .profile-page img.w-full.object-cover {
    height: 450px!important;
    border-bottom: 1px solid rgba(250, 250, 250, 0.5) !important;
}

.profile-toolbar-avatar {
    height: auto;
    width: 42px;
    border-radius: 100%;
    border: 1px solid #ffffff !important;
    margin-bottom: 15px !important;
}

.pointer-events-none.fixed.inset-y-0.right-0.flex.max-w-full.pl-10 {
    margin-top: 100px !important;
}

.relative.flex-1.px-4.py-6.sm\:px-6 .flow-root {
    overflow: scroll !important;
}

.css-1o5bsd6 {
    background-color: rgba(255, 255, 255, 0.1) !important;
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    padding: 16px;
}
.css-1cbcpl3 {
    box-sizing: border-box;
    display: flex;
    flex-flow: wrap;
    margin-top: -16px;
    width: calc(100% + 16px);
    margin-left: -8px !important;
    background-color: rgba(250, 250, 250, 0.0) !important;
}

.css-fkcv64 {
    display: flex;
    width: 100%;
    border-top: 1px solid rgba(250, 250, 250, 0.12) !important;
}

/*Shop by seller component */

.shop-list {
  display: flex;
  flex-wrap: nowrap;
  gap: 5px;
  margin: 25px 0px ; 
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding: 0px ; 
}

@media (max-width: 768px) {
  .shop-list {
    gap: 5px; 
    padding: 0 15px ;
    margin: 25px 0px; 
  }
}

  .shop-card {
    width: 150px; 
    align-items: center;
    border: none;
    border-radius: 0px;
    padding: 0px;
    text-align: center;
    flex-shrink: 0; 
  }
  .shop-card img {
    border-radius: 50%;
    height: 100px;
    width: 100px;
    object-fit: cover;
    border: 2px solid rgba(250, 250, 250, 0.4) !important;
    display: block; 
    margin: 0 auto; 
  }
  
  
  
  .shop-card h3 {
    margin-top: 10px;
    color: rgba(0,0,0,0.5);
  }
  
/* ShopProfile Tabs css */

.nav {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; 
    gap: 10px; 
  }
  
  .tab-button {
    flex: 0 0 auto; 
    white-space: nowrap;
  }
  


/* SponsorProduct.css */
.range-slider {
    width: 100%;
    height: 15px;
    border-radius: 5px;
    background: #1f9c29;
    outline: none;
    opacity: 0.7;
    -webkit-appearance: none; 
}

.range-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: var(--text-color);
    cursor: pointer;
    border: 3px solid #1f9c29;
}

.range-slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: var(--text-color);
    cursor: pointer;
    border: 3px solid #1f9c29;
}

/* map.css */

.map-container {
    position: relative;
    width: 100vw;
    height: 80vh;
  }

  .filters-container {
    background: #212121d4;
    border: #14b506;
    border-radius: 8px;
    box-shadow: 0 2px 4px #0003;
    max-height: calc(100vh - 40px);
    overflow-y: auto;
    padding: 20px;
    position: absolute;
    right: 60px;
    top: 55%;
    width: 300px;
    z-index: 2;
}
  
  @media (max-width: 768px) {
    .filters-container {
      width: 90%;
      top: 10px;
      right: 10px;
      max-height: calc(100vh - 20px);
    }
  }
/*----UPDATED: DUBS 07-15-2024-------*/

.friend-card-image img {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: 1px solid var(--border-color) !important;
    height: 120px;
    object-fit: cover;
    width: 100%;
}

.switcheroo-swap-icon {
    background-color: var(--primary-color);
    border: 1px solid rgba(0, 0, 0, 0.5) !important;
    border-radius: 50%;
    bottom: -10px;
    padding: 3px;
    position: absolute;
    right: -10px;
}
.switcheroo-shop-icon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
 color: var(--text-color);; 
}


.MuiBox-root.css-1ug174f, .bg-white {
    background-color: #fafafa33 !important;
    border: 1px solid #fafafa33 !important;
    border-top: 0px !important;
}

.flex.h-full.flex-col.overflow-y-scroll.bg-white.shadow-xl {
    background-color: #0c1117e6 !important;
}

.pointer-events-none.fixed.inset-y-0.right-0.flex.max-w-full.pl-10 {
    margin-top: 99px !important;
    border-top: 0px !important;
}

.friend-card-actions button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.css-1uch84x {
    background-color: var(--primary-color) !important;
    border-radius: var(--border-radius-full) !important;
}

.friend-card-actions {
    display: flex;
    justify-content: center;
    /* padding: 10px; */
    padding-bottom: 5px !important;
}

.suggested-friend-list {
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-wrap: nowrap;
    gap: 0px;
    justify-content: center;
    overflow-x: auto;
    padding-top: 25px;
    padding-bottom: 25px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.text-gray-700 {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity));
    color: #fafafa !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    border-left: 1px solid rgba(250, 250, 250, 0.3) !important;
    border-right: 1px solid rgba(250, 250, 250, 0.3) !important;
    border-bottom: 1px solid rgba(250, 250, 250, 0.3) !important;
    border-top: 1px solid rgba(250, 250, 250, 0.3) !important;
}

.MuiBox-root.css-miw4ts {
    color: #ffffff !important;
    margin-left: 0px !important;
}

.css-1r4ju6z {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(250, 250, 250, 0.87) !important;
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    background-color: var(--background-color);
    border-radius: 1rem !important;
    padding: 1rem 2rem;
}

.recommended-friends-container {
    margin: 0 auto;
    max-width: 85vw;
    padding: 20px;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.friend-card {
    background-color: var(--background-color) !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
    border-radius: 8px;
    box-shadow: 0 4px 8px #0000001a;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px;
    padding: 10px;
    transition: transform .2s, box-shadow .2s;
    width: 180px;
}

.friend-card-image img {
    border: 1px solid var(--border-color) !important;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-radius: 10px !important;
    height: 120px;
    object-fit: cover;
    width: 100%;
}

.shop-card {
    border: none;
    border-radius: 0px;
    background-color: transparent;
    flex-shrink: 0;
    padding: 0px;
    text-align: center;
    width: 150px;
}

.homepage-hero-slide-button {
    background-color: var(--primary-color) !important;
    border-radius: var(--border-radius-full) !important;
    color: #ffffff !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
}

.homepage-hero-slide {
    background-attachment: fixed;
    background-size: cover;
    position: absolute;
    background-position: 50% center;
    inset: 0px;
    transition: opacity 0.5s ease-in-out 0s;
    border-bottom: 1px solid rgba(250, 250, 250, 0.4) !important;
    border-radius: var(--border-radius-md);
}

h5.pt-3.text-\[15px\].text-blue-400.pb-3 {
    font-size: 16px !important;
    font-weight: 700;
}

h4.pb-3.font-\[500\].items-center {
    font-size: 15px !important;
}

h4.friend-card-name {
    font-size: 16px !important;
    font-weight: 500 !important;
    padding-bottom: 25px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
}

.bg-black {
    --tw-bg-opacity: 1;
    background-color: #0c1117 !important;
    border-bottom: 1px solid #fafafa33 !important;
}

.shop-list .shop-card h3 {
    font-weight: 600 !important;
}

.hover\:bg-green-700:hover {
    background-color: rgba(250, 250, 250, 0.3) !important;
}

.content-container .sm\:block {
    border: 1px solid var(--border-color) !important;
    padding: 15px !important;
    background-color: var(--background-color) !important;
    border-radius: 10px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
}

.profile-header {
    background-color: transparent !important;
    height: 0.3rem;
}

.css-a4r3a9 {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: var(--primary-color) !important;
    padding: 0.6rem !important;
    color: rgb(255, 255, 255);
    border-radius: var(--border-radius-full) !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}


.sidebar-badge {
    align-items: center;
    background-color: var(--primary-color) !important;
    border-radius: 9999px;
    color: #fff !important;
    display: inline-flex;
    font-size: .75rem;
    font-weight: 700;
    justify-content: center;
    padding: .25rem;
    position: absolute;
    right: -.55rem;
    top: -.25rem;
}

.sponsored-brands-widget-avatar {
    border-radius: 50%;
    height: 50px !important;
    object-fit: cover;
    width: 50px !important;
    margin: 15px !important;
    border: 1px solid rgba(250, 250, 250, 0.5) !important;
}

.sponsored-brands-widget-cover {
    height: 200px;
    object-fit: cover;
    width: 100%;
    border-top: 1px solid rgba(250, 250, 250, 0.5) !important;
    border-bottom: 1px solid rgba(250, 250, 250, 0.5) !important;
}

.css-1uch84x {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: var(--primary-color) !important;
    padding-right: 25px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 25px !important;
    color: rgb(255, 255, 255);
    border-radius: var(--border-radius-full) !important;
}



.shop-info-sidebar-top-row {
    align-items: center;
    background-color: var(--background-color) !important;
    box-shadow: 0 0 4px #00000050;
    gap: 10px;
}

.shop-info-sidebar-container, .shop-info-sidebar-top-row {
    border-radius: var(--border-radius-sm);
    display: flex;
    flex-direction: column;
    padding: 10px !important;
}

.share-buttons {
    justify-content: space-evenly !important;
    gap: 15px !important;
    margin-bottom: 15px !important;
}

.shop-info-sidebar-bottom-row {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 15px !important;
}

.xl\:gap-\[20px\] {
    background-color: var(--background-color) !important;
    border: 1px solid var(--border-color) !important;
    border-radius: 10px !important;
    gap: 20px;
    padding: 15px !important;
}

.w-full.h-\[420px\].rounded-lg.shadow-sm.p-3.relative.cursor-pointer {
    border: 1px solid var(--border-color) !important;
    padding-top: 25px !important;
}

.shop-profile-data-tab-button {
    align-items: center;
    background: none;
    border: none;
    border-bottom: 2px solid #0000;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 500;
    padding: 0px 15px !important;
}

button.shop-profile-data-tab-inactive.shop-profile-data-tab-button {
    font-size: 11px !important;
}

.css-1fzwgfs {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 11px !important;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: rgb(128, 128, 128);
}

aside.w-full.lg\:w-1\/4.p-2.bg-\[\#f5f5f5\].p-2.rounded-lg.lg\:sticky.lg\:top-24 {
    background-color: var(--background-color) !important;
    border: 1px solid var(--border-color) !important;
}

.px-20 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
}

.MuiBox-root.css-miw4ts > img {
    border: 1px solid rgba(250, 250, 250, 0.5) !important;
}

.notification-sidebar-panel-content {
    box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.notification-feed-connector {
    background-color: transparent !important;
    height: 100%;
    left: 1.25rem;
    margin-left: -1px;
    position: absolute;
    top: 1.25rem;
    width: .125rem;
}

li strong {
    color: rgba(250, 250, 250, 1.0) !important;
}

.shop-info-sidebar-container {
    background-color: transparent !important;
    border: none !important;
    gap: 15px !important;
}

.shop-info-sidebar-top-row {
    align-items: center;
    background-color: transparent !important;
    box-shadow: none !important;
    gap: 10px;
    border-bottom: none !important;
}

.shop-info-sidebar-container, .shop-info-sidebar-top-row {
    border-radius: 0px !important;
    display: flex;
    flex-direction: column;
    padding: 10px !important;
}

.shop-info-sidebar-top-row {
    padding-bottom: 15px !important;
    background-color: rgba(250, 250, 250, 0.0) !important;
    border: none !important;
    border-bottom: none !important;
}

.shop-info-sidebar-bottom-row {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 25px !important;
    border-top: none;
    margin-top: 15px !important;
}

.categories-dropdown-header-item:hover {
    background-color: rgba(250, 250, 250, 0.2) !important;
}

.categories-dropdown-header-product a:hover {
    background-color: rgba(250, 250, 250, 0.2) !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.css-aoeo82 div {
    width: 75% !important;
    padding-bottom: 25px !important;
    text-align: center !important;
}

.recommended-friends-title {
    color: rgba(0,0,0,0.5);
    font-size: 24px;
    font-weight: 700 !important;
    margin-bottom: 0px;
    text-align: center;
}


.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.css-aoeo82 div img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    margin: 10px;
    align-items: center;
    margin-left: 43% !important;
    border: 1px solid rgba(250, 250, 250, 0.7) !important;
}

.homepage-hero-slide {
    background-attachment: fixed;
    background-position: 50%;
    background-size: cover;
    border-bottom: none !important;
    border-radius: var(--border-radius-md);
    inset: 0;
    position: absolute;
    transition: opacity .5s ease-in-out 0s;
    border: 1px solid rgba(250, 250, 250, 0.7) !important;
}

/*-----------Questionable container beneath Root-------------*/
.MuiBox-root.css-1ug174f, .bg-white {
    background-color: rgba(12, 17, 23) !important;
    border-top: rgba(250, 250, 250) !important;
}

a.hidden.text-sm.font-semibold.text-green-600.hover\:text-green-500.sm\:block {
    color: #ffffff !important;
    padding: 10px !important;
    border: 1px solid var(--border-color) !important;
    border-radius: var(--border-radius-full) !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
}

h2.text-2xl.font-bold.tracking-tight.text-green-600 {
    background-color: transparent !important;
}

.max-w-2xl.mx-auto.py-16.px-4.sm\:py-24.sm\:px-6.lg\:max-w-7xl.lg\:px-8 {
    background-color: transparent !important;
}

.MuiBox-root.css-1ug174f, .bg-white {
    background-color: rgba(12, 17, 23) !important;
    border-top: none !important;
    border: none !important;
}

section.border-b.border-gray-200.px-4.py-6.sm\:px-6.lg\:px-8 {
    border: none !important;
    border-bottom: 1px solid rgba(250, 250, 250, 0.5) !important;
    margin-bottom: 25px !important;
    margin-top: 25px !important;
}

h2#filter-heading {
    color: #ffffff !important;
}


/* Category Products Page */
.category-products-page__container {
    background-color: rgba(0, 0, 0, 0.9);;
  }
  
  .category-products-page__content {
    display: flex;
    justify-content: center;
  }
  
  .category-products-page__filters {
    width: 100%;
    max-width: 7xl;
  }
  
  .category-products-page__disclosure-section {
    border-bottom: 1px solid #e5e7eb;
    padding: 1.5rem 0;
  }
  
  .category-products-page__heading {
    text-align: center;
    font-size: 1.875rem;
    color: #38bdf8;
    font-weight: 500;
  }
  
  .category-products-page__disclosure-button {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 0;
    font-size: 0.875rem;
    color: #9ca3af;
    cursor: pointer;
  }
  
  .category-products-page__disclosure-title {
    font-weight: 500;
    color: #111827;
  }
  
  .category-products-page__chevron {
    height: 1.25rem;
    width: 1.25rem;
  }
  
  .category-products-page__disclosure-panel {
    padding-top: 1rem;
  }
  
  .category-products-page__filter-options {
    display: flex;
    justify-content: center;
    padding: 0.5rem 0;
    gap: 1rem;
    overflow-x: auto;
  }
  
  .category-products-page__menu {
    display: inline-block;
    text-align: left;
  }
  
  .category-products-page__menu-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 40px;
    border-radius: var(--border-radius-full);
    background-color: var(--background-color);
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--text-color);
    box-shadow: 0 1px 2px 0 #0000000d;
    cursor: pointer;
    border: 1px solid var(--border-color);
  }
  
  
  .category-products-page__menu-button:hover {
    background-color: rgba(250, 250, 250, 0.3);
  }
  
  .category-products-page__menu-items {
    position: absolute;
    z-index: 10;
    margin-top: 0.5rem;
    width: 14rem;
    border-radius: 0.375rem;
    background-color: rgba(0, 0, 0, 0.9);;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    outline: none;
  }
  
  .category-products-page__menu-content {
    padding: 0.25rem 0;
  }
  
  .category-products-page__menu-item {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
   color: var(--text-color);;
    cursor: pointer;
  }
  
  .category-products-page__menu-item--active {
    background-color: rgba(255, 255, 255, 0.3);
  }
  
  .category-products-page__transition-enter {
    transition: all 0.1s ease-out;
  }
  
  .category-products-page__transition-enter-from {
    transform: scale(0.95);
    opacity: 0;
  }
  
  .category-products-page__transition-enter-to {
    transform: scale(1);
    opacity: 1;
  }
  
  .category-products-page__transition-leave {
    transition: all 0.075s ease-in;
  }
  
  .category-products-page__transition-leave-from {
    transform: scale(1);
    opacity: 1;
  }
  
  .category-products-page__transition-leave-to {
    transform: scale(0.95);
    opacity: 0;
  }
  
  .category-products-page__price-range {
    display: inline-block;
    text-align: left;
  }
  
  .category-products-page__price-range-panel {
    position: absolute;
    z-index: 10;
    margin-top: 0.5rem;
    width: 14rem;
    border-radius: 0.375rem;
    background-color: rgba(0, 0, 0, 0.9);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    outline: none;
    padding: 1rem;
  }
  
  .category-products-page__price-range-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .category-products-page__price-label {
    font-size: 0.875rem;
    font-weight: 600;
   color: var(--text-color);;
  }
  
  .category-products-page__price-range-input {
    width: 100%;
    height: 0.5rem;
    background-color: rgba(250, 250, 250, 0.3);
    border-radius: 0.375rem;
    appearance: none;
    cursor: pointer;
  }
  
  .category-products-page__price-value {
    font-size: 0.875rem;
   color: var(--text-color);;
    text-align: center;
  }
  
  .category-products-page__sort-dropdown {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    max-width: 14rem;
    margin: 0 auto;
  }
  
  .category-products-page__sort-label {
    font-size: 0.875rem;
    font-weight: 600;
     color: var(--text-color);
  }
  
  .category-products-page__sort-wrapper {
    position: relative;
    flex: 1;
  }
  
  .category-products-page__sort-select {
    display: block;
    width: 100%;
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
    background-color: var(--background-color);
     color: var(--text-color);
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius-lg);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    appearance: none;
    cursor: pointer;
  }
  
  .category-products-page__sort-select:focus {
    outline: none;
    border: 1px solid var(--primary-color);
  }
  
  .category-products-page__active-filters-bar {
    margin: 1rem auto;
    max-width: 7xl;
    padding: 1rem;
  }
  
  .category-products-page__active-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  
  .category-products-page__active-filter {
    display: inline-flex;
    align-items: center;
    background-color: #34d399;
    border: 1px solid #e5e7eb;
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: #4b5563;
    border-radius: 9999px;
  }
  
  .category-products-page__remove-filter {
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
    color: #9ca3af;
    cursor: pointer;
    outline: none;
  }
  
  .category-products-page__remove-filter:focus {
    outline: 2px solid rgba(250, 250, 250, 0.3) !important;
    outline-offset: 2px;
  }
  
  .category-products-page__remove-icon {
    height: 0.75rem;
    width: 0.75rem;
  }
  
  .category-products-page__products {
    max-width: 7xl;
    margin: 4rem auto;
    padding: 1rem;
  }
  
  .category-products-page__sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
  
  .category-products-page__product-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2.5rem 1.5rem;
  }
  
  @media (min-width: 640px) {
    .category-products-page__product-grid {
      grid-template-columns: repeat(2, 1fr);
    }

    .sm\:py-24 {
        padding-bottom: 25px !important;
        padding-top: 25px !important;
    }
  }
  
  @media (min-width: 1024px) {
    .category-products-page__product-grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  
  .category-products-page__no-products {
    text-align: center;
    font-size: 1.125rem;
    margin-bottom: 1rem;
  }
  
  .category-products-page__trending-products {
    width: 100%;
  }


/* Product details  */
  
  .product-details-component__content-bg {
    background-color: rgba(0, 0, 0, 0.9);
  }
  
  .product-details-component__section {
    width: 90%;
  }
  
  .product-details-component__py-5 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .product-details-component__flex-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .product-details-component__flex-left {
    width: 100%;
    flex: 1;
  }
  
  .product-details-component__flex-right {
    width: 100%;
    flex: 1;
    padding: 20px;
  }

  @media (max-width: 768px) {
    .product-details-component__flex-left {
      flex: auto;
      width: 100%; 
      padding-top: 30px;
    }
    .product-details-component__flex-right {
      width: 100%; 
      padding-top: 10px;
    }
  }
  
  .product-details-component__main-image {
    width: 100% ; 
    height: auto;
    object-fit: cover;
    border-radius: var(--border-radius-sm);
    box-shadow: 0 4px 6px #0000001a;
    float: right ; 
    margin-right: 25px; 
  }
  

  @media (max-width: 768px) {
    .product-details-component__main-image {
      width: 100%;
      margin-right: 0px;
    }
  }
  
  .product-details-component__image-selection {
    display: flex;
    flex-wrap: wrap;
    overflow: scroll;
    align-items: center;
    text-align: center;
    margin: auto;
    margin-top: 15px;
    float: right;
    padding: 15px;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    .product-details-component__image-selection {
      width: 100%;
     padding: 0px;
     margin-top: 5px;
    }
  }
  
  
  .product-details-component__thumbnail {
    height: 200px;
    width: auto;
    object-fit: cover;
    margin-right: 8px;
    margin-bottom: 8px;
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 8px;
    transition: all 0.3s ease;
  }
  
  .product-details-component__thumbnail:hover,
  .product-details-component__thumbnail--selected {
    border-color: #38a169;
  }
  
  .product-details-component__brand-container {
    align-items: center;
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius-full);
    display: flex;
    padding-right: 20px;
    width: fit-content;
  }
  
  
  .product-details-component__brand-label {
    background-color: var(--background-color);
    color: var(--text-color);
    font-weight: bold;
    font-size: 0.875rem;
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-right: 8px;
  }
  
  .product-details-component__brand-value {
    font-size: 12px;
    font-weight: 600;
  }
  
  .product-details-component__brand-image {
    width: auto;
    height: auto;
  }
  
  .product-details-component__product-title {
    font-size: 35px !important;
    font-weight: 700;
    margin-top: 15px !important;
  }
  
  .product-details-component__product-description {
    font-size: 1rem;
    line-height: 1.5;
  }
  
  .product-details-component__price-container {
    display: flex;
    padding-top: 1rem;
  }
  
  .product-details-component__discount-price {
    font-size: 18px;
    font-weight: 700;
  }
  
  
  .product-details-component__original-price {
    color: #e53e3e;
    font-size: 18px;
    font-weight: 600;
    margin-left: 1rem;
    text-decoration: line-through;
  }

  .product-details-component__top-bar {
    display: flex;
    justify-content: space-between;
  }
  
  .product-details-component__availability-container {
    display: flex;
    gap: 0.5rem;
    
  }
  
  .product-details-component__delivery-badge {
    background-color: #38a169;
     color: var(--text-color);
    font-weight: bold;
    font-size: 0.875rem;
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .product-details-component__pickup-badge {
    background-color: #3182ce;
     color: var(--text-color);
    font-weight: bold;
    font-size: 0.875rem;
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .product-details-component__quantity-wishlist-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.5rem;
    padding-right: 0.75rem;
  }
  
  .product-details-component__quantity-container {
    display: flex;
    align-items: center;
  }
  
  .product-details-component__quantity-button {
    background-color: var(--background-color);
    border: 1px solid var(--primary-color);
    box-shadow: 0 4px 6px #0000001a;
   color: var(--text-color);;
    font-weight: 700;
    padding: .5rem 1rem;
    transition: opacity .3s ease;
    border-radius: var(--border-radius-full) !important;
    margin-left: 7px !important;
    margin-right: 7px !important;
  }
  
  
  .product-details-component__quantity-button:hover {
    background-color: rgba(54, 211, 0, 0.4);
  }
  
  .product-details-component__quantity-value {
    background-color: #edf2f7;
    color: #2d3748;
    font-weight: 500;
    padding: 0.5rem 1rem;
  }
  
  .product-details-component__wishlist-icon {
    cursor: pointer;
  }
  
  .product-details-component__add-to-cart-button {
    align-items: center;
    border: 1px solid var(--primary-color);
    background-color: rgb(255 255 255 / 23%);
    border-radius: var(--border-radius-full);
    box-shadow: 0 4px 6px #0000001a;
   color: var(--text-color);;
    display: flex;
    font-weight: 700;
    height: 45px;
    justify-content: center;
    margin-top: 25px;
    transition: opacity 0.3s ease;
    width: 50%;
    cursor: pointer;
}

  @media (max-width: 480px) {
    .product-details-component__add-to-cart-button {
      width: 100%;
    }
  }
  
  
  .product-details-component__add-to-cart-button:hover {
    opacity: 0.75;
  }
  
  .product-details-component__add-to-cart-text {
    display: flex;
    align-items: center;
  }
  
  .product-details-component__cart-icon {
    margin-left: 0.25rem;
  }
  
  .product-details-component__shop-info {
    display: flex;
    align-items: center;
    padding-top: 2rem;
  }
  
  .product-details-component__shop-avatar {
    width: 50px;
    height: 50px;
    border-radius: 9999px;
    margin-right: 0.5rem;
  }
  
  .product-details-component__shop-details {
    padding-right: 2rem;
  }
  
  .product-details-component__shop-name {
    font-size: 1rem;
    font-weight: bold;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  
  .product-details-component__shop-ratings {
    font-size: 0.9375rem;
    color: #4a5568;
  }
  
  .product-details-component__send-message-button {
    align-items: center;
    background-color: var(--background-color);
    border: 1px solid var(--primary-color);
    border-radius: var(--border-radius-full) !important;
    box-shadow: 0 4px 6px #0000001a;
   color: var(--text-color);;
    display: flex;
    font-weight: 700;
    height: 25px !important;
    justify-content: center;
    margin-top: 25px !important;
    padding: 15px 25px !important;
    cursor: pointer !important;
    width: 28% !important;
  }
  
  
  .product-details-component__send-message-text {
    display: flex;
    align-items: center;
  }
  
  .product-details-component__message-icon {
    margin-left: 0.25rem;
  }
  
  .product-details-component__info-container {
    background-color: rgba(0, 0, 0, 0.9);
    padding: 0.75rem 2.5rem;
    border-radius: 0.5rem;
  }
  
  .product-details-component__info-tabs {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(250, 250, 250, 0.4);
    padding-bottom: 15px;
    padding-top: 15px;
  }
  
  .product-details-component__info-tab {
    position: relative;
    cursor: pointer;
    margin: 15px;
    background-color: var(--background-color);
    padding: 15px 25px;
    border-radius: var(--border-radius-full);
    padding-bottom: 7px;
    border-bottom: 0px !important;
    text-align: center; /* Centers content inside the tab */
  }
  
  .product-details-component__info-tab:active {
    margin: 15px;
    background-color: var(--background-color);
    padding: 15px 25px;
    border-radius: var(--border-radius-full);
    padding-bottom: 7px;
    border-bottom: 0px !important;
    border: 3px solid var(--primary-color);
  }


  .product-details-component__info-tab-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 25px;
    padding-left: 0px;
    padding-bottom: 10px;
  }
  
  @media screen and (max-width: 768px) {
    .product-details-component__info-container {
      padding: 0.5rem 1rem; 
    }
  
    .product-details-component__info-tabs {
      align-items: center;
      gap: 5px;
    }
  
    .product-details-component__info-tab {
      margin: 10px 0; 
      padding: 10px 15px;
    }
  
    .product-details-component__info-tab-title {
      font-size: 1rem; 
      line-height: 1.5rem;
      padding-bottom: 0px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .product-details-component__info-container {
      padding: 0.5rem; 
    }
  
    .product-details-component__info-tab {
      padding: 10px 10px; 
    }
  
    .product-details-component__info-tab-title {
      font-size: 0.875rem; 
    }
  }
  
  
  .product-details-component__product-description-text {
    padding-top: 0.5rem;
    padding-bottom: 2.5rem;
    font-size: 1.125rem;
    line-height: 2rem;
    white-space: pre-line;
  }
  
  .product-details-component__metrics-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .product-details-component__aggregate-metrics {
    flex: 1;
    min-width: 0;
    border: 1px solid #38a169;
    border-radius: 0.5rem;
    padding: 1rem;
  }
  
  .product-details-component__metrics-title {
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  
  .product-details-component__metrics-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
  }
  
  .product-details-component__metric-card {
    padding: 1rem;
    background-color: #2d3748;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .product-details-component__metric-label {
     color: var(--text-color);
  }
  
  .product-details-component__metric-value {
    font-size: 1.25rem;
    color: #38a169;
    font-weight: 600;
  }
  
  .product-details-component__test-results {
    flex: 1;
    min-width: 0;
    border: 1px solid #38a169;
    border-radius: 0.5rem;
    padding: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .product-details-component__test-results-image {
    max-width: 12rem;
    width: 100%;
    height: auto;
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  
  .product-details-component__barcode-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }
  
  .product-details-component__barcode {
    border: 1px solid #38a169;
    border-radius: 0.5rem;
    padding: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .product-details-component__barcode-image {
    max-width: 12rem;
    width: 100%;
    height: auto;
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  
  .product-details-component__barcode-string {
    margin-top: 1rem;
    text-align: center;
  }
  
  .product-details-component__barcode-value {
    color: #38a169;
    font-weight: bold;
  }
  
  .product-details-component__nutrition-info {
    margin-top: 1rem;
    border: 1px solid #38a169;
    border-radius: 0.5rem;
    padding: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .product-details-component__nutrition-info-image {
    max-width: 12rem;
    width: 100%;
    height: auto;
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  
  .product-details-component__reviews-container {
    width: 100%;
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0.75rem;
    overflow-y: scroll;
  }
  
  .product-details-component__review {
    width: 100%;
    display: flex;
    margin-bottom: 0.5rem;
  }
  
  .product-details-component__review-avatar {
    width: 50px;
    height: 50px;
    border-radius: 9999px;
  }
  
  .product-details-component__review-content {
    padding-left: 0.5rem;
  }
  
  .product-details-component__review-header {
    display: flex;
    align-items: center;
  }
  
  .product-details-component__reviewer-name {
    font-weight: 500;
    margin-right: 0.75rem;
  }
  
  .product-details-component__review-comment {
    font-size: 0.9375rem;
    color: #4a5568;
  }
  
  .product-details-component__no-reviews {
    text-align: center;
    font-size: 1rem;
  }
  
  .product-details-component__seller-info {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
    padding: 1.25rem;
  }
  
  .product-details-component__seller-section {
    width: 100%;
    padding: 0.75rem;
    background-color: var(--background-color);
    border-radius: 0.75rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .product-details-component__shop-link {
    text-decoration: none;
  }
  
  .product-details-component__shop-container {
    display: flex;
    align-items: center;
    padding: 1rem;
    background-color: var(--background-color);
    border-radius: 0.75rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
  }
  
  .product-details-component__shop-container:hover {
    background-color: #4a5568;
  }
  
  .product-details-component__shop-avatar-large {
    width: 64px;
    height: 64px;
    border-radius: 9999px;
    border: 2px solid #38a169;
  }
  
  .product-details-component__shop-details-large {
    padding-left: 1rem;
  }
  
  .product-details-component__shop-name-large {
    font-size: 1.125rem;
    font-weight: 600;
    color: #e2e8f0;
    transition: color 0.3s ease;
  }
  
  .product-details-component__shop-name-large:hover {
    color: #38a169;
  }
  
  .product-details-component__shop-rating {
    font-size: 0.875rem;
    color: #a0aec0;
  }
  
  .product-details-component__shop-description {
    margin-top: 1rem;
    font-size: 0.875rem;
    color: #e2e8f0;
  }
  
  .product-details-component__shop-metrics {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .product-details-component__shop-metric {
    width: 100%;
    max-width: 33%;
    padding: 1rem;
    background-color: var(--background-color);
    border-radius: 0.75rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .product-details-component__shop-metric-title {
    font-size: 0.9375rem;
    font-weight: 600;
    color: #e2e8f0;
  }
  
  .product-details-component__shop-metric-value {
    font-size: 1.125rem;
    color: #38a169;
    font-weight: 600;
  }
  
  .product-details-component__visit-shop {
    width: 100%;
    margin-top: 1rem;
    text-align: center;
  }
  
  .product-details-component__visit-shop-button {
    background-color: var(--primary-color);
    border-radius: 0.75rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    text-align: center;
    transition: background-color 0.3s ease;
    display: inline-block;
  }
  
  .product-details-component__visit-shop-button:hover {
    background-color: var(--primary-color-hover);
  }

  .product-details-component__visit-shop-text {
    font-size: 1.125rem;
    font-weight: bold;
     color: var(--text-color);
  }

.group.relative.p-4.shadow-lg.rounded-lg.bg-black.text-center {
    background-color: var(--background-color) !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}

a.hidden.text-sm.font-semibold.text-green-600.hover\:text-indigo-500.sm\:block {
    padding: 10px !important;
    padding-right: 25px !important;
    padding-left: 25px !important;
    border-radius: var(--border-radius-full) !important;
    border: 1px solid var(--border-color) !important;
    color: #ffffff !important;
    background-color: var(--background-color) !important;
    margin-bottom: 25px !important;
}

.xl\:mx-auto {
        margin-left: 4rem !important;
        margin-right: auto !important;
    }

.text-green-600 {
    --tw-text-opacity: .2;
    background-color: var(--background-color) !important;
}

.xl\:px-8 {
        padding-left: inherit !important;
        padding-right: 2rem;
    }

.bg-black {
    --tw-bg-opacity: 1;
    background-color: #0c1117 !important;
    border-bottom: 1px solid rgba(250, 250, 250, 0.3) !important;
}

.py-16.sm\:py-24.xl\:mx-auto.xl\:max-w-7xl.xl\:px-8 {
    margin: 0px 7% !important;
}

.w-\[150px\].bg-green-700.h-\[50px\].my-3.flex.items-center.justify-center.rounded-xl.cursor-pointer.ml-4.\!rounded-\[4px\] {
    height: 45px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    width: auto !important;
    max-width: 300px !important;
    margin: auto !important;
}

.homepage-category-component-section.hidden.sm\:block {
    padding: 0px !important;
}


.shop-profile-data-dashboard-link {
    align-items: center;
    background-color: var(--background-color) !important;
    border: 1px solid #0000;
    border-radius: var(--border-radius-full);
    border: 1px solid var(--border-color) !important;
   color: var(--text-color);;
    display: flex;
    font-size: 16px;
    font-weight: 500;
    justify-content: center;
    padding: 10px 20px;
    text-decoration: none;
    margin: 25px 0px !important;
}

.bg-\[\#f5f5f5\], .css-miw4ts {
    border: 1px solid var(--border-color) !important;
}

aside.w-full.lg\:w-1\/4.p-4.rounded-lg.lg\:sticky.lg\:top-24 div h2 {
    font-size: 11px !important;
}

aside.w-full.lg\:w-1\/4.p-4.rounded-lg.lg\:sticky.lg\:top-24 div {
    border: 1px solid var(--border-color) !important;
	background-color: var(--background-color) !important;
}

.shop-info-sidebar-action-button {
    align-items: center;
    background-color: var(--background-color) !important;
    border-radius: var(--border-radius-full) !important;
    border: 1px solid var(--border-color) !important;
   color: var(--text-color);;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
}

main.flex-grow.lg\:w-1\/2.p-4.rounded-lg {
    padding-top: 0px !important;
}

.shop-profile-data-content-container {
    padding: 20px;
    padding-top: 0px !important;
}

main.flex-grow.lg\:w-1\/2.p-4.rounded-lg {
    padding: 0px !important;
}

.shop-profile-data-content-container {
    padding: 0px !important;
    padding-top: 0px !important;
}

aside.w-full.lg\:w-1\/4.p-4.rounded-lg.lg\:sticky.lg\:top-24 {
    padding: 0px !important;
}

aside.w-full.lg\:w-1\/4.p-4.rounded-lg.lg\:sticky.lg\:top-24 div {
    margin-top: 0px !important;
}

aside.w-full.lg\:w-1\/4.p-4.rounded-lg.lg\:sticky.lg\:top-24 div {
    background-color: var(--background-color) !important;
    border: 1px solid var(--border-color) !important;
}

.friend-card-actions button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.css-1uch84x {
    padding-left: 25px !important;
    padding-right: 25px !important;
    height: 35px !important;
}

.homepage-category-component-branding {
    background-color: var(--background-color) !important;
    border-radius: .375rem;
    box-shadow: 0 1px 3px #0000001a;
    display: flex;
    justify-content: space-between;
    margin: 3rem 0;
    padding: 1.25rem;
    width: 100%;
    border: 1px solid var(--border-color) !important;
}

.homepage-category-component-categories-section {
    background-color: var(--background-color) !important;
    border-radius: .5rem;
    margin-bottom: 3rem;
    padding: 1.5rem;
    border: 1px solid var(--border-color);
}

.homepage-category-component-category-item {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 100px;
    justify-content: space-between;
    overflow: hidden;
    transition: background-color .3s, box-shadow .3s;
    width: 100%;
    border: 1px solid var(--border-color) !important;
    padding: 15px !important;
    border-radius: 15px !important;
    background-color: rgba(0, 0, 0, 0.3) !important;
}

.homepage-category-component-category-image {
    height: 100%;
    object-fit: cover;
    width: 120px;
    border-radius: 10px !important;
    margin-left: 10px !important;
    border: 1px solid rgba(250, 250, 250, 0.5) !important;
}

.homepage-category-component-category-title {
   color: var(--text-color);;
    font-size: 14px !important;
    line-height: 1.3;
    font-weight: 700 !important;
}

.best-deals-homepage-container {
    padding: 0px !important;
    width: 100%;
}

a.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.css-1hw9j7s {
    background-color: var(--primary-color) !important;
    border-radius: var(--border-radius-full) !important;
    padding: 5px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
}

.w-full.h-\[420px\].rounded-lg.shadow-sm.p-3.relative.cursor-pointer {
    border: 1px solid #fafafa66 !important;
    padding-top: 25px !important;
    background-color: var(--background-color) !important;
}

h5.pt-3.text-\[15px\].text-blue-400.pb-3 {
    font-size: 14px !important;
    font-weight: 700;
}

h4.pb-3.font-\[500\].items-center {
    font-size: 14px !important;
}

button.bg-\[\#56d879\].hover\:bg-teal-500.duration-300.px-5.py-2\.5.rounded-md.text-white.md\:w-auto.w-full {
    border-radius: var(--border-radius-full) !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
}

input.h-\[40px\].w-full.px-8.border-\[\#46d300\].border-\[2px\].rounded-\[2rem\] {
    border: 1px solid rgba(250, 250, 250, 0.5) !important;
}

.profile-upload-container {
  display: flex;
    gap: 30px;
  position: absolute;
  align-items: center;
  justify-content: left;
  background-color: rgba(0, 0, 0, 0.9);
  border: 1px solid rgba(250, 250, 250, 0.9);
  border-radius: 50%;
  cursor: pointer;
  right: 0px;
  bottom: 10px;
  text-align: centee;
  width: 35px ;
  height: 35px;
  z-index: 40;
  float: right ;
  padding: 12px 12px ;
}


@media (max-width: 480px) {
  .profile-upload-container {
    align-items: center;
    margin-left: 205px;
    margin-top: -50px;
}}


    .user-profile-data-tab-active {
    border-bottom-color: #22c55e;
    color: var(--primary-color) !important;
    border: none !important;
    font-weight: 700 !important;
}

.user-profile-data-tab-content {
    margin-top: 0px !important;
}


.css-1uch84x {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: rgb(31, 156, 41);
    padding: 0.6rem;
    color: rgb(255, 255, 255);
    border-radius: 8px;
    height: 35px !important;
}
  
/* All Products Admin Dashboard component */
.all-products-component {
    background-color: rgba(0,0,0,0.5)333;
    padding: 6px;
    margin-top: 20px;
    min-height: 100vh;
    border-radius: 6px;
  }
  
  .all-products-component h1 {
    font-size: 24px;
    font-weight: bold;
    color: #F7FAFC;
    margin-bottom: 20px;
  }
  
  .all-products-component .charts-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 40px;
    flex-wrap: wrap;
  }
  
  .all-products-component .chart {
    flex: 1;
    min-width: 300px;
    height: 420px;
  }
  
  .all-products-component .data-grid {
    width: 100%;
  }
  
  .all-products-component .data-grid .MuiDataGrid-columnHeaders {
    background-color: #146e10;
    color: #F7FAFC;
  }
  
  .all-products-component .data-grid .MuiDataGrid-cell {
    border-bottom: 1px solid #2D3748;
    color: #F7FAFC;
  }
  
  .all-products-component .data-grid .MuiDataGrid-cell:focus {
    outline: none;
  }

  .all-products-component-create-product-button {
    position: absolute;
    right: 20px;
    display: inline-block;
    background-color: var(--primary-color);
   color: var(--text-color);;
    font-size: 16px;
    font-weight: 600;
    padding: 12px 20px;
    border: none;
    border-radius: var(--border-radius-full);
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }
  
  .all-products-component-create-product-button:hover {
    background-color: #45a049;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }

  .all-products-component .actions {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
}
  
  
  .all-products-image {
    width: 125px;
    height: auto;
    border-radius: 8px;
  }
  .all-products-mobile-detail-card-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .all-products-mobile-detail-card-close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    font-size: 1.5rem;
    color: #333;
  }
  
  .all-products-mobile-detail-card-container {
    background-color: rgba(0,0,0,0.8);
    max-width: 600px;
    width: 90%;
    border-radius: var(--border-radius-md);
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 20px;
    backdrop-filter: blur(5px); 
    border: 1px solid var(--border-color);
  }
  
  .all-products-mobile-detail-card-header {
    text-align: center;
    display: flex;
    gap: 20px;
  }
  
  .all-products-mobile-detail-card-image {
    width: 150px;
    height: auto;
    border-radius: 8px;
    margin-bottom: 15px;
  }
  
  .all-products-mobile-detail-card-title {
    font-size: 1.25rem;
    font-weight: bold;
    color: #333;
  }
  
  .all-products-mobile-detail-card-content {
    margin-top: 20px;
  }
  
  .all-products-mobile-detail-card-item {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid rgba(250, 250, 250, 0.3);
  }
  
  .all-products-mobile-detail-card-label {
    font-weight: 500;
   color: var(--text-color);;
  }
  
  .all-products-mobile-detail-card-value {
   color: var(--text-color);;
  }
  
  .all-products-mobile-detail-card-actions {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 20px;
  }
  
  .all-products-mobile-detail-card-action {
    background: rgba(255, 255, 255, 0.1);
    border: none;
    cursor: pointer;
    padding: 14px;
    border-radius: var(--border-radius-lg);
    color: #4b5563;
    transition: color 0.2s;
  }
  
  .all-products-mobile-detail-card-action:hover {
    color: #000;
  }
  
  .all-products-mobile-detail-card-action.delete {
    color: #ef4444;
  }
  
  .all-products-mobile-detail-card-action.delete:hover {
    color: #b91c1c;
  }
  
  .all-products-mobile-detail-card-action.add-inventory {
    color: var(--primary-color);
  }
  
  .all-products-mobile-detail-card-action.add-inventory:hover {
    color: #388e3c;
  }
  
  .all-products-mobile-detail-card-action.view-log {
    color: #ffc107;
  }
  
  .all-products-mobile-detail-card-action.view-log:hover {
    color: #ff9800;
  }
  
  
  /* All Products Component Styles */

.all-products-component {
  background-color: transparent;
  border-radius: 0px;
  margin-top: 20px;
  margin-left: 50px;
  min-height: 100vh;
  padding: 25px;
  width: 100%;
  overflow-x: auto;
}

.all-products-component-header {
 padding: 5px;
}

.all-products-component h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: var(--text-color);
  margin-bottom: 10px;
  text-transform: uppercase;
  padding-left: 10px;
  border-left: 4px solid var(--primary-color);
}

@media (max-width: 480px) {
  .all-products-component h1 {
    font-size: 24px;
    font-weight: bold;
    color: #F7FAFC;
    margin-top: 20px;
    margin-left: 20px;
  }
}

.all-products-component-search-box {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.all-products-component-search-input {
  padding: 8px;
  border: 1px solid #CBD5E0;
  border-radius: 4px;
  margin-right: 10px;
}

.all-products-component-search-icon {
  color: #A0AEC0;
}

.all-products-component-search-clear-icon {
  cursor: pointer;
  color: #A0AEC0;
}

.all-products-component-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.all-products-component-filters-list {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: space-between;
}

.all-products-component-filter-select {
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-lg);
  background-color: var(--background-color);
 color: var(--text-color);;
}

.all-products-filter-toggle-mobile-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .all-products-filter-toggle-mobile-list {
    display: none;
  }
}

.all-products-filter-toggle {
  display: flex;
  align-items: center;
  
}

.all-products-filter-toggle-mobile {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.all-products-filter-toggle-label {
  position: relative;
  display: flex;
}
.all-products-filter-toggle-label-mobile  {
  position: relative;
  display: flex;
}
.all-products-filter-toggle-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.all-products-filter-toggle-slider {
  cursor: pointer;
  background-color: #ccc;
  transition: .4s;
  border-radius: 24px;
  width: 50px;
  height: 24px;
}

.all-products-filter-toggle-slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: var(--text-color);
  transition: .4s;
  border-radius: 50%;
}

.all-products-filter-toggle-input:checked + .all-products-filter-toggle-slider {
  background-color: #2196F3;
}

.all-products-filter-toggle-input:checked + .all-products-filter-toggle-slider:before {
  transform: translateX(26px);
}

.all-products-filter-toggle-text {
  margin-left: 10px;
  color: #F7FAFC;
  font-size: 16px;
}

.all-products-component-filter-dropdown {
  display: none;
}

.all-products-component-filter-dropdown.open {
  display: block;
  align-items: center;
  background-color: var(--background-color);
  padding: 20px;
  border-radius: var(--border-radius-sm);
  border: 1px solid rgba(250, 250, 250, 0.1);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 100;
  margin-bottom: 10px;
}

.all-products-component-desktop-filter-button {
  display: none;
}

@media (min-width: 768px) {
  .all-products-component-desktop-filter-button {
    display: inline-block;
    margin-right: 10px;
  }

  .all-products-component-mobile-filter-button {
    display: none;
  }
  .desktop-only {
    display: block;
  }

  .mobile-only {
    display: none;
  }
}

@media (max-width: 767px) {
  .desktop-only {
    display: none;
  }

  .mobile-only {
    display: block;
  }

  .all-products-table-mobile-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .all-products-table-mobile-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }

  .all-products-table-mobile-content {
    display: flex;
    flex-direction: column;
  }

  .all-products-table-mobile-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    gap: 20px;
    align-items: center;
  }

  .all-products-table-mobile-image {
    width: 42px;
    height: 42px; 
    object-fit: cover;  
  }

  .all-products-table-mobile-name {
    font-weight: bold;
   color: var(--text-color);;
  }

  .all-products-table-mobile-price {
   color: var(--text-color);;
  }

  .all-products-table-mobile-details {
    display: flex;
    gap: 10px;
    font-size: 14px;
    color: #777;
  }

  .all-products-table-mobile-chevron {
    width: 24px;
    height: 24px;
    color: #999;
  }
}

.all-products-component-data-grid-container {
  width: 100%;
  overflow-x: auto;
  background-color: var(--background-color);
}

.all-products-component table {
  width: 100%;
  border-collapse: collapse;
}

.all-products-component th,
.all-products-component td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #2D3748;
}

.all-products-component th {
  background-color: var(--background-color);
}

.all-products-component td img {
  width: 125px;
  height: auto;
  border-radius: 8px;
}

.all-products-component .actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.all-products-component-modal-style {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--modal-content-background);
  padding: 20px;
  border-radius: var(--border-radius-sm);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  outline: none;
  width: 80%;
  height: 100%;
  max-height: 95vh;
  overflow-y: auto;
  border: 1px solid var(--border-color);
  backdrop-filter: blur(5px);
  z-index: 1001;
}
.all-products-component-delete-modal-style {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: var(--border-radius-sm);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  outline: none;
  max-height: 80vh;
  overflow-y: auto;
  color: #F7FAFC;
  border: 1px solid var(--border-color);
  backdrop-filter: blur(5px);
  width: 400px;
  text-align: center;
  border-radius: var(--border-radius-sm);
  z-index: 1000;
}

.all-products-component-delete-modal-style h2{
  margin-bottom: 20px;
}

.all-products-component-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: var(--modal-overlay-background);
  backdrop-filter: var(--modal-overlay-backdrop-filter); 
  z-index: 1000; 
}

.all-products-component-close-icon-style {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
 color: var(--text-color);;
  font-size: 1.5rem;
}

.all-products-component-delete-button {
  padding: 10px 20px;
  background-color: #EF4444;
  color: #F7FAFC;
  border: none;
  border-radius: var(--border-radius-full);
  cursor: pointer;
  margin-right: 10px;
}

.all-products-component-cancel-button {
  padding: 10px 20px;
  background-color: #A0AEC0;
  color: #F7FAFC;
  border: none;
  border-radius: var(--border-radius-full);
  cursor: pointer;
}
.all-products-filter-icon {
  width: 24px;
  height: 24px;
}

.all-products-component-sidebar-content {
  padding: 20px;
  color: #F7FAFC;
  display: flex;
  gap: 10px;
}


/* Responsive Sidebar for Mobile */
@media (max-width: 640px) {
  .all-products-component {
    padding: 15px;
    margin-left: 0;
  }

  /* Show filters only in sidebar when on mobile */
  .all-products-component-filters {
    display: none;
  }

  .all-products-component-sidebar {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 80%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 50;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    overflow-y: auto;
    backdrop-filter: var(--modal-overlay-backdrop-filter);
    border-left: 1px solid rgba(250, 250, 250, 0.3);
  }

  .all-products-component-sidebar.open {
    transform: translateX(0);
  }

  .all-products-component-sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: var(--background-color);
  }

  .all-products-component-header-box {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0px 10px;
    justify-content: space-between;
  }

  .all-products-component-sidebar-close {
    background: none;
    border: none;
    color: #F7FAFC;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .all-products-component-sidebar-content {
    padding: 20px;
    color: #F7FAFC;
    display: flex;
    gap: 10px;
  }

  .all-products-component-sidebar-content .all-products-component-filters {
    display: flex;
  }

  .all-products-component-mobile-filter-button {
    display: block;
    background-color: var(--background-color);
    color: #F7FAFC;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 50%;
    cursor: pointer;
    
  }
}


@media (min-width: 640px) {
  .all-products-component-mobile-filter-button {
    display: none;
  }
  .all-products-component-sidebar-close {
    display: none;
  }
  .all-products-component-sidebar-header {
    display: none;
  }
}

@media (max-width: 480px) {
  .all-products-component {
    background-color: transparent;
    border-radius: 0px;
    margin-top: 0px;
    margin-left: 0px;
    min-height: 100vh;
    padding: 0px;
    width: 100%;
    overflow-x: auto;
  }
}



  
  
 /* Sponsor a product component */
 .sponsor-product-component-container {
    padding: 20px;
    background-color: rgba(250, 250, 250, 0.2);
    border-radius: var(--border-radius-lg);
    border: 1px solid rgba(250, 250, 250, 0.6);
    max-width: 100%;
    margin: auto;
    color: var(--text-color);
}

.sponsor-product-component-title {
    text-align: center;
}

.sponsor-product-component-product-selection {
    margin-bottom: 20px;
}

.sponsor-product-component-product-selection label {
    display: block;
    margin-bottom: 10px;
}

.sponsor-product-component-product-list {
    border: 1px solid rgba(250, 250, 250, 0.6);
    border-radius: var(--border-radius-lg);
    padding: 10px;
    max-height: 200px;
    overflow-y: auto;
    background-color: var(--background-color);
}

.sponsor-product-component-product-item {
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    position: relative;
}

.sponsor-product-component-product-item img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.sponsor-product-component-product-item.selected {
    background-color: var(--primary-color);
    border-radius: 15px;
}

.sponsor-product-component-close-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
     color: var(--text-color);
    cursor: pointer;
}

.sponsor-product-component-ad-preview {
    margin: 20px 0;
    max-width: 300px;
    padding: 10px;
    background-color: rgba(250, 250, 250, 0.2);
    border-radius: var(--border-radius-sm);
}

.sponsor-product-component-ad-details {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sponsor-product-component-ad-link {
    text-decoration: none;
    color: inherit;
    display: block;
}

.sponsor-product-component-ad-link img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.sponsor-product-component-ad-info {
    background-color: rgba(84, 82, 82, 0.5);
     color: var(--text-color);
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sponsor-product-component-visit-store {
    font-size: 12px;
    text-decoration: none;
    color: var(--primary-color);
    background-color: var(--background-color);
    padding: 5px 10px;
    border-radius: 5px;
}

.sponsor-product-component-slider {
    margin-bottom: 20px;
}

.sponsor-product-component-slider label {
    display: block;
    margin-bottom: 10px;
}

.sponsor-product-component-slider input[type="text"] {
    margin-bottom: 10px;
    padding: 10px 15px; 
    background-color: rgba(250, 250, 250, 0.2);
    border: 1px solid rgba(250, 250, 250, 0.6);
    border-radius: var(--border-radius-lg);
    color: var(--text-color);
}

.sponsor-product-component-range-slider {
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    background: var(--primary-color);
    border-radius: var(--border-radius-md);
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;
}

.sponsor-product-component-range-slider:hover {
  opacity: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); 
}


.sponsor-product-component-range-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: #1f9c29;
    cursor: pointer;
    border-radius: 50%;
}

.sponsor-product-component-range-slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: #1f9c29;
    cursor: pointer;
    border-radius: 50%;
}

.sponsor-product-component-total-spend {
    margin-bottom: 20px;
}

.sponsor-product-component-display-mode {
    margin-bottom: 20px;
}

.sponsor-product-component-display-mode button {
  margin-right: 10px;
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.1);
   color: var(--text-color);
  border: 1px solid var(--primary-color);
  border-radius: var(--border-radius-full);
  cursor: pointer;
}

.sponsor-product-component-display-mode button.active-time-button {
  background-color: var(--primary-color); 
   color: var(--text-color); 
  border: 1px solid var(--border-color); 
}


.sponsor-product-component-display-mode button:hover {
    background-color: #145d10;
}

.sponsor-product-component-predictions {
    margin-bottom: 20px;
}

.sponsor-product-component-prediction-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #545252;
    border-radius: 5px;
    margin-bottom: 10px;
}

.sponsor-product-component-prediction-item label {
    font-weight: bold;
    color: var(--primary-color);
}

.sponsor-product-component-prediction-item span {
     color: var(--text-color);
}

.sponsor-product-component-submit-button {
    width: 100%;
    padding: 10px;
    background-color: var(--primary-color);
    color: var(--text-color);
    border: none;
    border-radius: var(--border-radius-full);
    cursor: pointer;
}

.sponsor-product-component-submit-button:hover {
    background-color: #145d10;
}
/* Enabled state */
.sponsor-product-component-submit-button.enabled {
  background-color: var(--primary-color);
   color: var(--text-color);
}

/* Disabled state */
.sponsor-product-component-submit-button.disabled {
  background-color: #ccc; 
  color: #666; 
  cursor: not-allowed; 
}

.sponsor-product-component-null-message {
    text-align: center;
    color: #ffcc00;
    font-weight: bold;
    margin-top: 20px;
}

/* Sponsor shop component */

.sponsor-shop-component-container {
  padding: 20px;
  background-color: rgba(250, 250, 250, 0.2);
  border-radius: var(--border-radius-lg);
  border: 1px solid rgba(250, 250, 250, 0.6);
  max-width: 100%;
  margin: auto;
  color: var(--text-color);
}

.sponsor-shop-component-title {
  text-align: center;
}

.sponsor-shop-component-slider {
  margin-bottom: 20px;
}

.sponsor-shop-component-slider label {
  display: block;
  margin-bottom: 10px;
}

.sponsor-shop-component-input {
  margin-bottom: 10px;
  padding: 5px;
  background-color: rgba(250, 250, 250, 0.2);
  border: 1px solid rgba(250, 250, 250, 0.6);
  border-radius: 4px;
  color: var(--text-color);
}

.sponsor-shop-component-range-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background: var(--primary-color);
  outline: none;
  opacity: 0.7;
  transition: opacity .2s;
}


.sponsor-shop-component-range-slider:hover {
  opacity: 1;
}

.sponsor-shop-component-range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #1f9c29;
  cursor: pointer;
  border-radius: 50%;
}

.sponsor-shop-component-range-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #1f9c29;
  cursor: pointer;
  border-radius: 50%;
}

.sponsor-shop-component-total-spend {
  margin-bottom: 20px;
}

.sponsor-shop-component-display-mode {
  margin-bottom: 20px;
  text-align: center;
}

.sponsor-shop-component-display-mode button {
  margin-right: 10px;
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.1);
   color: var(--text-color);
  border: 1px solid var(--primary-color);
  border-radius: var(--border-radius-full);
  cursor: pointer;
}

.sponsor-shop-component-display-mode button:hover {
  background-color: #145d10;
}

.sponsor-shop-component-predictions {
  margin-bottom: 20px;
}

.sponsor-shop-component-prediction-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #545252;
  border-radius: 5px;
  margin-bottom: 10px;
}

.sponsor-shop-component-prediction-item label {
  font-weight: bold;
  color: var(--primary-color);
}

.sponsor-shop-component-prediction-item span {
   color: var(--text-color);
}

.sponsor-shop-component-ad-preview {
  margin: 20px 0;
  max-width: 100%;
  padding: 10px;
  background-color: rgba(250, 250, 250, 0.2);
  border-radius: var(--border-radius-sm);
  text-align: center;
}

.sponsor-shop-component-ad-link {
  text-decoration: none;
  color: inherit;
  display: block;
}

.sponsor-shop-component-ad-avatar {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin: 10px auto;
}

.sponsor-shop-component-ad-banner {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.sponsor-shop-component-ad-info {
  background-color: rgba(84, 82, 82, 0.5);
   color: var(--text-color);
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sponsor-shop-component-visit-store {
  padding: 5px 15px;
  border-radius: 5px;
  background: #1f9c29;
   color: var(--text-color);
  border: none;
  cursor: pointer;
  text-decoration: none;
}

.sponsor-shop-component-submit-button {
  width: 100%;
  padding: 10px;
  background-color: var(--primary-color);
   color: var(--text-color);
  border: none;
  border-radius: var(--border-radius-full);
  cursor: pointer;
}

.sponsor-shop-component-submit-button:hover {
  background-color: #145d10;
}

.sponsor-shop-component-null-message {
  text-align: center;
  color: #ffcc00;
  font-weight: bold;
  margin-top: 20px;
}

/* Sponsor brand component */

.sponsor-brand-component-container {
  padding: 20px;
  background-color: rgba(250, 250, 250, 0.2);
  border-radius: var(--border-radius-lg);
  border: 1px solid rgba(250, 250, 250, 0.6);
  max-width: 100%;
  margin: auto;
  color: var(--text-color);
}

.sponsor-brand-component-title {
  text-align: center;
}

.sponsor-brand-component-product-selection {
  margin-bottom: 20px;
}

.sponsor-brand-component-product-selection label {
  display: block;
  margin-bottom: 10px;
}

.sponsor-brand-component-product-list {
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-lg);
  padding: 10px;
  max-height: 200px;
  overflow-y: auto;
  background-color: var(--background-color);
}

.sponsor-brand-component-product-item {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  position: relative;
}

.sponsor-brand-component-product-item img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.sponsor-brand-component-product-item.selected {
  background-color: rgba(250, 250, 250, 0.3);
}

.sponsor-brand-component-close-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
   color: var(--text-color);
  cursor: pointer;
}

.sponsor-brand-component-ad-preview {
  margin: 20px 0;
  max-width: 420px;
  padding: 10px;
  background-color: rgba(250, 250, 250, 0.2);
  border-radius: var(--border-radius-sm);
}

.sponsor-brand-component-ad-details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sponsor-brand-component-ad-link {
  text-decoration: none;
  color: inherit;
  display: block;
}

.sponsor-brand-component-ad-avatar {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin: 10px auto;
}

.sponsor-brand-component-ad-info {
  background-color: rgba(84, 82, 82, 0.5);
   color: var(--text-color);
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sponsor-brand-component-visit-store {
  padding: 5px 10px;
  border-radius: 5px;
  background: #1f9c29;
   color: var(--text-color);
  border: none;
  cursor: pointer;
  text-decoration: none;
}

.sponsor-brand-component-slider {
  margin-bottom: 20px;
}

.sponsor-brand-component-slider label {
  display: block;
  margin-bottom: 10px;
}

.sponsor-brand-component-input {
  margin-bottom: 10px;
  padding: 5px;
  background-color: rgba(250, 250, 250, 0.2);
  border: 1px solid rgba(250, 250, 250, 0.6);
  border-radius: 4px;
  color: var(--text-color);
}

.sponsor-brand-component-range-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background: #3d6000;
  outline: none;
  opacity: 0.7;
  transition: opacity .2s;
}

.sponsor-brand-component-range-slider:hover {
  opacity: 1;
}

.sponsor-brand-component-range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #1f9c29;
  cursor: pointer;
  border-radius: 50%;
}

.sponsor-brand-component-range-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #1f9c29;
  cursor: pointer;
  border-radius: 50%;
}

.sponsor-brand-component-total-spend {
  margin-bottom: 20px;
}

.sponsor-brand-component-display-mode {
  margin-bottom: 20px;
  text-align: center;
}

.sponsor-brand-component-display-mode button {
  margin-right: 10px;
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.1);
   color: var(--text-color);
  border: 1px solid var(--primary-color);
  border-radius: var(--border-radius-full);
  cursor: pointer;
}

.sponsor-brand-component-display-mode button:hover {
  background-color: #145d10;
}

.sponsor-brand-component-predictions {
  margin-bottom: 20px;
}

.sponsor-brand-component-prediction-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #545252;
  border-radius: 5px;
  margin-bottom: 10px;
}

.sponsor-brand-component-prediction-item label {
  font-weight: bold;
  color: var(--primary-color);
}

.sponsor-brand-component-prediction-item span {
   color: var(--text-color);
}

.sponsor-brand-component-null-message {
  text-align: center;
  color: #ffcc00;
  font-weight: bold;
  margin-top: 20px;
}

.sponsor-brand-component-submit-button {
  width: 100%;
  padding: 10px;
  background-color: var(--primary-color);
   color: var(--text-color);
  border: none;
  border-radius: var(--border-radius-full);
  cursor: pointer;
}

.sponsor-brand-component-submit-button:hover {
  background-color: #145d10;
}

/* Sponsored Dashboard */

.sponsored-dashboard {
 color: var(--text-color);;
  background-color: var(--background-color);
  border: 1px solid rgba(250, 250, 250, 0.6);
  border-radius: 15px;
}

.dashboard-title {
  text-align: center;
  margin-bottom: 25px;
}

.sponsored-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 11px !important;
  font-weight: 300 !important;
  display: block;
  overflow-x: auto;
}

.sponsored-table th, .sponsored-table td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid rgba(250, 250, 250, 0.6);
}

.sponsored-table th {
  background-color: var(--background-color);
}

.sponsored-table tr:hover {
  background-color: var(--background-color);
}

.view-total-button {
  background-color: var(--primary-color); 
   color: var(--text-color);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 10px 0;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.2s ease;
}

.view-total-button:hover {
  background-color: var(--primary-color); 
}

.view-total-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0,123,255,0.5); 
}

.sponsored-dashboard-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  overflow-x: auto;
}

.sponsored-dashboard-button {
  flex: 1;
  margin: 0 5px;
  padding: 10px;
  background-color: var(--primary-color);
   color: var(--text-color);
  border: none;
  border-radius: var(--border-radius-full);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sponsored-dashboard-button-all {
  background-color: #28a745;
}

.sponsored-dashboard-button:hover {
  background-color: var(--primary-color);
}

.sponsored-dashboard-button-all:hover {
  background-color: #218838;
}

.sponsored-dashboard-buttons-row {
  display: flex;
  justify-content: space-between;
  
}

.sponsored-dashboard-filter-row {
  display: flex;
  justify-content: space-around;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  border: 1px solid var(--border-color);
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.05);
  
}

.sponsored-dashboard-filter-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #6c757d;
  border-radius: var(--border-radius-sm);
  width: 100px;
}

.sponsored-dashboard-filter-item.active {
  background-color: rgba(40, 167, 69, 0.1);
  color: #28a745;
  border: 2px solid #28a745;
}

.sponsored-dashboard-filter-item:hover {
  background-color: rgba(40, 167, 69, 0.05);
  color: #28a745;
}

.sponsored-dashboard-filter-icon {
  font-size: 1.8rem;
  margin-bottom: 8px;
  color: inherit;
}

.sponsored-dashboard-filter-item span {
  font-size: 1rem;
  font-weight: 600;
}


.sponsored-dashboard-time-buttons-row {
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  border: 1px solid var(--border-color);
}
.sponsored-dashboard-time-filter-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #6c757d;
  border-radius: var(--border-radius-sm);
}

.sponsored-dashboard-time-filter-item.active {
  background-color: rgba(40, 167, 69, 0.1);
  color: #28a745;
  border: 2px solid #28a745;
}

.sponsored-dashboard-time-filter-item:hover {
  background-color: rgba(40, 167, 69, 0.05);
  color: #28a745;
}

.sponsored-dashboard-time-filter-icon {
  font-size: 1.8rem;
  margin-bottom: 8px;
  color: inherit;
}

.sponsored-dashboard-time-filter-item span {
  font-size: 1rem;
  font-weight: 600;
}
.sponsored-dashboard-filter-time-row {
  display: flex;
  justify-content: space-between; 
  height: 100px;
  width: auto;
  gap: 25px;
}


@media (max-width: 480px) {
  .sponsored-dashboard-filter-time-row {
    width: 100%;
  }
}

/* Sponsored product AD carousel */

.sponsored-product-carousel-image-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  background-color: transparent !important;
  border-radius: 10px !important;
}
.sponsored-product-carousel-mobile-image-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  background-color: transparent !important;
  border-radius: 10px !important;
}

.sponsored-product-carousel-image {
  position: absolute;
  top: 0;
  left: 0;
}

.sponsored-product-mobile-carousel-image {
  position: absolute;
  top: 0;
  left: 0;
}

.sponsored-product-carousel-content {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  text-align: center;
  width: 99.9% !important;
  height: 99.9% !important;
  padding-bottom: 25px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px !important; 
  border: 1px solid var(--border-color) !important; 
  z-index: 2;
}

.sponsored-product-mobile-carousel-content {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  text-align: center;
  width: 99.9% !important;
  height: 99.9% !important;
  padding-bottom: 25px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px !important; 
  border: 1px solid var(--border-color) !important;
  z-index: 2;
}


.sponsored-product-carousel-title {
  font-size: 24px;
  margin: 0;
}

.sponsored-product-carousel-price {
  font-size: 18px;
  margin: 10px 0;
}

.sponsored-product-carousel-button {
  background-color: var(--secondary-color) !important;
 color: var(--text-color);;
  padding: 5px 25px !important;
  border: none;
  border-radius: var(--border-radius-full) !important;
  cursor: pointer;
  font-size: 16px;
  margin: 25px !important;
  text-decoration: none;
  transition: background-color 0.3s;
}


.sponsored-product-carousel-button:hover {
  background-color: var(--secondary-color);
}

.sponsored-carousel-nav-button {
  background-color: rgba(250, 250, 250, 0.4) !important;
  border: none;
  border-radius: var(--border-radius-full) !important;
 color: var(--text-color);;
  cursor: pointer;
  padding: 0px !important;
  position: absolute;
  height: 35px !important;
  width: 35px !important;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}


.sponsored-carousel-prev {
  left: 10px;
}

.sponsored-carousel-next {
  right: 10px;
}

.sponsored-carousel-nav-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}





/* Sponsored product AD carousel */
.sponsored-product-carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: var(--border-radius-sm);
  margin: 20px 0;
}

.sponsored-product-mobile-carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: var(--border-radius-sm);
  margin: 20px 0;
}

@media (max-width: 768px) {
  .sponsored-product-carousel {
    margin: 0px 0;
  }
}

.sponsored-product-carousel-paper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
 color: var(--text-color);;
  border-radius: var(--border-radius-sm);
  overflow: hidden;
  background-position: center center; 
  background-size: cover;             
  width: 100%;
  aspect-ratio: 3 / 1;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;                  
}

.sponsored-product-mobile-carousel-paper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
 color: var(--text-color);;
  border-radius: var(--border-radius-sm);
  overflow: hidden;
  background-position: center center; 
  background-size: cover;             
  width: 100%;
  aspect-ratio: 16 / 9; 
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;                    
}


.sponsored-product-carousel-title {
  font-size: 2em;
  margin: 0;
}

.sponsored-product-carousel-price {
  font-size: 1.25em;
  margin: 10px 0;
}

.sponsored-carousel-prev {
  left: 10px;
}

.sponsored-carousel-next {
  right: 10px;
}

/* Sponsored shop carousel AD */
.sponsored-shops-carousel-container {
  border-radius: var(--border-radius-sm);
  margin: 10px auto;
  overflow: hidden;
  position: relative;
}

.sponsored-shops-carousel-loading {
  text-align: center;
   color: var(--text-color);
}

.sponsored-shops-carousel-paper {
  background-position: center center;
  background-size: cover;
  aspect-ratio: 3/1;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
 color: var(--text-color);;
  border-radius: var(--border-radius-sm);
}
.sponsored-shops-carousel-paper-mobile {
  background-position: center center;
  background-size: cover;
  aspect-ratio: 16/9;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
 color: var(--text-color);;
  border-radius: var(--border-radius-sm);
}

.sponsored-shops-carousel-content {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  text-align: center;
  align-content: center !important;
  border: none !important;
  border-radius: 15px !important;
  height: 99.9% !important;
  padding-bottom: 25px !important;
  text-align: center !important;
  width: 99.9% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sponsored-shops-carousel-avatar {
  width: 100px;
  height: 100px;
  min-height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin: 10px;
  border: 1px solid #fafafab3 !important;
}

.sponsored-shops-carousel-avatar-mobile {
  width: 80px;
  height: 80px;
  min-height: 80px;
  object-fit: cover;
  border-radius: 50%;
  margin: 10px;
  border: 1px solid #fafafab3 !important;
}

.sponsored-shops-carousel-title {
  font-size: 2em;
  margin: 0;
}

.sponsored-shops-carousel-button {
  margin-top: 20px;
   color: var(--text-color);
  background-color: var(--secondary-color) !important;
  border-radius: var(--border-radius-full) !important;
  padding: 5px 25px !important;
  margin: 25px ;
  border: none;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  font-weight: 700
}

@media (max-width: 480px) {
  .sponsored-shops-carousel-button {
    margin-top: 0px;
    margin: 5px;
  }
}

.sponsored-shops-carousel-button:hover {
  background-color: var(--secondary-color);
}

/* Sponsored Shop Carousel Preview */
.ad-preview-sponsored-shop-carousel-container {
  padding: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: var(--border-radius-sm);
    margin: 10px auto;
    overflow: hidden;
    position: relative;
}

.ad-preview-sponsored-shop-carousel-wrapper {
  background-position: center;
  background-size: cover;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
 color: var(--text-color);;
  position: relative;
  border-radius: var(--border-radius-sm);
}

.ad-preview-sponsored-shop-carousel-content {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  text-align: center;
  width: 100%;
  border-radius: var(--border-radius-sm);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  top: 0;

}

.ad-preview-sponsored-shop-carousel-item {
  background-size: cover;
  aspect-ratio: 3 / 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
 color: var(--text-color);;
  margin: 1%;
  border-radius: var(--border-radius-sm);
  overflow: hidden;
  position: relative;
}

.ad-preview-sponsored-shop-carousel-banner-container {
position: relative;
width: 100%;
height: 100%;
overflow: hidden;
aspect-ratio: 3 / 1;
top: 0;
left: 0;
}

.ad-preview-sponsored-shop-carousel-avatar {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 10px;
  border: 2px solid #fafafa;
}

.ad-preview-sponsored-shop-carousel-title {
  font-size: 2em;
  margin: 0;
}

.ad-preview-sponsored-shop-carousel-button {
  margin-top: 20px;
   color: var(--text-color);
  background-color: var(--primary-color);
  border-radius: var(--border-radius-full);
  padding: 10px 30px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.ad-preview-sponsored-shop-carousel-button:hover {
  background-color: #28a000;
}



/* Quad product carousel AD */
.quad-product-ad-carousel-container {
  max-width: 100% !important;
  overflow: hidden;
  position: relative;
}

.quad-product-ad-carousel-item-link {
  display: block;
  text-decoration: none;
  color: inherit;
  width: 50%;
  padding: 10px;
}


@media (max-width: 768px) {
  .quad-product-ad-carousel-container {
    padding: 0px;
  }
  .quad-product-ad-carousel-item {
    margin: 0%;
  }
  .quad-product-ad-carousel-title {
    font-size: 14px !important;
    margin: 0;
  }
  .quad-product-ad-carousel-item-link {
    width: 100%;
    padding: 5px 0px;
  }
  
}

.quad-product-ad-carousel-loading {
  text-align: center;
   color: var(--text-color);
}

.quad-product-ad-carousel-quad {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  transition: transform 0.5s ease-in-out;
  width: 100%;
}

.quad-product-ad-carousel-error {
  text-align: center;
  color: red;
  width: 100%;
}

.quad-product-ad-carousel-item {
  position: relative;
  flex: 1 1 48%;
  width: 100%;
  aspect-ratio: 16 / 9;
  
  border-radius: var(--border-radius-sm);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
 color: var(--text-color);;
  background-size: cover;
  background-position: center;
}

.quad-product-ad-carousel-image-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: #f0f0f0;
}

.quad-product-ad-carousel-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.quad-product-ad-carousel-content {
  position: absolute;
  border-radius: 5px;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  text-align: center;
  width: 99.9% !important;
  height: 99.9% !important;
  padding-bottom: 25px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.quad-product-ad-carousel-title {
  font-size: 1.5em;
  margin: 0;
}

.quad-product-ad-carousel-price {
  font-size: 1.25em;
  margin: 10px 0;
}

.quad-product-ad-carousel-button {
  background-color: var(--primary-color);
   color: var(--text-color);
  padding: 10px 20px;
  border-radius: var(--border-radius-full);
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.quad-product-ad-carousel-button:hover {
  background-color: #28a200;
}

/* Sponsored brand AD carousel*/
.sponsored-brand-ad-carousel {
  display: flex;
  flex-direction: row;
  width: 100%; 
  overflow: hidden;
  position: relative;
}

.sponsored-brand-ad-carousel-item {
  background-position: center center;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
 color: var(--text-color);;
  border-radius: 15px;
  overflow: hidden;
  aspect-ratio: 3 / 1;
}

.sponsored-brand-ad-carousel-item-mobile {
  background-position: center center;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
 color: var(--text-color);;
  border-radius: 15px;
  overflow: hidden;
  aspect-ratio: 16 / 9;
}

.sponsored-brand-ad-carousel-content {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  text-align: center;
  border-radius: 15px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sponsored-brand-ad-carousel-title {
  font-size: 2em;
  margin: 0;
}

.sponsored-brand-ad-carousel-description {
  font-size: 1.25em;
  margin: 0;
}

.sponsored-brand-ad-carousel-button {
  margin-top: 20px;
   color: var(--text-color);
  background-color: var(--primary-color);
  border-radius: var(--border-radius-full);
  padding: 5px 25px;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s ease;
}

.sponsored-brand-ad-carousel-button:hover {
  background-color: #2ba600;
}


.ad-preview-sponsored-brands-carousel-mobile-container {
  max-width: 100%;
  margin-top: 200px;
}

.ad-preview-sponsored-brands-carousel-mobile-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-sm);
  overflow: hidden;
  width: 100%;
  aspect-ratio: 16 / 9;
  position: relative;
}

.ad-preview-sponsored-brands-carousel-mobile-image-container {
  width: 100%;
  position: relative;
  aspect-ratio: 16 / 9;
}

.ad-preview-sponsored-brands-carousel-mobile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ad-preview-sponsored-brands-carousel-mobile-content {
  padding: 10px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
}

.ad-preview-sponsored-brands-carousel-mobile-title {
  font-size: 18px;
   color: var(--text-color);
}

.ad-preview-sponsored-brands-carousel-mobile-description {
  font-size: 14px;
   color: var(--text-color);
}

.ad-preview-sponsored-brands-carousel-mobile-button {
  margin-top: 10px;
  padding: 8px 20px;
  background-color: var(--primary-color);
   color: var(--text-color);
  border-radius: var(--border-radius-full);
  cursor: pointer;
  text-decoration: none;
}



/* Dual Product ad carousel */
.dual-product-ad-carousel-container {
  padding: 0px;
  border-radius: var(--border-radius-sm);
  margin: 45px auto;
  overflow: hidden;
  position: relative;
}

@media (max-width: 768px) {
  .dual-product-ad-carousel-container {
    margin: 10px auto;
  }
}

.dual-product-ad-carousel-loading {
  text-align: center;
   color: var(--text-color);
}

.dual-product-ad-carousel-wrapper {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
  gap: 10px;
  max-width: 100%;
}

@media (min-width: 1024px) {
  .dual-product-ad-carousel-wrapper {
    gap: 10px; 
  }
}

.dual-product-ad-carousel-pair {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.dual-product-ad-carousel-error {
  text-align: center;
  color: red;
  width: 100%;
}

.dual-product-ad-carousel-item {
  position: relative;
  aspect-ratio: 1 / 1;
  width: 100%;
  border-radius: var(--border-radius-sm);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
 color: var(--text-color);;
}

.dual-product-ad-carousel-image-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  background-color: #f0f0f0;
  max-width: 100%;
}

.dual-product-ad-carousel-image {
  position: absolute;
  top: 0;
  left: 0;
}

.dual-product-ad-carousel-content {
  position: absolute;
  border-radius: 5px;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  text-align: center;
  width: 99.9% !important;
  height: 99.9% !important;
  padding-bottom: 25px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dual-product-ad-carousel-title {
  font-size: 2em;
  margin: 0;
}

@media (max-width: 768px) {
  .dual-product-ad-carousel-title {
    font-size: 20px;
    margin: 0;
  }
}

.dual-product-ad-carousel-price {
  font-size: 1.25em;
  margin: 10px 0;
}

.dual-product-ad-carousel-button {
  background-color: var(--primary-color);
   color: var(--text-color);
  padding: 10px 20px;
  border-radius: var(--border-radius-full);
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.dual-product-ad-carousel-button:hover {
  background-color: #28a200;
}



/* Sponsored carousel */

.sponsored-carousel-prev {
  left: 10px;
}

.sponsored-carousel-next {
  right: 10px;
}


/* Banner ad for product*/
.product-banner-ad-container {
    position: fixed;
    z-index: 50;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 15px 120px;
    margin-bottom: 20px;

}

.product-banner-ad-mobile-container {
  position: fixed;
  z-index: 10;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px 10px;
  margin-bottom: 60px;

}

@media (max-width: 1024px) {
  .product-banner-ad-container {
      padding: 15px 60px; 
  }
}

/* For mobile screens (width below 768px) */
@media (max-width: 768px) {
  .product-banner-ad-container {
      padding: 00px 20px;
      margin-bottom: 10vh;
      
  }
}

/* Gradient overlay */
.product-banner-ad-gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to left, rgba(0,0,0,0.1), rgba(0,0,0,0.8)); 
  z-index: 1;
}

.product-banner-ad-image-container {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  aspect-ratio: 12 / 1;
}

.product-banner-ad-mobile-image-container {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  aspect-ratio: 4 / 1;
}

.product-banner-ad-image {
  position: absolute;
}

.product-banner-ad-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(54, 54, 54, 0.75);
    background-size: cover;
    background-position: center;
    padding: 0px 36px;
    border-radius: var(--border-radius-sm);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.5);
    position: relative;
    overflow: hidden;
    border: 1px solid rgba(250, 250, 250, 0.2) ;
    aspect-ratio: 12 / 1;
}

.product-banner-ad-mobile-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(54, 54, 54, 0.75);
  background-size: cover;
  background-position: center;
  padding: 0px 36px;
  border-radius: var(--border-radius-sm);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.5);
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(250, 250, 250, 0.2) ;
  aspect-ratio: 4 / 1;
}

.product-banner-ad-link {
    font-size: 0.875rem;
    line-height: 1.5rem;
     color: var(--text-color);
    flex-grow: 1;
    text-decoration: none;
    z-index: 10;
}

.product-banner-ad-text {
  z-index: 10;
}

.product-banner-ad-strong {
    font-weight: 600;
}

.product-banner-ad-dismiss {
    margin: -12px;
    padding: 12px;
    z-index: 10;
}

.product-banner-ad-icon {
    height: 20px;
    width: 20px;
     color: var(--text-color);
}

.css-1uch84x {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: rgb(31, 156, 41);
    padding: 0.6rem;
    color: rgb(255, 255, 255);
    border-radius: 8px;
    height: 35px !important;
}

a.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.css-1hw9j7s {
    background-color: var(--primary-color) !important;
    border-radius: var(--border-radius-full) !important;
    padding: 5px 25px !important;
    margin: 25px 25px !important;
}

.css-ohwg9z {
    position: relative;
    overflow: hidden;
    margin: 25px 0px !important;
}

.w-10\/12.mx-auto {
    width: 100% !important;
}

.best-deals-homepage-heading {
   color: var(--text-color);;
    font-family: Roboto, sans-serif;
    font-size: 27px;
    font-weight: 600;
    padding-bottom: 20px;
    text-align: left !important;
}

.recommended-friends-title {
    color: #ffffff !important;
    font-size: 24px;
    font-weight: 700 !important;
    margin-bottom: 0;
    text-align: left !important;
}

.suggested-friend-list {
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-wrap: nowrap;
    gap: 0;
    justify-content: left;
    overflow-x: auto;
    padding-bottom: 25px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 25px;
}

.css-aoeo82 {
    /*background-color: var(--background-color) !important;*/
    background-color: transparent !important;
    color: #ffffff !important;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 15px;
    /*border: 1px solid var(--border-color) !important;*/
    border: none !important;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
}

.relative.h-\[60px\].mt-\[10px\].w-\[270px\].hidden.\31 000px\:block {
    border: 1px solid rgba(250, 250, 250, 0.5) !important;
    border-bottom: none !important;
    background-color: var(--background-color) !important;
    bottom: 0;
    margin-bottom: -25px !important;
    margin-left: 15px !important;
    margin-right: 15px !important;
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.css-aoeo82 div {
    padding-bottom: 25px !important;
    text-align: center !important;
    width: 99.9% !important;
    height: 99.9% !important;
    align-content: center !important;
    border: none !important;
    border-radius: 15px !important;
}

.sponsored-product-carousel-title {
    font-size: 24px !important;
    margin: 0;
    font-weight: 700 !important;
    letter-spacing: 2px !important;
}

.w-full.px-12.py-8.lg\:px-16 {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.sponsored-products-component-container {
    background-color: var(--background-color) !important;
    border-radius: var(--border-radius-sm);
    margin: 10px auto;
    max-width: 1000px;
    padding: 20px;
    border: 1px solid var(--border-color) !important;
}

/* Sponsored product AD */
.sponsored-products-component-container {
  padding: 20px;
  background-color: rgba(0,0,0,0.5);
  border-radius: var(--border-radius-sm);
  margin: 10px auto;
  max-width: 1000px;
}

.sponsored-products-component-title {
  text-align: center;
  margin-bottom: 20px;
  color: rgba(250, 250, 250, 0.8) !important;
}

.sponsored-products-component-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sponsored-products-component-item {
  border: 1px solid #fafafa80 !important;
  border-radius: var(--border-radius-sm);
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;
  aspect-ratio: 1 / 1;
}


.sponsored-products-component-link {
  text-decoration: none;
  color: inherit;
  display: block;
  width: 100%;
}

.sponsored-products-component-image-wrapper {
  width: 100%;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  position: relative; 
  background-color: #f0f0f0; 
}

.sponsored-products-component-image {
  position: absolute; 
  top: 0;
  left: 0;
  transform: translate(
    var(--x-offset, 0px), 
    var(--y-offset, 0px)
  ); 
  object-fit: cover;
}

.sponsored-products-component-info {
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(250, 250, 250, 0.2);
   color: var(--text-color);
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: absolute;
  bottom: 0;
}


.sponsored-products-component-product-name {
  font-size: 16px;
  margin: 0;
}

.sponsored-products-component-product-price {
  font-size: 14px;
  font-weight: bold;
  margin: 5px 0;
}

.sponsored-products-component-visit-store {
  font-size: 12px;
  text-decoration: none;
  color: #fff !important;
  background-color: var(--secondary-color) !important;
  padding: 5px 15px;
  border-radius: var(--border-radius-full);
  font-weight: 700 !important;
}



.sponsored-brands-widget-title {
    color: #777777 !important;
    font-size: 12px !important;
    font-weight: 300;
    letter-spacing: 1px;
    margin-bottom: 10px;
    text-align: center;
}

.profile-name {
    color: #ffffff !important;
    font-size: 25px !important;
    font-weight: 700 !important;
}

.profile-name-wrapper {
    margin-bottom: 0px !important;
    display: contents;
}

.sponsored-products-component-title {
    font-size: 12px !important;
    margin-bottom: 15px !important;
    text-align: center;
}

.user-profile-data-content-container {
    padding-top: 0px !important;
}


.css-miw4ts {
    padding: 15px !important;
    border-radius: 10px !important;
}

.bg-\[\#f5f5f5\] {
    --tw-bg-opacity: .2;
    background-color: rgb(250 250 250 / var(--tw-bg-opacity));
    background-color: #fafafa33 !important;
    background-color: var(--background-color) !important;
}


.sponsored-products-component-container {
    background-color: transparent !important;
    border: none !important;
    border-radius: var(--border-radius-sm);
    margin: 0px !important;
    max-width: 1000px;
    padding: 0px !important;
}

main.main-feed-page-main-content.p-2.w-full.lg\:order-2.main-feed-page-scrollable {
    padding-top: 0px !important;
}

/* Marketplace hero component */
  .marketplace-hero-slider {
    border-radius: var(--border-radius-sm);
    border: 1px solid var(--border-color);
    margin-top: 35px ;
    position: relative;
    height: 300px;
  }

  @media (max-width: 768px) {
    .marketplace-hero-slider {
      margin-top: 10px;
    }
  }
  
  .marketplace-hero-slide {
    position: absolute;
    inset: 0;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    transition: opacity 0.5s ease-in-out;
  }
  
  .marketplace-hero-slide-active {
    opacity: 1;
  }
  
  .marketplace-hero-slide-inactive {
    opacity: 0;
  }
  
  .marketplace-hero-slide-content {
    height: 100%;
    background: linear-gradient(to left, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.5) 100%);
     color: var(--text-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .marketplace-hero-slide-title {
    font-size: 4rem; 
    font-weight: bold;
    white-space: nowrap; 
    overflow: hidden;
    
}
  
  .marketplace-hero-slide-button {
    margin-top: 1rem;
    display: inline-block;
    background-color: #22c55e;
     color: var(--text-color);
    font-size: 2rem;
    font-weight: bold;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    text-decoration: none;
    transition: background-color 0.3s ease-in-out;
  }
  
  @media (max-width: 1024px) {
    .marketplace-hero-slide-title {
        font-size: 3rem; 
    }

    .marketplace-hero-slide-button {
        font-size: 1.5rem; 
        padding: 0.4rem 0.9rem; 
    }
}

@media (max-width: 768px) {
    .marketplace-hero-slide-title {
        font-size: 2rem; 
    }

    .marketplace-hero-slide-button {
        font-size: 1rem; 
        padding: 0.2rem 0.5rem; 
    }
}
  
  .marketplace-hero-slide-button:hover {
    background-color: #15803d;
  }
  
  .marketplace-hero-slider-button {
    position: absolute;
    top: 50%;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
     color: var(--text-color);
    border: none;
    border-radius: 50%;
    padding: 0.75rem;
    cursor: pointer;
    transform: translateY(-50%);
  }
  
  .marketplace-hero-slider-button-left {
    left: 1rem;
  }
  
  .marketplace-hero-slider-button-right {
    right: 1rem;
  }
  
  .marketplace-hero-icon {
    height: 1.5rem;
    width: 1.5rem;
  }
  

  .marketplace-page-content {
    padding-left: 120px;
    padding-right: 120px;
    box-sizing: border-box;
    max-width: 100%;
    overflow: hidden;
  }
  
  @media (max-width: 768px) {
    .marketplace-page-content {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  
  @media (max-width: 1024px) {
    .marketplace-page-content {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  /* Retail marketplace category section */

  .category-marketplace-section-component-bg-black {
    border-bottom: 1px solid #fafafa4d !important;
}

  .category-marketplace-section-component-section-wrapper {
    padding-top: 4rem;
    padding-bottom: 6rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 80rem;
  }
  
  .category-marketplace-section-component-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .category-marketplace-section-component-title {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .category-marketplace-section-component-browse-link {
    display: none;
    font-size: 0.875rem;
    font-weight: 600;
    transition: color 0.3s ease;
    background-color: #fafafa1a !important;
    border: 1px solid #fafafa66 !important;
    border-radius: var(--border-radius-full) !important;
    color: #fff !important;
    margin-bottom: 25px !important;
    padding: 10px 25px !important;
  }
  
  .category-marketplace-section-component-browse-link:hover {
    color: #4f46e5;
  }
  
  @media (min-width: 640px) {
    .category-marketplace-section-component-browse-link {
      display: block;
    }
  }
  
  .category-marketplace-section-component-categories-wrapper {
    margin-top: 1rem;
    overflow: hidden;
  }
  
  .category-marketplace-section-component-categories-inner-wrapper {
    margin-top: -0.5rem;
  }
  
  .category-marketplace-section-component-categories-list {
    position: relative;
    height: 22rem;
    overflow-x: auto;
    overflow-y: hidden;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  
  .category-marketplace-section-component-categories-list-inner {
    position: absolute;
    display: flex;
    gap: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  @media (max-width: 768px) {
    .category-marketplace-section-component-categories-list-inner {
      position: absolute;
      display: flex;
      gap: 10px;
      padding-left: 0px;
      padding-right: 1rem;
    }
  }
  
  .category-marketplace-section-component-category-item {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 20rem;
    width: 14rem;
    padding: 1.5rem;
    border-radius: 0.75rem;
    transition: opacity 0.3s ease;
  }
  
  .category-marketplace-section-component-category-item:hover {
    opacity: 0.75;
  }
  
  .category-marketplace-section-component-category-image-wrapper {
    position: absolute;
    inset: 0;
  }
  
  .category-marketplace-section-component-category-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 10%;
  }
  
  .category-marketplace-section-component-category-overlay {
    position: absolute;
    left:0;
    right:0;
    bottom: 0;
    height: 66.67%;
    background: linear-gradient(to top, #1a1a1a 0%, rgba(26, 26, 26, 0) 100%);
    opacity: 0.5;
  }
  
  .category-marketplace-section-component-category-name {
    position: relative;
    margin-top: auto;
    text-align: center;
    font-size: 1.25rem;
    font-weight: bold;
     color: var(--text-color);
  }
  
  .category-marketplace-section-component-browse-link-wrapper {
    margin-top: 1.5rem;
    padding-left: 1rem;
  }
  
  .category-marketplace-section-component-browse-link-sm {
    display: block;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 16px;
    transition: color 0.3s ease;
  }
  
  .category-marketplace-section-component-browse-link-sm:hover {
    color: #4338ca;
  }

  
/* Shop dashboard hero */

.shop-dashboard-hero-header-container {
  width: 100%;
  padding: 2rem;
  margin-left: 50px;
}

@media (max-width: 480px) {
  .shop-dashboard-hero-header-container {
    width: 100%;
    padding: 0rem;
    margin-left: 0px;
  }
}

.shop-dashboard-hero-header-title {
  font-size: 22px;
  font-family: 'Poppins', sans-serif;
  padding-bottom: 0.5rem;
}

.shop-dashboard-hero-header-overview {
  display: block;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 800px) {
  .shop-dashboard-hero-header-overview {
      display: flex;
  }
}

.shop-dashboard-hero-header-card {
  width: 100%;
  margin-bottom: 1rem;
  padding: 2rem 1rem;
}

.shop-dashboard-hero-header-card-content {
  display: flex;
  align-items: center;
}

.shop-dashboard-hero-header-card-title {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.25rem;
}

.shop-dashboard-hero-header-card-subtitle {
  font-size: 16px;
}

.shop-dashboard-hero-header-card-value {
  padding-top: 0.5rem;
  padding-left: 2.25rem;
  font-size: 22px;
  font-weight: 500;
}

.shop-dashboard-hero-header-card-link {
  padding-top: 1rem;
  padding-left: 0.5rem;
  color: var(--primary-color);
  text-decoration: none;
}

.shop-dashboard-hero-header-latest-orders {
  font-size: 22px;
  font-family: 'Poppins', sans-serif;
  padding-bottom: 0.5rem;
}

@media (max-width: 480px) {
  .shop-dashboard-hero-header-latest-orders {
    padding-left: 10px;
  }
}

.shop-dashboard-hero-header-orders-container {
  width: 100%;
  background-color: var(--background-color);
  border-radius: 0.5rem;
  padding: 1rem;
  overflow-x: auto; 
}

.shop-dashboard-hero-header-orders-table {
  width: 100%;
}

.shop-dashboard-hero-header-data-grid {
  width: 100%;
  border-collapse: collapse; 
  overflow-x: unset;
}

.shop-dashboard-hero-header-data-grid th,
.shop-dashboard-hero-header-data-grid td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #2d3748;
}

.shop-dashboard-hero-header-data-grid th {
  background-color: var(--background-color);
  color: #f7fafc;
}

.shop-dashboard-hero-header-data-grid td {
  color: #f7fafc;
}

.shop-dashboard-hero-header-data-grid td button {
  background: none;
  border: none;
  cursor: pointer;
  color: inherit;
}

.shop-dashboard-hero-all-orders-pending-row {
  background-color: rgba(250, 250, 250, 0.2);
}

.shop-dashboard-sidebar-container {
  width: 60px;
  height: 90vh;
  background-color: var(--sidebar-background);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  overflow-y: auto; 
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  transition: width 0.3s ease;
  border-bottom-right-radius: 14px;
  border: 1px solid var(--border-color);
  border-left: none;
  border-top: none;
  margin-bottom: 50px;
  position: fixed;
  top: 80px;
  backdrop-filter: blur(5px); 
  -webkit-backdrop-filter: blur(5px); 
}

.shop-dashboard-sidebar-container:hover {
  width: 220px; /* Updated to match media query */
}

.shop-dashboard-sidebar-category {
  width: 100%;
}

.shop-dashboard-sidebar-category-header {
  color: #aaa;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 15px;
  text-transform: uppercase;
  white-space: nowrap;
}

.shop-dashboard-sidebar-item {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1rem 15px;
}

.shop-dashboard-sidebar-link {
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.shop-dashboard-sidebar-link h5 {
  display: none;
  padding-left: 10px; /* Increased padding for better spacing */
  font-size: 14px; /* Increased font size for readability */
  font-weight: 400;
  opacity: 0; 
  transition: opacity 0.3s ease; 
  white-space: nowrap; 
}

.shop-dashboard-sidebar-container:hover .shop-dashboard-sidebar-link h5 {
  display: block;
  opacity: 1; 
}

.shop-dashboard-sidebar-link .icon {
  font-size: 20px; 
  margin-right: 0.5rem; 
  transition: none; 
}

.shop-dashboard-sidebar-link.active {
  color: var(--primary-color);
}

.shop-dashboard-sidebar-category-header {
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.shop-dashboard-sidebar-container:hover .shop-dashboard-sidebar-category-header {
  display: block;
  opacity: 1;
}

.shop-dashboard-sidebar-profile-switch {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #026506;
  margin-top: auto; /* Push to the bottom */
}

@media (min-width: 800px) {
  .shop-dashboard-sidebar-container {
      width: 60px; 
  }

  .shop-dashboard-sidebar-container:hover {
      width: 220px; /* Adjusted to match new width */
  }

  .shop-dashboard-sidebar-link h5 {
      display: none;
  }

  .shop-dashboard-sidebar-container:hover .shop-dashboard-sidebar-link h5 {
      display: block;
      opacity: 1;
  }

  .shop-dashboard-sidebar-category-header {
      display: none;
  }

  .shop-dashboard-sidebar-container:hover .shop-dashboard-sidebar-category-header {
      display: block;
      opacity: 1;
  }
}

@media (max-width: 1024px) {
  .shop-dashboard-sidebar-container {
      display: none;
  }
}






/* Edit product in shop dashboard component */

.edit-product-component-form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.edit-product-component-section {
  border-bottom: 1px solid #2d3748;
  padding-bottom: 2rem;
}

.edit-product-component-title {
  font-size: 1.125rem;
  font-weight: 600;
   color: var(--text-color);
}

.edit-product-component-subtitle {
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #a0aec0;
}

.edit-product-component-grid {
  margin-top: 1.5rem;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(1, 1fr);
}

@media (min-width: 640px) {
  .edit-product-component-grid {
    grid-template-columns: repeat(6, 1fr);
    gap: 1.5rem;
  }
}

.edit-product-component-col-span-1 {
  grid-column: span 1 / span 1;
}

.edit-product-component-col-span-3 {
  grid-column: span 3 / span 3;
}

.edit-product-component-col-span-4 {
  grid-column: span 4 / span 4;
}

.edit-product-component-col-span-6 {
  grid-column: span 6 / span 6;
}

.edit-product-component-label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: #a0aec0;
}

.edit-product-component-input,
.edit-product-component-textarea,
.edit-product-component-file-input {
  margin-top: 0.25rem;
  display: block;
  width: 100%;
  border-radius: var(--border-radius-lg);
  border: 1px solid var(--border-color);
  background-color: var(--background-color);
  backdrop-filter: blur(5px);
  border: none;
  padding: 0.5rem 0.75rem;
  color: var(--text-color);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.edit-product-component-input:focus,
.edit-product-component-textarea:focus,
.edit-product-component-file-input:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(90, 103, 216, 0.45);
}

.edit-product-component-checkbox-container {
  display: flex;
  gap: 1rem;
  align-items: center;
  color: #a0aec0;
}

.edit-product-component-checkbox {
  margin-right: 0.5rem;
  border-radius: 0.375rem;
  background-color: #4a5568;
  color: var(--primary-color);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.edit-product-component-file-input-container {
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #a0aec0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.edit-product-component-submit-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  float: right;
  border: none;
  border-radius: 0.375rem;
  background-color: #38a169;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 500;
   color: var(--text-color);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: background-color 0.2s;
}

.edit-product-component-submit-button:hover {
  background-color: #2f855a;
}

.edit-product-component-submit-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(56, 161, 105, 0.45);
}

.edit-product-component-image-previews {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 15px;
}

.edit-product-component-product-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}

.MuiBox-root.css-miw4ts {
    margin: 15px 10px !important;
}

.mx-auto.flex.w-full.flex-col.lg\:flex-row.items-start.gap-x-8.px-4.py-10.sm\:px-6.lg\:px-24 {
    column-gap: 20px !important;
}

.user-profile-data-tab-content .MuiBox-root.css-miw4ts {
    margin: 0px !important;
}


/* Menu avatar dropdown */
.avatar-dropdown-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.29);
  z-index: 10;
}

.avatar-dropdown-menu-popup {
  background-color: var(--sidebar-background);
  backdrop-filter: var(--modal-overlay-backdrop-filter);
  border-left: 1px solid var(--border-color);
  box-shadow: none !important;
  height: 100%;
  max-width: 35% !important;
  overflow-y: auto;
  padding: 20px;
  position: fixed;
  right: 0;
  width: 25% !important;
  top: 10.4% !important;
}


.avatar-dropdown-menu-close-btn-container {
  display: flex;
  justify-content: flex-end;
  z-index: 1000;
  background-color: rgba(250, 250, 250, 0.2) !important;
  border: 1px solid rgba(250, 250, 250, 0.5) !important;
  border-radius: var(--border-radius-full) !important;
 color: var(--text-color);;
  position: relative;
  padding: 7px !important;
  cursor: pointer !important;
}


.avatar-dropdown-menu-close-btn {
  cursor: pointer;
}

.avatar-dropdown-menu-header {
  display: flex;
  align-items: center;
  padding: 16px 0;
}

.avatar-dropdown-menu-avatar {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 9999px;
  border-width: 2px;
  border-color: var(--border-color);
  cursor: pointer;
}

.avatar-dropdown-menu-items {
  border-top: 1px solid var(--border-color);
}

.avatar-dropdown-menu-item {
  border-bottom: 1px solid var(--border-color);
  padding: 16px 0;
}

.avatar-dropdown-menu-link {
  color: var(--text-color);
  text-decoration: none;
  display: block;
  padding: 8px 16px;
}


.avatar-dropdown-menu-link:hover {
  background-color: var(--background-color);
  color: rgba(250, 250, 250, 0.8);
  border-radius: var(--border-radius-full);
}

.avatar-dropdown-menu-link-cursor {
  cursor: pointer;
}

.avatar-dropdown-menu-subscription-icon-container {
  padding: 16px;
}

.avatar-dropdown-menu-subscription-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.product-details-component__content-bg {
    background-color: transparent !important;
    margin: 25px 7% !important;
}

.product-details-component__info-container {
    background-color: transparent !important;
    border-radius: .5rem;
    padding: 0px !important;
}

.p-4.w-10\/12.mx-auto {
    margin: 25px 7% !important;
    padding: 0px !important;
}


.profile-page {
    background-color: rgba(250, 250, 250, 0.0) !important;
}

.bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(12, 17, 23, 0.9) !important;
    border-bottom: 1px solid rgba(250, 250, 250, 0.4) !important;
}

button.h-full.w-full.flex.justify-between.items-center.pl-10.font-sans.text-lg.font-\[500\].select-none.rounded-t-md {
    background-color: rgba(255, 255, 255, 0.1) !important;
}

button.h-full.w-full.flex.justify-between.items-center.pl-10.font-sans.text-lg.font-\[500\].select-none.rounded-t-md {
    background-color: rgba(255, 255, 255, 0.1) !important;
    border: 1px solid rgba(250, 250, 250, 0.1) !important;
    border-bottom: 0px !important;
}

/*Filter Button Dropdown*/
.text-gray-700 {
    --tw-text-opacity: 1;
    background-color: var(--background-color) !important;
    border: 1px solid var(--border-color) !important;
    color: rgb(55 65 81 / var(--tw-text-opacity));
    color: #fafafa !important;
    padding: 5px 10px !important;
    border-radius: var(--border-radius-full) !important;
}

select#price-filter {
    color: #999999 !important;
    background-color: var(--background-color) !important;
    border: 1px solid var(--border-color) !important;
    padding: 10px 25px !important;
    border-radius: var(--border-radius-full) !important;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

aside.w-full.lg\:w-1\/4.p-4.rounded-lg.lg\:sticky.lg\:top-24 div {
    background-color: transparent !important;
    border: none !important;
}

.text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(250, 250, 250, 1.0) !important;
    margin-top: 15px !important;
}

a.hidden.text-sm.font-semibold.text-green-600.hover\:text-green-500.sm\:block {
    border: 1px solid var(--border-color) !important;
    border-radius: var(--border-radius-full) !important;
    color: #fff !important;
    padding: 10px 25px !important;
    background-color: var(--background-color) !important;
}

a.hidden.text-sm.font-semibold.text-green-600.hover\:text-indigo-500.sm\:block {
    background-color: var(--background-color) !important;
    border: 1px solid var(--border-color) !important;
    border-radius: var(--border-radius-full) !important;
    color: #fff !important;
    margin-bottom: 25px !important;
    padding: 10px 25px !important;
}

.w-10\/12.mx-auto.w-\[90\%\].\38 00px\:w-\[60\%\] {
    margin: 0px 7% !important;
}

.notification-sidebar-panel-content {
    background-color: transparent !important;
}


.fixed.top-0.right-0.h-full.w-\[80\%\].\38 00px\:w-\[25\%\].bg-white.flex.flex-col.overflow-y-scroll.justify-between.shadow-sm {
    background-color: rgba(12, 17, 23, 0.9) !important;
    border-left: 1px solid rgba(250, 250, 250, 0.4) !important;
    box-shadow: none !important;
    height: 100%;
    max-width: 28rem !important;
    overflow-y: auto;
    padding: 20px;
    position: fixed;
    right: 0;
    top: 10%;
    width: 400px;
}

.pointer-events-none.fixed.inset-y-0.right-0.flex.max-w-full.pl-10 {
    border-top: 0 !important;
    margin-top: 99px !important;
}

.flex.h-full.flex-col.overflow-y-scroll.bg-white.shadow-xl {
    background-color: transparent !important;
}

.pointer-events-none.fixed.inset-y-0.right-0.flex.max-w-full.pl-10.sm\:pl-16 {
    background-color: rgba(12, 17, 23, 0.9) !important;
    border-left: 1px solid rgba(250, 250, 250, 0.4) !important;
    box-shadow: none !important;
    height: 100%;
    max-width: 28rem !important;
    overflow-y: auto;
    padding: 20px;
    position: fixed;
    right: 0;
    top: 10%;
    width: 400px;
}



.text-gray-700 {
    --tw-text-opacity: 1;
    border: none !important;
    border-radius: 0px !important;
    color: #fafafa !important;
    padding: 0px !important;
}

label.block.text-sm.font-medium.text-gray-700 {
    background-color: transparent !important;
}

elect#price-filter {
    border: 1px solid #fafafa66 !important;
    border-radius: var(--border-radius-full) !important;
    color: #999 !important;
    margin-bottom: 15px !important;
    margin-top: 15px !important;
    padding: 10px 25px !important;
    background-color: var(--background-color) !important;
}

.shop-info-sidebar-actions {
    display: flex;
    flex-direction: column;
    gap: 25px !important;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.css-a4r3a9 {
    padding: 5px 15px !important;
    margin: 0px !important;
    margin-bottom: 0px !important;
}

.MuiBox-root.css-1ug174f, .bg-white {
    background-color: var(--background-color) !important;
    border: 1px solid var(--border-color) !important;
    border-radius: 10px !important;
}

.shop-profile-data-tab-content {
    margin-bottom: 15px !important;
    margin-top: 15px !important;
}
  
/* Search box header component*/
.search-box-component-container {
    width: 25%;
    position: relative;
  }
  
  .search-box-component-input {
    height: 40px;
    width: 100%;
    padding-left: 32px; 
    border: 1px solid var(--border-color) !important; 
    border-radius: 2rem;
    background-color: var(--background-color);
  }
  
  .search-box-component-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  
  .search-box-component-search-icon {
    left: 8px;
  }
  
  .search-box-component-close-icon {
    right: 8px; 
  }
  
  .search-box-component-dropdown {
    position: absolute;
    min-height: 30vh;
    max-height: 60vh;
    overflow-y: auto;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 9;
    padding: 16px;
    border-radius: 8px;
  }
  
  .search-box-component-dropdown-item {
    display: flex;
    align-items: start;
    padding: 8px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .search-box-component-dropdown-item:hover {
    background-color: rgba(250, 250, 250, 0.2); 
    border-radius: 8px;
  }
  
  .search-box-component-item-image {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    border-radius: 5px;
  }
  
  .search-box-component-item-name {
    font-size: 1rem;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.9);
  }
  
  .search-box-component-item-details {
    display: flex;
    align-items: center;
    margin-top: 4px;
  }
  
  .search-box-component-item-brand,
  .search-box-component-item-shop {
    font-size: 0.75rem;
    font-weight: 600;
    padding: 2px 4px;
    border-radius: 4px;
    margin-right: 4px;
  }
  
  .search-box-component-item-brand {
    background-color: rgba(34, 197, 94, 0.2); 
    color: rgba(34, 197, 94, 1); 
  }
  
  .search-box-component-item-shop {
    background-color: var(--background-color);
    color: rgba(34, 197, 94, 1); 
  }
  
  /* My post widget */

.my-post-widget-wrapper {
    border: 1px solid var(--border-color);
    border-radius: 0px;
   color: var(--text-color);;
    margin-bottom: 25px;
    padding: 25px;
    border-left: 0px;
    border-right: 0px;
}
.my-post-widget-input-base {
  background-color: transparent;
  border: none;
  border-radius: 0px;
  color: var(--text-color);
  padding: 20px 0px;
  width: 100%;
  font-size: 21px;
  font-weight: 500;
  overflow: scroll
}
.my-post-widget-input-base:focus {
  outline: none; 
}

.my-post-widget-link-post-preview-container {
  display: flex;
  align-items: flex-start;
}

.my-post-widget-link-post-preview-image {
  width: 80px;
  height: 80px;
  margin-right: 1rem;
}

.my-post-widget-link-post-preview-content {
  flex: 1;
}

.my-post-widget-link-post-preview-title {
  font-weight: bold;
}

.my-post-widget-link-post-preview-description {
  color: gray;
}

.my-post-widget-link-post-preview-url {
  color: blue;
}

.my-post-widget-divider {
  border-color: var(--border-color);
  margin-top: 25px !important;
  margin-bottom: 25px;
  margin: 1.25rem 0px;
  color: #fafafae6 !important;
}

.my-post-widget-image-video-container {
  margin: auto !important;
  margin-top: 0px !important;
  width: 75%;
}
@media (max-width: 480px) {
  .my-post-widget-wrapper {
    border-radius: 0px;
  }
  .my-post-widget-image-video-container {
    width: 100%;
  }
}

.my-post-widget-icon {
  color: #808080;
}

.my-post-widget-typography:hover {
  cursor: pointer;
  color: #808080;
}

.my-post-widget-post-button {
    background-color: var(--primary-color);
    border-radius: var(--border-radius-full);
    color: #fafafa;
    cursor: pointer;
    font-weight: 700;
    padding: 2px;
    min-width: 100px;
    width: 100px;
    height: 35px;
    font-size: 14px;
}

.my-post-widget-image-dropzone-container,
.my-post-widget-video-dropzone-container {
  border: 1px solid #808080;
  border-radius: 5px;
  margin-top: 1rem;
  padding: 1rem;
}

.my-post-widget-dropzone-inner {
  display: flex;
  align-items: center;
}

.my-post-widget-dropzone-box {
  border: 2px dashed #1bab27;
  padding: 1rem;
  width: 100%;
}

.my-post-widget-dropzone-box:hover {
  cursor: pointer;
}

.my-post-widget-image-preview,
.my-post-widget-video-preview {
  width: 100%;
  border-radius: 5px;
}

.my-post-widget-flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.my-post-widget-add-more-button {
  background-color: #28a745;
 color: var(--text-color);;
  border: none;
  padding: 0.5rem 1rem;
  margin-left: 0.5rem;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
}

.my-post-widget-add-more-button:hover {
  background-color: #218838;
}

/* Align Dropzone and Remove Button Horizontally */
.my-post-widget-dropzone-and-remove-button {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

/* Ensure Dropzone Takes Up Remaining Space */
.my-post-widget-dropzone-inner {
  flex: 1;
}

.my-post-widget-delete-icon-button-container {
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: var(--modal-overlay-backdrop-filter);
  padding: 10px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 50;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  display: flex;
  border: 1px solid var(--border-color);
}

/* Style the Remove Button */
.my-post-widget-delete-icon-button {
  cursor: pointer;
  font-size: 1.5rem;
  padding: 0;
  color: #808080;
  width: 40px;
  height: 40px;
}

.my-post-widget-delete-icon-button:hover {
  color: red;
}


  
  /* Post widget CSS */

.post-widget-form {
    width: 100%;
  }
  
  .post-widget-edit-description-textarea {
    width: 100%;
    background: none;
    border: none;
    font-size: inherit;
    color: inherit;
    line-height: inherit;
    resize: none;
    outline: none;
  }
  
  .post-widget-form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    margin-top: 0.75rem;
  }
  
  .post-widget-save-button,
  .post-widget-cancel-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.375rem;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    font-weight: 600;
    transition: background-color 0.2s ease-in-out;
  }
  
  .post-widget-save-button {
    background-color: #16a34a;
     color: var(--text-color);
  }
  
  .post-widget-save-button:hover {
    background-color: #15803d;
  }
  
  .post-widget-cancel-button {
    background-color: #d1d5db;
    color: #374151;
  }
  
  .post-widget-cancel-button:hover {
    background-color: #9ca3af;
  }
  
  .post-widget-hashtag {
    color: green;
    cursor: pointer;
  }

.post-widget-wrapper {
    border: none;
    margin: 0 0px;
    padding-top: 25px;
    padding: 25px 15px;
    position: relative;
    border-bottom: 1px solid var(--border-color);
    border-radius: 0px;
}

@media (min-width: 1024){
  .post-widget-wrapper {
    padding: 15px;
}
}

  /* Responsive Adjustments */
@media (max-width: 480px) {
  .post-widget-wrapper {
    border-radius: 0px;
    margin: 0px;
    margin-bottom: -1px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .my-post-widget-post-button {
    background-color: var(--primary-color);
    border-radius: var(--border-radius-full);
    color: #fafafa;
    cursor: pointer;
    font-weight: 700;
    padding: 0px;
    min-width: 85px;
    width: 85px;
    height: 25px;
    font-size: 12px ;
}
}

  
  .post-widget-popover {
    position: relative;
    float: right !important;
  }
  
  .post-widget-popover-button {
    display: inline-flex;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 600;
    color: #111827;
  }
  
  .post-widget-icon {
    height: 2.5rem;
    width: 2.5rem;
  }

  .post-widget-icons {
    color: var(--icon-color);
  }
  
  .post-widget-transition-enter {
    transition: opacity 0.2s ease-out, transform 0.2s ease-out;
  }
  
  .post-widget-transition-enter-from {
    opacity: 0;
    transform: translateY(0.25rem);
  }
  
  .post-widget-transition-enter-to {
    opacity: 1;
    transform: translateY(0);
  }
  
  .post-widget-transition-leave {
    transition: opacity 0.15s ease-in, transform 0.15s ease-in;
  }
  
  .post-widget-transition-leave-from {
    opacity: 1;
    transform: translateY(0);
  }
  
  .post-widget-transition-leave-to {
    opacity: 0;
    transform: translateY(0.25rem);
  }
  
  .post-widget-popover-panel {
    position: absolute;
    z-index: 10;
    margin-top: 0.5rem;
    background-color: rgba(250, 250, 250, 0.2) ;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: solid 1px;
    border-color: rgba(250, 250, 250, 0.9);
    right: 0rem;
  }
  
  .post-widget-popover-content {
    padding: 0.5rem 0;
  }
  
  .post-widget-popover-item {
    display: block;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    color: var(--text-color);
  }
  
  .post-widget-reshare {
    border-bottom: 1px solid rgba(250, 250, 250, 0.3) !important;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  
  .post-widget-reshare-header {
    display: flex;
    align-items: left;
  }
  
  .post-widget-reshare-avatar {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .post-widget-reshare-by {
    font-weight: bold;
  }
  
  .post-widget-reshare-description {
    margin-left: 40px;
    margin-top: 5px;
  }
  
  .post-widget-reshare-container {
    display: flex;
    flex-direction: column;
  }

  
  .post-widget-reshare-textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    margin-bottom: 0.5rem;
  }
  
  .post-widget-reshare-button {
    background-color: var(--primary-color); 
   color: var(--text-color);; 
    border: none;
    border-radius: var(--border-radius-full) !important; 
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 600;
    padding: 10px 25px !important; 
    transition: background-color 0.2s ease-in-out;
    width: 25% !important; 
    float: right !important; 
    align-items: center;
    margin-top: 10px !important; 
  }
  
  
  .post-widget-reshare-button:hover {
    background-color: #15803d;
  }
  
  .post-widget-cancel-button {
    background-color: #d1d5db;
    color: #374151;
  }
  
  .post-widget-cancel-button:hover {
    background-color: #9ca3af;
  }
  
  .post-widget-date {
    color: var(--text-color); 
    font-size: 10px;
    padding-left: 77px !important;
    padding-right: 4.5rem !important;
    margin-top: -15px !important; 
  }
  
  
  .post-widget-media-container {
    margin-top: 0.75rem;
  }
  
  .post-widget-image-gallery {
    display: flex;
    flex-direction: column;
  }
  
  .post-widget-image {
    border-radius: 0.75rem;
    margin-top: 0.5rem;
  }
  
  .post-widget-video-gallery {
    display: flex;
    flex-direction: column;
  }
  
  .post-widget-video {
    border-radius: 0.75rem;
    margin-top: 0.5rem;
  }
  
  .post-widget-like-icon {
    width: 24px;
    height: 24px;
    filter: var(--image-invert-filter)
  }

  .post-widget-like-icon-liked {
    width: 24px;
    height: 24px;
  }
  
  .post-widget-comment-container {
    display: flex;
    align-items: flex-start;
    padding: 10px;
  }
  
  .post-widget-comment-avatar-container {
    flex-shrink: 0;
  }
  
  .post-widget-comment-avatar {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
  }
  
  .post-widget-comment-content {
    margin-left: 0.5rem;
    flex-grow: 1;
  }
  
  .post-widget-comment-author {
    font-weight: bold;
  }

    
  .post-widget-comment-box {
    display: flex;
    justify-content: space-between;
  }
  
  
  .post-widget-comment-text-box {
    margin-top: 0.25rem;
  }
  
  .post-widget-comment-edit-input {
    width: 100%;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    padding: 0.25rem;
  }
  
  .post-widget-comment-text {
    display: block;
  }
  
  .post-widget-comment-popover {
    position: relative;
    display: inline-block;
  }
  
  .post-widget-comment-options-icon {
    cursor: pointer;
  }
  
  .post-widget-comment-options-panel {
    position: absolute;
    z-index: 10;
    margin-top: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .post-widget-comment-options-container {
    padding: 0.5rem 0;
  }
  
  .post-widget-comment-option-button {
    display: block;
    width: 100%;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .post-widget-comment-save-button {
    background-color: #16a34a;
     color: var(--text-color);
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .post-widget-comment-save-button:hover {
    background-color: #15803d;
  }
  
  .post-widget-comment-input-container {
    margin-top: 1.5rem;
  }
  
  .post-widget-comment-input-flex {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
  }
  
  .post-widget-comment-input-flex-1 {
    flex: 1;
  }
  
  .post-widget-comment-input-form {
    position: relative;
  }
  
  .post-widget-comment-input {
    width: 100%;
    resize: none;
    border: none;
    background: transparent;
    color: #fff !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    padding: 10px !important;
    outline: none;
}
  
  .post-widget-comment-submit-container {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem;
   
  }
  
  .post-widget-comment-submit-button {
     color: var(--text-color);
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    background-color: var(--primary-color) !important;
    border-radius: var(--border-radius-full) !important;
    margin-bottom: 0 !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    font-size: 1rem !important;
    line-height: 0.75rem;
  }
  
  .post-widget-comment-submit-button:hover {
    background-color: var(--primary-color-hover);
  }

  .post-widget-comment-options {
    position: relative;
  }
  
  .post-widget-report-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0,0,0,0.5);
    padding: 2rem;
    border: 2px solid #000;
    box-shadow: 24px 24px 48px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    width: 400px;
  }
  
  .post-widget-report-modal-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .post-widget-report-form {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .post-widget-report-submit-button {
    background-color: var(--primary-color);
     color: var(--text-color);
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    font-weight: 600;
    cursor: pointer;
  }
  
  .post-widget-report-cancel-button {
    background-color: #d1d5db;
    color: #374151;
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    font-weight: 600;
    cursor: pointer;
  }
  
  /* Following/follower panel*/

.followers-following-panel-content-body {
  padding: 15px !important;
  padding-top: 0px !important;
}

.followers-following-panel-tabs-nav {
  margin-left: 15px !important;
}

  .followers-following-panel-container {
    position: relative;
    z-index: 30;
    background-color: var(--sidebar-background);
    backdrop-filter: var(--modal-overlay-backdrop-filter);
    border-left: 1px solid var(--border-color);
    box-shadow: none;
    height: 100%;
    max-width: 28rem;
    overflow-y: auto;
    position: fixed;
    right: 0;
    width: 25% ;
    top: 100px 
  }

  /* Responsive adjustments */
@media (max-width: 1024px) {
  .followers-following-panel-container {
      width: 50%; 
      
  }
}

@media (max-width: 768px) {
  .followers-following-panel-container {
      width: 100%;
      border: none;
      top: 7%;
  }
}
  
  .followers-following-panel-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  
  .followers-following-panel-wrapper {
    position: relative;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 15px;
  }

    
  
  .followers-following-panel-inner {
    width: 100%;
    overflow: hidden;
  }
  
  .followers-following-panel-content {
    background-color: transparent; 
    border: none;
    border-radius: 0px;
    box-shadow: none ; 
    overflow-y: auto; 
    padding: 20px; 
    pointer-events: none; 
    position: relative;
  }  

  @media (max-width: 768px) {
    .followers-following-panel-wrapper {
      margin-top: 0px;
    }
    .followers-following-panel-content {
      padding: 0px; 
    }  
  }
 
  
  .followers-following-panel-enter-from {
    transform: translateX(100%);
  }
  
  .followers-following-panel-enter-to {
    transform: translateX(0);
  }
  
  
  .followers-following-panel-leave-from {
    transform: translateX(0);
  }
  
  .followers-following-panel-leave-to {
    transform: translateX(100%);
  }
  
  .followers-following-panel-panel {
    pointer-events: auto;
   
    max-width: 28rem;
    border: 1px solid #fafafa66 !important;
    border-radius: 10px !important;
    background-color: #0c1117e6 !important;
    box-shadow: none !important;
    height: 100%;
    max-width: 28rem !important;
  }
  
  .followers-following-panel-body {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  
  .followers-following-panel-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .followers-following-panel-header {
    padding-left: 15px;
    margin-top: 15px;
  }

  @media (max-width: 780px) {
    .followers-following-panel-header {
      padding: 15px;
    }
  }
  
  .followers-following-panel-title {
    font-size: 1.125rem;
    font-weight: 500;
  }
  
  .followers-following-panel-close-button-wrapper {
    margin-left: 0.75rem;
    display: flex;
    height: 1.75rem;
    align-items: center;
  }
  
  .followers-following-panel-close-button {
    border-radius: 0.375rem;
    color: #718096;
    transition: color 0.3s ease;
  }
  
  .followers-following-panel-close-button:hover {
    color: var(--text-color);
  }
  
  .followers-following-panel-close-button:focus {
    outline: 2px solid var(--primary-color);
    outline-offset: 2px;
  }
  
  .followers-following-panel-icon {
    height: 1.5rem;
    width: 1.5rem;
  }
  
  .followers-following-panel-tabs {
    margin-bottom: 1rem;
    border-bottom: 1px solid #e2e8f0;
  }
  
  .followers-following-panel-tabs-nav {
    display: flex;
    gap: 2rem;
  }

  .followers-following-panel-tab {
    color: var(--text-color);
    font-size: 14px;
    font-weight: 700;
    padding: 1rem 0.25rem;
    transition: color 0.3s ease, border-color 0.3s ease;
  }
  
  .followers-following-panel-tab-active {
    color: var(--primary-color);
  }
  
  .followers-following-panel-tab-inactive:hover {
    color: #2d3748;
    border-color: #e2e8f0;
  }
  
  /* 4 slides carousel component*/

  .carousel-4-slides-component-paper {
    background-position: center center;
    background-size: cover;
    height: 400px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
   color: var(--text-color);;
    
}

.carousel-4-slides-component-content {
    background: rgba(0, 0, 0, 0.5); 
    padding: 20px;
    align-content: center !important;
    border: none !important;
    border-radius: 15px !important;
    height: 99.9% !important;
    padding-bottom: 25px !important;
    text-align: center !important;
    width: 99.9% !important;
}

.carousel-4-slides-component-content h2 {
    font-size: 2em;
}

.carousel-4-slides-component-content p {
    font-size: 1.25em;
}
.carousel-4-slides-component-title {
    font-size: 2em;
}

.carousel-4-slides-component-description {
    font-size: 1.25em;
    margin-bottom: 1rem;
}

.carousel-4-slides-component-button {
     color: var(--text-color);
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 4px;
    margin-top: 40px !important;
    transition: background-color 0.3s ease;
    background-color: var(--primary-color) !important;
    border-radius: var(--border-radius-full) !important;
    padding: 5px 25px !important;
    margin: 25px 25px !important;
}

.carousel-4-slides-component-button:hover {
  background-color: var(--primary-color-hover);
}

/* Events page */
.events-page-container {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  background-color: #1a1a1a; 
  color: #f0f0f0; 
}

/* Sidebar */
.events-page-sidebar {
  width: 25%;
  min-width: 250px;
  max-width: 300px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  padding: 20px;
  background-color: rgba(40, 40, 40, 0.8); /* Dark semi-transparent background */
  transition: all 0.3s ease;
}

@media (max-width: 1024px) {
  .events-page-sidebar {
      display: none; /* Hide sidebar on smaller screens */
  }
}

/* Main Content */
.events-page-main-content {
  flex-grow: 1;
  padding: 20px;
  padding-top: 25px;
  margin-left: 5vw; 
  display: flex;
  flex-direction: column;
}

/* Filters */
.events-page-filters {
  margin-bottom: 20px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.events-page-select {
  background-color: var(--background-color);
  border: 1px solid #38a169;
  color: #f0f0f0;
  padding: 10px 16px;
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.3s, background-color 0.3s;
}

.events-page-select:focus {
  outline: none;
  background-color: rgba(250, 250, 250, 0.15);
  border-color: #2f855a;
}

/* Events List */
.events-page-events-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  gap: 20px;
}

/* Aside */
.events-page-aside {
  width: 25%;
  min-width: 250px;
  max-width: 300px;
  padding: 20px;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(40, 40, 40, 0.8); 
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  transition: all 0.3s ease;
}

@media (max-width: 1024px) {
  .events-page-aside {
      display: none; /* Hide aside on smaller screens */
  }
}

/* Create Event Button */
.events-page-create-event-button-container {
  display: flex;
  justify-content: center;
}

.events-page-create-event-button {
  background-color: #38a169;
  color: var(--text-color);
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s, transform 0.2s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.events-page-create-event-button:hover {
  background-color: #2f855a;
  transform: translateY(-2px);
}

/* Modal Overlay */
.events-page-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: var(--modal-overlay-backdrop-filter);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Box */
.events-page-modal-box {
  align-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  max-height: 80vh;
  overflow-y: auto;
  padding: 20px;
  position: relative;
  width: 80%;
  border: 1px solid var(--border-color);
  color: #f0f0f0;
  animation: slideDown 0.3s ease-out;
  max-width: 500px;
}

/* Scrollbar Styling for Modal */
.events-page-modal-box::-webkit-scrollbar {
  width: 8px;
}

.events-page-modal-box::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
}

.events-page-modal-box::-webkit-scrollbar-thumb {
  background-color: #38a169;
  border-radius: 4px;
}


.events-page-modal-title {
  text-align: center;
  font-size: 28px;
  color: #38a169;
  margin-bottom: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .events-page-modal-box {
    align-content: center;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 8px;
    max-height: 80vh;
    overflow-y: auto;
    padding: 0px;
    position: relative;
    width: 90%;
    border: 1px solid var(--border-color);
    color: #f0f0f0;
    animation: slideDown 0.3s ease-out;
    max-width: 500px;
  }
  .events-page-container {
      flex-direction: column;
  }

  .events-page-main-content {
      padding: 0px;
      margin-left: 0;
  }

  .events-page-filters {
      flex-direction: column;
  }

  .events-page-select {
      width: 100%;
  }

  .events-page-aside {
      display: none; 
  }
}




/* Event creation form component */
.event-creation-form-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

/* Main form styles */
.event-creation-form-form {
  background-color: transparent;
  border-radius: 8px;
  box-shadow: 0 4px 8px #0000001a;
  display: flex;
  flex-direction: column;
}

/* Form Title */
.event-creation-form-title {
  font-size: 1.5rem;
  color: #f0f0f0;
  text-align: center;
  margin-bottom: 10px;
  letter-spacing: 0.5px;
}

/* Labels */
.event-creation-form-label {
  display: block;
  margin-bottom: 5px;
  color: #cbd5e0;
  font-size: 0.9rem;
  font-weight: 600;
  letter-spacing: 0.4px;
}

.required-marker {
  color: var(--primary-color);
}

/* Inputs, Textareas, File Inputs */
.event-creation-form-input,
.event-creation-form-textarea,
.event-creation-form-file-input {
  background-color: var(--background-color);
  border: 1px solid rgba(250, 250, 250, 0.5);
  border-radius: var(--border-radius-full);
  color: rgba(250, 250, 250, 0.9);
  font-size: 16px;
  margin-bottom: 7px;
  margin-top: 5px;
  padding: 10px 15px;
  transition: border-color 0.3s, background-color 0.3s;
  width: 100%;
  box-sizing: border-box;
}

/* Textarea can have its own style if needed */
.event-creation-form-textarea {
  border-radius: var(--border-radius-md); 
  min-height: 80px;
  resize: vertical;
}

/* Focus states */
.event-creation-form-input:focus,
.event-creation-form-textarea:focus,
.event-creation-form-file-input:focus {
  outline: none;
  background-color: rgba(0, 0, 0, 0.9);
  border-color: var(--primary-color);
}

/* Image Upload Label */
.event-upload-label {
  display: inline-block;
 color: var(--text-color);;
  background-color: var(--primary-color);
  border-radius: var(--border-radius-full);
  padding: 8px 16px;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 600;
  transition: background-color 0.3s;
  text-align: center;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.event-upload-label:hover {
  background-color: #2db500;
}

/* Image Preview Container */
.image-preview {
  margin-top: 10px;
  text-align: center;
}

.preview-image {
  max-width: 100%;
  border-radius: 8px;
}

/* Date & Time Row */
.event-creation-form-date-time-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.date-time-group {
  flex: 1;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Error message styling */
.event-error-message {
  color: #ff5c5c;
  background-color: #ffdddd;
  border: 1px solid #ff5c5c;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  margin-top: 5px;
}

/* Submit Button */
.event-creation-form-button {
  background-color: var(--primary-color);
  border: none;
  border-radius: var(--border-radius-full);
 color: var(--text-color);;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  padding: 12px 24px;
  transition: background-color 0.3s, transform 0.2s;
  width: 50%;
  margin: 10px auto 0;
  display: block;
  text-align: center;
}

.event-creation-form-button:hover {
  background-color: #2db500;
  transform: scale(1.02);
}

.event-creation-form-button:disabled {
  background-color: #888;
  cursor: not-allowed;
  transform: none;
}

/* Responsive Styling */
@media (max-width: 768px) {

  .event-creation-form-label {
    font-size: 0.85rem;
  }

  .event-creation-form-button {
    width: 100%;
  }

  .event-creation-form-date-time-row {
    flex-direction: column;
    gap: 16px;
  }

  .date-time-group {
    min-width: 100%;
  }
}

/* Events card component */

.event-card-component-card {
  position: relative;
  margin: 20px;
  border: 1px solid var(--border-color);
  overflow: hidden;
  border-radius: var(--border-radius-md);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  animation: fadeInUp 1s ease forwards; 
  opacity: 0;
}

.event-card-component-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
}

.event-card-component-image {
  width: 100%;
  
  object-fit: cover;
  transition: transform 0.3s ease;
}

.event-card-component-card:hover .event-card-component-image {
  transform: scale(1.05);
}

.event-card-component-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7) 20%, transparent 100%);
   color: var(--text-color);
  padding: 20px;
  transition: background 0.5s ease;
}

.event-card-component-title {
  margin: 0;
  font-weight: bold;
  font-size: 24px;
}


.event-card-component-datetime {
  margin: 10px 0;
}

.event-card-component-date,
.event-card-component-time,
.event-card-component-location {
  margin: 5px 0;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
}

.event-card-icon {
  margin-right: 8px;
}

.event-card-component-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.event-card-component-button {
  background-color: var(--primary-color);
  border: none;
  border-radius: var(--border-radius-full) ; 
  box-shadow: 3px 3px #0000004d; 
 color: var(--text-color);; 
  cursor: pointer;
  font-size: 16px;
  padding: 10px 20px; 
  transition: background-color 0.3s ease; 
}


.event-card-component-button:hover {
  background-color: #ffa733;
}

.event-card-component-cancel-button {
  background-color: #dc3545;
   color: var(--text-color);
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: var(--border-radius-full);
  font-size: 16px;
  box-shadow: 3px 3px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease;
}

.event-card-component-cancel-button:hover {
  background-color: #c82333;
}

.event-card-component-calendar-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  background: rgba(250, 250, 250, 0.4);
  border-radius: 15px;
  padding: 10px;
  
}

.event-card-component-calendar-buttons > * {
  flex: 1;
}

.event-card-owner-details {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  border-radius: var(--border-radius-lg);
  padding: 8px;
 color: var(--text-color);;
}

.event-card-component-owner-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 8px; /* Spacing between avatar and name */
}

.event-card-component-owner-name {
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
}



/* Modal Overlay */
.event-card-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: var(--modal-overlay-backdrop-filter);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease;
}

/* Modal Content */
.event-card-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  padding: 30px;
  border-radius: var(--border-radius-lg);
  border: 1px solid rgba(250, 250, 250, 0.1);
  position: relative;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  animation: slideDown 0.3s ease;
}

/* Close Button */
.event-card-modal-close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
 color: var(--text-color);;
  transition: color 0.2s ease;
}

.event-card-modal-close-button:hover {
  color: #fd8309;
}

/* Modal Title */
.event-card-modal-content h2 {
  margin-top: 0;
  text-align: center;
  font-size: 24px;
}

/* Modal Buttons */
.event-card-modal-buttons {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.event-card-modal-buttons > * {
  width: 100%;
}

.event-card-modal-cancel-button {
  background-color: #dc3545; 
   color: var(--text-color);
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: var(--border-radius-sm);
  font-size: 16px;
  box-shadow: 3px 3px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.event-card-modal-cancel-button:hover {
  background-color: #c82333; 
}




/* Events creation calendar */
/* Container */
.custom-calendar {
  display: inline-block;
  width: 100%;
  max-width: 400px; /* Limit maximum width for better responsiveness */
  background-color: #ffffff0d; /* Subtle transparent white */
  backdrop-filter: blur(6px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  padding: 16px;
  color: #f0f0f0;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Header / Navigation */
.calendar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.calendar-nav {
  display: flex;
  gap: 4px;
}

.nav-button {
  background-color: transparent;
  border: none;
 color: var(--text-color);;
  font-size: 18px;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.nav-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Month and Year Selectors */
.calendar-selectors {
  display: flex;
  align-items: center;
  gap: 8px;
}

.calendar-select {
  background-color: transparent;
 color: var(--text-color);;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 14px;
  cursor: pointer;
}

.calendar-select:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Calendar Grid */
.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 6px;
}

/* Day Headers (Sun, Mon, Tue, etc.) */
.calendar-day-header {
  font-weight: 500;
  text-align: center;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

/* Individual Days */
.calendar-day {
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  transition: background-color 0.2s, transform 0.2s;
  cursor: pointer;
  min-height: 36px; /* Slightly consistent box size */
}

/* Hover effect on each day */
.calendar-day:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transform: scale(1.03);
}

/* Selected Day */
.calendar-day.selected {
  background-color: var(--primary-color);
 color: var(--text-color);;
}

/* Today Highlight */
.calendar-day.today {
  border: 1px solid var(--primary-color);
}

/* Empty Slots */
.calendar-empty {
  visibility: hidden;
}

/* Today Button */
.today-button {
  margin-top: 12px;
  display: block;
  width: 100%;
  background-color: var(--primary-color);
  border: none;
 color: var(--text-color);;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  transition: background-color 0.2s;
  text-align: center;
}

.today-button:hover {
  background-color: #2db500;
}

/* Responsive Tweaks */
@media (max-width: 400px) {
  .custom-calendar {
    padding: 12px;
  }

  .calendar-header {
    flex-direction: column;
    gap: 8px;
  }

  .calendar-nav {
    order: 1;
  }

  .calendar-selectors {
    order: 2;
    justify-content: center;
  }
}



/* Admin Dashboard Orders */

.admin-dashboard-orders {
  display: flex;
  flex-direction: column;
  height: 100vh;
  color: var(--text-color);
}

.admin-dashboard-orders__content {
  display: flex;
  flex: 1;
}

.admin-dashboard-orders__main {
  flex: 1;
  padding: 20px;
  margin-left: 15vw;
}

.admin-dashboard-orders__section {
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  padding: 20px;
  border-radius: var(--border-radius-md);
  box-shadow: var(--box-shadow);
  width: 80vw;
}

.admin-dashboard-orders__title {
  font-size: var(--font-size-lg);
  margin-bottom: 20px;
  color: var(--primary-color);
}

.admin-dashboard-orders__error {
  padding: 15px;
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-sm);
  margin-bottom: 20px;
}

.admin-dashboard-orders__table-container {
  overflow-x: auto;
}

.admin-dashboard-orders__table {
  width: 100%;
  border-collapse: collapse;
  min-width: 600px;
}

.admin-dashboard-orders__thead th {
  padding: 12px 15px;
  background-color: var(--background-color);
 color: var(--text-color);;
  text-align: left;
  font-size: var(--font-size-md);
}

.admin-dashboard-orders__tbody td {
  padding: 12px 15px;
  border-bottom: 1px solid var(--border-color);
  font-size: var(--font-size-sm);
}

.admin-dashboard-orders__tr:hover {
  background-color: var(--background-dark);
 color: var(--text-color);;
}

.admin-dashboard-orders__status {
  font-weight: bold;
  text-transform: capitalize;
}

.admin-dashboard-orders__status--delivered {
  color: #28a745; /* Green */
}

.admin-dashboard-orders__status--pending {
  color: #dc3545; /* Red */
}

/* Responsive Design */
@media (max-width: 768px) {
  .admin-dashboard-orders__main {
    padding: 10px;
  }

  .admin-dashboard-orders__section {
    padding: 15px;
  }

  .admin-dashboard-orders__thead th,
  .admin-dashboard-orders__tbody td {
    padding: 10px;
  }

  .admin-dashboard-orders__title {
    font-size: var(--font-size-md);
  }
}

  /* Mobile View - Card Style */
.shop-all-orders-mobile-list {
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
}

.shop-all-orders-mobile-list-item {
  background-color: var(--background-color);
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--border-color);
}

.shop-all-orders-mobile-content {
  display: flex;
  flex-direction: column;
}

.shop-all-orders-mobile-header {
  display: flex;
  justify-content: space-between;
}

.shop-all-orders-mobile-header h3 {
  font-size: 18px;
  font-weight: 600;
}

.shop-all-orders-mobile-header p {
  font-size: 14px;
  color: #6b7280;
}

.shop-all-orders-mobile-content p {
  font-size: 16px;
  margin: 5px 0;
}

.shop-all-orders-mobile-action {
  display: flex;
  align-items: center;
}

.shop-all-orders-mobile-action a {
  color: #4f46e5;
}
  
  
  /* Brand Page Shop Dashboard*/

  .brand-page-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .brand-page-main-container {
    max-width: 1200px;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 80px;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
  }

  @media (max-width: 480px) {
    .brand-page-main-container {
      margin-left: 0px;
      margin-bottom: 80px;
    }
  }
  
  
  .brand-page-grid-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
  }
  
  .brand-page-button {
    background-color: var(--primary-color);
     color: var(--text-color);
    padding: 8px 16px;
    border: none;
    border-radius: var(--border-radius-full);
    cursor: pointer;
  }
  
  .brand-page-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 500px;
    background-color: rgba(0, 0, 0, 0.8);
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius-sm);
    box-shadow: 24px 24px 48px rgba(0, 0, 0, 0.5);
    padding: 32px;
    z-index: 1000;
  }
  
  .brand-page-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .brand-page-modal-title {
     color: var(--text-color);
    font-size: 24px;
  }
  
  .brand-page-modal-close-button {
     color: var(--text-color);
    border: none;
    border-radius: 50%;
    padding: 8px;
    cursor: pointer;
    font-size: 24px;
  }
  
  .brand-page-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: var(--modal-overlay-backdrop-filter);
    z-index: 999;
  }

  /* Brand Management Page */

  .brand-management-container {
    width: 100%;
  }
  
  .brand-management-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .brand-management-sidebar {
    width: 80px;
  }
  
  @media (max-width: 768px) {
    .brand-management-sidebar {
      width: 0px;
    }
  }
  
  .brand-management-main-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  

  /* Owned brand */
  .owned-brand-component-container {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: var(--border-radius-sm);
    background-color: rgba(250, 250, 250, 0.2);
  }
  
  .owned-brand-component-cover-image-container {
    width: 100%;
    max-height: 250px;
    object-fit: cover;
    position: relative;
    text-align: center;
    overflow: hidden;
    border-radius: var(--border-radius-md);
  }

  .owned-brand-component-cover-image {
    width: 100%;
  }
  
  .owned-brand-component-avatar-container {
    position: relative;
    text-align: center;
    margin-top: -125px;
  }
  
  .owned-brand-component-avatar-image {
    width: 200px;
    height: 200px;
    border: 3px solid white;
    border-radius: 50%;
    overflow: hidden;
  }
  
  .owned-brand-component-camera-icon-container {
    position: absolute;
    right: 5px;
    bottom: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: green;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    z-index: 20;
    padding: 1px;
  }
  
  .owned-brand-component-brand-name {
    font-size: 24px;
    color: #000000;
    font-weight: bold;
    margin: 10px 0 5px;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
  }
  
  .owned-brand-component-brand-description {
    font-size: 16px;
    color: #000000;
    margin: 0 auto;
    max-width: 80%;
    line-height: 1.5;
    text-align: center;
  }
  
  .owned-brand-component-textarea {
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    margin-top: 5px;
    background: #000000;
    color: rgba(250, 250, 250, 0.2);
  }
  
  .owned-brand-component-button {
    margin-top: 20px;
    background-color: var(--background-color);
    border: 1px solid var(--primary-color);
     color: var(--text-color);
    padding: 10px 20px;
    border-radius: var(--border-radius-full);
    cursor: pointer;
    font-weight: bold;
  }
  
  .owned-brand-component-button.cancel {
    margin-left: 10px;
    background-color: grey;
  }
  
  .owned-brand-component-flex-center {
    display: flex;
    justify-content: center;
  }
  
  .owned-brand-component-add-catalog-item {
    background-color: #006400;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }
  
  .owned-brand-component-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--modal-overlay-background);
    backdrop-filter: var(--modal-overlay-backdrop-filter);
    z-index: 999;
  }
  
  .owned-brand-component-modal-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 600px;
    background-color: rgba(0, 0, 0, 0.8);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    box-shadow: 24px 24px 48px rgba(0, 0, 0, 0.5);
    padding: 32px;
    z-index: 1000;
    overflow-y: auto;
    height: 900px;
  }

  /* Brand catalog itme create */

  .brand-catalog-item-create-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    border: 1px solid var(--border-color);
    padding: 16px;
    border-radius: 8px;
    background-color: rgba(250, 250, 250, 0.2);
  }
  
  .brand-catalog-item-create-section {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .brand-catalog-item-create-title {
    font-size: 1.125rem;
    font-weight: 600;
    color: rgba(0,0,0,0.5);
  }
  
  .brand-catalog-item-create-input,
  .brand-catalog-item-create-textarea,
  .brand-catalog-item-create-select {
    width: 100%;
    padding: 8px;
    border-radius: 15px;
    border: 1px solid var(--border-color);
    background-color: var(--background-color);
   color: var(--text-color);;
    font-size: 0.875rem;
  }
  
  .brand-catalog-item-create-textarea {
    resize: vertical;
  }
  
  .brand-catalog-item-create-file-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px dashed #ccc;
    border-radius: 8px;
    padding: 16px;
    background-color: rgba(250, 250, 250, 0.2);
    text-align: center;
  }
  
  .brand-catalog-item-create-file-upload input[type="file"] {
    display: none;
  }
  
  .brand-catalog-item-create-file-upload-label {
    display: inline-block;
    cursor: pointer;
    background-color: #e0e0e0;
    padding: 8px 16px;
    border-radius: 4px;
    margin-top: 8px;
  }
  
  .brand-catalog-item-create-preview-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 16px;
  }
  
  .brand-catalog-item-create-preview-item {
    position: relative;
    border: 1px solid #4caf50;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .brand-catalog-item-create-preview-item img,
  .brand-catalog-item-create-preview-item video {
    width: 96px;
    height: 96px;
    object-fit: cover;
  }
  
  .brand-catalog-item-create-remove-button {
    position: absolute;
    top: 4px;
    right: 4px;
    background-color: #f44336;
   color: var(--text-color);;
    border: none;
    border-radius: 50%;
    padding: 4px;
    cursor: pointer;
  }
  
  .brand-catalog-item-create-checkbox-group {
    display: flex;
    gap: 16px;
    align-items: center;
  }
  
  .brand-catalog-item-create-checkbox-group input {
    margin-right: 8px;
  }
  
  .brand-catalog-item-create-actions {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    padding-top: 16px;
  }
  
  .brand-catalog-item-create-button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 600;
  }
  
  .brand-catalog-item-create-button.cancel {
    background-color: #e0e0e0;
    color: rgba(0,0,0,0.5);
  }
  
  .brand-catalog-item-create-button.submit {
    background-color: var(--primary-color);
   color: var(--text-color);;
  }

  /* brand catalog component*/

  .brand-catalog-component-container {
    background-color: rgba(0, 0, 0, 0.8);
    display: inline-block; 
    padding: 20px; 
    border-radius: var(--border-radius-sm); 
}
  
  .brand-catalog-component-inner {
    max-width: 1280px;
    margin: 0 auto;
    padding: 64px 16px;
  }
  
  .brand-catalog-component-title {
    font-size: 2rem;
    font-weight: bold;
    color: rgba(0,0,0,0.5)333;
    text-align: center;
  }
  
  .brand-catalog-component-grid {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 48px 16px;
  }
  
  @media (min-width: 640px) {
    .brand-catalog-component-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (min-width: 1024px) {
    .brand-catalog-component-grid {
      grid-template-columns: repeat(4, 1fr);
      gap: 48px;
    }
  }
  
  .brand-catalog-component-item {
    position: relative;
    cursor: pointer;
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: 5px;
    padding-bottom: 20px;
  }
  
  .brand-catalog-component-item-image-container {
    width: 100%;
    overflow: hidden;
    border-radius: 0.5rem;
  }
  
  .brand-catalog-component-item-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  
  .brand-catalog-component-item-details {
    margin-top: 16px;
    text-align: center;
  }
  
  .brand-catalog-component-item-name {
    font-size: 1rem;
    font-weight: medium;
    color: rgba(0,0,0,0.5)333;
  }
  
  .brand-catalog-component-item-category {
    margin-top: 4px;
    font-size: 0.875rem;
    color: #666666;
  }
  
  .brand-catalog-component-item-price {
    margin-top: 8px;
    font-size: 1rem;
    font-weight: bold;
    color: var(--primary-color);
  }
  
  /* Modal styles */
  .brand-catalog-component-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--modal-overlay-background);
    z-index: 999;
    backdrop-filter: var(--modal-overlay-backdrop-filter);
  }
  
  .brand-catalog-component-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 1024px;
    background-color: rgba(0, 0, 0, 0.8);
    border: 1px solid var(--border-color);
    border-radius: 0.5rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 32px;
    overflow-y: auto;
  }
  
  .brand-catalog-component-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .brand-catalog-component-modal-close {
    cursor: pointer;
    background-color: transparent;
    border: none;
  }
  
  .brand-catalog-component-modal-content {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
  }

  .brand-catalog-component-edit-form {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
  }
  
  @media (min-width: 768px) {
    .brand-catalog-component-modal-content {
      flex-direction: row;
      gap: 32px;
    }
  }
  
  .brand-catalog-component-modal-images {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .brand-catalog-component-modal-main-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
  }
  
  .brand-catalog-component-modal-thumbnails {
    display: flex;
    gap: 8px;
    overflow-x: auto;
  }
  
  .brand-catalog-component-modal-thumbnail {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 0.5rem;
    cursor: pointer;
    border: 2px solid transparent;
  }
  
  .brand-catalog-component-modal-thumbnail.selected {
    border-color: var(--primary-color);
  }
  
  .brand-catalog-component-modal-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .brand-catalog-component-modal-title {
    font-size: 2rem;
    font-weight: bold;
    color: rgba(0,0,0,0.5)333;
  }
  
  .brand-catalog-component-modal-description {
    font-size: 1rem;
    color: #666666;
  }
  
  .brand-catalog-component-modal-price {
    font-size: 1.25rem;
    font-weight: bold;
    color: var(--primary-color);
  }
  
  .brand-catalog-component-modal-details {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .brand-catalog-component-modal-details-item {
    font-size: 1rem;
    color: rgba(0,0,0,0.5)333;
  }
  
  .brand-catalog-component-modal-gallery {
    margin-top: 16px;
  }
  
  .brand-catalog-component-modal-gallery-title {
    font-size: 1.25rem;
    font-weight: bold;
    color: rgba(0,0,0,0.5)333;
  }
  
  .brand-catalog-component-modal-gallery-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
  
  .brand-catalog-component-modal-gallery-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 0.5rem;
    cursor: pointer;
  }

  .brand-catalog-current-images-container {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 0.5rem;
    cursor: pointer;
  }

  .brand-catalog-current-image-preview {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 0.5rem;
    cursor: pointer;
  }
  
  .brand-catalog-component-modal-actions {
    position: absolute;
    bottom: 16px;
    right: 16px;
    display: flex;
    gap: 8px;
  }
  
  .brand-catalog-component-modal-button {
    padding: 8px 16px;
    border: none;
    border-radius: var(--border-radius-full);
    cursor: pointer;
    font-weight: 600;
  }
  
  .brand-catalog-component-modal-button.close {
    background-color: #e0e0e0;
    color: rgba(0,0,0,0.5);
  }
  
  .brand-catalog-component-modal-button.edit {
    background-color: var(--background-color);
   color: var(--text-color);;
  }
  
  .brand-catalog-component-edit-label {
    font-size: 14px;
    font-weight: 600;
   color: var(--text-color);;
    margin-bottom: 8px;
  }

  
.brand-catalog-component-edit-input,
.brand-catalog-component-edit-textarea {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  font-size: 14px;
  background-color: rgba(0, 0, 0, 0.8);
}

.brand-catalog-component-edit-textarea {
  resize: vertical;
}

.brand-catalog-component-edit-checkbox-group {
  display: flex;
  align-items: center;
  gap: 8px;
}

.brand-catalog-component-edit-checkbox {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.brand-catalog-component-edit-checkbox-label {
  font-size: 14px;
 color: var(--text-color);;
}


.brand-catalog-component-modal-button.save,
.brand-catalog-component-modal-button.edit {
  background-color: var(--primary-color);
 color: var(--text-color);;
}

/* Brand create component */

.brand-create-component-container {
  background-color: rgba(250, 250, 250, 0.2);
  padding: 20px;
  border-radius: 8px;
  max-height: 80vh; 
  overflow-y: auto;
}

.brand-create-component-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.brand-create-component-form-group {
  display: flex;
  flex-direction: column;
}

.brand-create-component-label {
  font-size: 0.875rem;
  font-weight: 500;
 color: var(--text-color);;
  margin-bottom: 4px;
}

.brand-create-component-input,
.brand-create-component-textarea,
.brand-create-component-file-input {
  padding: 8px;
  font-size: 1rem;
  border-radius: var(--border-radius-md);
  border: 1px solid var(--border-color);
  background-color: var(--background-color);
 color: var(--text-color);;
}

.brand-create-component-file-input {
  padding: 4px;
  background-color: #444;
  color: #bbb;
}

.brand-create-component-submit-button {
  align-self: flex-start;
  padding: 10px 16px;
  font-size: 0.875rem;
  font-weight: 500;
   color: var(--text-color);
  background-color: var(--background-color);
  border: 1px solid var(--primary-color);
  border-radius: var(--border-radius-full);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.brand-create-component-submit-button:hover {
  background-color: #2f855a;
}

.brand-create-component-image-preview {
  margin-top: 10px;
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}

/* Flex between */

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


/* Friend component */

.friend-name {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0;
  transition: color 0.3s ease,
}

/* For small screens (mobile) */
@media (max-width: 768px) {
  .friend-name {
    font-size: 1rem; 
  }
}

.friend-name:hover {
  color: var(--primary-color);
}

.friend-subtitle {
  color: var(--primary-color);
  font-size: 0.75rem;
  margin: 0;
}


/* Admin all products */

.admin-all-products-component {
  padding: 20px;
  background-color: var(--background-color);
}

.admin-all-products-charts-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.admin-all-products-chart {
  flex: 1 1 45%;
  min-width: 300px;
  height: 400px;
}

.admin-all-products-data-grid {
  margin-top: 20px;
}

.admin-all-products-data-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: rgba(250, 250, 250, 0.2);
}

.admin-all-products-data-table th,
.admin-all-products-data-table td {
  padding: 12px 15px;
  text-align: center;
}

.admin-all-products-data-table th {
  background-color: var(--background-color);
  text-transform: uppercase;
  font-size: 14px;
  border: 1px solid var(--primary-color);
}

.admin-all-products-data-table td {
  border-bottom: 1px solid #ddd;
  font-size: 14px;
}


.admin-all-products-data-table tr:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.admin-all-products-icon-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #146e10;
  transition: color 0.3s ease;
}

.admin-all-products-icon-button:hover {
  color: rgba(0, 0, 0, 0.8);
}

.admin-all-products-icon-button:focus {
  outline: none;
}

.admin-all-products-icon {
  width: 20px;
  height: 20px;
}

/* Timeframe Buttons Container */
.admin-all-products-component-timeframe-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

/* Timeframe Buttons */
.admin-all-products-component-timeframe-button {
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 600;
  border: 2px solid var(--primary-color);
  background-color: var(--background-color);
  color: var(--primary-color);
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  outline: none;
}

/* Active Button */
.admin-all-products-component-timeframe-button.active {
  background-color: var(--primary-color);
   color: var(--text-color);
}

/* Hover & Focus Effects */
.admin-all-products-component-timeframe-button:hover,
.admin-all-products-component-timeframe-button:focus {
  background-color: var(--primary-color);
   color: var(--text-color);
  box-shadow: 0px 0px 8px rgba(75, 192, 192, 0.6);
}

/* Button Press Effect */
.admin-all-products-component-timeframe-button:active {
  transform: scale(0.95);
}

/* --- Search Bar Styling --- */
.admin-all-products-search-bar-container {
  display: flex;
  margin-bottom: 20px;
}

.admin-all-products-search-input {
  width: 300px;
  padding: 10px;
  background-color: var(--background-color);
  border: 1px solid var(--primary-color);
  border-radius: 25px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s ease-in-out;
}

.admin-all-products-search-input:focus {
  border-color: #4BC0C0;
}

.admin-charts {
  display: flex;
  margin-bottom: 30px;
  gap: 20px;
  background-color: var(--background-color);
  padding: 10px;
  border-radius: var(--border-radius-md);
  border: 1px solid var(--border-color);
  width: 80vw;
  height: 420px;
}


/* Admin all events component */

.admin-all-events-component-container {
  width: 100%;
  margin: 40px auto;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.admin-all-events-component-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.admin-all-events-component-table th,
.admin-all-events-component-table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: left;
}

.admin-all-events-component-table th {
  background-color: rgba(250, 250, 250, 0.2);
  font-weight: bold;
}

.admin-all-events-component-preview-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--primary-color);
  transition: color 0.3s ease;
}

.admin-all-events-component-preview-btn:hover {
  color: var(--primary-color);
}


/* Owned brands component*/

.owned-brands-component {
  padding: 20px;
  background-color: #f8f9fa;
}

.owned-brands-component__title {
  text-align: center;
  margin: 20px 0;
}

.owned-brands-component__add-button {
  background-color: green;
   color: var(--text-color);
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin: 20px 0;
}

.owned-brands-component__add-button:hover {
  background-color: darkgreen;
}

.owned-brands-component__modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--modal-overlay-background);
  backdrop-filter: var(--modal-overlay-backdrop-filter);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.owned-brands-component__modal-content {
  background-color: #323232;
  padding: 20px;
  border-radius: var(--border-radius-sm);
  width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
}

.owned-brands-component__modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
   color: var(--text-color);
  font-size: 24px;
  cursor: pointer;
}

.owned-brands-component__brand-card {
  width: 800px;
  margin: 0 auto 20px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: var(--border-radius-sm);
  background-color: var(--text-light);
}

.owned-brands-component__brand-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.owned-brands-component__brand-header {
  text-align: center;
}

.owned-brands-component__cover-image {
  width: 100%;
  max-height: 150px;
  object-fit: cover;
}

.owned-brands-component__avatar-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: relative;
  top: -25px;
  border: 3px solid white;
}

.owned-brands-component__submit-button,
.owned-brands-component__cancel-button,
.owned-brands-component__edit-button {
  background-color: blue;
   color: var(--text-color);
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
}

.owned-brands-component__submit-button:hover,
.owned-brands-component__cancel-button:hover,
.owned-brands-component__edit-button:hover {
  background-color: darkblue;
}


/* Add inventory component */

.add-inventory-component-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0,0,0,0.5)333;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  color: #F7FAFC;
  z-index: 1000;
  width: 400px;
  max-height: 80vh;
  overflow-y: auto;
}

.add-inventory-component-close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #F7FAFC;
}

.add-inventory-component-form {
  background: rgba(0, 0, 0, 0.8);
}

.add-inventory-component-form-group {
  margin-bottom: 1rem;
}

.add-inventory-component-form label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: #a0aec0;
}

.add-inventory-component-form input {
  margin-top: 0.25rem;
  display: block;
  width: 100%;
  border-radius: 0.375rem;
  background-color: #4a5568;
  border: none;
  padding: 0.5rem 0.75rem;
   color: var(--text-color);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.add-inventory-component-form input:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(90, 103, 216, 0.45);
}

.add-inventory-component-submit-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 0.375rem;
  background-color: var(--primary-color);
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 500;
   color: var(--text-color);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: background-color 0.2s;
}

.add-inventory-component-submit-button:hover {
  background-color: #2f855a;
}

.add-inventory-component-submit-button:disabled {
  background-color: #68d391;
}

/* Inventory Log */

.inventory-log-component {
  margin-top: 20px;
  background-color: rgba(0,0,0,0.5)333;
  padding: 16px;
  border-radius: 6px;
  color: #F7FAFC;
}

.inventory-log-component h2 {
  font-size: 20px;
  margin-bottom: 16px;
}

.inventory-log-timeline {
  list-style-type: none;
  padding: 0;
}

.inventory-log-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #444;
}

.inventory-log-date {
  font-weight: bold;
}

.inventory-log-details {
  display: flex;
  gap: 10px;
}

.inventory-log-batch,
.inventory-log-quantity {
  background-color: #444;
  padding: 5px 10px;
  border-radius: 4px;
}

/* Shop Switcheroo */

.switcheroo-shop-component {
  position: relative;
}

.switcheroo-shop-component-avatar-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: green;
  border-radius: 50%;
  border: 1px solid var(--border-color);
  width: 42px;
  height: 42px;
  position: relative;
  cursor: pointer;
}

.switcheroo-shop-component-avatar {
  height: 42px;
  width: 42px;
  max-width: 42px;
  border-radius: 100%;
}

.switcheroo-shop-component-swap-icon {
  position: absolute;
  right: -10px;
  bottom: -10px;
  background-color: green;
  border-radius: 50%;
  border: 1px solid var(--border-color);
  padding: 3px;
}

.switcheroo-shop-component-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.29);
  z-index: 10;
}

.switcheroo-shop-component-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 420px;
  max-width: 90%;
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.25);
  padding: 16px;
  border-radius: 24px;
  overflow: hidden;
  border: 2px solid green;
}

.switcheroo-shop-component-close-btn {
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  cursor: pointer;
   color: var(--text-color);
}

.switcheroo-shop-component-modal-title {
   color: var(--text-color);
  margin-bottom: 16px;
}

.switcheroo-shop-component-modal-item {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  margin: 5px 0;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.switcheroo-shop-component-modal-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.switcheroo-shop-component-modal-item-image {
  height: 25px;
  width: 25px;
  border-radius: 100%;
  object-fit: cover;
  margin-right: 10px;
}

.switcheroo-shop-component-check-icon {
  height: 24px;
  width: 24px;
  margin-left: auto;
  color: green;
}

/* Admin dashboard main */

.admin-dashboard-main-container {
  width: 100%;
  padding: 2rem;
}

.admin-dashboard-main-title {
  font-size: 22px;
  font-family: 'Poppins', sans-serif;
  padding-bottom: 0.5rem;
}

.admin-dashboard-main-overview {
  display: block;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 800px) {
  .admin-dashboard-main-overview {
      display: flex;
  }
}

.admin-dashboard-main-card {
  width: 100%;
  margin-bottom: 1rem;
  min-height: 20vh;
  background-color: var(--background-color);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-lg);
  padding: 2rem 1rem;
}

@media (min-width: 800px) {
  .admin-dashboard-main-card {
      width: 30%;
  }
}

.admin-dashboard-main-card-content {
  display: flex;
  align-items: center;
}

.admin-dashboard-main-card-title {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.25rem;
}

.admin-dashboard-main-card-value {
  padding-top: 0.5rem;
  padding-left: 36px;
  font-size: 22px;
  font-weight: 500;
}

.admin-dashboard-main-card-link {
  padding-top: 1rem;
  padding-left: 2px;
  color: var(--primary-color);
  text-decoration: none;
}

.admin-dashboard-main-subtitle {
  font-size: 22px;
  font-family: 'Poppins', sans-serif;
  padding-bottom: 0.5rem;
}

.admin-dashboard-main-orders-container {
  width: 100%;
  min-height: 45vh;
  background-color: rgba(250, 250, 250, 0.2);
  border-radius: 0.5rem;
}

.admin-dashboard-main-orders-table {
  width: 100%;
  min-height: 45vh;
  border-collapse: collapse;
}

.admin-dashboard-main-orders-table th,
.admin-dashboard-main-orders-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.admin-dashboard-main-orders-table th {
  background-color: var(--background-color);
}

.admin-dashboard-main-orders-table tr.admin-dashboard-main-pending-row {
  background-color: #fff3cd;
}

.admin-dashboard-main-icon {
  margin-right: 10px;
}

/* Follower list */
.follower-list-widget-wrapper {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(250, 250, 250, 0.3);
  border-radius: 0;
  box-shadow: none;
  padding: 0px;
}


.follower-list-widget-title {
  color: #2c4a2e;
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.follower-list-widget-search {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  margin-bottom: 1.5rem;
  box-sizing: border-box;
  background-color: rgba(250, 250, 250, 0.2)
}

.follower-list-widget-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.follower-list-widget-content p {
  margin: 0;
}

/* Following list widget */

.following-list-widget-wrapper {
  padding: 1rem;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.following-list-widget-title {
  color: #2c4a2e;
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.following-list-widget-search {
  width: 100%;
  padding: 0.5rem;
  ;
  border-radius: 8px;
  margin-bottom: 1.5rem;
  box-sizing: border-box;
  background-color: rgba(250, 250, 250, 0.2)
}

.following-list-widget-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.following-list-widget-content p {
  margin: 0;
}

/* Admin all users component */

.admin-all-users-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

.admin-all-users-chart-section {
  width: 100%;
  padding: 16px;
}

.admin-all-users-title {
  font-size: 22px;
  font-family: 'Poppins', sans-serif;
  padding-bottom: 8px;
}

.admin-all-users-timeframe-button {
   color: var(--text-color);
  padding: 10px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 8px;
}

.admin-all-users-timeframe-button-daily {
  background-color: var(--primary-color);
}

.admin-all-users-timeframe-button-monthly {
  background-color: #008CBA;
}

.admin-all-users-chart-container {
  width: 100%;
  background-color: rgba(250, 250, 250, 0.2);
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  min-height: 420px;
}

.admin-all-users-grid-section {
  width: 100%;
  padding: 16px;
}

.admin-all-users-grid-container {
  width: 100%;
  background-color: rgba(250, 250, 250, 0.2);
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin-top: 32px;
}

.admin-all-users-table {
  width: 100%;
  border-collapse: collapse;
}

.admin-all-users-table th,
.admin-all-users-table td {
  padding: 8px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.admin-all-users-avatar {
  width: 42px;
  height: 42px;
  border-radius: 50%;
}

.admin-all-users-view-profile-button {
  text-decoration: none;
   color: var(--text-color);
  background-color: var(--primary-color);
  padding: 5px 10px;
  border-radius: 4px;
}

.admin-all-users-delete-button {
  background: none;
  border: none;
  cursor: pointer;
}

.admin-all-users-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.39);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.admin-all-users-modal-content {
  width: 95%;
  max-width: 500px;
  min-height: 20vh;
  background-color: rgba(250, 250, 250, 0.2);
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 16px;
  position: relative;
}

.admin-all-users-modal-close {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.admin-all-users-modal-title {
  font-size: 25px;
  text-align: center;
  padding: 20px 0;
  font-family: 'Poppins', sans-serif;
  color: #000000cb;
}

.admin-all-users-modal-actions {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.admin-all-users-modal-button {
  background-color: var(--primary-color);
   color: var(--text-color);
  padding: 10px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
}

/* Connections widget */
.connections-widget {
  position: relative;
  z-index: 10;
}

.connections-widget-container {
  background-color: var(--background-color);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 24px;
  border-radius: 8px;
  border: 1px solid rgba(250, 250, 250, 0.5)
}

.connections-widget-header {
  display: flex;
  align-items: start;
  justify-content: space-between;
}

.connections-widget-header h1 {
  font-size: 1.125rem;
  font-weight: 500;
  color: #1a202c;
}

.connections-widget-tabs {
  margin-bottom: 16px;
  border-bottom: 1px solid #e2e8f0;
}

.connections-widget-tabs nav {
  display: flex;
  gap: 32px;
}

.connections-widget-tab {
  padding-bottom: 16px;
  border-bottom: 2px solid transparent;
  font-weight: 500;
  font-size: 0.875rem;
  color: #718096;
  background: none;
  border: none;
  cursor: pointer;
}

.connections-widget-tab:hover {
  color: #4a5568;
  border-color: #e2e8f0;
}

.connections-widget-tab.active {
  border-color: #38a169;
  color: var(--primary-color);
}

/* All coupons shop dashboard */
.all-coupons-shop-component-container {
  width: 100%;
  padding: 16px;
  background-color: var(--background-color);
}

.all-coupons-shop-component-create-button-container {
  display: flex;
  justify-content: flex-end;
}

.all-coupons-shop-component-create-button {
  padding: 10px 20px;
  border: none;
  border-radius: var(--border-radius-full);
  background-color: var(--background-color);
  border: 1px solid var(--primary-color);
   color: var(--text-color);
  cursor: pointer;
  margin-right: 16px;
  margin-bottom: 16px;
  transition: background-color 0.3s ease;
}
.all-coupons-shop-component-create-button:hover {
  background-color: var(--primary-color); 
}

/* Data grid table styles for desktop */
.all-coupons-shop-component-data-grid {
  width: 100%;
}

.all-coupons-shop-component-data-grid table {
  width: 100%;
  border-collapse: collapse;
}

.all-coupons-shop-component-data-grid th,
.all-coupons-shop-component-data-grid td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.all-coupons-shop-component-delete-button {
  background: none;
  border: none;
  cursor: pointer;
}

.all-coupons-shop-component-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20000;
}

.all-coupons-shop-component-modal-content {
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 800px;
}

.all-coupons-shop-component-modal-header {
  display: flex;
  justify-content: flex-end;
}

.all-coupons-shop-component-modal-close {
  cursor: pointer;
}

.all-coupons-shop-component-modal-title {
  font-size: 25px;
  text-align: center;
}

.all-coupons-shop-component-form {
  width: 100%;
}

.all-coupons-shop-component-input-group {
  margin-bottom: 16px;
}

.all-coupons-shop-component-input-group label {
  display: block;
  margin-bottom: 8px;
}

.all-coupons-shop-component-input-group input,
.all-coupons-shop-component-input-group select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.8);
}

.all-coupons-shop-component-error {
  color: red;
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.all-coupons-shop-component-success {
  color: green;
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.all-coupons-shop-component-required {
  color: red;
}

.all-coupons-shop-component-icon {
  margin-right: 4px;
}
.all-coupons-shop-component-multi-select {
  height: 150px; 
  overflow-y: auto;
}

.all-coupons-shop-component-selected-products {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.all-coupons-shop-component-product-item {
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.8);
}

.all-coupons-shop-component-product-image {
  width: 40px;
  height: 40px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 4px;
}
@media (max-width: 768px) {
  .all-coupons-shop-component-card {
    display: block;
  }
}

/* Mobile styles for card layout */
@media (max-width: 768px) {
  /* Hide the table on mobile */
  .all-coupons-shop-component-data-grid table {
    display: none;
  }

  /* Coupon cards container */
  .all-coupons-shop-component-data-grid {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  /* Individual coupon card */
  .all-coupons-shop-component-card {
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius-sm);
    padding: 16px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .all-coupons-shop-component-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .all-coupons-shop-component-card-title {
    font-weight: bold;
  }

  .all-coupons-shop-component-card-delete-button {
    background: none;
    border: none;
    cursor: pointer;
    color: red;
  }

  .all-coupons-shop-component-card-body {
    margin-top: 8px;
  }
}


/* Other users followers list widget */

.other-followers-list-widget-wrapper {
  padding: 1rem;
  background-color: var(--background-color);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.other-followers-list-widget-title {
  color: #2c4a2e;
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.other-followers-list-widget-search {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1.5rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: rgba(250, 250, 250, 0.2);
}

.other-followers-list-widget-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.other-followers-list-widget-content p {
  margin: 0;
}
/* Other following list widget */

.other-following-list-widget-wrapper {
  padding: 1rem;
  background-color: var(--background-color);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.other-following-list-widget-title {
  color: #2c4a2e;
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.other-following-list-widget-search {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1.5rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: rgba(250, 250, 250, 0.2);
}

.other-following-list-widget-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.other-following-list-widget-content p {
  margin: 0;
}

/* Bulk product upload component */

.bulk-product-upload-button {
  background-color: var(--primary-color); 
  color: #fff;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius-full);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease; 
}

.bulk-product-upload-button:hover {
  background-color: #28a200; 
  transform: scale(1.05); 
}

.bulk-product-upload-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(54, 211, 0, 0.5); 
}


.bulk-product-upload-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.bulk-product-upload-modal {
  background: rgba(0, 0, 0, 0.8);
  padding: 24px;
  border-radius: 15px;
  max-width: 600px;
  width: 60%;
  position: relative;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  outline: none;
  overflow-y: auto;
}

.bulk-product-upload-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #2d3748;
  margin-bottom: 1rem;
}

.bulk-product-upload-close-button {
  margin-top: 1rem;
  background-color: #e53e3e;
   color: var(--text-color);
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.bulk-product-upload-close-button:hover {
  background-color: #c53030;
}

/* CSV product upload component */

.CSV-upload-component-container {
  padding: 1rem;
}

.CSV-upload-component-file-input {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 1rem;
}

.CSV-upload-component-upload-button,
.CSV-upload-component-submit-button {
  background-color: #38a169;
   color: var(--text-color);
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 1rem;
  display: inline-block;
}

.CSV-upload-component-upload-button:hover,
.CSV-upload-component-submit-button:hover {
  background-color: #2f855a;
}

.CSV-upload-component-product {
  margin-top: 2rem;
}

.CSV-upload-component-input-group {
  margin-bottom: 1rem;
}

.CSV-upload-component-input-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.CSV-upload-component-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 1rem;
}

.CSV-upload-component-error {
  color: red;
  font-size: 0.875rem;
}

/* USPS rate calculator */

.USPS-rate-calculator-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid rgba(250, 250, 250, 0.6);
  border-radius: var(--border-radius-sm);
  background-color: rgba(250, 250, 250, 0.2);
}

.USPS-rate-calculator-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.USPS-rate-calculator-form-group {
  margin-bottom: 15px;
}

.USPS-rate-calculator-form-group label {
  display: block;
  margin-bottom: 5px;
}

.USPS-rate-calculator-form-group input {
  width: calc(100% - 22px);
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  margin-bottom: 5px;
  background-color: rgba(0, 0, 0, 0.8);
}

.USPS-rate-calculator-form-group input[type="number"] {
  width: calc(33% - 22px);
  display: inline-block;
}

.USPS-rate-calculator-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: var(--primary-color);
   color: var(--text-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.USPS-rate-calculator-button:hover {
  background-color: var(--primary-color);
}

.USPS-rate-calculator-rate-result {
  text-align: center;
  margin-top: 20px;
}

.USPS-rate-calculator-rate-result h3 {
  margin-bottom: 10px;
}

.USPS-rate-calculator-rate-result p {
  font-size: 24px;
  color: #28a745;
}

/* Shipping rules component */

.shipping-rules-component {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.shipping-rules-component-add-rule-btn {
  background-color: var(--background-color);
  border: 1px solid var(--primary-color);
   color: var(--text-color);
  padding: 5px 10px;
  border-radius: var(--border-radius-full);
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
  margin-bottom: 20px;
}

.shipping-rules-component-add-rule-btn:hover {
  background-color: #3d6000;
}

.shipping-rules-component-add-rule-icon {
  height: 20px;
  width: 20px;
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 2px;
}

.shipping-rules-component-table {
  width: 100%;
  border-collapse: collapse;
  background-color: rgba(250, 250, 250, 0.2);
  border: 1px solid rgba(250, 250, 250, 0.6);
  border-radius: var(--border-radius-sm);
  margin-bottom: 20px;
}

.shipping-rules-component-table th, .shipping-rules-component-table td {
  border: 1px solid rgba(250, 250, 250, 0.6);
  padding: 8px;
  text-align: left;
}

.shipping-rules-component-table th {
  background-color: var(--background-color);
  border: 1px solid rgba(250, 250, 250, 0.6);
  color: var(--text-color);
}

.shipping-rules-component-table tr:hover {
  background-color: rgba(250, 250, 250, 0.2);
}

.shipping-rules-component-table button {
  padding: 5px 10px;
  margin-right: 5px;
  background-color: var(--primary-color);
  border-radius: 5px;
   color: var(--text-color);
  border: none;
  cursor: pointer;
}

.shipping-rules-component-table button:hover {
  background-color: #10560b;
}

/* Packing slip form */

.packing-slip-form-modal-content {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  position: relative;
}

.packing-slip-form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.packing-slip-form-close-btn {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #999;
}

.packing-slip-form {
  display: flex;
  flex-direction: column;
}

.packing-slip-form-group {
  margin-bottom: 15px;
}

.packing-slip-form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.packing-slip-form-group input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid var(--border-color);
  border-radius: 4px;
}

.packing-slip-form-add-item-btn, 
.packing-slip-form-submit-btn {
  background: #146e10;
 color: var(--text-color);;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.packing-slip-form-add-item-btn:hover, 
.packing-slip-form-submit-btn:hover {
  background: #10560b;
}

.packing-slip-form-item-list {
  list-style-type: none;
  padding: 0;
  margin-top: 10px;
}

.packing-slip-form-item-list li {
  background: rgba(250, 250, 250, 0.2);
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Create product component */

.create-product-component-wrapper {
  width: 100%;
  padding: 25px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  overflow-y: auto;
  margin: 0 auto;
}

.create-product-component-title {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 1.5rem;
}

.create-product-component-section {
  padding-top: 1rem;
}

.create-product-component-subtitle {
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-color);
  margin-bottom: 0.5rem;
}

.create-product-component-description {
  font-size: 0.875rem;
  color: var(--text-color);
  margin-bottom: 1rem;
}

.create-product-component-brand-type-container {
  display: flex;
  width: 100%;
  gap: 10px;
}

.create-product-component-radio-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: var(--border-radius-lg);
}

.create-product-component-radio-item {
  display: flex;
  width: 50%;
  align-items: center;
  padding: 1rem;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-lg);
  cursor: pointer;
  position: relative;
  transition: border-color 0.2s, background-color 0.2s;
}


@media (max-width: 480px) {
  .create-product-component-brand-type-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
  }
  .create-product-component-radio-item {
    width: 100%;
  }
}

.create-product-component-radio-item:hover {
  border-color: var(--primary-color); /* Green border on hover */
}

.create-product-component-radio-input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.create-product-component-radio-item.checked {
  border-color: var(--primary-color); /* Green border when checked */
}

.create-product-component-radio-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  height: 1.25rem;
  min-width: 1.25rem;
  border-radius: 50%;
  border: 1px solid var(--border-color);
  background-color: var(--text-light);
  margin-right: 1rem;
  transition: border-color 0.2s, background-color 0.2s;
}

.create-product-component-radio-input:checked + .create-product-component-radio-circle {
  border-color: var(--primary-color); 
  background-color: var(--primary-color); 
}

.create-product-component-radio-input:checked + .create-product-component-radio-circle::after {
  content: '';
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: var(--text-light);
}

.create-product-component-radio-label-container {
  display: flex;
  flex-direction: column;
}

.create-product-component-radio-label {
  font-size: 1rem;
  font-weight: 500;
 color: var(--text-color);;
}

.create-product-component-radio-item.checked .create-product-component-radio-label {
  color: var(--primary-color); 
}

.create-product-component-radio-description {
  font-size: 0.875rem;
 color: var(--text-color);;
}

.create-product-component-radio-item.checked  {
  color: var(--primary-color); 
}



.create-product-component-select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid rgba(250, 250, 250, 0.6);
  border-radius: 0.375rem;
  background-color: var(--background-color);
  color: var(--text-color);
  font-size: 1rem;
}

.create-product-component-catalog-items {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1.5rem;
}

@media (min-width: 640px) {
  .create-product-component-catalog-items {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .create-product-component-catalog-items {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 2rem;
    margin-top: 10px;
  }
}

.create-product-component-catalog-item {
  position: relative;
  cursor: pointer;
}

.create-product-component-catalog-item-image-container {
  position: relative;
  width: 100%;
  height: 18rem;
  overflow: hidden;
  border-radius: 0.375rem;
}

.create-product-component-catalog-item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.create-product-component-catalog-item-info {
  position: relative;
  margin-top: 1rem;
}

.create-product-component-catalog-item-name {
  font-size: 0.875rem;
  font-weight: 500;
  color: #1a202c;
}

.create-product-component-catalog-item-category {
  font-size: 0.875rem;
  color: #718096;
  margin-top: 0.25rem;
}

.create-product-component-catalog-item-price {
  font-size: 0.875rem;
  font-weight: 600;
  color: #4a5568;
  margin-top: 0.5rem;
}

.create-product-component-measurement-section {
  display: flex;
  justify-content: space-between;
}
.create-product-component-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.create-product-component-form-section {
  margin-top: 1.5rem;
}

.create-product-component-form-group {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.create-product-component-form-group-row {
  display: flex;
  gap: 1rem;
}

.create-product-component-form-label {
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  top: 0;
  bottom: 100px;
}


.create-product-component-form-required {
  color: #48bb78;
}
.create-product-component-product-type-section {
  display:flex 
}

.create-product-component-form-input,
.create-product-component-form-textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #d2d6dc;
  border-radius: 0.375rem;
  background-color: var(--background-color);
   color: var(--text-color);
  font-size: 1rem;
  transition: border-color 0.15s ease-in-out;
}

.create-product-component-form-input:focus,
.create-product-component-form-textarea:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(72, 187, 120, 0.5);
  outline: none;
}

.create-product-component-upload-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}

.create-product-component-upload-label {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0.75rem;
  cursor: pointer ;
  padding: 15px 25px !important;
  background-color: var(--background-color);
  border-radius: var(--border-radius-full) !important;
  border: 1px solid var(--border-color) !important;
}

@media (max-width: 480px) {
  .create-product-component-form-section {
    margin-top: 10px;
  }
  
  .create-product-component-upload-section {
    margin-top: 0px;
  }
  .create-product-component-upload-label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0.5rem;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: var(--border-radius-full) !important;
    border: 1px solid var(--border-color);
  }
}


.create-product-component-dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.5rem;
  width: 100%;
  border: 2px dashed var(--border-color);
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out;
}

.create-product-component-dropzone-active {
  border-color: rgba(250, 250, 250, 0.6);
}

.create-product-component-dropzone-content {
  text-align: center;
}

.create-product-component-dropzone-icon {
  width: 3rem;
  height: 3rem;
  color: #718096;
  margin-bottom: 0.75rem;
}

.create-product-component-dropzone-label {
  display: inline-block;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--text-color);
  transition: color 0.15s ease-in-out;
}

.create-product-component-dropzone-label:hover {
  color: #48bb78;
}

.create-product-component-dropzone-note {
  font-size: 0.75rem;
}

.create-product-component-image-previews,
.create-product-component-video-previews,
.create-product-component-barcode-previews,
.create-product-component-test-result-previews,
.create-product-component-nutrition-info-previews {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;
}

.create-product-component-image-preview,
.create-product-component-video-preview,
.create-product-component-barcode-preview,
.create-product-component-test-result-preview,
.create-product-component-nutrition-info-preview {
  position: relative;
  margin: 0.5rem;
  border: 1px solid #48bb78;
  border-radius: 0.375rem;
  overflow: hidden;
}

.create-product-component-image-preview-dragging {
  opacity: 0.75;
}

.create-product-component-image-preview-img,
.create-product-component-video-preview-video,
.create-product-component-barcode-preview-img,
.create-product-component-test-result-preview-img,
.create-product-component-nutrition-info-preview-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 240px;
}

.create-product-component-image-preview-badge,
.create-product-component-video-preview-badge,
.create-product-component-barcode-preview-badge,
.create-product-component-test-result-preview-badge,
.create-product-component-nutrition-info-preview-badge {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--primary-color);
  color: var(--text-color);
  font-size: 0.75rem;
  padding: 0.25rem;
}

.create-product-component-image-preview-remove,
.create-product-component-video-preview-remove,
.create-product-component-barcode-preview-remove,
.create-product-component-test-result-preview-remove,
.create-product-component-nutrition-info-preview-remove {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  background-color: #e53e3e;
   color: var(--text-color);
  border: none;
  border-radius: 50%;
  padding: 0.25rem;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out;
}

.create-product-component-image-preview-remove:hover,
.create-product-component-video-preview-remove:hover,
.create-product-component-barcode-preview-remove:hover,
.create-product-component-test-result-preview-remove:hover,
.create-product-component-nutrition-info-preview-remove:hover {
  background-color: #c53030;
}

.create-product-component-checkbox-group {
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
}

.create-product-component-checkbox-item {
  display: flex;
  align-items: center;
}

.create-product-component-checkbox-input {
  width: 1.25rem;
  height: 1.25rem;
  color: #48bb78;
  border-color: #d2d6dc;
  margin-right: 0.5rem;
}

.create-product-component-checkbox-label {
  font-size: 0.875rem;
  color: var(--text-color);
}

/* Wrapper for the form group */
.create-product-component-form-group {
  margin: 16px 0;
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Label for the checkbox */
.create-product-component-checkbox-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
 color: var(--text-color);;
  gap: 8px;
}

/* Native checkbox input (hidden for styling purposes) */
.create-product-component-checkbox-input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* Custom checkbox styling */
.create-product-component-checkbox-custom {
  width: 16px;
  height: 16px;
  border: 2px solid #ccc;
  border-radius: 4px;
  display: inline-block;
  transition: all 0.2s ease-in-out;
  position: relative;
  background-color: var(--text-light);
}

/* Custom checkbox checked state */
.create-product-component-checkbox-input:checked + .create-product-component-checkbox-custom {
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}

/* Checkmark inside the custom checkbox */
.create-product-component-checkbox-input:checked + .create-product-component-checkbox-custom::after {
  content: '';
  position: absolute;
  top: 2px;
  left: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Hover state for the custom checkbox */
.create-product-component-checkbox-label:hover .create-product-component-checkbox-custom {
  border-color: #888;
}

.create-product-component-weight-dimensions-container {
  display: flex;
  gap: 5%;
}

.create-product-component-switch-group {
  display: flex;
  width: 100%;
  margin: 5px;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  border-radius: var(--border-radius-lg);
  padding: 20px;
  background-color: var(--background-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease-in-out;
}

.create-product-component-switch-group-active {
  background-color: rgba(72, 187, 120, 0.2);
}

.create-product-component-switch-label {
  display: flex;
  font-size: 1rem;
 color: var(--text-color);;
  margin-bottom: 0.5rem;
  text-align: center;
}

.create-product-component-switch {
  position: relative;
  width: 3rem;
  height: 1.5rem;
  background-color: var(--toggle-color);
  border-radius: 0.75rem;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out;
}

.create-product-component-switch-active {
  background-color: #48bb78;
}

.create-product-component-switch-toggle {
  position: absolute;
  top: 50%;
  left: 0.125rem;
  width: 1.25rem;
  height: 1.25rem;
  background-color: var(--toggle-color);
  border: 1px solid var(--strong-border-color);
  border-radius: 50%;
  transform: translateY(-50%);
  transition: transform 0.15s ease-in-out;
}

.create-product-component-switch-toggle-active {
  transform: translateX(1.5rem) translateY(-50%);
}



.create-product-component-submit {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.create-product-component-submit-button {
  display: inline-flex;
  justify-content: center;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.375rem;
  background-color: var(--primary-color);
   color: var(--text-color);
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out;
}

.create-product-component-submit-button:hover {
  background-color: #38a169;
}

.create-product-component-submit-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(72, 187, 120, 0.5);
}

/* Checkout component */

.checkout-component-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.checkout-component-content {
  width: 100%;
  display: flex;
  gap: 20px
}


@media (max-width: 480px) {
  .checkout-component-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0px
  }
}

.checkout-component-left, .checkout-component-right {
  width: 50%;
  margin-top: 2rem;
}

.checkout-component-shipping-info {
  width: 100%;
  background-color: var(--background-color);
  border-radius: var(--border-radius-md);
  padding: 1.25rem;
}

.checkout-component-section-title {
  font-size: 1.125rem;
  font-weight: 500;
}

.checkout-component-form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding-bottom: 0.75rem;
}

.checkout-component-form-group {
  flex: 1;
}

.checkout-component-label {
  display: block;
  padding-bottom: 0.5rem;
}

.checkout-component-input {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-md);
  background-color: var(--background-color);
   color: var(--text-color);
  font-size: 1rem;
  transition: border-color 0.15s ease-in-out;
}

.checkout-component-input:focus {
  border-color: #48bb78;
  outline: none;
}

.checkout-component-select {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-md);
  background-color: var(--background-color);
   color: var(--text-color);
  font-size: 1rem;
  transition: border-color 0.15s ease-in-out;
}

.checkout-component-saved-address-title {
  font-size: 1.125rem;
  cursor: pointer;
  display: inline-block;
}

.checkout-component-saved-address-item {
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
}

.checkout-component-checkbox {
  margin-right: 0.75rem;
}

.checkout-component-saved-address-type {
  font-size: 1rem;
}

.checkout-component-cart-data {
  width: 100%;
  background-color: var(--background-color);
  border-radius: var(--border-radius-md);
  padding: 1.25rem;
}

.checkout-component-cart-item {
  display: flex;
  justify-content: space-between;
}

.checkout-component-cart-item-border {
  border-bottom: 1px solid #e2e8f0;
  padding-bottom: 0.75rem;
}

.checkout-component-cart-label {
  font-size: 1rem;
  font-weight: 400;
  color: #000000a4;
}

.checkout-component-cart-value {
  font-size: 1.125rem;
  font-weight: 600;
}

.checkout-component-total-price {
  font-size: 1.125rem;
  font-weight: 600;
  text-align: end;
  padding-top: 0.75rem;
}

.checkout-component-button {
  width: 240px;
  height: 60px;
  background-color: var(--primary-color);
   color: var(--text-color);
  border-radius: var(--border-radius-full);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  
}

.checkout-component-button-text {
  font-size: 1rem;
  font-weight: 500;
}

.checkout-component-heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
  text-align: center;
}

.checkout-component-cart-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.checkout-component-cart-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.checkout-component-cart-item-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 16px;
}

.checkout-component-cart-item-details {
  flex: 1;
}

.checkout-component-cart-item-name {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  color: #333;
}

.checkout-component-cart-item-qty,
.checkout-component-cart-item-price {
  font-size: 14px;
  color: #666;
  margin: 4px 0;
}

.checkout-component-cart-summary {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 2px solid #f5f5f5;
}

.checkout-component-cart-item {
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
}

.checkout-component-cart-label {
  font-size: 16px;
  color: #333;
}

.checkout-component-cart-value {
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

.checkout-component-total-price {
  font-size: 20px;
  font-weight: bold;
  text-align: right;
  color: #1e7e34; 
  margin-top: 12px;
}

.checkout-component-coupon-form {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  margin-top: 20px;
}



.checkout-component-apply-code-button {
  padding: 8px 16px;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
 color: var(--text-color);;
  background-color: var(--background-color);
  border: 1px solid var(--primary-color);
  border-radius: var(--border-radius-full);
  margin-top: 20px;
  cursor: pointer;
}

.checkout-component-apply-code-button:hover {
  background-color: var(--primary-color);
}

.checkout-tos-container {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  color: #4a4a4a;
}

.checkout-tos-label {
  display: flex;
  align-items: center;
  gap: 0.5rem; 
  cursor: pointer;
}

.checkout-tos-checkbox {
  width: 1rem;
  height: 1rem;
  accent-color: var(--primary-color); 
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  cursor: pointer;
}

.checkout-tos-text {
 color: var(--text-color);;
  line-height: 1.2;
}

.checkout-tos-link {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 600;
}

.checkout-tos-link:hover {
  text-decoration: underline;
}

.checkout-component-button-container .checkout-component-button:disabled {
  background-color: #ccc;
  color: #6c757d;
  cursor: not-allowed;
  border: 1px solid #ccc;
}


/* Overlay background */
.checkout-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: var(--modal-overlay-backdrop-filter);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 1000;
}

/* Show modal when "open" class is added */
.checkout-modal.open {
  opacity: 1;
  visibility: visible;
}

/* Modal content box */
.checkout-modal-content {
  width: 90%;
  height: 90vh;
  max-width: 600px;
  background: rgba(0, 0, 0, 0.7);
  padding: 30px;
  border-radius: var(--border-radius-md);
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  overflow-y: scroll;
}

@media (max-width: 480px) {
  .checkout-modal-content {
    width: 100%;
    height: 100vh;
    max-width: 600px;
    background: transparent;
    padding: 30px;
    border-radius: var(--border-radius-md);
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    overflow-y: scroll;
  }
}

/* Close button */
.close-checkout-modal-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
 color: var(--text-color);;
  background: none;
  border: none;
}

/* Checkout steps component */

.checkout-steps-component-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (max-width: 480px) {
  .checkout-steps-component-wrapper {
    display: none;
  }
}

.checkout-steps-component-content {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

@media (min-width: 800px) {
  .checkout-steps-component-content {
    width: 50%;
  }
}

.checkout-steps-component-flex {
  display: flex;
  align-items: center;
}

.checkout-steps-component-cart-button {
  padding: 0 20px;
  height: 38px;
  border-radius: var(--border-radius-full);
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.checkout-steps-component-cart-button.active {
  background-color: var(--primary-color);
}

.checkout-steps-component-cart-button.inactive {
  background-color: #3d6000;
}

.checkout-steps-component-cart-button-text {
 color: var(--text-color);;
  font-size: 16px;
  font-weight: 600;
}

.checkout-steps-component-cart-button-text.inactive-text {
 color: var(--text-color);;
}

.checkout-steps-component-line {
  width: 25px;
  height: 4px;
  background-color: #fde1e6;
}

.checkout-steps-component-line.active {
  background-color: #f63b60;
}

@media (min-width: 800px) {
  .checkout-steps-component-line {
    width: 70px;
  }


}

/* Cookie consent component */

.cookie-consent-component-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.8); 
}

.cookie-consent-component-container {
  max-width: 1120px; 
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.5rem; 
  padding-right: 1.5rem; 
  padding-top: 1rem; 
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 1024px) {
  .cookie-consent-component-container {
    flex-direction: row;
    padding-left: 2rem; 
    padding-right: 2rem; 
  }
}

@media (max-width:768px) {
  .cookie-consent-component-wrapper {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 60px;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.8); 
  }
}

.cookie-consent-component-text {
  font-size: 1.125rem; 
  font-weight: bold; 
  color: var(--text-color); 
  text-align: center;
}

.cookie-consent-component-link {
  font-size: 0.875rem; 
  font-weight: 600; 
  color: #48bb78; 
  margin-left: 0.5rem; 
}

.cookie-consent-component-button-container {
  margin-top: 1rem; 
  display: flex;
  align-items: center;
  gap: 1.5rem;
  flex-shrink: 0;
}

@media (min-width: 1024px) {
  .cookie-consent-component-button-container {
    margin-top: 0; 
  }
}

.cookie-consent-component-button {
  border-radius: var(--border-radius-full); 
  background-color: var(--primary-color); 
  padding-left: 0.875rem; 
  padding-right: 0.875rem; 
  padding-top: 0.625rem; 
  padding-bottom: 0.625rem; 
  font-size: 20px;
  font-weight: 600;
   color: var(--text-color);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); 
  transition: background-color 0.2s;
}

.cookie-consent-component-button:hover {
  background-color: #3d6000; 
}

.cookie-consent-component-button:focus {
  outline: 2px solid var(--primary-color); 
  outline-offset: 2px;
}

/* Dubs additions */

.css-1rf4mk1 .MuiDataGrid-columnHeaders {
  background-color: var(--background-color) !important;
  color: rgb(247, 250, 252);
}
.css-1rf4mk1 .MuiDataGrid-withBorderColor {
  border-color: rgba(250, 250, 250, 0.6) !important;
  background-color: var(--background-color) !important;
}

.css-1cccqvr.css-1cccqvr.css-1cccqvr {
  padding-right: 24px;
  min-width: 16px;
  color: #ffffff !important;
}

.css-14349d1 {
  width: 100%;
  height: 100%;
  display: flex;
  align-self: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background-color: rgba(250, 250, 250, 0.3) !important;
}

.shop-dashboard-header-card {
  background-color: var(--background-color) !important;
  border: rgba(250, 250, 250, 0.3) !important;
  border-radius: .55rem;
  box-shadow: 0 1px 3px #0000001f, 0 1px 2px #0000003d;
  margin-bottom: 1rem;
  min-height: 20vh;
  padding: 2rem 1rem;
  width: 100%;
}

.switcheroo-swap-icon {
  background-color: var(--primary-color);
  border: 1px solid #00000080 !important;
  border-radius: 50%;
  bottom: -10px;
  padding: 0px !important;
  padding-bottom: 20px !important;
  position: absolute;
  right: -5px !important;
  width: 20px !important;
  height: 20px !important;
}


/* Seller order details */

.seller-order-details-component-container {
  padding: 1rem 0;
  min-height: 100vh;
  max-width: 90%;
  margin: 0 auto;
}

.seller-order-details-component-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.seller-order-details-component-header-left {
  display: flex;
  align-items: center;
}

.seller-order-details-component-title {
  padding-left: 0.5rem;
  font-size: 25px;
}

.seller-order-details-component-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1.5rem;
}

.seller-order-details-component-info-text {
  color: #00000084;
}

.seller-order-details-component-item {
  display: flex;
  align-items: start;
  margin-bottom: 1.25rem;
}

.seller-order-details-component-item-image {
  width: 420px;
  height: 420px;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
}

.seller-order-details-component-item-details {
  width: 100%;
  padding-left: 1.5rem;
}

.seller-order-details-component-item-name {
  font-size: 24px;
}

.seller-order-details-component-item-price {
  font-size: 20px;
  color: #00000091;
}

.seller-order-details-component-review-button {
  background-color: #f63b60;
   color: var(--text-color);
  padding: 0.25rem 1rem;
  border-radius: 0.375rem;
  cursor: pointer;
}

.seller-order-details-component-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
}

.seller-order-details-component-modal-content {
  width: 50%;
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 0.375rem;
  padding: 1rem;
}

.seller-order-details-component-modal-close {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}

.seller-order-details-component-close-icon {
  cursor: pointer;
}

.seller-order-details-component-modal-title {
  font-size: 25px;
  font-weight: 500;
  text-align: center;
}

.seller-order-details-component-modal-body {
  display: flex;
}

.seller-order-details-component-modal-image {
  width: 80px;
  height: 80px;
  border-radius: 0.375rem;
}

.seller-order-details-component-modal-item-name {
  padding-left: 0.75rem;
  font-size: 20px;
}

.seller-order-details-component-modal-item-price {
  padding-left: 0.75rem;
  font-size: 20px;
}

.seller-order-details-component-rating-label {
  padding-left: 0.75rem;
  font-size: 20px;
  font-weight: 500;
}

.seller-order-details-component-rating-required {
  color: red;
}

.seller-order-details-component-rating {
  display: flex;
  padding-left: 0.75rem;
  padding-top: 0.25rem;
}

.seller-order-details-component-star-icon {
  margin-right: 0.25rem;
  cursor: pointer;
}

.seller-order-details-component-comment {
  padding-left: 0.75rem;
}

.seller-order-details-component-comment-label {
  display: block;
  font-size: 20px;
  font-weight: 500;
}

.seller-order-details-component-comment-optional {
  font-weight: 400;
  font-size: 16px;
  color: #00000052;
}

.seller-order-details-component-textarea {
  width: 95%;
  margin-top: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  outline: none;
}

.seller-order-details-component-submit-button {
  background-color: #f63b60;
   color: var(--text-color);
  font-size: 20px;
  padding: 0.5rem 1rem;
  margin-left: 0.75rem;
  border-radius: 0.375rem;
  cursor: pointer;
}

.seller-order-details-component-total {
  border-top: 1px solid #e2e8f0;
  text-align: right;
}

.seller-order-details-component-total-text {
  padding-top: 1rem;
  font-size: 18px;
}

.seller-order-details-component-shipping-payment {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 800px) {
  .seller-order-details-component-shipping-payment {
    flex-direction: row;
  }
}

.seller-order-details-component-shipping {
  width: 100%;
  margin-bottom: 1rem;
}

@media (min-width: 800px) {
  .seller-order-details-component-shipping {
    width: 60%;
  }
}

.seller-order-details-component-shipping-title {
  padding-top: 1rem;
  font-size: 20px;
  font-weight: 600;
}

.seller-order-details-component-shipping-address {
  padding-top: 1rem;
  font-size: 20px;
}

.seller-order-details-component-shipping-location {
  font-size: 20px;
}

.seller-order-details-component-payment {
  width: 100%;
}

@media (min-width: 800px) {
  .seller-order-details-component-payment {
    width: 40%;
  }
}

.seller-order-details-component-payment-title {
  padding-top: 1rem;
  font-size: 20px;
}

.seller-order-details-component-payment-status {
  font-size: 20px;
}

.seller-order-details-component-refund-button {
  background-color: #f63b60;
   color: var(--text-color);
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  cursor: pointer;
}

.seller-order-details-component-message-button {
  background-color: #f63b60;
   color: var(--text-color);
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  cursor: pointer;
  text-align: center;
}

/* Newsletter sign-up */

.newsletter-signup-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1.75rem 5rem;
  background-color: rgba(250, 250, 250, 0.2);
}

@media (min-width: 768px) {
  .newsletter-signup-container {
    flex-direction: row;
    padding: 1.75rem 5rem;
  }
}

.newsletter-signup-title {
  font-size: 1.875rem; 
  line-height: 1.25; 
  font-weight: 600; 
  margin-bottom: 1.5rem;
  text-align: center;
}

@media (min-width: 1024px) {
  .newsletter-signup-title {
    font-size: 2.25rem; 
    margin-bottom: 0;
  }
}

.newsletter-signup-highlight {
  color: #56d879;
}

.newsletter-signup-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media (min-width: 640px) {
  .newsletter-signup-form {
    flex-direction: row;
    align-items: center;
  }
}

.newsletter-signup-input {
  color: #2d3748; 
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.625rem;
  border-radius: 0.375rem;
  border: none;
  outline: none;
}

@media (min-width: 640px) {
  .newsletter-signup-input {
    width: 18rem; 
    margin-right: 1.25rem; 
    margin-bottom: 0;
  }
}

.newsletter-signup-button {
  background-color: #56d879;
   color: var(--text-color);
  padding: 0.625rem 1.25rem;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.newsletter-signup-button:hover {
  background-color: #38b2ac; 
}

@media (min-width: 768px) {
  .newsletter-signup-button {
    width: auto;
  }
}

/* User order details */

.user-order-details-container {
  padding: 1rem 0;
  min-height: 100vh;
  max-width: 90%;
  margin: 0 auto;
}

.user-order-details-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user-order-details-header-left {
  display: flex;
  align-items: center;
}

.user-order-details-title {
  padding-left: 0.5rem;
  font-size: 25px;
}

.user-order-details-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1.5rem;
}

.user-order-details-info-text {
  color: #00000084;
}

.user-order-details-item {
  display: flex;
  align-items: start;
  margin-bottom: 1.25rem;
}

.user-order-details-item-image {
  width: 420px;
  height: 420px;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
}

.user-order-details-item-details {
  width: 100%;
  padding-left: 1.5rem;
}

.user-order-details-item-name {
  font-size: 24px;
}

.user-order-details-item-price {
  font-size: 20px;
  color: #00000091;
}

.user-order-details-review-button {
  background-color: #f63b60;
   color: var(--text-color);
  padding: 0.25rem 1rem;
  border-radius: 0.375rem;
  cursor: pointer;
}

.user-order-details-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-order-details-modal-content {
  width: 50%;
  background-color: rgba(250, 250, 250, 0.2);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 0.375rem;
  padding: 1rem;
}

.user-order-details-modal-close {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}

.user-order-details-close-icon {
  cursor: pointer;
}

.user-order-details-modal-title {
  font-size: 25px;
  font-weight: 500;
  text-align: center;
}

.user-order-details-modal-body {
  display: flex;
}

.user-order-details-modal-image {
  width: 80px;
  height: 80px;
  border-radius: 0.375rem;
}

.user-order-details-modal-item-name {
  padding-left: 0.75rem;
  font-size: 20px;
}

.user-order-details-modal-item-price {
  padding-left: 0.75rem;
  font-size: 20px;
}

.user-order-details-rating-label {
  padding-left: 0.75rem;
  font-size: 20px;
  font-weight: 500;
}

.user-order-details-rating-required {
  color: red;
}

.user-order-details-rating {
  display: flex;
  padding-left: 0.75rem;
  padding-top: 0.25rem;
}

.user-order-details-star-icon {
  margin-right: 0.25rem;
  cursor: pointer;
}

.user-order-details-comment {
  padding-left: 0.75rem;
}

.user-order-details-comment-label {
  display: block;
  font-size: 20px;
  font-weight: 500;
}

.user-order-details-comment-optional {
  font-weight: 400;
  font-size: 16px;
  color: #00000052;
}

.user-order-details-textarea {
  width: 95%;
  margin-top: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  outline: none;
}

.user-order-details-submit-button {
  background-color: #f63b60;
   color: var(--text-color);
  font-size: 20px;
  padding: 0.5rem 1rem;
  margin-left: 0.75rem;
  border-radius: 0.375rem;
  cursor: pointer;
}

.user-order-details-total {
  border-top: 1px solid #e2e8f0;
  text-align: right;
}

.user-order-details-total-text {
  padding-top: 1rem;
  font-size: 18px;
}

.user-order-details-shipping-payment {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 800px) {
  .user-order-details-shipping-payment {
    flex-direction: row;
  }
}

.user-order-details-shipping {
  width: 100%;
  margin-bottom: 1rem;
}

@media (min-width: 800px) {
  .user-order-details-shipping {
    width: 60%;
  }
}

.user-order-details-shipping-title {
  padding-top: 1rem;
  font-size: 20px;
  font-weight: 600;
}

.user-order-details-shipping-address {
  padding-top: 1rem;
  font-size: 20px;
}

.user-order-details-shipping-location {
  font-size: 20px;
}

.user-order-details-payment {
  width: 100%;
}

@media (min-width: 800px) {
  .user-order-details-payment {
    width: 40%;
  }
}

.user-order-details-payment-title {
  padding-top: 1rem;
  font-size: 20px;
}

.user-order-details-payment-status {
  font-size: 20px;
}

.user-order-details-refund-button {
  background-color: #f63b60;
   color: var(--text-color);
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  cursor: pointer;
}

.user-order-details-message-button {
  background-color: #f63b60;
   color: var(--text-color);
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  cursor: pointer;
  text-align: center;
}

/* Shop by brand carousel */

.shop-by-brand-carousel-bg {
  background-color: rgba(0, 0, 0, 0.8)
}

.shop-by-brand-carousel-container {
  padding: 4rem 0;
  max-width: 112rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem; 
  padding-right: 2rem; 
}

.shop-by-brand-carousel-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem; 
  padding-right: 1rem; 
}

.shop-by-brand-carousel-title {
  font-size: 1.5rem; 
  font-weight: bold;
  color: #38a169; 
  letter-spacing: -0.025em;
}

.shop-by-brand-carousel-content {
  margin-top: 2rem; 
}

.shop-by-brand-carousel-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  gap: 1rem; 
}

@media (min-width: 640px) {
  .shop-by-brand-carousel-grid {
    grid-template-columns: repeat(3, 1fr); 
  }
}

@media (min-width: 1024px) {
  .shop-by-brand-carousel-grid {
    grid-template-columns: repeat(4, 1fr); 
  }
}

.shop-by-brand-carousel-item {
  position: relative;
  padding: 1rem; 
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); 
  border-radius: 0.5rem; 
  background-color: black;
  text-align: center;
}

.shop-by-brand-carousel-link {
  text-decoration: none;
}

.shop-by-brand-carousel-brand {
   color: var(--text-color);
  font-weight: bold;
}

.shop-by-brand-carousel-explore {
  margin-top: 0.5rem; 
  font-size: 0.875rem; 
  color: #d1d5db; 
}

/* Google calendar button */
.google-calendar-button-link {
  text-decoration: none;
}

.google-calendar-button {
  background-color: #1a73e8; 
   color: var(--text-color);
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-family: "Google Sans", sans-serif;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.google-calendar-button.hover {
  background-color: #2c83f6; 
}

/* Outlook calendar button */
.outlook-calendar-button-link {
  text-decoration: none;
}

.outlook-calendar-button {
  background-color: #0078D4; 
   color: var(--text-color);
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-family: "Segoe UI", sans-serif;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.outlook-calendar-button.hover {
  background-color: #005A9E; 
}

/* Hashtag Posts page */

.hashtag-posts-page {
  background-color: black;
}

.hashtag-posts-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2.5rem 1rem;
}

@media (min-width: 1024px) {
  .hashtag-posts-container {
    flex-direction: row;
    padding-left: 6rem;
    padding-right: 6rem;
  }
}

.hashtag-posts-aside {
  display: none;
}

@media (min-width: 1024px) {
  .hashtag-posts-aside {
    display: block;
    position: sticky;
    top: 6rem;
    width: 100%;
    max-width: 17rem;
    background-color: #f5f5f5;
    padding: 1rem;
    border-radius: 0.5rem;
  }
}

.hashtag-posts-main {
  flex-grow: 1;
  padding: 1rem;
  width: 100%;
}

.hashtag-posts-header {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.hashtag-posts-title {
  font-size: 2rem;
  font-weight: bold;
   color: var(--text-color);
}

/* Footer component */

.footer-component-bg {
  background-color: rgba(0, 0, 0, 0.8);
   color: var(--text-color);
}

.footer-component-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.5rem;
  padding: 4rem 1.25rem;
  text-align: center;
}

@media (min-width: 640px) {
  .footer-component-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1024px) {
  .footer-component-grid {
    grid-template-columns: repeat(4, 1fr);
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.footer-component-column {
  text-align: center;
}

@media (min-width: 640px) {
  .footer-component-column {
    text-align: start;
  }
}

.footer-component-logo-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-component-social-icons {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.footer-component-icon {
  cursor: pointer;
  margin-left: 1rem;
}

.footer-component-icon:first-child {
  margin-left: 0;
}

.footer-component-title {
  margin-bottom: 0.25rem;
  font-weight: 600;
}

.footer-component-link {
  color: #888;
  transition: color 0.3s ease;
  font-size: 0.875rem;
  line-height: 1.5rem;
  cursor: pointer;
}

.footer-component-link:hover {
  color: var(--primary-color);
}

.footer-component-bottom {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2.5rem;
  text-align: center;
  padding-top: 0.5rem;
  color: #888;
  font-size: 0.875rem;
  padding-bottom: 2rem;
}

@media (min-width: 640px) {
  .footer-component-bottom {
    grid-template-columns: repeat(2, 1fr);
  }

  .footer-component-bottom span:last-child {
    text-align: right;
  }
}

@media (min-width: 1024px) {
  
  .footer-component-bottom span:last-child {
    grid-column: span 2;
    text-align: right;
  }
}

/* Log in component */

.login-component-container {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 1.5rem;
}

.login-component-header {
  margin: 0 auto;
  max-width: 28rem;
  text-align: center;
}

.login-component-title {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 1.875rem;
  font-weight: 800;
  color: #047857; 
}

.login-component-form-container {
  margin-top: 2rem;
  margin: 0 auto;
  max-width: 28rem;
}

.login-component-form-wrapper {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 2rem 4rem;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
}

.login-component-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.login-component-label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
   color: var(--text-color);
}

.login-component-input-wrapper {
  margin-top: 0.25rem;
}

.login-component-input {
  background-color: rgba(255, 255, 255, 0.2);
  appearance: none;
  width: 100%;
  padding: 0.5rem 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  font-size: 0.875rem;
  outline: none;
}

.login-component-input:focus {
  border-color: var(--primary-color); 
  box-shadow: 0 0 0 3px rgba(5, 150, 105, 0.3); 
}

.login-component-icon {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  cursor: pointer;
}

.login-component-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login-component-remember-me {
  display: flex;
  align-items: center;
}

.login-component-checkbox {
  height: 1rem;
  width: 1rem;
  color: #3b82f6;
  border-color: #d1d5db;
  border-radius: 0.25rem;
}

.login-component-checkbox-label {
  margin-left: 0.5rem;
  font-size: 0.875rem;
   color: var(--text-color);
}

.login-component-forgot-password {
  font-size: 0.875rem;
}

.login-component-forgot-password-link {
  font-weight: 500;
  color: #3b82f6;
  transition: color 0.3s ease;
}

.login-component-forgot-password-link:hover {
  color: #2563eb; 
}

.login-component-submit-button {
  background-color: var(--primary-color);
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: none;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
   color: var(--text-color);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-component-submit-button:hover {
  background-color: #3d6000; 
}

.login-component-signup-link {
  display: flex;
  align-items: center;
  width: 100%;
}

.login-component-signup-text {
  color: #3b82f6;
  padding-left: 0.5rem;
}

/* Forgot password */

.forgot-password-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 0 1rem;
}

.forgot-password-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #2d3748;
  margin: 1rem 0;
}

.forgot-password-form-group {
  margin-bottom: 1rem;
}

.forgot-password-label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: #4a5568;
  margin-bottom: 0.5rem;
}

.forgot-password-input {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  background-color: var(--text-light);
  border: 1px solid #cbd5e0;
  border-radius: 0.375rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  outline: none;
  font-size: 0.875rem;
}

.forgot-password-input:focus {
  border-color: var(--primary-color);
}

.forgot-password-button-container {
  display: flex;
  justify-content: flex-end;
}

.forgot-password-button {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
 color: var(--text-color);;
  background-color: var(--primary-color);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.forgot-password-button:hover {
  background-color: #4c51bf;
}

.forgot-password-message {
  margin-top: 0.5rem;
  font-size: 0.875rem;
}

.forgot-password-message.success {
  color: #38a169;
}

.forgot-password-message.error {
  color: #e53e3e;
}

/* Forgot password reset */

.forgot-password-reset-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 0 1rem;
}

.forgot-password-reset-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #2d3748;
  margin: 1rem 0;
}

.forgot-password-reset-form-group {
  margin-bottom: 1rem;
}

.forgot-password-reset-label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: #4a5568;
  margin-bottom: 0.5rem;
}

.forgot-password-reset-input {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  background-color: var(--text-light);
  border: 1px solid #cbd5e0;
  border-radius: 0.375rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  outline: none;
  font-size: 0.875rem;
}

.forgot-password-reset-input:focus {
  border-color: var(--primary-color);
}

.forgot-password-reset-button-container {
  display: flex;
  justify-content: flex-end;
}

.forgot-password-reset-button {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
 color: var(--text-color);;
  background-color: var(--primary-color);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.forgot-password-reset-button:hover {
  background-color: #4c51bf;
}

.forgot-password-reset-message {
  margin-top: 0.5rem;
  font-size: 0.875rem;
}

.forgot-password-reset-message.success {
  color: #38a169;
}

.forgot-password-reset-message.error {
  color: #e53e3e;
}

/* Suggested product */
.suggested-product-container {
  padding: 1rem;
}

.suggested-product-heading {
  font-size: 25px;
  font-weight: 500;
  border-bottom: 1px solid #e5e7eb;
  margin-bottom: 1.25rem;
}

.suggested-product-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .suggested-product-grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 5px;
    
  }
}

/* Trending products carousel retail */

.trending-products-carousel-bg {
  background-color: rgba(250, 250, 250, 0.2);
}

.trending-products-carousel-container {
  padding-top: 4rem;
  padding-bottom: 6rem;
  max-width: 112rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
}

.trending-products-carousel-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
}

.trending-products-carousel-title {
  font-size: 2rem;
  font-weight: bold;
  color: var(--primary-color);
}

.trending-products-carousel-link-hidden {
  display: none;
  font-size: 0.875rem;
  font-weight: 600;
  color: #16a34a;
  transition: color 0.3s ease;
}

.trending-products-carousel-link-hidden:hover {
  color: #3d6000; 
}

@media (min-width: 640px) {
  .trending-products-carousel-link-hidden {
    display: block;
  }
}

.trending-products-carousel-wrapper {
  position: relative;
  margin-top: 2rem;
}

.trending-products-carousel-scroll {
  position: relative;
  width: 100%;
  overflow-x: auto;
}

.trending-products-carousel-list {
  display: inline-flex;
  gap: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  list-style: none;
}

@media (min-width: 1024px) {
  .trending-products-carousel-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    margin-left: 0;
    margin-right: 0;
  }
}

.trending-products-carousel-item {
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  width: 16rem;
}

@media (min-width: 1024px) {
  .trending-products-carousel-item {
    width: auto;
  }
}

.trending-products-carousel-card {
  position: relative;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.trending-products-carousel-image-container {
  position: relative;
  overflow: hidden;
  border-radius: 0.5rem;
  background-color: black;
  height: 300px;
}

@media (max-width: 1024px) {
  .trending-products-carousel-image-container {
    height: 200px;
  }
  .trending-products-carousel-list {
    gap: 10px;
    margin-left: 0rem;
    margin-right: 0rem;
  }
}

.trending-products-carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: opacity 0.3s ease;
}

.trending-products-carousel-info {
  background-color: black;
  padding: 1rem;
}

.trending-products-carousel-category {
  font-size: 0.875rem;
  color: #d1d5db; 
}

.trending-products-carousel-product-name {
  margin-top: 0.25rem;
  font-weight: 600;
   color: var(--text-color);
}

.trending-products-carousel-price {
  margin-top: 0.25rem;
  color: var(--primary-color); 
}

.trending-products-carousel-footer {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.trending-products-carousel-link {
  font-size: 0.875rem;
  font-weight: 600;
  color: #16a34a;
  transition: color 0.3s ease;
}

.trending-products-carousel-link:hover {
  color: var(--primary-color); 
}

@media (min-width: 640px) {
  .trending-products-carousel-footer {
    display: none;
  }
}

/* Trending products carousel wholesale */
.trending-products-carousel-wholesale-bg {
  background-color: rgba(250, 250, 250, 0.2);
}

.trending-products-carousel-wholesale-container {
  padding-top: 4rem;
  padding-bottom: 6rem;
  max-width: 112rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
}

.trending-products-carousel-wholesale-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
}

.trending-products-carousel-wholesale-title {
  font-size: 2rem;
  font-weight: bold;
  color: var(--primary-color); 
}

.trending-products-carousel-wholesale-link-hidden {
  display: none;
  font-size: 0.875rem;
  font-weight: 600;
  color: #16a34a;
  transition: color 0.3s ease;
}

.trending-products-carousel-wholesale-link-hidden:hover {
  color: #15803d; 
}

@media (min-width: 640px) {
  .trending-products-carousel-wholesale-link-hidden {
    display: block;
  }
}

.trending-products-carousel-wholesale-wrapper {
  position: relative;
  margin-top: 2rem;
}

.trending-products-carousel-wholesale-scroll {
  position: relative;
  width: 100%;
  overflow-x: auto;
}

.trending-products-carousel-wholesale-list {
  display: inline-flex;
  gap: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  list-style: none;
}

@media (min-width: 1024px) {
  .trending-products-carousel-wholesale-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    margin-left: 0;
    margin-right: 0;
  }
}

.trending-products-carousel-wholesale-item {
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  width: 16rem;
}

@media (min-width: 1024px) {
  .trending-products-carousel-wholesale-item {
    width: auto;
  }
}

.trending-products-carousel-wholesale-card {
  position: relative;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.trending-products-carousel-wholesale-image-container {
  position: relative;
  overflow: hidden;
  border-radius: 0.5rem;
  background-color: black;
}

.trending-products-carousel-wholesale-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: opacity 0.3s ease;
}

.trending-products-carousel-wholesale-info {
  background-color: black;
  padding: 1rem;
}

.trending-products-carousel-wholesale-category {
  font-size: 0.875rem;
  color: #d1d5db; 
}

.trending-products-carousel-wholesale-product-name {
  margin-top: 0.25rem;
  font-weight: 600;
   color: var(--text-color);
}

.trending-products-carousel-wholesale-price {
  margin-top: 0.25rem;
  color: var(--primary-color); 
}

.trending-products-carousel-wholesale-footer {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.trending-products-carousel-wholesale-link {
  font-size: 0.875rem;
  font-weight: 600;
  color: #16a34a;
  transition: color 0.3s ease;
}

.trending-products-carousel-wholesale-link:hover {
  color: var(--primary-color); 
}

@media (min-width: 640px) {
  .trending-products-carousel-wholesale-footer {
    display: none;
  }
}

/* Profile Content Component */

.profile-content-component-container {
  width: 100%;
}

.profile-content-component-avatar-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.profile-content-component-avatar-wrapper {
  position: relative;
}

.profile-content-component-avatar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #ffffff;
}

.profile-content-component-avatar-overlay {
  width: 25px;
  height: 25px;
  background-color: #e3e9ee;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.profile-content-component-avatar-input {
  display: none;
}

.profile-content-component-avatar-label {
  cursor: pointer;
}

.profile-content-component-form-container {
  width: 100%;
  padding: 0 20px;
  padding-top: 20px;
}

.profile-content-component-form-row {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 15px;
}

.profile-content-component-form-group {
  width: 100%;
  padding-right: 15px;
}

.profile-content-component-form-label {
  display: block;
  padding-bottom: 10px;
}

.profile-content-component-form-input {
  width: 95%;
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  outline: none;
  background-color: rgba(0, 0, 0, 0.8);
}

.profile-content-component-submit-btn {
  width: 250px;
  height: 40px;
  background-color: var(--primary-color);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-full);
  color: var(--text-color);
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  margin-top: 25px;
  margin-bottom: 25px;
}


.profile-content-component-orders {
  padding-left: 20px;
  padding-top: 10px;
}

.profile-content-component-table {
  width: 100%;
  border-collapse: collapse;
}

.profile-content-component-table th,
.profile-content-component-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.profile-content-component-view-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.profile-content-component-password-change {
  width: 100%;
  padding: 0 20px;
}

.profile-content-component-title {
  font-size: 25px;
  text-align: center;
  font-weight: 600;
  color: #000000ba;
  padding-bottom: 20px;
}

.profile-content-component-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-content-component-address {
  width: 100%;
  padding: 0 20px;
}

.profile-content-component-address-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-content-component-add-btn {
  background-color: var(--primary-color);
  color: var(--text-color);
  border: none;
  padding: 10px 20px;
  border-radius: var(--border-radius-full);
  cursor: pointer;
}

.profile-content-component-address-item {
  background-color: rgba(250, 250, 250, 0.5);
  padding: 15px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.profile-content-component-no-address {
  text-align: center;
  padding-top: 20px;
  font-size: 18px;
}

.profile-content-component-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-content-component-modal-content {
  width: 35%;
  max-height: 80vh;
  background-color: rgba(250, 250, 250, 0.5);
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  padding: 20px;
}

.profile-content-component-modal-close {
  display: flex;
  justify-content: flex-end;
}

.profile-content-component-modal-title {
  font-size: 25px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}

.profile-content-component-modal-form-group {
  margin-bottom: 15px;
}

/* Profile Sidebar Component Styles */

.profile-sidebar-component-container {
  width: 100%;
  background-color: var(--background-color);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-md);
  padding: 16px;
  padding-top: 32px;
}

/* Sidebar container to switch between vertical and horizontal layout */
.profile-sidebar-component-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.profile-sidebar-component-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  margin-bottom: 32px;
}

.profile-sidebar-component-text {
  padding-left: 12px;
  color: var(--text-color);
}

.profile-sidebar-component-text.active {
  color: var(--primary-color);
}

/* Active item style */
.profile-sidebar-component-item.active .profile-sidebar-component-text {
  color: var(--primary-color);
}

/* Change layout to horizontal scrollable bar on screens below 800px */
@media (max-width: 800px) {
  .profile-sidebar-component-container {
    width: 100%;
    padding: 8px 0;
    overflow-x: auto;
    white-space: nowrap;
  }

  .profile-sidebar-component-list {
    flex-direction: row;
  }

  .profile-sidebar-component-item {
    display: flex;
    align-items: center;
    margin-right: 16px;
    margin-bottom: 0;
  }

}

/* Styles for larger screens */
@media (min-width: 800px) {
  .profile-sidebar-component-text {
    display: block;
  }
}

@media (max-width: 480px) {
  .profile-sidebar-component-container {
    margin-left: 0px;
    padding: 12px 0px;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    background-color: transparent;
    border-radius: 0px;
  }
}


.profile-sidebar-component-button {
  margin-top: 20px;
  text-align: center;
  background-color: var(--primary-color);
  padding: 10px;
  border-radius: 3px;
  cursor: pointer;
}

.profile-sidebar-component-button-text {
   color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-sidebar-component-button-text h1 {
  margin-right: 8px;
}

/* Track Order Component */

.track-order-component-container {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.track-order-component-status {
  font-size: 20px;
  text-align: center;
}

/* ShopCreate Component */

.shop-create-component-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 1.5rem;
}

.shop-create-component-header {
  text-align: center;
}

.shop-create-component-header h2 {
  margin-top: 1.5rem;
  font-size: 1.875rem;
  font-weight: 800;
 color: var(--text-color);;
}

@media (max-width: 480px) {
  .shop-create-component-header h2 {
    margin-top: 0rem;
  }
}

.shop-create-component-form-container {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.shop-create-component-form {
  background-color: var(--background-color);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--border-color);
  padding: 2rem 1rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 35rem;
}

.shop-create-component-form-elements {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.shop-create-component-form-group {
  display: flex;
  flex-direction: column;
}

.shop-create-component-form-group label {
  font-size: 0.875rem;
  font-weight: 500;
 color: var(--text-color);;
  margin-bottom: 0.25rem;
}

.shop-create-component-input {
  padding: 0.5rem 0.75rem;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-sm);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  font-size: 0.875rem;
 color: var(--text-color);;
  outline: none;
}

.shop-create-component-input:focus {
  border-color: var(--primary-color);
}

.shop-create-component-password-container {
  position: relative;
}

.shop-create-component-eye-icon {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.shop-create-component-avatar-container {
  display: flex;
  align-items: center;
}

.shop-create-component-avatar-preview {
  display: inline-block;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  overflow: hidden;
}

.shop-create-component-avatar-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.shop-create-component-avatar-icon {
  height: 2rem;
  width: 2rem;
}

.shop-create-component-file-label {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
  margin-left: 1.25rem;
  cursor: pointer;
}

.shop-create-component-file-label:hover {
  background-color: var(--primary-color);
}

.shop-create-component-file-input {
  display: none;
}

.shop-create-component-submit {
  width: 100%;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: var(--border-radius-full);
  background-color: var(--primary-color);
  color: var(--text-color);
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
}

.shop-create-component-submit:hover {
  background-color: #3d6000;
}

.shop-create-component-signin {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shop-create-component-link {
  color: #3b82f6;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
}

/* ShopLogin Component  */

.shop-login-component-container {
  min-height: 100vh;
  background-color: rgba(250, 250, 250, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 1.5rem;
}

.shop-login-component-header {
  text-align: center;
}

.shop-login-component-header h2 {
  margin-top: 1.5rem;
  font-size: 1.875rem;
  font-weight: 800;
  color: #111827;
}

.shop-login-component-form-container {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.shop-login-component-form {
  background-color: rgba(250, 250, 250, 0.2);
  padding: 2rem 1rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  width: 100%;
  max-width: 28rem;
}

.shop-login-component-form-elements {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.shop-login-component-form-group {
  display: flex;
  flex-direction: column;
}

.shop-login-component-form-group label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
  margin-bottom: 0.25rem;
}

.shop-login-component-input {
  padding: 0.5rem 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  font-size: 0.875rem;
  color: #111827;
  outline: none;
}

.shop-login-component-input:focus {
  border-color: #3b82f6;
}

.shop-login-component-password-container {
  position: relative;
}

.shop-login-component-eye-icon {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.shop-login-component-button {
  width: 100%;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  padding: 0.5rem 1rem;
  border: none;
  font-size: 0.875rem;
  font-weight: 500;
  border-radius: 0.375rem;
  color: var(--text-color);
  background-color: #3b82f6;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.shop-login-component-button:hover {
  background-color: #2563eb;
}

.shop-login-component-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shop-login-component-footer h4 {
  margin: 0;
}

.shop-login-component-footer a {
  padding-left: 0.5rem;
  color: #3b82f6;
  text-decoration: none;
}

.shop-login-component-footer a:hover {
  text-decoration: underline;
}

/* ShopSettings Component  */

.shop-settings-component-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shop-settings-component-content {
  width: 100%;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1.25rem 0;
}
@media (max-width: 480px) {
  .shop-settings-component-content {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
  }
}

.shop-settings-component-banner,
.shop-settings-component-avatar {
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 1.25rem;
}

.shop-settings-component-banner img,
.shop-settings-component-avatar img {
  cursor: pointer;
}

.shop-settings-component-banner img {
  width: 100%;
  height: 300px;
}

.shop-settings-component-avatar img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

.shop-settings-component-camera-icon-container {
  width: 25px;
  height: 25px;
  background-color: #e3e9ee;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  right: 15px;
}

.shop-settings-component-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shop-settings-component-form-group {
  width: 100%;
  max-width: 50%;
  margin-top: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 480px) {
  .shop-settings-component-form-group {
    width: 100%;
    max-width: 90%;
    margin-top: 1.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.shop-settings-component-form-group label {
  width: 100%;
  padding-left: 3%;
  margin-bottom: 0.5rem;
}

.shop-settings-component-input {
  width: 95%;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  background-color: rgba(250, 250, 250, 0.2);
  outline: none;
}

.shop-settings-component-input:focus {
  border-color: #10b981;
}

.shop-settings-component-opening-hours {
  width: 100%;
  max-width: 50%;
  margin-top: 1.25rem;
}

@media (max-width: 480px) {
  .shop-settings-component-opening-hours {
    width: 100%;
    max-width: 90%;
    margin-top: 1.25rem;
  }
}


.shop-settings-component-opening-hours-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.shop-settings-component-opening-hours-item label {
  font-weight: 500;
  color: #374151;
}

.shop-settings-component-opening-hours-input {
  width: 5rem;
  margin: 0 0.5rem;
  padding: 0.25rem 0.5rem;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-lg);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  background-color: var(--background-color);
  outline: none;
}

.shop-settings-component-opening-hours-input:focus {
  border-color: #10b981;
}

.shop-settings-component-submit-button {
  width: 95%;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #10b981;
  border-radius: 0.375rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  background-color: var(--primary-color);
  text-align: center;
  cursor: pointer;
}

/* SponsoredFeedProducts Component */

.sponsored-feed-products-container {
  background-color: rgba(0,0,0,0.5);
  border-radius: var(--border-radius-sm);
  margin: 10px auto;
  max-width: 1000px;
  overflow: hidden;
}

.sponsored-feed-products-title {
  text-align: center;
  margin-bottom: 20px;
   color: var(--text-color);
}

.sponsored-feed-products-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sponsored-feed-products-card {
  width: 100%;
  text-align: center;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: var(--border-radius-sm);
  overflow: hidden;
  aspect-ratio: 1 / 1;
  position: relative;
}
.sponsored-feed-products-image-container {
  position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 1;
}

.sponsored-feed-products-link {
  text-decoration: none;
  color: inherit;
  display: block;
}

.sponsored-feed-products-image {
  height: auto;
}

.sponsored-feed-products-info {
  padding: 10px 30px;
  display: flex;
  align-items: center;
   color: var(--text-color);
  position: absolute;
  z-index: 10;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  justify-content: space-between;
}

.sponsored-feed-products-name {
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.sponsored-feed-products-price {
  font-size: 14px;
  font-weight: bold;
  margin: 5px 0;
}

.sponsored-feed-products-store-link {
  font-size: 12px;
  font-weight: 700;
  text-decoration: none;
  color: var(--text-color);
  background-color: var(--secondary-color) !important;
  padding: 8px 20px;
  border-radius: var(--border-radius-full);
}

/* Top banner ad */

/* Container */
.top-banner-ad-container {
  border-radius: var(--border-radius-sm);
  margin: 10px auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

/* Content */
.top-banner-ad-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: var(--border-radius-sm);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.5);
  position: relative;
  overflow: hidden;
  background-blend-mode: darken;
  width: 100%;
}

.top-banner-ad-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 1;
  position: relative;
  transition: transform 0.5s ease-in-out;
}

/* Image container */
.top-banner-ad-image-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  aspect-ratio: 16 / 1;
  top: 0;
  left: 0;
  z-index: 1;
}

/* Draggable Image */
.top-banner-ad-image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  object-fit: cover;
}

/* Overlay */
.top-banner-ad-overlay {
  padding: 20px;
  align-content: center;
  border: none;
  height: 100%;
  padding-bottom: 25px;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
}

/* Text */
.top-banner-ad-text {
  z-index: 2;
   color: var(--text-color);
  display: flex;
  flex-direction: column;
  position: relative;
}

.top-banner-ad-link {
   color: var(--text-color);
  text-decoration: none;
  font-size: 14px;
  line-height: 1.5;
}

.top-banner-ad-link strong {
  font-weight: bold;
}

/* Dismiss Button */
.top-banner-ad-dismiss {
  background: none;
  border: none;
  padding: 12px;
  cursor: pointer;
  z-index: 2;
}

/* Dismiss Icon */
.top-banner-ad-icon {
  height: 20px;
  width: 20px;
   color: var(--text-color);
}


/* Mobile Specific Classes */
.top-banner-ad-mobile-container {
  background-color: transparent !important; 
  border-bottom: none !important; 
  margin-top: 7vh;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  box-sizing: border-box;
  width: 100%;
}

.top-banner-ad-mobile-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.75); 
  padding: 0px 25px;
  border-radius: var(--border-radius-sm);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.5); 
  position: relative;
  overflow: hidden;
  background-blend-mode: darken;
  border: 1px solid rgba(250, 250, 250, 0.2) !important;
  aspect-ratio: 5 / 1;
}

.top-banner-ad-image-mobile-container {
  position: absolute; 
  width: 100%;
  top: 0;
  left: 0;
  aspect-ratio: 5 / 1;
}

.top-banner-ad-mobile-text {
  z-index: 2; 
   color: var(--text-color);
  display: flex;
  flex-direction: column;
  position: relative;
}

.top-banner-mobile-ad-link {
   color: var(--text-color);
  text-decoration: none;
  font-size: 14px;
  line-height: 1.5;
  flex-grow: 1;
}

.top-banner-ad-mobile-dismiss {
  background: none;
  border: none;
  padding: 12px;
  cursor: pointer;
  z-index: 2; 
}



/* Map product ad slider */
.map-product-ad-slider-container {
  width: 235px;
}

/* Paper component styling */
.map-product-ad-slider-paper {
  background-position: center center;
  background-size: cover;
  height: 200px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
 color: var(--text-color);;
  border-radius: var(--border-radius-md);
  overflow: hidden;
  position: relative;
}

/* Content within each slide */
.map-product-ad-slider-content {
  background: rgba(0, 0, 0, 0.3);
  border-radius: var(--border-radius-sm);
  padding: 20px;
  text-align: center;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

/* Title styling */
.map-product-ad-slider-title {
  font-size: 1.2em;
  margin: 0;
}

/* Price styling */
.map-product-ad-slider-price {
  font-size: 1em;
  margin: 0;
}

/* Button styling */
.map-product-ad-slider-button {
  background-color: #4caf50 !important;
  margin-top: 20px;
 color: var(--text-color);;
}


.map-product-ad-slider-button .MuiButton-root {
  margin: 0;
}


/* Wishlist */

.wishlist-component-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.29);
  z-index: 10;
}

.wishlist-component-container {
  position: fixed;
  top: 10%;
  right: 0;
  height: 100%;
  width: 80%;
  overflow-y: scroll;
  background-color: var(--sidebar-background);
  backdrop-filter: var(--modal-overlay-backdrop-filter);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-left: 1px solid var(--border-color);
}

@media (min-width: 800px) {
  .wishlist-component-container {
    width: 25%;
  }
}
@media (max-width: 480px) {
  .wishlist-component-container {
    width: 100%;
    height: 100vh;
  }
}

.wishlist-component-empty {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wishlist-component-close-button {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 40px 20px 0 0;
  position: absolute;
  top: 3px;
  right: 3px;
}

.wishlist-component-header {
  display: flex;
  align-items: center;
  padding: 20px;
}

.wishlist-component-header-title {
  padding-left: 8px;
  font-size: 20px;
  font-weight: 500;
}

.wishlist-component-items-container {
  width: 100%;
  border-top: 1px solid #e5e5e5;
}

.wishlist-component-item {
  border-bottom: 1px solid #e5e5e5;
  padding: 16px;
}

.wishlist-component-item-content {
  display: flex;
  align-items: center;
}

.wishlist-component-item-remove {
  cursor: pointer;
  margin-bottom: 8px;
  margin-left: 8px;
}

.wishlist-component-item-image {
  width: 125px;
  margin: 0 8px;
  border-radius: 5px;
}

.wishlist-component-item-details {
  padding-left: 5px;
}

.wishlist-component-item-price {
  font-weight: 600;
  padding-top: 8px;
  font-size: 17px;
  color: #d02222;
}

.wishlist-component-item-add-to-cart {
  cursor: pointer;
}

/* Shop all products page */

.shop-all-products-page-container {
  width: 100%;
}

.shop-all-products-page-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.shop-all-products-page-main {
  width: 100%;
  display: flex;
  justify-content: center;
}

/* Shop all coupons page */

.shop-all-coupons-page-container {
  width: 100%;
}

.shop-all-coupons-page-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.shop-all-coupons-page-main {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-left: 80px;
}
@media (max-width: 480px) {
  .shop-all-coupons-page-main {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-left: 0px;
  }
}

/* Shop settings page */

.shop-settings-page-container {
  width: 100%;
}

.shop-settings-page-content {
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 100%;
}

.shop-settings-page-main {
  flex: 1;
  padding: 16px;
}

.shop-settings-page-manage-payment {
  margin-top: 16px; 
}

.shop-settings-page-manage-payment-btn {
  background-color: var(--primary-color);
   color: var(--text-color);
  font-weight: bold;
  padding: 8px 16px;
  border-radius: 8px;
  text-decoration: none;
  display: inline-block;
}

.shop-settings-page-manage-payment-btn:hover {
  background-color: #3d6000; 
}

@media (max-width: 480px) {
  .shop-settings-page-main {
    padding: 0px;
  }
}

/* Shop dashboard page */
.shop-dashboard-page-container {
  width: 100%;
}

.shop-dashboard-page-content {
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 480px) {
  .shop-dashboard-page-content {
    padding-bottom: 80px;
  }
}

/* Category section component */
.category-section-component-bg {
  background-color: rgba(0, 0, 0, 0.8);
}

.category-section-component-container {
  padding-top: 16px;
  padding-bottom: 24px;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}

.category-section-component-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
}

.category-section-component-title {
  font-size: 2rem;
  font-weight: bold;
  color: #1f2937; 
}

.category-section-component-browse-link {
  display: none;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--primary-color); 
  text-decoration: none;
}

.category-section-component-browse-link:hover {
  color: #3d6000; 
}

.category-section-component-categories {
  margin-top: 16px;
}

.category-section-component-categories-inner {
  overflow-x: auto;
  height: 320px;
}

.category-section-component-categories-list {
  display: flex;
  gap: 32px;
  padding-left: 16px;
  padding-right: 16px;
}

.category-section-component-category {
  position: relative;
  flex: 0 0 auto;
  width: 224px;
  height: 320px;
  border-radius: 10%;
  padding: 24px;
  text-align: center;
  text-decoration: none;
  color: inherit;
  overflow: hidden;
}

.category-section-component-category:hover {
  opacity: 0.75;
}

.category-section-component-category-image-wrapper {
  position: absolute;
  inset: 0;
}

.category-section-component-category-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10%;
}

.category-section-component-category-gradient {
  position: absolute;
  left: 0;
  right:0;
  bottom: 0;
  height: 66%;
  background: linear-gradient(to top, rgba(31, 41, 55, 0.8), transparent); 
  opacity: 0.5;
}

.category-section-component-category-name {
  position: relative;
  margin-top: auto;
  font-size: 1.25rem;
  font-weight: bold;
   color: var(--text-color);
}

.category-section-component-browse-mobile {
  margin-top: 24px;
  padding-left: 16px;
  display: block;
}

.category-section-component-browse-link-mobile {
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--primary-color); 
  text-decoration: none;
}

.category-section-component-browse-link-mobile:hover {
  color: #3d6000; 
}

@media (min-width: 640px) {
  .category-section-component-browse-link {
      display: block;
  }

  .category-section-component-browse-mobile {
      display: none;
  }
}

/* Tax code selector */

.tax-code-selector-container {
  position: relative;
}

.tax-code-selector-label {
  display: flex;
  font-size: 0.875rem;
  font-weight: 500;
 color: var(--text-color);;
}

.tax-code-selector-required {
  color: var(--primary-color); 
}

.tax-code-selector-input {
  margin-top: 0.25rem;
  display: block;
  width: 100%;
  padding: 8px 25px;
  border: 1px solid #d1d5db; 
  background-color: var(--background-color);
  border-radius: var(--border-radius-lg);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  font-size: 16px;
}

.tax-code-selector-input:focus {
  outline: none;
  border-color: var(--primary-color); 
  box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.5)
}

.tax-code-selector-dropdown {
  position: absolute;
  z-index: 10;
  margin-top: 0.25rem;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  border: 1px solid var(--border-color); 
  border-radius: var(--border-radius-lg);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 240px;
  overflow-y: auto;
}

.tax-code-selector-loading {
  padding: 8px 12px;
}

.tax-code-selector-item {
  cursor: pointer;
  padding: 8px 12px;
}

.tax-code-selector-item:hover {
  background-color: var(--background-color); 
}

/* Shop order details */
.shop-order-details-container {
  padding-top: 16px;
  min-height: 100vh;
  max-width: 1200px;
  margin: 0 auto;
  background-color: var(--background-color);
  padding: 20px;
  border-radius: var(--border-radius-md);
}

.shop-order-details-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}

.shop-order-details-header-left {
  display: flex;
  align-items: center;
}

.shop-order-details-title {
  font-size: 25px;
  color: var(--text-color);
  margin-left: 10px;
    margin-bottom: 10px;
    text-transform: uppercase;
    padding-left: 10px;
    border-left: 4px solid var(--primary-color);
}

.shop-order-details-button {
    background-color: var(--background-color);
    border-radius: var(--border-radius-full);
    border: 1px solid var(--primary-color);
   color: var(--text-color);;
    font-weight: 600;
    height: 45px;
    font-size: 18px;
    padding: 0 16px;
    display: inline-block;
    margin-top: 20px;
    text-align: center;
    line-height: 45px;
    cursor: pointer;
}

.shop-order-details-info {
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
  color: #00000084;
}

.shop-order-details-id,
.shop-order-details-date {
  color: #6b7280; /* Gray color */
}

.shop-order-details-customer {
  padding-top: 24px;
  padding-bottom: 10px;
}

.shop-order-details-subtitle {
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 8px;
}

.shop-order-details-customer-name,
.shop-order-details-customer-email {
  font-size: 16px;
  color: #374151; /* Dark gray */
}

.shop-order-details-item {
  display: flex;
  align-items: start;
  margin-bottom: 20px;
}

.shop-order-details-item-image {
  width: 200px;
  height: 200px;
  border-radius: 8px;
  margin-right: 8px;
  background-color: #f3f4f6; /* Light gray */
}

.shop-order-details-item-info {
  width: 100%;
}

.shop-order-details-item-name {
  padding-left: 8px;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}

.shop-order-details-item-variants {
  padding-left: 8px;
}

.shop-order-details-item-sku {
  padding-left: 8px;
}

.shop-order-details-item-price {
  padding-left: 8px;
  font-size: 18px;
  color: #4b5563;
  margin-top: 20px;
}

.shop-order-details-total {
  border-top: 1px solid #e5e7eb; 
  text-align: right;
  padding-top: 16px;
}

.shop-order-details-total-price {
  font-size: 18px;
  font-weight: 600;
  color: #111827; 
}

.shop-order-details-shipping {
  display: flex;
  flex-direction: column;
  padding-top: 24px;
}

.shop-order-details-shipping-address,
.shop-order-details-payment {
  width: 100%;
  margin-bottom: 16px;
}

.shop-order-details-select {
  width: 200px;
  margin-top: 8px;
  border: 1px solid #d1d5db; /* Border gray */
  background-color: var(--background-color);
  height: 35px;
  border-radius: 5px;
  padding: 4px;
  font-size: 16px;
}

.shop-order-details-update-button {
  background-color: var(--background-color);
  border-radius: var(--border-radius-full);
  border: 1px solid var(--primary-color);
 color: var(--text-color);;
  font-weight: 600;
  height: 45px;
  font-size: 18px;
  padding: 0 16px;
  display: inline-block;
  margin-top: 20px;
  margin-left: 20px;
  text-align: center;
  line-height: 45px;
  cursor: pointer;
}

.shop-order-details-update-button:hover {
  background-color: rgba(250, 250, 250, 0.3);
}

.shop-order-details-label-download {
  margin-top: 20px;
  margin-bottom: 20px;
}

.shop-order-details-download-link {
  display: inline-block;
  padding: 8px 16px; 
  font-size: 14px; 
  font-weight: bold;
  color: var(--text-color); 
  background-color: var(--primary-color); 
  border-radius: var(--border-radius-lg); 
  text-decoration: none; 
  transition: background-color 0.3s ease, transform 0.2s ease; 
}

/* Hover effect for the download link */
.shop-order-details-download-link:hover {
  background-color: #45a049; 
  transform: translateY(-2px); 
}


/* Product card component */

.product-card-component-container {
  background-color: var(--background-color) !important;
  border-radius: var(--border-radius-md);
  box-shadow: 0 1px 3px #0000001a;
  cursor: pointer;
  padding-bottom: 10px;
  position: relative;
  border: 1px solid var(--border-color) !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-card-component-info-container {
  padding: 10px 20px;
}


.product-card-component-image {
  width: 100%;
  object-fit: contain;
}

.product-card-component-shop-name {
  padding: 4px;
}

.product-card-component-shop {
  position: absolute;
  top: 10px;
  left: 10px;
  font-weight: bold;
  margin-bottom: 8px;
  background-color: rgba(0, 0, 0, 0.7);
  width: fit-content;
  padding: 4px;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-md);
  font-size: 12px;
  display: flex;
}

.product-card-component-shop-avatar {
  height: 24px;
  width: 24px;
  border-radius: 50%;
}

.product-card-component-product-name {
  font-weight: 500;
}

.product-card-component-ratings {
  display: flex;
  padding-top: 10px;
}

.product-card-component-price-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 8px;
}

.product-card-component-price {
  display: flex;
  align-items: center;
}

.product-card-component-discount-price {
  font-weight: bold;
}

.product-card-component-original-price {
  text-decoration: line-through;
  margin-left: 8px;
  color: #888;
}

.product-card-component-sold-out {
  font-weight: 400;
  font-size: 17px;
  color: #68d284;
}

.product-card-component-quantity-selector {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}

.product-card-component-quantity-button {
  border: 1px solid #ddd;
  padding: 4px 8px;
}

.product-card-component-quantity {
  margin: 0 8px;
}

.product-card-component-cart-icon {
  margin-left: 8px;
}

.product-card-component-wishlist-icon {
  position: absolute;
  right: 8px;
  top: 8px;
  background-color: var(--primary-color);
  border-radius: 50%;
  padding: 4px;
}

.product-card-component-eye-icon {
  position: absolute;
  right: 8px;
  top: 48px;
  background-color: var(--primary-color);
  border-radius: 50%;
  padding: 4px;
}

.product-card-component-brand-badge {
  position: absolute;
  left: 8px;
  top: 8px;
}

.product-card-component-brand-image {
  width: 40px;
  height: 40px;
}

.product-card-component-variants {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}

.product-card-component-color-swatches {
  display: flex;
  margin-top: 10px;
  height: 40px;
  justify-content: center;
}

.product-card-component-color-swatch {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
  border: 2px solid transparent;
  transition: transform 0.2s ease, box-shadow 0.2s ease, border 0.3s ease;
}

.product-card-component-color-swatch.active {
  border-color: var(--text-light);
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.5);
  transform: scale(1.1); 
}

/* Shop dashboard header */
.shop-dashboard-header-container {
  width: 100%;
  height: 80px;
  background-color: var(--header-background);
  border-bottom: 1px solid var(--border-color);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  left: 0;
  z-index: 30;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
}

.shop-dashboard-header-logo {
  margin-top: 8px;
  cursor: pointer;
  max-height: 70px; 
}

.shop-dashboard-header-links {
  display: flex;
  align-items: center;
}

.shop-dashboard-header-icons {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.shop-dashboard-header-icon-link {
  display: none;
}

@media (min-width: 800px) {
  .shop-dashboard-header-icon-link {
    display: block;
  }
}

.shop-dashboard-header-icon {
  color: #555;
  font-size: 25px;
  margin: 0 10px;
  cursor: pointer;
}

.shop-dashboard-header-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

/* Download ICS calendar button */

.download-ICS-button {
  background-color: var(--primary-color);
   color: var(--text-color);
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.download-ICS-button:hover {
  background-color: #45a049;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Product details card */

.product-details-card-bg {
  background-color: rgba(0, 0, 0, 0.8);
}

.product-details-card-container {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(250, 250, 250, 0.2);
  z-index: 40;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-details-card-content {
  background-color: rgba(74, 74, 74, 0.4);
  width: 90%;
  max-width: 60%;
  height: 90vh;
  overflow-y: scroll;
  max-height: 75vh;
  border-radius: var(--border-radius-sm);
  position: relative;
  padding: 16px;
}

.product-details-card-close-icon {
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 50;
  cursor: pointer;
}

.product-details-card-flex {
  display: flex;
  flex-direction: column;
}

.product-details-card-image-section {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.product-details-card-shop-details {
  display: flex;
}

.product-details-card-shop-link {
  display: flex;
  align-items: center;
}

.product-details-card-shop-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 8px;
}

.product-details-card-shop-name {
  font-weight: 500;
}

.product-details-card-shop-ratings {
  padding-bottom: 8px;
  font-size: 15px;
}

.product-details-card-message-button {
  background-color: #000;
  margin-top: 16px;
  border-radius: 4px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.product-details-card-message-button-text {
 color: var(--text-color);;
  display: flex;
  align-items: center;
}

.product-details-card-sold-out {
  color: red;
  margin-top: 20px;
}

.product-details-card-info-section {
  width: 100%;
  padding-top: 20px;
  padding-left: 5px;
  padding-right: 5px;
}

.product-details-card-title {
  font-size: 20px;
}

.product-details-card-pricing {
  display: flex;
  padding-top: 12px;
}

.product-details-card-discount-price {
  font-size: 24px;
  font-weight: 700;
  color: red;
}

.product-details-card-original-price {
  text-decoration: line-through;
  margin-left: 8px;
  font-size: 18px;
  color: gray;
}

.product-details-card-quantity-wishlist {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 48px;
  padding-right: 12px;
}

.product-details-card-quantity-controls {
  display: flex;
}

.product-details-card-decrement,
.product-details-card-increment {
  background: linear-gradient(to right, #4caf50, #4caf50);
   color: var(--text-color);
  font-weight: bold;
  padding: 0 16px;
  cursor: pointer;
  border-radius: 4px;
}

.product-details-card-quantity {
  background-color: #e0e0e0;
  color: #424242;
  font-weight: 500;
  padding: 11px 16px;
}

.product-details-card-wishlist-icon {
  cursor: pointer;
}

.product-details-card-add-to-cart-button {
  background-color: #000;
  margin-top: 24px;
  border-radius: 4px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.product-details-card-add-to-cart-button-text {
 color: var(--text-color);;
  display: flex;
  align-items: center;
}

/* Individual shop products */

.individual-shop-products-container {
  padding: 20px;
}

.individual-shop-products-filter-container {
  padding-top: 16px;
  padding-bottom: 16px;
}

.individual-shop-products-filter-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  padding: 0 16px;
}

.individual-shop-products-filter-button {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #4a5568;
  background: none;
  border: none;
  cursor: pointer;
}

.individual-shop-products-filter-icon {
  margin-right: 8px;
  height: 20px;
  width: 20px;
  color: #a0aec0;
}

.individual-shop-products-chevron-icon {
  margin-left: 8px;
  height: 20px;
  width: 20px;
  color: #718096;
}

.rotate-180 {
  transform: rotate(180deg);
}

.individual-shop-products-disclosure-panel {
  border-top: 1px solid #e2e8f0;
  padding-top: 40px;
  padding-bottom: 40px;
}

.individual-shop-products-disclosure-content {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  padding: 0 16px;
}

.individual-shop-products-disclosure-inner {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.individual-shop-products-label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #4a5568;
}

.individual-shop-products-select {
  margin-top: 4px;
  display: block;
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #cbd5e0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  outline: none;
}

.individual-shop-products-select:focus {
  border-color: #4a5568;
}

.individual-shop-products-checkbox {
  height: 16px;
  width: 16px;
  color: #4c51bf;
  border-radius: 4px;
  border: 1px solid #cbd5e0;
  outline: none;
}

.individual-shop-products-checkbox:focus {
  border-color: #4a5568;
}

.individual-shop-products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 48px;
}

/* change subscription modal */

.change-subscription-modal {
  background: var(--modal-overlay-background);
  backdrop-filter: var(--modal-overlay-backdrop-filter);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.change-subscription-modal-content {
  background-color: #1a1a1a;
  padding: 40px 24px;
  border-radius: 8px;
  position: relative;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.change-subscription-close-btn {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 24px;
  cursor: pointer;
   color: var(--text-color);
}

.change-subscription-title {
  font-size: 24px;
  font-weight: 600;
   color: var(--text-color);
  text-align: center;
}

.change-subscription-frequency {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.change-subscription-frequency-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4px;
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
}

.change-subscription-frequency-option {
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 16px;
  text-align: center;
   color: var(--text-color);
  transition: background-color 0.3s ease;
}

.change-subscription-frequency-option.active {
  background-color: #38a169;
}

.change-subscription-tier-group {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.change-subscription-tier-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 8px;
  background-color: #2d3748;
  cursor: pointer;
  transition: border-color 0.3s ease;
  border: 2px solid transparent;
}

.change-subscription-tier-option.active {
  border-color: #38a169;
}

.change-subscription-tier-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.change-subscription-tier-name {
  font-size: 20px;
  font-weight: 600;
   color: var(--text-color);
}

.change-subscription-tier-description {
  font-size: 14px;
  color: #a0aec0;
}

.change-subscription-tier-features {
  font-size: 14px;
  color: #a0aec0;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.change-subscription-tier-price {
  font-size: 24px;
  font-weight: 700;
   color: var(--text-color);
}

.change-subscription-tier-check-icon {
  height: 24px;
  width: 24px;
   color: var(--text-color);
}

.change-subscription-confirmation-modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
}

.change-subscription-confirmation-content {
  background-color: rgba(250, 250, 250, 0.5);
  padding: 32px;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 100%;
}

.change-subscription-confirmation-title {
  font-size: 20px;
  font-weight: 700;
  color: #2d3748;
}

.change-subscription-confirmation-text {
  margin-top: 16px;
  font-size: 16px;
  color: #4a5568;
}

.change-subscription-confirmation-actions {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.change-subscription-confirmation-cancel {
  background-color: #718096;
   color: var(--text-color);
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
}

.change-subscription-confirmation-confirm {
  background-color: #38a169;
   color: var(--text-color);
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
}

/* Shop change subscription modal */
.shop-change-subscription-modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shop-change-subscription-modal-content {
  background-color: rgba(0,0,0,0.5);
  padding: 20px;
  border-radius: 8px;
   color: var(--text-color);
  width: 80%;
  max-width: 600px;
  position: relative;
}

.shop-change-subscription-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.shop-change-subscription-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}


.shop-change-subscription-frequency {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.shop-change-subscription-frequency-option {
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 4px;
  background-color: #555;
   color: var(--text-color);
  transition: background-color 0.3s ease;
}

.shop-change-subscription-frequency-option.active {
  background-color: var(--primary-color);
}


.shop-change-subscription-tier-group {
  margin-top: 20px;
}

.shop-change-subscription-tier-option {
  cursor: pointer;
  background-color: #444;
  border: 1px solid #666;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
  position: relative;
  transition: background-color 0.3s ease;
}

.shop-change-subscription-tier-option.active {
  background-color: var(--primary-color);
}

.shop-change-subscription-tier-content {
  display: flex;
  flex-direction: column;
}

.shop-change-subscription-tier-name {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}

.shop-change-subscription-tier-description {
  font-size: 14px;
  margin-bottom: 10px;
}

.shop-change-subscription-tier-features {
  font-size: 14px;
  margin-bottom: 10px;
}

.shop-change-subscription-tier-price {
  font-size: 18px;
  font-weight: bold;
}

.shop-change-subscription-price-suffix {
  font-size: 14px;
  color: #ccc;
  margin-left: 5px;
}

.shop-change-subscription-tier-check-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
   color: var(--text-color);
}


.shop-change-subscription-confirmation-modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
}

.shop-change-subscription-confirmation-content {
  background-color: rgba(250, 250, 250, 0.5);
  padding: 20px;
  border-radius: 8px;
  color: black;
  width: 90%;
  max-width: 400px;
  text-align: center;
}

.shop-change-subscription-confirmation-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.shop-change-subscription-confirmation-text {
  margin-bottom: 20px;
}

.shop-change-subscription-confirmation-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.shop-change-subscription-confirmation-cancel,
.shop-change-subscription-confirmation-confirm {
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.shop-change-subscription-confirmation-cancel {
  background-color: #ccc;
}

.shop-change-subscription-confirmation-confirm {
  background-color: var(--primary-color);
   color: var(--text-color);
}

/* Subscription pricing */

.subscription-pricing-container {
  background-color: rgba(250, 250, 250, 0.2);
  padding: 96px 0;
 color: var(--text-color);;
}

.subscription-pricing-inner {
  max-width: 1120px;
  margin: 0 auto;
  padding: 0 24px;
}

.subscription-pricing-header {
  text-align: center;
}

.subscription-pricing-subtitle {
  font-size: 16px;
  color: #68D391;
  font-weight: 600;
}

.subscription-pricing-title {
  margin-top: 16px;
  font-size: 40px;
  font-weight: 700;
}

.subscription-pricing-description {
  max-width: 640px;
  margin: 24px auto;
  text-align: center;
  font-size: 18px;
  color: #A0AEC0;
}

.subscription-pricing-frequency {
  display: flex;
  justify-content: center;
  margin-top: 64px;
  margin-bottom: 40px;
}

.subscription-pricing-frequency-option {
  padding: 8px 16px;
  border-radius: var(--border-radius-md);
  background-color: #2D3748;
 color: var(--text-color);;
  cursor: pointer;
  margin: 0 8px;
  transition: background-color 0.3s ease;
}

.subscription-pricing-frequency-option.active {
  background-color: #68D391;
}

.subscription-pricing-tiers {
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
}

@media (min-width: 1024px) {
  .subscription-pricing-tiers {
    grid-template-columns: repeat(3, 1fr);
  }
}

.subscription-pricing-tier {
  background-color: #2D3748;
  border-radius: 24px;
  padding: 32px;
  text-align: left;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  position: relative;
}

.subscription-pricing-tier.most-popular {
  background-color: #1A202C;
  border: 2px solid #68D391;
  box-shadow: 0 0 10px rgba(104, 211, 145, 0.5);
}

.subscription-pricing-tier-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subscription-pricing-tier-name {
  font-size: 20px;
  font-weight: 600;
}

.subscription-pricing-tier-popular {
  background-color: #68D391;
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 12px;
  color: #1A202C;
}

.subscription-pricing-tier-description {
  margin-top: 16px;
  font-size: 16px;
  color: #A0AEC0;
}

.subscription-pricing-tier-price {
  margin-top: 24px;
  font-size: 36px;
  font-weight: 700;
}

.subscription-pricing-tier-price-suffix {
  font-size: 16px;
  color: #A0AEC0;
}

.subscription-pricing-subscribe-btn {
  display: block;
  width: 100%;
  background-color: #68D391;
  color: #1A202C;
  border-radius: 8px;
  padding: 12px 0;
  margin-top: 24px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.subscription-pricing-subscribe-btn:hover {
  background-color: #48BB78;
}

.subscription-pricing-features {
  margin-top: 32px;
  list-style: none;
  padding: 0;
}

.subscription-pricing-feature {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #A0AEC0;
  margin-bottom: 12px;
}

.subscription-pricing-feature-check {
  color: #68D391;
  margin-right: 8px;
  font-weight: 700;
}

/* shop subscription pricing */

.shop-subscription-pricing-container {
  background-color: rgba(250, 250, 250, 0.2);
  padding: 3rem 0;
}

.shop-subscription-pricing-content {
  max-width: 80rem;
  margin: 0 auto;
  padding: 0 1.5rem;
}

.shop-subscription-pricing-header {
  text-align: center;
}

.shop-subscription-pricing-title {
  color: #28a745;
  font-size: 1.25rem;
  font-weight: 600;
}

.shop-subscription-pricing-subtitle {
  margin-top: 0.5rem;
  font-size: 2.25rem;
   color: var(--text-color);
  font-weight: 700;
}

.shop-subscription-pricing-description {
  text-align: center;
  margin-top: 1rem;
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.125rem;
  color: #d1d5db;
}

.shop-subscription-pricing-frequency {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.shop-subscription-pricing-frequency-option {
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  background-color: rgba(255, 255, 255, 0.2);
   color: var(--text-color);
  cursor: pointer;
  margin: 0 0.25rem;
}

.shop-subscription-pricing-frequency-option.active {
  background-color: #28a745;
}

.shop-subscription-pricing-tiers {
  display: grid;
  gap: 1.5rem;
  margin-top: 2rem;
  grid-template-columns: repeat(3, 1fr);
}

.shop-subscription-pricing-tier {
  background-color: #2d2d2d;
  padding: 2rem;
  border-radius: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.shop-subscription-pricing-tier.most-popular {
  border: 2px solid #28a745;
}

.shop-subscription-pricing-tier-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shop-subscription-pricing-tier-name {
  font-size: 1.125rem;
   color: var(--text-color);
  font-weight: 600;
}

.shop-subscription-pricing-tier-badge {
  background-color: #28a745;
   color: var(--text-color);
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 600;
}

.shop-subscription-pricing-tier-description {
  margin-top: 0.75rem;
  color: #d1d5db;
  font-size: 0.875rem;
}

.shop-subscription-pricing-tier-price {
  margin-top: 1.5rem;
  font-size: 1.5rem;
   color: var(--text-color);
  font-weight: 700;
}

.shop-subscription-pricing-subscribe-btn {
  background-color: #3d6300;
   color: var(--text-color);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius-full);
  margin-top: 1rem;
  cursor: pointer;
}

.shop-subscription-pricing-features {
  margin-top: 1.5rem;
  list-style: none;
  padding: 0;
}

.shop-subscription-pricing-feature {
  color: #d1d5db;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
}

/* User inbox */

.user-inbox-component-container {
  width: 100%;
}

.user-inbox-component-title {
  text-align: center;
  font-size: 25px;
  padding: 12px;
  font-family: 'Poppins', sans-serif;
}

.user-inbox-component-message-item {
  width: 100%;
  display: flex;
  padding: 12px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.user-inbox-component-message-item.active {
  background-color: #f0f0f0;
}

.user-inbox-component-avatar {
  position: relative;
}

.user-inbox-component-avatar-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.user-inbox-component-online-indicator,
.user-inbox-component-offline-indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  right: 2px;
}

.user-inbox-component-online-indicator {
  background-color: #38c776;
}

.user-inbox-component-offline-indicator {
  background-color: #c7b9b9;
}

.user-inbox-component-message-content {
  padding-left: 12px;
}

.user-inbox-component-message-username {
  font-size: 18px;
}

.user-inbox-component-message-text {
  font-size: 16px;
  color: rgba(0,0,0,0.5);
}

.user-inbox-component-chat-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
}

.user-inbox-component-chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background-color: var(--background-color);
  border-bottom: 1px solid #ddd;
}

.user-inbox-component-chat-header-left {
  display: flex;
  align-items: center;
}

.user-inbox-component-chat-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.user-inbox-component-chat-user-info {
  padding-left: 12px;
}

.user-inbox-component-chat-username {
  font-size: 18px;
  font-weight: 600;
}

.user-inbox-component-chat-status {
  font-size: 14px;
  color: #555;
}

.user-inbox-component-back-icon {
  cursor: pointer;
}

.user-inbox-component-messages {
  padding: 12px;
  height: 75vh;
  overflow-y: scroll;
}

.user-inbox-component-message {
  display: flex;
  margin: 8px 0;
}

.user-inbox-component-message.sent {
  justify-content: flex-end;
}

.user-inbox-component-message.received {
  justify-content: flex-start;
}

.user-inbox-component-message-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 8px;
}

.user-inbox-component-message-image {
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: var(--border-radius-sm);
  margin-left: 8px;
  margin-bottom: 8px;
}

.user-inbox-component-message-bubble {
  background-color: #38c776;
 color: var(--text-color);;
  padding: 8px;
  border-radius: 8px;
  max-width: 75%;
}

.user-inbox-component-message.sent .user-inbox-component-message-bubble {
  background-color: #000;
}

.user-inbox-component-message-time {
  font-size: 12px;
  color: rgba(0,0,0,0.5);
  padding-top: 4px;
}

.user-inbox-component-message-input-container {
  display: flex;
  align-items: center;
  padding: 12px;
}

.user-inbox-component-gallery-icon {
  width: 25px;
}

.user-inbox-component-file-input {
  display: none;
}

.user-inbox-component-message-input-box {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.user-inbox-component-message-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.user-inbox-component-send-button {
  display: none;
}

.user-inbox-component-send-icon {
  position: absolute;
  right: 16px;
  cursor: pointer;
}

/* create sales event */
.create-sales-event-container {
  width: 90%;
  max-width: 70%;
  background-color: rgba(250, 250, 250, 0.2);
  border: 1px solid rgba(250, 250, 250, 0.6);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  height: 80vh;
  border-radius: 4px;
  padding: 16px;
  overflow-y: scroll;
}

.create-sales-event-title {
  font-size: 25px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  margin-bottom: 20px;
}

.create-sales-event-form-group {
  margin-bottom: 16px;
}

.create-sales-event-input,
.create-sales-event-textarea,
.create-sales-event-select {
  width: 100%;
  padding: 8px;
  background-color: var(--background-color);
  border: 1px solid rgba(250, 250, 250, 0.6);
  border-radius: 4px;
  font-size: 14px;
  color: var(--text-color);
}

.create-sales-event-textarea {
  resize: vertical;
}

.create-sales-event-file-input {
  display: none;
}

.create-sales-event-image-preview {
  display: flex;
  flex-wrap: wrap;
}

.create-sales-event-upload-icon {
  cursor: pointer;
  margin-top: 12px;
  color: #555;
}

.create-sales-event-image {
  width: 120px;
  height: 120px;
  object-fit: cover;
  margin: 8px;
}

.create-sales-event-submit-button {
  width: 100%;
  padding: 8px;
  background-color: var(--primary-color);
   color: var(--text-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
}

.create-sales-event-submit-button:hover {
  background-color: #45a049;
}

.required {
  color: red;
}

/* My images component */

.my-images-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.my-images-component {
  margin: 0 auto;
  margin-bottom: 25px; 
  padding: 15px; 
  border: 1px solid var(--border-color); 
  border-radius: var(--border-radius-sm);
  background-color: transparent; 
  min-height: 200px; 
}


.my-images-component h3 {
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  margin-top: 0px !important;
}


.my-images-component-image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.my-images-component-thumbnail {
  aspect-ratio: 1;
  cursor: pointer;
  object-fit: cover;
  border-radius: 4px;
  transition: transform 0.2s;
  border: 1px solid var(--border-color) ;
}


.my-images-component-thumbnail:hover {
  transform: scale(1.05);
}

.my-images-component-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--modal-overlay-background);
  backdrop-filter: var(--modal-overlay-backdrop-filter);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.my-images-component-modal-content {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.my-images-component-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.my-images-component-gallery {
  display: flex;
  align-items: center;
}

.my-images-component-gallery-image {
  max-width: 100%;
  max-height: 80vh;
  margin: 0 20px;
  border-radius: 4px;
}

.my-images-component-nav-button {
  background: none;
  border: none;
  font-size: 25px;
  cursor: pointer;
  user-select: none;
  padding: 10px;
}

.my-images-component-nav-button:hover {
  color: var(--primary-color);
}

.my-images-component-nav-button.left {
  margin-right: 20px;
}

.my-images-component-nav-button.right {
  margin-left: 20px;
}

.my-images-component-thumbnail-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 5px;
  overflow-x: auto;
  width: 100%;
}

.my-images-component-thumbnail-small {
  width: 60px;
  height: 60px;
  cursor: pointer;
  object-fit: cover;
  border-radius: 4px;
  opacity: 0.6;
  transition: opacity 0.2s, transform 0.2s;
}

.my-images-component-thumbnail-small:hover,
.my-images-component-thumbnail-small.active {
  opacity: 1;
  transform: scale(1.1);
}

/* Product reviews */
.product-reviews-component-container {
  background-color: rgba(250, 250, 250, 0.2);
  padding: 16px;
}

@media (min-width: 640px) {
  .product-reviews-component-container {
    padding: 24px;
  }
}

@media (min-width: 1024px) {
  .product-reviews-component-container {
    padding: 32px;
  }
}

.product-reviews-component-header {
  font-size: 24px;
  font-weight: bold;
  color: #1f2937; 
}

.product-reviews-component-average {
  margin-top: 12px;
  display: flex;
  align-items: center;
}

.product-reviews-component-average-stars {
  color: #fbbf24; 
}

.product-reviews-component-average-text {
  margin-left: 8px;
  font-size: 14px;
  color: #1f2937; 
}

.product-reviews-component-rating {
  margin-top: 24px;
}

.product-reviews-component-rating-item {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin: 4px 0;
}

.product-reviews-component-rating-text {
  width: 32px;
  text-align: right;
  font-weight: 500;
  color: #1f2937; 
}

.product-reviews-component-rating-bar-container {
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.product-reviews-component-rating-star {
  color: #fbbf24;
  height: 20px;
  width: 20px;
}

.product-reviews-component-rating-bar-wrapper {
  position: relative;
  margin-left: 8px;
  width: 100%;
}

.product-reviews-component-rating-bar-background {
  height: 8px;
  border-radius: 9999px;
  background-color: #e5e7eb; 
}

.product-reviews-component-rating-bar-fill {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-radius: 9999px;
  background-color: #fbbf24;
  height: 100%;
}

.product-reviews-component-review-list {
  margin-top: 32px;
}

.product-reviews-component-review-item {
  padding: 16px 0;
  border-bottom: 1px solid #e5e7eb; 
}

.product-reviews-component-review-item:last-child {
  border-bottom: 0;
}

.product-reviews-component-review-user {
  font-size: 18px;
  font-weight: 600;
}

.product-reviews-component-review-stars {
  display: flex;
  align-items: center;
  margin: 4px 0;
}

.product-reviews-component-review-comment {
  margin-top: 8px;
  color: #4b5563; 
}

/* Shop shipping page */

.shop-shipping-page-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.shop-shipping-page-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  margin-left: 50px;
  padding: 20px;
}

.shop-shipping-page-title {
  color: var(--text-color);
  margin-bottom: 10px;
  text-transform: uppercase;
  padding-left: 10px;
  border-left: 4px solid var(--primary-color);
}

.shop-shipping-page-main h1{
  font-size: 24px;
    font-weight: bold;
    color: #F7FAFC;
    margin-bottom: 20px;
}

@media (max-width: 480px) {
  .shop-shipping-page-main {
    margin-left: 0px;
    padding: 0px;
  }
}

.shop-shipping-page-section {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.shop-shipping-page-section-custom {
  margin-bottom: 32px;
  padding: 20px;
  background: var(--background-color); 
  border-radius: var(--border-radius-md);
  border: 1px solid var(--border-color);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.shop-shipping-page-subtitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
  color: var(--text-color);
  margin-bottom: 10px;
  text-transform: uppercase;
  padding-left: 10px;
  border-left: 4px solid var(--primary-color);
}

.shop-shipping-page-shipping-rules {
  margin-top: 16px;
  padding: 20px;
  background-color: var(--background-color);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--border-color);
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.shop-shipping-page-button-open-modal {
  margin-top: 16px;
  padding: 10px 20px;
  background-color: var(--primary-color);
   color: var(--text-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.shop-shipping-page-button-open-modal:hover {
  background-color: var(--primary-color);
}

/* Shipping Rates Selector */
.shipping-rates-selector-component {
  margin: 20px 0;
}

.shipping-rates-selector-label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
 color: var(--text-color);;
}

.shipping-rates-selector-list {
  display: flex;
  flex-direction: column;
}

.shipping-rates-selector-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  margin-bottom: 8px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  transition: background-color 0.2s ease, border-color 0.2s ease;
}

.shipping-rates-selector-item.selected {
  background-color: var(--primary-color);
  border-color: #c3e6cb;
}

.shipping-rates-selector-deselect-button {
  background: none;
  border: none;
  font-size: 20px;
  color: #dc3545;
  cursor: pointer;
  margin-left: 10px;
  transition: color 0.2s ease;
}

.shipping-rates-selector-deselect-button:hover {
  color: #c82333;
}

/* All refund orders */

.all-refund-orders-container {
  width: 100%;
  margin: 2rem auto;
  padding-top: 1rem;
  background-color: rgba(250, 250, 250, 0.2);
}

.all-refund-orders-table {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;
}

.all-refund-orders-table th, .all-refund-orders-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.all-refund-orders-table th {
  background-color: rgba(0, 0, 0, 0.8);
  font-weight: bold;
}

.all-refund-orders-table tbody tr:hover {
  background-color: #f1f1f1;
}

.all-refund-orders-button {
  background: none;
  border: none;
  cursor: pointer;
}

/* Hashtag suggestions component */

.hashtag-suggestions-component-container {
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  margin-top: 10px;
}

.hashtag-suggestions-component-loading {
  font-size: 14px;
  color: #999;
}

.hashtag-suggestions-component-item {
  padding: 8px;
  background-color: rgba(250, 250, 250, 0.2);
  border-radius: 4px;
  margin-bottom: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.hashtag-suggestions-component-item:hover {
  background-color: rgba(250, 250, 250, 0.4);
}

.hashtag-suggestions-component-text {
  font-size: 14px;
  color: rgba(0,0,0,0.5);
}

/* My event card component */

.my-event-card-component-card {
  position: relative;
  margin-bottom: 10px;
  border: 1px solid rgba(250, 250, 250, 0.6);
  overflow: hidden;
  border-radius: var(--border-radius-sm);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.my-event-card-component-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
}

.my-event-card-component-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.my-event-card-component-card:hover .my-event-card-component-image {
  transform: scale(1.05);
}

.my-event-card-component-info {
  background: linear-gradient(90deg, #000c 20%, #0000);
 color: var(--text-color);;
  left: 0;
  bottom: 0;
  padding: 20px !important;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 0.1px;
}

.my-event-card-component-title {
  margin: 0;
  font-weight: bold;
  font-size: 24px;
}

.my-event-card-component-description {
  font-size: 16px;
  color: #d0d0d0;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.my-event-card-component-datetime {
  margin: 0px;
}

.my-event-card-component-date,
.my-event-card-component-time,
.my-event-card-component-location {
  margin: 5px 0;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
}

.my-event-card-icon {
  margin-right: 8px; 
}

.my-event-card-component-button {
  background-color: var(--primary-color);
   color: var(--text-color);
  border: none;
  padding: 5px 10px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: var(--border-radius-sm);
  font-size: 16px;
  box-shadow: 3px 3px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease;
}

.my-event-card-component-button:hover {
  background-color: #e04050;
}

.my-event-card-component-calendar-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  background: rgba(250, 250, 250, 0.4);
  border-radius: 15px;
  padding: 10px;
}

.my-event-card-component-calendar-buttons > * {
  flex: 1;
}
.my-events-container {
  display: flex; 
  flex-direction: column; 
  align-items: center; 
  justify-content: center; 
  border-radius: var(--border-radius-sm);
  margin: 0 auto;
}

.my-events-title {
  text-align: center; 
  margin-bottom: 5px;
  color: rgb(119, 119, 119) !important;
  font-size: 12px;
  font-weight: 300;
}

/* Map Page.*/

/* General Styles */
.map-page-container {
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
}

/* Mobile Filters */
.map-page-mobile-filter-dialog {
  position: relative;
  z-index: 40;
}

.map-page-mobile-filter-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.25);
}

.map-page-mobile-filter-panel {
  position: fixed;
  inset: 0;
  z-index: 40;
  display: flex;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

.map-page-mobile-filter-panel-open {
  transform: translateX(0);
}

.map-page-mobile-filter-content {
  flex-direction: column;
  background-color: var(--background-color);
  padding-top: 16px;
  padding-bottom: 48px;
  max-width: 320px;
  width: 100%;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.map-page-mobile-filter-header {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
}

.map-page-mobile-filter-close-button {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--background-color);
  color: rgba(0, 0, 0, 0.4);
}

.map-page-filter-form {
  margin-top: 16px;
  border-top: 1px solid rgba(200, 200, 200, 1);
}

.map-page-category-list {
  padding: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
}

.map-page-filter-section {
  border-top: 1px solid rgba(200, 200, 200, 1);
  padding: 24px 16px;
}

.map-page-filter-section-button {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: var(--background-color);
  padding: 16px;
  color: rgba(0, 0, 0, 0.4);
  font-weight: 500;
}

.map-page-filter-section-label {
  color: rgba(0, 0, 0, 0.9);
}

.map-page-filter-section-content {
  padding-top: 24px;
}

.map-page-filter-option {
  display: flex;
  align-items: center;
}

.map-page-filter-option-checkbox {
  height: 16px;
  width: 16px;
  border-radius: 4px;
  border: 1px solid rgba(200, 200, 200, 1);
  margin-right: 12px;
}

.map-page-filter-option-label {
  flex: 1;
  color: rgba(80, 80, 80, 1);
}

/* Desktop Layout */
.map-page-main {
  margin: 0 auto;
  padding: 0 16px;
}

.map-page-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(200, 200, 200, 1);
  padding: 24px 16px;
}

.map-page-title {
  font-size: 2.25rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.9);
}

.map-page-filters {
  display: flex;
  align-items: center;
  gap: 16px;
}

.map-page-listbox-button {
  display: flex;
  align-items: center;
  background-color: var(--background-color);
  padding: 8px 12px;
  font-size: 0.875rem;
  font-weight: 500;
  color: rgba(80, 80, 80, 1);
  border-radius: 4px;
  border: 1px solid rgba(200, 200, 200, 1);
}

.map-page-listbox-options {
  position: absolute;
  z-index: 10;
  margin-top: 8px;
  max-height: 240px;
  overflow-y: auto;
  background-color: var(--background-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.map-page-listbox-option {
  padding: 8px 16px;
  font-size: 1rem;
}

.map-page-listbox-option-active {
  background-color: rgba(224, 242, 255, 1);
  color: rgba(33, 150, 243, 1);
}

.map-page-listbox-option-selected {
  font-weight: 500;
}

.map-page-sort-menu {
  position: relative;
  display: inline-block;
  text-align: left;
}

.map-page-sort-menu-button {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 500;
  color: rgba(80, 80, 80, 1);
  border: none;
  background: none;
}

.map-page-sort-menu-options {
  position: absolute;
  right: 0;
  z-index: 10;
  margin-top: 8px;
  width: 160px;
  background-color: var(--background-color);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.map-page-sort-menu-option {
  padding: 8px 16px;
  font-size: 0.875rem;
}

.map-page-sort-menu-option-active {
  background-color: rgba(240, 240, 240, 1);
}

.map-page-grid-button {
  margin-left: 20px;
  padding: 8px;
  color: rgba(100, 100, 100, 1);
  border: none;
  background: none;
}

.map-page-filters-button {
  margin-left: 20px;
  padding: 8px;
  color: rgba(100, 100, 100, 1);
  border: none;
  background: none;
}

.map-page-section {
  padding: 24px 16px;
}

.map-page-shop-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
}

@media (min-width: 768px) {
  .map-page-shop-list {
      grid-template-columns: 1fr 1fr;
  }
}

.map-page-shop-item {
  position: relative;
  background-color: black;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
}

.map-page-shop-item img {
  width: 100%;
  height: 128px;
  object-fit: cover;
}

.map-page-shop-item-avatar {
  position: absolute;
  top: 16px;
  left: 16px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid white;
  object-fit: cover;
}

.map-page-shop-item-content {
  padding-top: 32px;
  padding-bottom: 16px;
  text-align: center;
}

.map-page-shop-item-title {
  font-size: 1rem;
  font-weight: bold;
  margin-top: 8px;
}

.map-page-shop-item-description {
  font-size: 0.875rem;
  color: rgba(80, 80, 80, 1);
}

/* Map Container */
.map-page-map-container {
  width: 100%;
  height: 500px;
}






/* Google calendar component */

.google-calendar-button-link {
  display: inline-block;
}

.google-calendar-icon {
  width: 64px; 
  height: 64px; 
  cursor: pointer;
  transition: transform 0.2s ease;
}

.google-calendar-icon:hover {
  transform: scale(1.1); 
}

/* Outlook calendar button */

.outlook-calendar-button-link {
  display: inline-block;
}

.outlook-calendar-icon {
  width: 64px; 
  height: 64px; 
  cursor: pointer;
  transition: transform 0.2s ease;
}

.outlook-calendar-icon:hover {
  transform: scale(1.1); 
}

/* Download ics button */
.download-ICS-button {
  width: 64px;
  height: 64px;
  cursor: pointer;
  transition: transform 0.2s ease, color 0.2s ease;
  background: #36d000;
  border-radius: 50%;
}

.download-ICS-icon {
  background: #36d000;
  font-size: 24px;
 color: var(--text-color);; 
  transition: transform 0.2s ease, color 0.2s ease;
}

.download-ICS-button:hover {
  transform: scale(1.1); 
  
}



/* Wallet sidebar */
.crypto-wallet-sidebar-dialog {
  position: fixed;
  inset: 0;
  z-index: 30;
  overflow: hidden;
}

.crypto-wallet-sidebar-overlay {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.25);
}

.crypto-wallet-sidebar-container {
  position: fixed;
  right: 0;
  max-width: 100%;
  display: flex;
  margin-top: 10vh;
  height: 90vh;
}

.crypto-wallet-sidebar-panel {
  position: relative;
  width: 100%;
  max-width: 28rem;
  background-color: #303335;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.crypto-wallet-sidebar-inner {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 24rem;
}

.crypto-wallet-cover-container {
  position: relative;
  text-align: center;
}

.crypto-wallet-cover-image {
  width: 100%;
  height: 12rem;
  object-fit: cover;
}

.crypto-wallet-cover-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 1; 
}

.crypto-wallet-avatar {
  position: absolute;
  left: 50%;
  top: 35%;
  transform: translateX(-50%) translateY(-50%);
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  border: 0.15rem solid #2fdbeb;
  z-index: 2;
}

.crypto-wallet-avatar-info {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(30% + 3rem);
  width: 100%;
  text-align: center;
  z-index: 2;
}

.crypto-wallet-title {
   color: var(--text-color);
  font-size: 2rem;
  margin: 0;
}

.crypto-wallet-subtitle {
  color: #ccc;
  font-size: 0.5rem;
  margin: 0.25rem 0 0;
}

.crypto-wallet-close-button-container {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
}

.crypto-wallet-close-button {
  border-radius: 50%;
  padding: 0.25rem;
  cursor: pointer;
}

.crypto-wallet-close-icon {
  width: 1.5rem;
  height: 1.5rem;
}

/* Button container with titles */
.crypto-wallet-buttons-container {
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  gap: 0.5rem;
  border: 1px solid rgba(250, 250, 250, 0.2);
  padding-bottom: 5px;
  align-items: center;
}

.crypto-wallet-button-wrapper {
  flex: 1;
  text-align: center;
}

.crypto-wallet-button {
  width: 60px;
  height: 60px;
  padding: 0.5rem;
  border-radius: 0.25rem;
  border: none;
  cursor: pointer;
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Button icons */
.crypto-wallet-button-icon {
  width: 2.5rem;
  height: 2.5rem;
}

/* Individual button colors */
.crypto-wallet-button.wallet {
  background-color: #00d332; 
}

.crypto-wallet-button.buy {
  background-color: #00d1d1; 
}

.crypto-wallet-button.sell {
  background-color: #00a3fe; 
}

.crypto-wallet-button.pay {
  background-color: #0070e0; 
}

.crypto-wallet-button.trade {
  background-color: #05e5ab; 
}

.crypto-wallet-button-title {
   color: var(--text-color);
  font-size: 0.75rem;
}

/* Section Title with Icon */
.crypto-wallet-section-title {
  display: flex;
  align-items: center;
  margin: 0.5rem;
}

.crypto-wallet-section-icon {
  background-color: #00d332; 
  border-radius: 50%;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
}
.crypto-wallet-section-icon-buy {
  background-color: #00d1d1; 
  border-radius: 50%;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
}
.crypto-wallet-section-icon-sell {
  background-color: #00a3fe; 
  border-radius: 50%;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
}
.crypto-wallet-section-icon-pay {
  background-color: #00a3fe; 
  border-radius: 50%;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
}

.crypto-wallet-section-icon-img {
  width: 1rem;
  height: 1rem;
   color: var(--text-color);
}

.crypto-wallet-section-text {
  font-weight: bold;
   color: var(--text-color);
  font-size: 1rem;
}

/* Form section */
.crypto-wallet-form-container {
  padding-left: 1rem;
  padding-right: 1rem;
  border-top: 1px solid rgba(250, 250, 250, 0.8);
  border-bottom: 1px solid rgba(250, 250, 250, 0.8);
}

.crypto-wallet-form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-top: 12px;
  padding-bottom: 12px;
}

.crypto-wallet-input-group {
  display: flex;
  flex-direction: column;
  position: relative;
}

.crypto-wallet-label {
   color: var(--text-color);
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
}

.crypto-wallet-input-wrapper {
  display: flex;
  align-items: center;
}

.crypto-wallet-input {
  width: 100%;
  height: 28px;
  padding: 15px;
  border-radius: 1.5rem;
  background-color: #333;
  border: 1px solid #ddd;
  outline: none;
}

.crypto-wallet-input-note {
  height: 56px;
}

/* Right-aligned content for "Amount" and "From" */
.crypto-wallet-currency,
.crypto-wallet-placeholder-balance {
  position: absolute;
  right: 1rem;
  font-size: 0.875rem;
 color: var(--text-color);;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0.25rem 0.5rem;
  border-radius: 1.5rem;
  margin-left: 0.5rem;
}

.crypto-wallet-placeholder-balance {
  background-color: transparent;
}

.crypto-wallet-chevron-wrapper {
  position: absolute;
  right: 1rem;
  display: flex;
  align-items: center;
}

.crypto-wallet-vertical-line {
  width: 1px;
  height: 16px;
  background-color: var(--text-color);
  margin-right: 0.5rem;
}

/* Centered vertical line for "Pay With" field */
.crypto-wallet-input-group-pay-with .crypto-wallet-vertical-line {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.crypto-wallet-chevron-icon {
  width: 1rem;
  height: 1rem;
 color: var(--text-color);;
}

/* Summary section */
.crypto-wallet-summary {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.crypto-wallet-summary-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.crypto-wallet-summary-item {
   color: var(--text-color);
  font-size: 1rem;
}

.crypto-wallet-summary-value {
   color: var(--text-color);
  font-size: 1rem;
}

.crypto-wallet-divider {
  width: 100%;
  border: none;
  border-bottom: 1px solid #ddd;
  margin: 0.5rem 0;
}
.crypto-wallet-dashboard-value-bold-part {
  font-weight: bold;
}

/* Actions section */
.crypto-wallet-actions {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 0.5rem;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(250, 250, 250, 0.2);
}

.crypto-wallet-action-button {
  padding: 0.5rem 1rem;
  border-radius: 1.25rem;
  width: 120px;
  cursor: pointer;
  border: none;
}

.crypto-wallet-action-button.cancel {
  background-color: #5c5c5c;
   color: var(--text-color);
}

.crypto-wallet-action-button.buy {
  background-color: var(--primary-color);
   color: var(--text-color);
}
.crypto-wallet-action-button-active {
  width: 70px;
  height: 70px;
}

/* Wallet Bottom Bar Section */
.crypto-wallet-bottom-bar {
  margin-top: auto; 
  background-color: #333;
  padding: 0.5rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
}

.crypto-wallet-bottom-icon {
  background-color: #00d332; 
}

.crypto-wallet-section-text { 
  font-size: 1rem;
  font-weight: bold;
}

/* Balance Container Section */
.crypto-wallet-balance-container { 
  border-top: 1px solid rgba(0, 0, 0, 0.8);
  border-bottom: 1px solid rgba(0, 0, 0, 0.8);
}
.crypto-wallet-balance-inner-container{
  background-color: #393d3f;
  padding: 1rem;
  border-radius: 0.5rem; 
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.crypto-wallet-balance-item {
  display: flex;
  justify-content: space-between;
   color: var(--text-color);
  padding: 0.5rem 0;
  font-size: 0.875rem;
}

.crypto-wallet-balance-title {
  font-weight: bold;
}

.crypto-wallet-balance-value {
  font-weight: normal;
}


/* Pay Portal */
.crypto-wallet-pay-portal-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.crypto-wallet-pay-portal {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  flex: 1;
}

.crypto-wallet-pay-portal-button {
  flex: 1;
  padding: 0.75rem;
   color: var(--text-color);
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  border: none;
}

/* Individual button colors */
.crypto-wallet-pay-portal-button.ap {
  background-color: #00a3fe; 
}

.crypto-wallet-pay-portal-button.ch {
  background-color: #0865fe; 
}

.crypto-wallet-pay-portal-button.ca {
  background-color: #00d030; 
}

.crypto-wallet-pay-portal-button.pp {
  background-color: #008cff; 
}

.crypto-wallet-pay-portal-button.x {
  background-color: #00defc; 
}

.crypto-wallet-pay-portal-button.boa {
  background-color: #ff001f; 
}

/* Chevron Icons */
.crypto-wallet-chevron-icon {
  width: 1.5rem;
  height: 1.5rem;
   color: var(--text-color);
  cursor: pointer;
}

.crypto-wallet-dashboard-item {
  flex: 1;
  background-color: #393d3f;
  padding: 1rem;
  border-radius: 5px;
   color: var(--text-color);
  text-align: center;
}

.crypto-wallet-dashboard-title {
  margin-top: 0.5rem;
  font-size: 0.875rem;
}

.crypto-wallet-dashboard-value {
  font-size: 0.75rem;
  margin-top: 0.25rem;
}

.crypto-wallet-offer-button {
  padding: 1rem;
  background-color: #00d332;
}

/* Dashboard Section */
.crypto-wallet-dashboard-container {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  overflow-x: auto;
  padding: 0.5rem;
}

.crypto-wallet-dashboard-item {
  flex: 1;
  background-color: #393d3f;
  padding-top: 0.5rem;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
   color: var(--text-color);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.crypto-wallet-dashboard-sub-title {
  position: absolute;
  top: 1rem; 
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.875rem;
}

.crypto-wallet-dashboard-image {
  margin-top: 2rem; 
  margin-bottom: 0.5rem;
}

.crypto-wallet-dashboard-value {
  font-size: 0.75rem;
  margin-top: 0.25rem;
}

/* Specific image heights */
.rewards-image {
  height: 40px; 
}

.convert-image {
  height: 34px; 
}

.trading-image {
  height: 40px; 
}

/* Offers Section */
.crypto-wallet-offers-container {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  overflow-x: auto;
  padding: 0.5rem;
}

.crypto-wallet-offer-item {
  flex: 1;
  background-color: #393d3f;
  padding: 1rem;
  border-radius: 5px;
   color: var(--text-color);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.crypto-wallet-offer-title {
  font-size: 0.875rem;
  font-weight: bold;
}

.crypto-wallet-offer-divider {
  width: 100%;
  border: 0;
  border-top: 1px solid #fff;
  margin: 0.5rem 0;
}

.crypto-wallet-offer-accept-button {
  padding: 4px 20px;
  background-color: #00d332;
  border-radius: 15px;
   color: var(--text-color);
  text-align: center;
  cursor: pointer;
  border: none;
  margin-top: 0.5rem;
  width: 100%;
  font-size: 14px;
}

/* Additional Styles for the Pay Sidebar */
.crypto-wallet-transfer-buttons {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
}

.crypto-wallet-transfer-button {
  flex: 1;
  background-color: var(--primary-color);
   color: var(--text-color);
  border-radius: 15px;
  padding: 0.5rem 1rem;
  text-align: center;
  margin: 0 0.25rem;
  cursor: pointer;
  border: none;
  font-weight: bold;
  font-size: 12px;
}

.crypto-wallet-input-group-date-time .crypto-wallet-input-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.crypto-wallet-input-group-date-time .crypto-wallet-chevron-icon {
  margin-left: 0.5rem;
}

.crypto-wallet-input-group-upload {
  position: relative;
}

.crypto-wallet-input-upload {
  padding-right: 2.5rem;
}

.crypto-wallet-upload-note {
  display: block;
  font-size: 0.75rem;
  color: #888;
  margin-top: 0.5rem;
}

.crypto-wallet-section-icon-pay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00aaff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.crypto-wallet-input-group-date-time-wrapper {
  display: flex;
  gap: 1rem; 
}

.crypto-wallet-input-group-date-time {
  flex: 1;
}

.crypto-wallet-payment-options {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  margin-bottom: 1rem;
  padding-left: 10px;
  padding-right: 10px;
}

.crypto-wallet-option-button {
  flex: 1;
  background-color: #2d2f31;
   color: var(--text-color);
  border: none;
  border-radius: 15px;
  padding: 2px 14px;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
}

.crypto-wallet-option-button:hover {
  background-color: #3c3f42;
}

.crypto-wallet-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #333;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
  z-index: 10;
  overflow: hidden;
}

.crypto-wallet-dropdown-item {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
   color: var(--text-color);
  cursor: pointer;
}

.crypto-wallet-dropdown-item:hover {
  background-color: #444;
}

.crypto-wallet-dropdown-icon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}

/* Gambling page */

.gambling-page-casino-landing-page {
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
}

.gambling-page-casino-landing-page-wrapper {
  margin-left: 120px;
  margin-right: 120px;
}

/* Hero Slider */
.gambling-page-hero-slider {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  border-radius: 15px;
  height: 420px;
}

.gambling-page-slides-container {
  display: flex;
  transition: transform 0.5s ease-in-out;
  position: relative;
  width: 100%;
}


.gambling-page-slider-image {
  width: 100%;
  height: auto;
  border-radius: 15px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}


.gambling-page-slide {
  position: relative;
  min-width: 100%;
  display: none;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.gambling-page-active {
  display: block;
  opacity: 1;
}

.gambling-page-slide-content {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%); 
  width: 690px;
  text-align: center;
   color: var(--text-color);
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: var(--border-radius-sm);
  z-index: 1; 
}

.gambling-page-slide-content h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.gambling-page-slide-button {
  background-color: var(--primary-color);
   color: var(--text-color);
  padding: 10px 20px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 1rem;
}

.gambling-page-slide-button:hover {
  background-color: #0056b3;
}



.gambling-page-slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
   color: var(--text-color);
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
}

.gambling-page-prev {
  left: 10px;
}

.gambling-page-next {
  right: 10px;
}

/* Call-to-Action Boxes */
.gambling-page-cta-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  height: 250px;
}

.gambling-page-cta-box {
  flex: 1;
  background-color: rgba(250, 250, 250, 0.2);
  padding: 20px;
  margin: 0 10px;
  border-radius: 15px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.gambling-page-cta-box h3 {
  margin-bottom: 10px;
  font-size: 1.5rem;
  color: #007bff;
}

.gambling-page-cta-box p {
  margin-bottom: 15px;
  font-size: 1rem;
  color: #555;
}

.gambling-page-cta-button {
  background-color: var(--primary-color);
   color: var(--text-color);
  padding: 10px 20px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 1rem;
}

.gambling-page-cta-button:hover {
  background-color: #0056b3;
}

/* List of Games */
.gambling-page-games-list {
  margin-bottom: 40px;
}

.gambling-page-games-list h2 {
  margin-bottom: 20px;
  font-size: 2rem;
  text-align: center;
}

.gambling-page-games-grid {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.gambling-page-game-card {
  background-color: #333;
  width: calc(25% - 20px);
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 15px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.gambling-page-game-card h4 {
  margin-top: 10px;
  font-size: 1.2rem;
 color: var(--text-color);;
}

.gambling-page-game-image {
  width: 100%;
  height: 200px;
  max-height: 200px;
  border-radius: var(--border-radius-sm);
}

@media (max-width: 768px) {
  .gambling-page-cta-section {
    flex-direction: column;
  }

  .gambling-page-cta-box {
    margin-bottom: 20px;
  }

  .gambling-page-games-grid {
    flex-direction: column;
  }

  .gambling-page-game-card {
    width: 100%;
  }
}

/* Ad set modal */
.ad-set-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: var(--modal-overlay-backdrop-filter);
}

.ad-set-modal {
  background: var(--modal-content-background);
  padding: 20px;
  border: 1px solid var(--background-color);
  border-radius: var(--border-radius-sm);
  width: 90%;
  max-width: 100%;
  height: 90vh;
  max-height: 90vh; 
  position: relative;
  overflow-y: auto; 
}

@media (max-width: 768px) {
 
  .ad-set-modal {
    padding: 20px;
    width: 100%;
    max-width: 100%;
    height: 95vh;
    max-height: 95vh; 
    position: relative;
    overflow-y: auto; 
  }
  
}




.ad-set-modal h2 {
font-weight: bold;
font-size: 24px;
margin-bottom: 10px;
}

.close-modal-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5em;
}

.ad-format-section {
  margin-bottom: 20px;
  border-radius: var(--border-radius-sm);
  border: 1px solid rgba(250, 250, 250, 0.6);
  padding: 15px;
  background-color: rgba(250, 250, 250, 0.2);
}
.ad-format-section h3 { 
font-weight: bold;
margin-bottom: 20px;
}


.ad-format-controls {
  display: flex;
  justify-content: center; 
  align-items: center; 
  margin-top: 10px;
  margin-bottom: 20px;
}

.ad-format-controls label {
  margin-bottom: 10px;
}

.ad-format-preview {
  margin-top: 10px;
}

.ad-format-preview img {
  border: 1px solid #ddd;
  border-radius: var(--border-radius-sm);
}

.customize-ad-set-button-container {
  margin-top: 20px;
  margin-bottom: 20px; 
  display: flex;
  justify-content: center; 
  align-items: center; 
  width: 100%;
}
.ad-set-modal-edit-button {
  margin-left: 15px;
  background-color: var(--background-color);
  color: var(--text-color);
  border: 1px solid var(--primary-color);
  padding: 5px 10px;
  cursor: pointer;
  border-radius: var(--border-radius-full);
  transition: background-color 0.3s ease;
}

.ad-set-modal-edit-button:hover {
  background-color: var(--primary-color);
}

.customize-ad-set-button {
  background-color: var(--primary-color);
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
   color: var(--text-color);
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}
.customize-ad-set-button.disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}
.customize-ad-set-button:hover {
  background-color: var(--primary-color);
}

.save-ad-set-button {
  background-color: var(--primary-color);
   color: var(--text-color);
  padding: 10px 20px;
  border: none;
  border-radius: var(--border-radius-full);
  cursor: pointer;
  display: block;
  margin: 0 auto;
}

.save-ad-set-button:hover {
  background-color: #3d6000;
}

.draggable-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  cursor: grab;
}

.draggable-container:active {
  cursor: grabbing;
}

.draggable-container img {
  position: absolute;
  top: 0;
  left: 0;
  cursor: inherit;
  user-select: none;
  max-width: none; 
  max-height: none; 
}
/* Upload button */
/* Hide the actual file input */
.ad-preview-upload-input {
  display: none;
}

/* Style for the custom file upload icon */
.ad-preview-upload-icon-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 15px;
  padding: 20px;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-lg);
}

/* Style for the upload icon (default state) */
.ad-preview-upload-icon {
  font-size: 24px;
  color: #666;
  transition: color 0.3s ease;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.ad-preview-upload-icon.uploaded {
  color: #28a745; 
  transform: scale(1.2); 
  transition: transform 0.3s ease, box-shadow 0.3s ease; 
  box-shadow: 0 0 10px 5px rgba(40, 167, 69, 0.6); 
}

/* Style for the uploaded image text */
.ad-preview-upload-text {
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: #333;
  margin-left: 10px; 
}


/* Top banner ad preview */

/* Top Banner Ad Container */
.ad-preview-top-banner-container {
  padding: 20px;
  background-color: rgba(0,0,0,0.5);
  border-radius: var(--border-radius-sm);
  margin: 10px auto;
  overflow: hidden;
  position: relative;
}

.ad-preview-top-banner-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 1;
  transition: transform 0.5s ease-in-out;
  position: relative;
}

/* Top Banner Content */
.ad-preview-top-banner-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: var(--border-radius-sm);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.5);
  position: relative;
  overflow: hidden;
  background-blend-mode: darken;
  width: 100%;
}

/* Wrapper for the Image in Preview */
.ad-preview-top-banner-image-container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  aspect-ratio: 16 / 1;
  top: 0;
  left: 0;
  z-index: 1;
}

/* Draggable Image */
.ad-preview-top-banner-image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  object-fit: cover;
}

/* Top Banner Overlay */
.ad-preview-top-banner-overlay {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  align-content: center;
  border: none;
  height: 100%;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  z-index: 1;
}

/* Text Content Wrapper */
.ad-preview-top-banner-text {
  z-index: 2;
   color: var(--text-color);
  display: flex;
  flex-direction: column;
  position: relative;
}

/* Text Link */
.ad-preview-top-banner-ad-link {
   color: var(--text-color);
  text-decoration: none;
  font-size: 14px;
  line-height: 1.5;
  flex-grow: 1;
}

/* Dismiss Button */
.ad-preview-top-banner-ad-dismiss {
  background: none;
  border: none;
  padding: 12px;
  cursor: pointer;
  z-index: 2;
}

.ad-preview-top-banner-ad-icon {
  height: 20px;
  width: 20px;
   color: var(--text-color);
}


/* Mobile top banner preview */
.ad-preview-top-banner-content-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.75);
  padding: 0px 25px;
  border-radius: var(--border-radius-sm);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.5);
  position: relative;
  overflow: hidden;
  background-blend-mode: darken;
  aspect-ratio: 5 / 1;
}
.ad-preview-top-banner-mobile-container {
  width: 100%;
  margin-top: 10px;
  box-sizing: border-box;
  position: relative;
  margin-top: 30px;
}

/* ad preview for sponsored product */

.ad-preview-mobile-container {
  padding: 20px;
  background-image: url('https://res.cloudinary.com/dzlopmfj8/image/upload/v1728566751/mobile_format_for_ads_lygpfw.svg'); /* Add your SVG path here */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 10px auto;
  max-width: 360px;
  width: 100%;
  height: 700px; /* Adjust height to match the aspect ratio of your template */
  justify-content: center;
  position: relative;
}


/* Sponsored product AD Preview */
.ad-preview-sponsored-products-container {
  padding: 20px;
  background-color: rgba(250, 250, 250, 0.2);
  border-left: 1px solid rgba(250, 250, 250, 0.6);
  border-right: 1px solid rgba(250, 250, 250, 0.6);
  margin: 10px auto;
  max-width: 320px;
}

.ad-preview-sponsored-products-title {
  text-align: center;
  margin-bottom: 20px;
   color: var(--text-color);
}

.ad-preview-sponsored-products-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ad-preview-sponsored-products-item {
  width: 100%;
  text-align: center;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: var(--border-radius-sm);
  overflow: hidden;
  position: relative;
  aspect-ratio: 1 / 1;
}

.ad-preview-sponsored-products-image-container {
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1;
  overflow: hidden; 
}

.ad-preview-sponsored-products-image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1; 
}

.ad-preview-sponsored-products-info {
  background-color: rgba(0,0,0,0.5);
   color: var(--text-color);
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2; 
  position: absolute;
  bottom: 0;
  width: 100%;
}

.ad-preview-sponsored-products-product-name {
  font-size: 16px;
  margin: 0;
}

.ad-preview-sponsored-products-product-price {
  font-size: 14px;
  font-weight: bold;
  margin: 5px 0;
  color: #fff;
}

.ad-preview-sponsored-products-visit-store {
  background-color: var(--secondary-color) !important;
    border-radius: var(--border-radius-full);
    color: #fff !important;
    font-size: 12px;
    font-weight: 700 !important;
    padding: 5px 15px;
    text-decoration: none;
}


/* Banner ad for product Preview */
.ad-preview-banner-ad-container {
  position: relative;
  z-index: 5;
  left: 0;
  right: 0;
  bottom: 0;
  margin-bottom: 20px;
  overflow: hidden; 
  border-radius: var(--border-radius-sm);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.75);
}

.ad-preview-banner-mobile-ad-container {
  position: relative;
  z-index: 5;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0px 25px;
  overflow: hidden; 
  border-radius: var(--border-radius-sm);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.75);
  aspect-ratio: 4 / 1;
  margin-top: 550px;
}

/* Image container for draggable image */
.ad-preview-banner-ad-image-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  aspect-ratio: 12 / 1; 
}

.ad-preview-banner-mobile-ad-image-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  aspect-ratio: 4 / 1; 
}

/* Draggable Image */
.ad-preview-banner-ad-image {
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  z-index: 2; 
  
}

/* Content overlay on top of the image */
.ad-preview-banner-ad-content {
  display: flex;
  align-items: center;
  padding: 15px 25px;
  border-radius: 8px;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.05);
  aspect-ratio: 12 / 1;
}
.ad-preview-banner-mobile-ad-content {
  display: flex;
  align-items: center;
  padding: 0px 10px;
  border-radius: 8px;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
  
}

.ad-preview-banner-ad-dismiss {
  margin: -12px;
  padding: 12px;
  z-index: 3;
}

.ad-preview-banner-mobile-ad-dismiss {
  margin: -12px;
  padding: 12px;
  z-index: 3;
}

.ad-preview-banner-ad-icon {
  height: 20px;
  width: 20px;
   color: var(--text-color);
  z-index: 3;
}

.ad-preview-banner-ad-link{
   color: var(--text-color);
  text-decoration: none;
  font-size: 14px;
  line-height: 1.5;
  flex-grow: 1;
}

/* Dual Product ad carousel Preview */
.ad-preview-dual-product-carousel-container {
  padding: 0px;
  border-radius: var(--border-radius-sm);
  margin: 10px auto;
  overflow: hidden;
  position: relative;
}

.ad-preview-dual-product-carousel-wrapper {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%; 
}

.ad-preview-dual-product-carousel-pair {
  display: flex;
  width: 100%; 
}

.ad-preview-dual-product-carousel-item {
  background-position: center center;
  background-size: cover;
  background: rgba(0, 0, 0, 0.5);
  aspect-ratio: 1 / 1;
  width: 100%; 
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
 color: var(--text-color);;
  margin: 1%;
  border-radius: var(--border-radius-sm);
  overflow: hidden;
}

/* Container for the draggable image */
.ad-preview-dual-product-carousel-image-container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* Draggable Image */
.ad-preview-dual-product-carousel-image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1; 
  object-fit: cover;
  background: rgba(0, 0, 0, 0.5);
}

/* Content overlay on top of the image */
.ad-preview-dual-product-carousel-content {
  padding: 20px;
  text-align: center;
  align-content: center !important;
  border: none !important;
  height: 100% !important;
  padding-bottom: 25px !important;
  text-align: center !important;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.ad-preview-dual-product-carousel-title {
  font-size: 2em;
  margin: 0;
  z-index: 2
}

.ad-preview-dual-product-carousel-price {
  font-size: 1.25em;
  margin: 10px 0;
  z-index: 2
}

.ad-preview-dual-product-carousel-button {
  background-color: var(--primary-color) !important;
   color: var(--text-color);
  border-radius: var(--border-radius-full) !important;
  padding: 5px 25px !important;
  margin: 25px !important;
  border: none;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  z-index: 2
}

.ad-preview-dual-product-carousel-button:hover {
  background-color: var(--primary-color);
}

.ad-preview-dual-product-carousel-item.empty-box {
  background-color: rgba(250, 250, 250, 0.2); 
  border: 2px dashed rgba(250, 250, 250, 0.6);
}

/* Quad product carousel Preview */
.ad-preview-quad-product-carousel-container {
  padding: 20px;
  background-color: rgba(0,0,0,0.5);
  border-radius: var(--border-radius-sm);
  margin: 10px auto;
  overflow: hidden;
  position: relative;
}

.ad-preview-quad-product-carousel-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease-in-out;
}

.ad-preview-quad-product-carousel-item {
  background-position: center center;
  background-size: cover;
  flex: 1 1 48%;
  aspect-ratio: 16 / 9;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
 color: var(--text-color);;
  margin: 1%;
  border-radius: var(--border-radius-sm);
  overflow: hidden;
  position: relative;
}

/* Container for the draggable image */
.ad-preview-quad-product-carousel-image-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 1;
}

/* Draggable Image */
.ad-preview-quad-product-carousel-image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

/* Content overlay on top of the image */
.ad-preview-quad-product-carousel-content {
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  align-content: center !important;
  border: none !important;
  height: 100%;
  padding-bottom: 25px !important;
  text-align: center !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}

.ad-preview-quad-product-carousel-title {
  font-size: 1.5em;
  margin: 0;
  z-index: 2;
}

.ad-preview-quad-product-carousel-price {
  font-size: 1em;
  z-index: 2;
}

.ad-preview-quad-product-carousel-button {
  margin-top: 10px;
   color: var(--text-color);
  background-color: var(--primary-color) !important;
  border-radius: var(--border-radius-full) !important;
  margin: 25px !important;
  padding: 5px 25px !important;
  border: none;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  z-index: 2;
}

.ad-preview-quad-product-carousel-button:hover {
  background-color: var(--primary-color);
}

.ad-preview-quad-product-carousel-item.empty-box {
  background-color: rgba(250, 250, 250, 0.2); 
  border: 2px dashed rgba(250, 250, 250, 0.6); 
}

/* Sponsored product carousel AD Preview */

/* Container for Sponsored Product Carousel */
.ad-preview-sponsored-product-carousel-container {
  padding: 20px;
  background-color: rgba(0,0,0,0.5);
  border-radius: var(--border-radius-sm);
  margin: 10px auto;
  overflow: hidden;
  position: relative;
}

.ad-preview-sponsored-product-carousel-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease-in-out;
}

.ad-preview-sponsored-product-carousel-item {
  background-position: center center;
  background-size: cover;
  flex: 1 1 48%;
  aspect-ratio: 3 / 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
 color: var(--text-color);;
  margin: 1%;
  border-radius: var(--border-radius-sm);
  overflow: hidden;
  position: relative;
}

/* Image container for draggable image */
.ad-preview-sponsored-product-carousel-image-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  aspect-ratio: 3/1;
  top: 0;
  left: 0;
  z-index: 1;
}

/* Draggable Image */
.ad-preview-sponsored-product-carousel-image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  
}

/* Content overlay on top of the image */
.ad-preview-sponsored-product-carousel-content {
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  align-content: center;
  border: none;
  height: 99.9%;
  padding-bottom: 25px;
  text-align: center;
  width: 99.9%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
}

.ad-preview-sponsored-product-carousel-title {
  font-size: 1.5em;
  margin: 0;
  z-index: 2;
}

.ad-preview-sponsored-product-carousel-price {
  font-size: 1em;
  z-index: 2;
}

.ad-preview-sponsored-product-carousel-button {
  margin-top: 10px;
   color: var(--text-color);
  background-color: var(--primary-color);
  border-radius: var(--border-radius-full);
  margin: 25px;
  padding: 5px 25px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  z-index: 2;
}

.ad-preview-sponsored-product-carousel-button:hover {
  background-color: var(--primary-color);
}


.ad-preview-sponsored-product-mobile-carousel-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  transition: transform 0.5s ease-in-out;
  margin-top: 200px;
}

.ad-preview-sponsored-product-carousel-mobile-item {
  background-position: center center;
  background-size: cover;
  flex: 1 1 48%;
  width: 100%;
  aspect-ratio: 16 / 9;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
 color: var(--text-color);;
  margin: 1%;
  border-radius: var(--border-radius-sm);
  overflow: hidden;
  position: relative;
}

.ad-preview-sponsored-product-carousel-mobile-image-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 1;
}

.ad-preview-sponsored-product-carousel-mobile-image {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  background-size: cover;
  background-position: center;
}

.ad-preview-sponsored-product-carousel-mobile-content {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  text-align: center;
  width: 99.9% !important;
  height: 99.9% !important;
  padding-bottom: 25px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}


.ad-preview-sponsored-product-carousel-mobile-title {
  font-size: 2em;
  margin: 0;
  z-index: 3;
}

.ad-preview-sponsored-product-carousel-mobile-price {
  font-size: 1.25em;
  z-index: 3;
}

.ad-preview-sponsored-product-carousel-mobile-button {
  background-color: var(--primary-color) !important;
   color: var(--text-color);
  padding: 5px 25px !important;
  border: none;
  border-radius: var(--border-radius-full) !important;
  cursor: pointer;
  font-size: 16px;
  margin: 25px 25px !important;
  text-decoration: none;
  transition: background-color 0.3s;
  z-index: 3;
}

.ad-preview-sponsored-product-carousel-nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
   color: var(--text-color);
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  z-index: 3;
}

.ad-preview-sponsored-product-carousel-prev {
  left: 10px;
}

.ad-preview-sponsored-product-carousel-next {
  right: 10px;
}

.ad-preview-sponsored-product-carousel-nav-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

/* Map ad slider preview */
.ad-preview-map-product-slider-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}

.ad-preview-map-product-slider-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0f0f0;
  border-radius: var(--border-radius-md);
  overflow: hidden;
  position: relative;
}

.ad-preview-map-product-slider-item {
  background-position: center center;
  background-size: cover;
  aspect-ratio: 4 / 5;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
 color: var(--text-color);;
  border-radius: var(--border-radius-md);
  overflow: hidden;
  position: relative;
}

.ad-preview-map-product-slider-image-container {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ad-preview-map-product-slider-image {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: var(--border-radius-md);
}

.ad-preview-map-product-slider-content {
  background: rgba(0, 0, 0, 0.3);
  border-radius: var(--border-radius-sm);
  padding: 20px;
  text-align: center;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 1;
}

.ad-preview-map-product-slider-title {
  font-size: 1.2em;
  margin: 0;
}

.ad-preview-map-product-slider-price {
  font-size: 1em;
  margin: 0;
}

.ad-preview-map-product-slider-button {
  background-color: var(--primary-color);
 color: var(--text-color);;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.ad-preview-sponsored-shops-container {
  padding: 20px;
    background-color: rgba(250, 250, 250, 0.2);
    border-left: 1px solid rgba(250, 250, 250, 0.6);
    border-right: 1px solid rgba(250, 250, 250, 0.6);
    margin: 10px auto;
    max-width: 320px;
}

.ad-preview-sponsored-shops-list {
  width: 100%;
  text-align: center;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: var(--border-radius-sm);
  overflow: hidden;
  background-color: #444;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  aspect-ratio: 1 / 1;
}

.ad-preview-sponsored-shops-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.ad-preview-sponsored-shops-image-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
   
}

.ad-preview-sponsored-shops-image {
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  z-index: 2;
}


.ad-preview-sponsored-shops-container:hover .ad-preview-sponsored-shops-image {
  filter: brightness(0.7);
}

.ad-preview-sponsored-shops-content {
  position: absolute;
  bottom: 15px;
  left: 15px;
   color: var(--text-color);
  text-align: left;
  background-color: rgba(0, 0, 0, 0.4); 
  padding: 10px;
  border-radius: 5px;
}

.ad-preview-sponsored-shops-info { 
  position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
     color: var(--text-color);
    opacity: 1;
    transition: opacity 0.3s ease;
    z-index: 2;
}

.ad-preview-sponsored-shops-title {
  font-size: 1.5em;
  margin-bottom: 5px;
   color: var(--text-color);
}

.ad-preview-sponsored-shops-link {
  background: var(--primary-color);
  padding: 8px 16px;
  border-radius: var(--border-radius-full);
   color: var(--text-color);
  text-decoration: none;
  cursor: pointer;
  transition: background 0.3s ease;
}

.ad-preview-sponsored-shops-link:hover {
  background: #138c1f;
}

.ad-preview-sponsored-shops-dismiss {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
   color: var(--text-color);
  font-size: 1.2em;
  cursor: pointer;
}
.ad-preview-sponsored-shops-cta {
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
}

/* Sponsored brands preview */

.ad-preview-sponsored-brands-container {
  padding: 20px;
    background-color: rgba(250, 250, 250, 0.2);
    border-left: 1px solid rgba(250, 250, 250, 0.6);
    border-right: 1px solid rgba(250, 250, 250, 0.6);
    margin: 10px auto;
    max-width: 320px;
}

.ad-preview-sponsored-brands-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.ad-preview-sponsored-brands-item {
  background-position: center center;
  background-size: cover;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
 color: var(--text-color);;
  border-radius: var(--border-radius-md);
  overflow: hidden;
  position: relative;
  aspect-ratio: 1/1;
}

.ad-preview-sponsored-brands-image-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  aspect-ratio: 1/1;
}

.ad-preview-sponsored-brands-image {
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  z-index: 2;
}

.ad-preview-sponsored-brands-info {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
   color: var(--text-color);
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); 
  padding: 10px;
  border-radius: 5px;
  z-index: 2;
  width: 100%;
  justify-content: space-between;
}

.ad-preview-sponsored-brands-name {
font-size: 20px;
padding-bottom: 5px;
}

.ad-preview-sponsored-brands-discover-link {
  background-color: var(--primary-color);
  padding: 10px;
  border-radius: var(--border-radius-lg);
}

/* Sponsored brand carousel preview */

.ad-preview-sponsored-brands-carousel-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.ad-preview-sponsored-brands-carousel-item {
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
   color: var(--text-color);
  border-radius: 15px;
  overflow: hidden;
  position: relative;
}

.ad-preview-sponsored-brands-carousel-image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.ad-preview-sponsored-brands-carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ad-preview-sponsored-brands-carousel-content {
  z-index: 2;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.ad-preview-sponsored-brands-carousel-title {
  font-size: 2em;
  margin: 0;
}

.ad-preview-sponsored-brands-carousel-desctiption {
  font-size: 1em;
  margin: 0;
}

.ad-preview-sponsored-brands-carousel-button {
  background-color: var(--primary-color);
 color: var(--text-color);;
  padding: 10px 20px;
  border-radius: var(--border-radius-full);
  cursor: pointer;
  margin-top: 15px;
  transition: background-color 0.3s;
}

.ad-preview-sponsored-brands-carousel-button:hover {
  background-color: #2ba600;
}


/* Sponsored feed preview */

.ad-preview-sponsored-feed-products-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.ad-preview-sponsored-feed-products-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  text-align: center;
}

.ad-preview-sponsored-feed-products-item {
  background-color: rgba(84, 82, 82, 0.5);
  border: 1px solid #ddd;
  border-radius: var(--border-radius-sm);
  overflow: hidden;
  margin: 10px 0;
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1;
}

.ad-preview-sponsored-feed-products-image-container {
  position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 1;
}


.ad-preview-sponsored-feed-products-image {
  height: auto;
  transition: transform 0.3s ease;
  position: absolute;
}

.ad-preview-sponsored-feed-products-content {
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
   color: var(--text-color);
  position: absolute;
  z-index: 5;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
}
.ad-preview-sponsored-feed-products-content-info {
  display: flex;
  flex-direction: column;
}
.ad-preview-sponsored-feed-products-content-button {
padding: 5px
}

.ad-preview-sponsored-feed-products-title {
  font-size: 18px;
  margin-top: 10px;
  font-weight: 600;
}

.ad-preview-sponsored-feed-products-price {
  font-size: 16px;
  margin: 5px 0;
  font-weight: bold;
}

.ad-preview-sponsored-feed-products-store-link {
  font-size: 14px;
  color: var(--text-color);
  background-color: var(--secondary-color);
  text-decoration: none;
  padding: 8px 20px;
  border-radius: var(--border-radius-full);
  font-weight: 500;
  margin-top: 10px;
  margin-left: 60px;
  transition: background-color 0.3s ease;
}

@media (max-width: 768px) {
  .ad-preview-sponsored-feed-products-store-link {
    margin-left: 0px;
  }
}

.ad-preview-sponsored-feed-products-store-link:hover {
  background-color: #e0a400;
}

.ad-preview-sponsored-feed-products-item:hover .ad-preview-sponsored-feed-products-image {
  transform: scale(1.05);
}




/* AdFormatIconRow  */
.ad-format-icon-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: var(--border-radius-sm);
  border: 1px solid var(--border-color);
  background-color: var(--background-color);
  overflow: hidden;
  overflow-x: auto;
}

@media (max-width: 768px) {
  .ad-format-icon-row {
    padding: 0px;
  }
}

.ad-format-icon-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 15px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.ad-format-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 5px;
  margin-right: 10px;
  margin-left: 10px;
}

.ad-format-label {
  font-size: 10px;
  text-align: center;
  line-height: 14px;
}

/* New styles for the active state */
.ad-format-icon-item.active {
  transform: scale(1.1);
  background-color: var(--background-color);
  border-radius: var(--border-radius-sm);
  padding: 10px;
}

@media (max-width: 768px) {
  .ad-format-content {
    display: flex;
    flex-direction: column;
  }
}



/* Create custom ad component */

.create-custom-ad-container {
  padding: 20px;
  background-color: rgba(250, 250, 250, 0.2);
  border-radius: var(--border-radius-lg);
  border: 1px solid rgba(250, 250, 250, 0.6);
  margin: auto;
  color: var(--text-color);
}

.create-custom-ad-container h2 {
  text-align: center;
  margin-bottom: 5px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 10px;
}

.form-group input[type="text"],
.form-group input[type="number"] {
  width: 100%;
  padding: 10px;
  border-radius: var(--border-radius-lg);
  border: 1px solid rgba(250, 250, 250, 0.6);
  background-color: var(--background-color);
  color: var(--text-color);
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  background-color: var(--background-color);
  padding: 10px;
  border-radius: var(--border-radius-sm);
}

.product-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: var(--border-radius-sm);
  background-color: rgba(250, 250, 250, 0.2);
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s;
}

.product-item.selected {
  background-color: var(--primary-color);
}

.product-item img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.create-ad-button {
  width: 100%;
  padding: 10px 20px;
  background-color: var(--primary-color);
  border: none;
  border-radius: var(--border-radius-full);
   color: var(--text-color);
  font-size: 16px;
  cursor: pointer;
  flex-grow: 1;
  transition: background-color 0.3s ease;
}


.create-ad-button:hover {
  background-color: #145d10;
}

/* Container for the entire page */
.shop-create-advertisement-campaign-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden; 
}

/* Layout that includes the dashboard sidebar */
.ad-dashboard-layout {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

/* Content section with grid layout */
.shop-create-advertisement-campaign-content {
  display: grid;
  grid-template-columns: 1fr 3fr;
  flex-grow: 1;
  padding: 20px;
  overflow: hidden;
  gap: 25px !important;
  margin-left: 50px;
}


/* Sidebar container */
.shop-create-advertisement-campaign-sidebar {
  background-color: var(--background-color);
  border: 1px solid rgba(250, 250, 250, 0.6);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px; 
  height: 100%; 
  overflow-y: auto; 
}

/* Main content container */
.shop-create-advertisement-campaign-main {
  display: flex;
  flex-direction: column;
  
  height: 100%;
  overflow-y: auto; 
}

/* Box for creating a campaign */
.shop-create-advertisement-campaign-box {
 
  border-radius: var(--border-radius-sm);
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  overflow: hidden;
  
  align-content: center;
  padding: 20px;
}
.shop-create-advertisement-campaign-box-modal {
 width: 75%;
}

.shop-create-advertisement-campaign-box:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Campaign start button */
.shop-create-advertisement-campaign-button {
  display: block;
  padding: 15px;
  background-color: var(--primary-color);
  color: var(--text-color);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
  transition: background-color 0.3s ease;
}

.shop-create-advertisement-campaign-button:hover {
  background-color: #3d6000;
}

/* Campaign title */
.shop-create-advertisement-campaign-title {
  border-top: 1px solid rgba(250, 250, 250, 0.3);
  padding-top: 10px;
  font-size: 22px;
  color: var(--text-color);
  padding-bottom: 10px;
  text-align: center;
  font-weight: bold;
}

/* Options for selecting ad types */
.shop-create-advertisement-campaign-options {
  padding: 10px 0;
  border-top: 1px solid rgba(250, 250, 250, 0.3);
}

/* Individual option item */
.shop-create-advertisement-campaign-option-item {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  padding: 20px;
  border-radius: 60px;
  cursor: pointer;
  border: 1px solid var(--border-color);
  width: 160px;
  height: 160px;
  justify-content: center;
}

.shop-create-advertisement-campaign-option-item:hover {
  background-color: rgba(250, 250, 250, 0.15);
}
.shop-create-advertisement-campaign-option-item.selected {
  box-shadow: 0 0 12px 7px rgba(54, 211, 0, 0.7); 
  transform: scale(1.05); 
}

.shop-create-advertisement-campaign-option-item input {
  margin-right: 10px;
  transform: scale(1.2);
  cursor: pointer;
}

.shop-create-advertisement-campaign-option-item label {
  font-size: 16px;
  cursor: pointer;
  color: var(--text-color);
}

/* Expanded sections for selected ad types */
.shop-create-advertisement-campaign-expanded-sections {
  padding-top: 20px;
  border-top: 1px solid rgba(250, 250, 250, 0.3);
  animation: fadeIn 0.3s ease-in-out;
}

/* Responsive Design */
@media (max-width: 768px) {
  .shop-create-advertisement-campaign-content {
    grid-template-columns: 1fr;
    margin-left: 0px;
  }

  .shop-create-advertisement-campaign-sidebar,
  .shop-create-advertisement-campaign-main {
    width: 100%; 
  }
}
@media (max-width: 480px) {
  .shop-create-advertisement-campaign-content {
    margin-bottom: 80px;
    padding: 0px;
  }
}

.shop-create-advertisement-campaign-options-row {
  display: flex;
  gap: 40px;
  align-items: center;
  margin-top: 20px;
  padding: 10px;
}

.shop-create-advertisement-campaign-option-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.shop-create-advertisement-campaign-icon-label-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.shop-create-advertisement-campaign-option-icon {
  font-size: 40px; /* Icon size */
  color: #5a5a5a; /* Icon color */
}

.shop-create-advertisement-campaign-option-label {
  font-size: 16px;
  font-weight: 500;
  margin-top: 5px;
}

.shop-create-advertisement-campaign-toggle-switch {
  display: flex;
  justify-content: center;
  align-items: center;
}

.shop-create-advertisement-campaign-switch-input {
  position: absolute;
  opacity: 0;
}

.shop-create-advertisement-campaign-switch-paddle {
  width: 50px;
  height: 25px;
  background-color: #ccc;
  border-radius: 15px;
  position: relative;
  cursor: pointer;
}

.shop-create-advertisement-campaign-switch-paddle:before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: var(--text-color);
  border-radius: 50%;
  top: 2.5px;
  left: 2.5px;
  transition: left 0.2s;
}

.shop-create-advertisement-campaign-switch-input:checked + .shop-create-advertisement-campaign-switch-paddle:before {
  left: 27px; 
  background-color: var(--primary-color); 
}

/* Toggle Switch Styles */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  
}

.switch-input {
  display: none;
}

.switch-paddle {
  display: flex;
  align-items: center;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--toggle-color);
  border: 1px solid var(--border-color);
  transition: 0.4s;
  border-radius: 34px;
}

.switch-paddle:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  top: 9%;
  left: 4px;
  bottom: 4px;
  background-color: var(--toggle-color);
  transition: 0.4s;
  border-radius: 50%;
  border: 1px solid var(--strong-border-color);
}

.switch-input:checked + .switch-paddle {
  background-color: var(--primary-color);
}

.switch-input:checked + .switch-paddle:before {
  transform: translateX(26px);
}

.switch-label {
  font-size: 16px;
  color: var(--text-color);
  vertical-align: middle;
}

/* Create Ad Steps component */

.create-ad-steps-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.create-ad-steps-content {
  width: 100%;
  max-width: 1200px; 
  display: flex;
  flex-wrap: wrap;
  align-items: center; 
  box-sizing: border-box;
  overflow: hidden;
}

.create-ad-flex {
  display: flex;
  align-items: center;
  flex-grow: 1; 
  justify-content: space-around; 
}

/* Gradient Background for the Step Container */
.create-ad-step {
  padding: 20px; 
  position: relative;
  overflow: hidden;
}


.create-ad-step::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(54, 211, 0, 0.8) 0%, rgba(54, 211, 0, 0) 100%);
  opacity: 0;
  transition: opacity 0.8s ease; 
  z-index: 0;
}

.create-ad-step.past::before {
  background-color: rgba(54, 211, 0, 0.8); 
  opacity: 1;
}

.create-ad-step.active::before {
  opacity: 1; 
}

.create-ad-step-button {
  padding: 0 20px;
  height: 38px;
  border-radius: var(--border-radius-full);
  border: 1px solid rgba(250, 250, 250, 0.6);
  background-color: rgba(0, 0, 0, 0.2); 
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0; 
  z-index: 1; 
}

.create-ad-step-text {
 color: var(--text-color);;
  font-size: 16px;
  font-weight: 600;
}

.create-ad-step-text.past-text {
 color: var(--text-color);;
}
.create-ad-step-text.inactive-text {
  color: rgba(250, 250, 250, 0.6); 
}

.create-ad-line {
  width: 100%;
  height: 4px;
  background-color: #fde1e6;
  flex-grow: 1; 
}

.create-ad-line.active {
  background-color: #f63b60;
}

@media (min-width: 800px) {
  .create-ad-line {
    height: 4px;
    width: auto;
    flex-grow: 1;
  }
}

.create-ad-button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%; 
  box-sizing: border-box;
}

.create-ad-start-button {
  background-color: var(--primary-color);
   color: var(--text-color);
  border-radius: var(--border-radius-full);
}

.create-ad-previous-button {
  display: inline-block;
  width: 200px;
  padding: 10px 20px;
  background-color: #6c757d;
   color: var(--text-color);
  border-radius: var(--border-radius-full);
  text-align: center; 
    white-space: nowrap
}

.create-ad-next-button {
  background-color: var(--primary-color);
  border-radius: var(--border-radius-full);
   color: var(--text-color);
}

.create-ad-complete-button {
  background-color: #28a745;
   color: var(--text-color);
  border-radius: var(--border-radius-full);
}



/* ad campaign preview component */
.ad-campaign-ad-preview-container {
  background-color: var(--background-color);
  border-radius: var(--border-radius-sm);
  border: 1px solid var(--border-color);
  max-width: 900px;
  margin: 0 auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Title styling */
.ad-campaign-ad-preview-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
 color: var(--text-color);;
}

/* Empty message styling */
.ad-campaign-ad-preview-empty-message {
  text-align: center;
  color: #999;
}

/* List styling */
.ad-campaign-ad-preview-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Item container styling */
.ad-campaign-ad-preview-item {
  background-color: var(--background-color);
  padding: 20px;
  border-radius: var(--border-radius-sm);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

/* Item title styling */
.ad-campaign-ad-preview-item-title {
  font-size: 20px;
  margin-bottom: 15px;
  color: #444;
}

/* Details section styling */
.ad-campaign-ad-preview-details p {
  margin: 5px 0;
  font-size: 16px;
  color: #555;
}

/* Images container styling */
.ad-campaign-ad-preview-images {
  margin-top: 15px;
}

.ad-campaign-ad-preview-image-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.ad-campaign-ad-preview-image-item p {
  margin-right: 10px;
  font-size: 14px;
  color: #666;
}

.ad-campaign-ad-preview-image-item img {
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

/* Edit button styling */
.ad-campaign-ad-preview-edit-button {
  margin-top: 15px;
  padding: 10px 20px;
  background-color: var(--primary-color);
   color: var(--text-color);
  border: none;
  border-radius: var(--border-radius-full);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.ad-campaign-ad-preview-edit-button:hover {
  background-color: #3d6000;
}

.shop-create-advertisement-checkout-summary {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid rgba(250, 250, 250, 0.6);
  border-radius: var(--border-radius-sm);
  background-color: var(--background-color);
}

.shop-create-advertisement-checkout-summary p {
  margin: 5px 0;
  font-size: 16px;
}


/* Top performing ads component */
.top-performing-ads-container {
  background-color: var(--background-color);
  padding: 20px;
  border-radius: var(--border-radius-sm);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.top-performing-ads-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
 color: var(--text-color);;
  text-align: center;
}

.top-performing-ads-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.top-ad-card {
  display: flex;
  background-color: var(--background-color);
  border: 1px solid rgba(250, 250, 250, 0.6);
  border-radius: var(--border-radius-sm);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  transition: transform 0.2s ease-in-out;
}

.top-ad-card:hover {
  transform: translateY(-5px);
}

.top-ad-rank {
  width: 70px;
  font-size: 32px;
  font-weight: bold;
  color: var(--primary-color);
  padding: 20px;
  background-color: rgba(250, 250, 250, 0.2);
  border-right: 2px solid var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-ad-content {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 10px;
}

.top-ad-image img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 20px;
}

.top-ad-details {
  flex-grow: 1;
  position: relative;
  margin-left: 5px; 
}

.top-ad-name {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin-bottom: 5px;
}

.top-ad-description {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}

.top-ad-stats {
  display: flex;
  gap: 15px;
  font-size: 14px;
 color: var(--text-color);;
}

/* Ellipsis icon */
.top-ad-ellipsis {
  position: absolute;
  top: -18px;
  right: 1px;
  cursor: pointer;
  font-size: 1.5em;
 color: var(--text-color);; 
  z-index: 1; 
  padding: 5px; 
}


/* Modal styles */
.top-performing-ad-details-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.top-performing-ad-details-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.top-performing-ad-details-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5em;
 color: var(--text-color);;
  cursor: pointer;
}

.rerun-ad-button {
  top: 10px;
  left: 10px;
  background-color: var(--primary-color);
 color: var(--text-color);;
  padding: 8px 16px;
  border: none;
  border-radius: var(--border-radius-full);
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.rerun-ad-button:hover {
  background-color: #28a200;
}

.top-performing-ad-details-content {
  position: relative;
  background: rgba(0, 0, 0, 0.8); 
  border: 1px solid var(--border-color);
  padding: 20px;
  border-radius: var(--border-radius-sm);
  z-index: 1001;
  max-width: 800px;
  width: 90%;
 color: var(--text-color);;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  max-height: 90%;
}

.top-performing-ad-details-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5em;
 color: var(--text-color);;
  cursor: pointer;
}

.top-performing-ad-details-content h3 {
  margin-top: 30px;
  color: #f63b60; 
}

.ad-details-section {
  display: grid;
  grid-template-columns: 1fr 1fr; 
  gap: 16px; 
  color: var(--text-color); 
  margin: 20px 0; 
}

.ad-details-item {
  border-left: 2px solid rgba(255, 255, 255, 0.6); 
  padding-left: 16px; 
  display: flex;
  flex-direction: column;
  gap: 8px; 
}

.ad-details-name {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7); 
}

.ad-details-value {
  font-size: 20px;
  font-weight: 600; 
  color: var(--text-color);
}


.ad-details-list {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.ad-details-list li {
  margin: 5px 0;
}

.ad-details-list a {
  color: var(--primary-color); 
  text-decoration: none;
}

.ad-details-list a:hover {
  text-decoration: underline;
}

/* Graph styles */
.ad-details-graph {
  margin-top: 20px;
  background-color: var(--background-color);
  padding: 20px;
  border-radius: var(--border-radius-sm);
}

/* Campaign Totals */
.campaign-totals-container {
  padding: 10px;
  border-radius: var(--border-radius-sm);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  width: 25%;
  margin-top: 18px;
}

.campaign-totals-header {
  font-size: 1.25rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}

.campaign-totals-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.campaign-totals-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--background-color);
  padding: 16px;
  border-radius: var(--border-radius-sm);
  border: 1px solid var(--border-color);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.campaign-totals-item-title {
  font-size: 0.875rem;
  font-weight: 500;
  color: #888;
  margin-bottom: 8px;
}

.campaign-totals-item-value {
  font-size: 1.75rem;
  font-weight: 600;
}

.shop-create-advertisement-campaign-top {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  flex: 2;
}

.ad-overview-row {
  background-color: rgba(250, 250, 250, 0.2);
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 12px;
  border: 1px solid var(--border-color);
  display: flex;
  align-items: center;
}

.ad-overview-content {
  display: flex;
  align-items: center;
}

.ad-overview-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: var(--border-radius-sm);
  margin-right: 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.ad-overview-details {
  flex-grow: 1;
}

.ad-overview-details h4 {
  margin-bottom: 8px;
  font-size: 1.2em;
  font-weight: bold;
}

.ad-overview-details p {
  margin: 4px 0;
}

.ad-overview-details button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: var(--primary-color);
   color: var(--text-color);
  border: none;
  border-radius: var(--border-radius-full);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.ad-overview-details button:hover {
  background-color: #0056b3;
}

.w-full img.w-full.object-cover {
    border-bottom: 1px solid rgba(250, 250, 250, 0.2) !important;
}


.shop-info-sidebar-top-row {
    background-color: #fafafa00 !important;
    border: none !important;
    /* border-bottom: 1px solid #fafafa80 !important; */
    padding-bottom: 15px !important;
    padding-top: 25px !important;
}


/*TEMP*/


.other-followers-list-widget-wrapper {
    background-color: transparent !important;
    border-radius: 8px;
    box-shadow: none !important;
    padding: 15px !important;
}

.shop-info-sidebar-middle-row {
    border: 1px solid var(--border-color) !important;
    border-radius: 15px !important;
    padding: 15px !important;
    padding-top: 0px !important;
    background-color: var(--background-color) !important;
}

.other-followers-list-widget-search {
    background-color: rgba(250, 250, 250, 0.2) !important;
    border: 1px solid rgba(250, 250, 250, 0.5) !important;
    border-radius: var(--border-radius-full) !important;
    margin-bottom: 1.5rem;
    padding: .5rem;
    width: 100%;
    padding-left: 15px !important;
}


.categories-dropdown-header-product-shop {
    color: rgba(250, 250, 250, 0.9) !important;
    font-size: 15px !important;
    font-weight: 600 !important;
}


.shop-info-sidebar-bottom-row {
    align-items: center;
    border-top: none !important;
    display: flex;
    flex-direction: column;
    gap: 0px !important;
    margin-top: 5px !important;
    padding-top: 10px !important;
}

.connections-widget-container {
    background-color: transparent !important;
    border: none !important;
    border-radius: 8px;
    box-shadow: 0 2px 10px #0000001a;
    padding: 0px !important;
}

.category-section-component-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
    padding: 0px !important;
}

.category-section-component-bg {
    background-color: rgba(250, 250, 250, 0.0) !important;
}

.trending-products-carousel-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 100% !important;
    padding: 0px !important;
}



.carousel-4-slides-component-paper {
    align-items: center;
    background-position: 50%;
    background-size: cover;
   color: var(--text-color);;
    display: flex;
    flex-direction: column;
    height: 400px;
    justify-content: center;
    position: relative;
    border-radius: 15px !important;
}

a.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.css-1hw9j7s {
    background-color: var(--secondary-color) !important;
    border-radius: var(--border-radius-full) !important;
    margin: 10px !important;
    padding: 0px 0px !important;
}

.map-page-shop-item-avatar {
    border: 2px solid #fff;
    border-radius: 50%;
    height: 88px !important;
    left: 16px;
    object-fit: cover;
    position: absolute;
    top: 16px;
    width: 88px !important;
}

.map-product-ad-slider-paper {
    align-items: center;
    background-position: 50%;
    background-size: cover;
    border-radius: 15px !important;
   color: var(--text-color);;
    display: flex;
    height: 200px;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: 200px;
}

.events-page-aside {
    background-color: rgba(250, 250, 250, 0.9) !important;
    border-left: 1px solid var(--border-color);
    height: 100vh;
    padding: 20px;
    width: 25%;
}

.events-page-create-event-button {
    background-color: var(--primary-color);
    border: none;
    border-radius: var(--border-radius-full);
   color: var(--text-color);;
    cursor: pointer;
    padding: 7px 25px !important;
    transition: background-color .3s;
}

.crypto-wallet-sidebar-panel {
    background-color: #303335;
    box-shadow: 0 .5rem 1rem #00000080;
    display: flex;
    flex-direction: column;
    max-width: 28rem;
    overflow-y: auto;
    position: relative;
    width: 100%;
    border-left: 1px solid var(--border-color);
}

.map-page-shop-item {
    background-color: rgba(0, 0, 0, 0.4) !important;
    border-radius: 8px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    border: 1px solid rgba(250, 250, 250, 0.5) !important;
}

.text-gray-700 {
    --tw-text-opacity: 1;
    border: none !important;
    border-radius: 0 !important;
    color: #fafafa !important;
    padding: 7px 15px !important;
    border-radius: var(--border-radius-full) !important;
}

.crypto-wallet-sidebar-panel {
    background-color: rgba(0, 0, 0, 0.9) !important;
    border-left: 1px solid var(--border-color);
    box-shadow: 0 .5rem 1rem #00000080;
    display: flex;
    flex-direction: column;
    max-width: 28rem;
    overflow-y: auto;
    position: relative;
    width: 100%;
}

aside.main-feed-page-sticky-sidebar.hidden.lg\:block.w-full.lg\:w-1\/4.shrink-0.bg-\[\#f5f5f5\].p-2.rounded-lg.lg\:order-1.main-feed-page-scrollable {
    padding: 15px !important;
}



.profile-main {
  display: grid;
  grid-template-columns: 25% 47% 25%; 
  gap: 20px; 
  padding-left: 7%;
  padding-right: 7%;
  width: 100% !important;
  align-items: center !important;
  margin-top: 0px !important;
}


@media (max-width: 768px) {
  .profile-main {
    display: block;
    z-index: 5;
    position: relative;
  }
}


.profile-extra-container {
  grid-column: 1 / 2; 
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-sm);
  height: 310px;
  overflow: hidden;
}

.profile-extra-image {
  max-width: 100%;
  border-radius: var(--border-radius-sm); 
  object-fit: cover; 
}

.profile-mycontent-carousel {
  grid-column: 3 / 4; 
  display: flex;
  justify-content: flex-end;
}


/* Dubs additions */

.newsletter-signup-input {
  border: none;
  border-radius: var(--border-radius-full) !important;
  color: #2d3748;
  margin-bottom: 1rem;
  outline: none;
  padding: .625rem;
  padding-left: 15px !important;
  width: 100%;
}

.css-dejdd {
  border: 2px solid rgba(250, 250, 250, 0.7) !important;
  border-radius: 100% !important;
  height: 60.5px !important;
  width: 60.5px !important;
}

img.h-10.w-10.rounded-full {
  border: 2px solid rgba(250, 250, 250, 0.7) !important;
}

.post-widget-image {
  border-radius: .75rem;
  margin-top: .5rem;
  border: 1px solid rgba(250, 250, 250, 0.2) !important;
}

p.MuiTypography-root.MuiTypography-body1.css-5vij8j span {
  margin: 1rem 5px 0px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: var(--primary-color) !important;
}


/* FollowersAvatarDisplay */

.followers-avatar-display-container {
  display: flex;
  align-items: center;
  gap: 16px; 
}
@media (max-width: 480px) {
  .followers-avatar-display-container {
    align-items: center;
    display: flex;
    gap: 16px;
   border-radius: 0px; 
    padding: 5px 10px;
    width: 100%;
    justify-content: center;
    border-top: 1px solid #666;
    border-bottom: 1px solid #666;
  }
}

.followers-avatar-display-images {
  display: flex;
  position: relative;
  padding: 4px;
  margin-right: -8px;
  overflow: hidden;
}

.followers-avatar-display-image {
  z-index: 1;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid white;
  margin-left: -8px; 
}

.followers-avatar-display-image:first-child {
  margin-left: 0;
  overflow: hidden;
}

.followers-avatar-display-count {
  font-size: 12px;
  font-weight: 600;
  color: #fff;
}

/* Main header */

.main-header-component-container {
  padding: 25px 20px;
  background-color: var(--header-background);
  position: sticky;
  top: 0;
  z-index: 500;
  border-bottom: 1px solid var(--border-color) ;
}

@media (max-width: 768px) {
  .main-header-component-container {
    display: none; /* Hide main header on mobile */
  }}

.main-header-component-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  margin-left: 50px;
  
}

.main-header-component-site-logo {
  margin-top: 12px;
  cursor: pointer;
  max-width: 150px;
}

.main-header-component-categories {
  position: relative;
  height: 60px;
  margin-top: 10px;
  width: 270px;
  display: flex;
  align-items: center;
  background-color: #fafafa1a !important;
    border: 1px solid #fafafa80 !important;
    border-bottom: none !important;
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
    bottom: 0;
    margin-bottom: -25px !important;
    margin-left: 15px !important;
    margin-right: 15px !important;
}

.main-header-component-menu-icon {
  position: absolute;
  top: 15px;
  left: 10px;
}

.main-header-component-categories-button {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px; 
  font-family: sans-serif;
  font-size: 25px;
  font-weight: 700; 
  background-color: transparent !important; 
  border: none;
  border-radius: 5px;
  color: var(--text-color);
  cursor: pointer;
}


.main-header-component-arrow-icon {
  position: absolute;
  right: 10px;
  top: 20px;
  cursor: pointer;
}

.main-header-component-actions {
  display: flex;
  align-items: center;
}

.main-header-component-action {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.main-header-component-wishlist,
.main-header-component-cart {
  position: relative;
  cursor: pointer;
}

.main-header-component-wishlist-icon {
  color: var(--text-color)
}

.main-header-component-wishlist-count,
.main-header-component-cart-count {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #3bc177;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
   color: var(--text-color);
  font-size: 12px;
  border-radius: 50%;
}

.main-header-component-avatar {
  position: relative;
}

.main-header-component-avatar-img {
  border-radius: 100%;
  display: block;
  max-height: 45px;
  max-width: 45px;
  border: 2px solid var(--strong-border-color);
}


.main-header-component-mobile-header {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);  
  position: fixed; 
  top: 0;
  left: 0;
  z-index: 50;
  border-bottom: 1px solid rgba(250, 250, 250, 0.4);
  display: flex;  
  align-items: center;
  backdrop-filter: blur(3px);
}

.main-header-component-mobile-header-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;  
}


.main-header-component-mobile-menu-icon {
  cursor: pointer;
}

.main-header-component-mobile-logo {
  margin-top: 3px;
  cursor: pointer;
  max-width: 160px;
}

.main-header-component-mobile-cart {
  position: relative;
  cursor: pointer;
}

.main-header-component-mobile-cart-count {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #3bc177;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
   color: var(--text-color);
  font-size: 12px;
  border-radius: 50%;
}

.main-header-component-sidebar {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 20;
  position: fixed;
  top: 0;
  left: 0;
}

.main-header-component-sidebar-inner {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.9);
  padding-bottom: 80px;
  position: fixed;
}

.main-header-component-sidebar-top {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
}

.main-header-component-wishlist-icon {
  position: relative;
  cursor: pointer;
}

.main-header-component-close-icon {
  cursor: pointer;
}

.main-header-component-search-box {
  margin: 15px;
  position: relative;
}

.main-header-component-search-input {
  width: auto;
  height: 40px;
  padding: 0 60px;
  border: 1px solid var(--border-color) !important;
  border-radius: var(--border-radius-full) !important;
  background-color: rgba(255, 255, 255, 0.2);
   color: var(--text-color);
}

@media (max-width: 768px) {
  .main-header-component-search-input {
    padding: 0 30px;
    width: 100%;
  }
}

.main-header-component-search-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.main-header-component-clear-search-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.main-header-component-search-dropdown {
  position: absolute;
  top: 45px;
  width: 100%;
  background-color: black;
  padding: 10px;
  z-index: 9;
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.main-header-component-search-item {
  display: flex;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.main-header-component-search-item:hover {
  background-color: #3bc177;
}

.main-header-component-search-item-img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 10px;
}

.main-header-component-search-item-title {
  font-size: 16px;
   color: var(--text-color);
  font-weight: 600;
}

.main-header-component-search-item-details {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.main-header-component-search-item-brand,
.main-header-component-search-item-shop {
  font-size: 12px;
  font-weight: 600;
   color: var(--text-color);
  padding: 2px 6px;
  border-radius: 5px;
  margin-right: 5px;
}

.main-header-component-search-item-brand {
  background-color: blue;
}

.main-header-component-search-item-shop {
  background-color: green;
}

.main-header-component-become-seller {
  margin: 20px;
  text-align: center;
}

.main-header-component-become-seller-text {
   color: var(--text-color);
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-header-component-become-seller-icon {
  margin-left: 5px;
}

.main-header-component-mobile-user-actions {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.main-header-component-login-link,
.main-header-component-signup-link {
  font-size: 18px;
  color: black;
  margin-right: 10px;
}

.main-header-component-avatar-menu {
  position: relative;
  display: inline-block;
}

.main-header-component-avatar-dropdown {
  position: absolute;
  right: 0;
  top: 70px;
  width: 150px;
  background-color: var(--background-color);
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.main-header-component-avatar-dropdown-content {
  padding: 10px;
}

.main-header-component-avatar-dropdown-item {
  display: block;
  padding: 10px;
  color: black;
  font-size: 14px;
  text-decoration: none;
}

.main-header-component-avatar-dropdown-item-active {
  background-color: gray;
   color: var(--text-color);
}

/* Hide the mobile header by default (visible on mobile only) */
.main-header-component-mobile-header {
  display: none; /* Hidden by default */
}

/* Show mobile header only on screens smaller than 800px */
@media (max-width: 768px) {
  .main-header-component-mobile-header {
    display: block;
  }

  .main-header-component-wrapper {
    display: none; /* Hide the desktop header on mobile */
  }
}

/* Ensure the desktop header is shown on larger screens */
@media (min-width: 800px) {
  .main-header-component-wrapper {
    display: flex; /* Show the desktop header on larger screens */
  }
}

.main-header-component-cart-count, .main-header-component-wishlist-count {
  align-items: center;
  background-color: var(--primary-color) !important;
  border-radius: 50%;
 color: var(--text-color);;
  display: flex;
  font-size: 12px;
  height: 16px;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 16px;
}

body {
  background-image: url(https://res.cloudinary.com/dzlopmfj8/image/upload/v1721666994/banners/lbdsruo3aq4jxh4q2sle.jpg) !important;
}



.newsletter-signup-highlight {
  color: var(--primary-color) !important;
}

.dual-product-ad-carousel-button {
  background-color: var(--secondary-color) !important;
  border-radius: var(--border-radius-full);
 color: var(--text-color);;
  cursor: pointer;
  font-size: 16px;
  padding: 5px 20px !important;
  text-decoration: none;
  transition: background-color .3s;
}

.newsletter-signup-button {
  background-color: var(--primary-color) !important;
  border-radius: var(--border-radius-full) !important;
 color: var(--text-color);;
  cursor: pointer;
  padding: 10px 25px !important;
  transition: background-color .3s;
}

.newsletter-signup-input {
  border: none;
  border-radius: var(--border-radius-full) !important;
  color: #2d3748;
  margin-bottom: 0px !important;
  outline: none;
  padding: .625rem;
  padding-left: 15px !important;
  width: 100%;
}

.newsletter-signup-container {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7) !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.75rem 5rem;
}

.quad-product-ad-carousel-button {
  background-color: var(--secondary-color) !important;
  border-radius: var(--border-radius-full);
 color: var(--text-color);;
  cursor: pointer;
  font-size: 16px;
  padding: 5px 20px !important;
  text-decoration: none;
  transition: background-color .3s;
}

.event-card-component-calendar-buttons {
  background: rgba(250, 250, 250, 0.2) !important;
  border-radius: var(--border-radius-full) !important;
  display: flex;
  gap: 10px;
  margin-top: 10px;
  padding: 10px 25px !important;
}

.event-card-component-title {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  text-transform: capitalize !important;
}

.events-page-aside {
  background-color: rgba(0, 0, 0, 0.7) !important;
  border-left: 1px solid var(--border-color);
  height: 100vh;
  padding: 20px;
  width: 25%;
}

.my-event-card-component-title {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  text-transform: capitalize !important;
}



.my-event-card-component-button {
  background-color: var(--primary-color);
  border: none;
  border-radius: var(--border-radius-full);
  box-shadow: 3px 3px #0000004d;
 color: var(--text-color);;
  cursor: pointer;
  font-size: 16px;
  margin: auto !important;
  padding: 5px 20px !important;
  transition: background-color .3s ease;
}

.my-event-card-component-date, .my-event-card-component-location, .my-event-card-component-time {
  align-items: center;
  color: #fffc;
  display: flex;
  font-size: 14px;
  margin: 5px 0;
  text-transform: capitalize !important;
}

.event-card-component-description {
  font-size: 16px;
  color: #d0d0d0;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.my-events-title {
  color: rgba(250, 250, 250, 0.8) !important;
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 5px;
  text-align: center;
}

.events-page-create-event-button {
  background-color: var(--primary-color);
  border: none;
  border-radius: var(--border-radius-full);
 color: var(--text-color);;
  cursor: pointer;
  padding: 7px 25px !important;
  transition: background-color .3s;
  margin: auto;
  text-align: center;
}

.my-event-card-component-button, .my-event-card-component-button a:hover {
  background-color: var(--primary-color);
  border: none;
  border-radius: var(--border-radius-full);
  box-shadow: 3px 3px #0000004d;
 color: var(--text-color);;
  cursor: pointer;
  font-size: 16px;
  padding: 5px 20px !important;
  transition: background-color .3s ease;
}

.my-event-card-component-calendar-buttons {
  background: rgba(0, 0, 0, 0.7) !important;
  border-radius: var(--border-radius-full) !important;
  display: flex;
  gap: 10px;
  margin-top: 10px;
  padding: 5px 25px !important;
}

.events-page-select {
  background-color: rgba(0, 0, 0, 0.8) !important;
  border: 1px solid rgba(250, 250, 250, 0.5) !important;
  border-radius: var(--border-radius-full) !important;
  color: #ffffff !important;
  font-size: 16px;
  padding: 5px 25px !important;
  transition: border-color .3s;
}

.map-page-listbox-options {
  background-color: rgba(0, 0, 0, 0.7) !important;
  border: 1px solid var(--border-color) !important;
  border-radius: 4px;
  box-shadow: 0 4px 8px #0000001a;
  margin-top: 8px;
  max-height: 240px;
  overflow-y: auto;
  position: absolute;
  z-index: 10;
}

.map-page-listbox-button {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7) !important;
  border: 1px solid rgba(250, 250, 250, 0.5) !important;
  border-radius: var(--border-radius-full) !important;
  color: #ffffff !important;
  display: flex;
  font-size: .875rem;
  font-weight: 500;
  padding: 8px 12px;
}

/* My content carousel */

.my-content-carousel-container {
  position: relative;
  overflow: hidden;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-sm);
  aspect-ratio: 1;
  width: 100%;
  background-color: var(--background-color) !important;
  margin-bottom: 25px !important;
}


.my-content-carousel-content {
  display: flex;
  transition: transform 0.5s ease-in-out;
  height: 100%;
}

.my-content-carousel-item {
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: opacity 0.5s ease-in-out;
}

.my-content-carousel-item.active {
  opacity: 1;
}

.my-content-carousel-video-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.my-content-carousel-image-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.my-content-carousel-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 8px;
  text-align: center;
   color: var(--text-color);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.my-content-carousel-title {
  font-size: 1rem;
  margin-bottom: 5px;
}

.my-content-carousel-description {
  font-size: 0.8rem;
  margin-bottom: 10px;
}

.my-content-carousel-button {
  background-color: #ff5722;
   color: var(--text-color);
  padding: 5px 10px;
  text-decoration: none;
  border-radius: 15px;
  transition: background-color 0.3s ease;
  font-size: 0.8rem;
  width: 50%;
}

.my-content-carousel-button:hover {
  background-color: #e64a19;
}

.my-content-carousel-control {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 15px !important;
   color: var(--text-color);
  background-color: rgba(250, 250, 250, 0.2) !important;
  border: none;
  padding: 0px !important;
  z-index: 1;
  transition: background-color 0.3s ease;
  border-radius: 100% !important;
  height: 25px !important;
  width: 25px !important;
}


.my-content-carousel-control.left {
  left: 5px;
}

.my-content-carousel-control.right {
  right: 5px;
}

.my-content-carousel-control:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

/* Meme slide container */
.meme-slide {
  background-color: var(--background-color);
  position: relative;
  height:100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Meme slide title */
.meme-slide-title {
  font-size: 0.5rem;
  margin-bottom: 10px;
}

/* Meme slide button container */
.meme-slide-button-container {
  width: 100%;
  background-color: transparent; 
  text-align: center;
  padding: 10px 0;
  z-index: 1000000;
  display: block;
  position: absolute;
  bottom: 20px;
}


/* Meme slide button */
.meme-create-button {
  padding: 10px 20px;
  background-color: var(--primary-color);
 color: var(--text-color);;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.meme-create-button:hover {
  background-color: #0056b3;
}

/* Modal container */
.meme-modal {
  display: block;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: var(--modal-overlay-background);
  backdrop-filter: var(--modal-overlay-backdrop-filter);
}

.meme-modal-content {
  background-color: rgba(0, 0, 0, 0.8); 
  margin: 10% auto;
  padding: 25px; 
  border: 1px solid var(--border-color); 
  width: 80%;
  max-width: 600px;
  border-radius: var(--border-radius-lg);
}

.meme-plus-button {
  position: absolute;
  bottom: 45px; 
  right: 10px;
  width: 35px; 
  height: 35px; 
  background-color: rgba(0, 0, 0, 0.8);
   color: var(--text-color);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-full); 
  font-size: 18px; 
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s, transform 0.2s;
  top: 0; 
  padding-top: 0px; 
  margin-top: 0px; 
  line-height: 0; 
}


.meme-plus-button:hover {
  background-color: var(--primary-color); 
  transform: scale(1.05); 
}


/* Close button */
.meme-close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.meme-close:hover,
.meme-close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}


/* Meme creator */
.meme-creator {
  max-width: 100%;
  margin: 0 auto;
  padding: 0px;
  border: none;
  border-radius: var(--border-radius-sm);
  background-color: transparent;
}

.meme-creator h2 {
  text-align: center;
  margin-bottom: 20px;
}

.meme-upload-section {
  text-align: center;
  margin-bottom: 20px;
}

.meme-upload-label {
  cursor: pointer;
  padding: 10px 20px;
  background-color: rgba(250, 250, 250, 0.3);
 color: var(--text-color);;
  border-radius: 5px;
  display: inline-block;
}

.meme-text-input {
  width: 100%;
  padding: 10px 0px 10px 15px;
  margin-bottom: 20px;
  border: 1px solid rgba(250, 250, 250, 0.5);
  color: #ffffff !important;
  border-radius: var(--border-radius-full) !important;
  background-color: var(--background-color);
}


.meme-preview {
  position: relative;
  text-align: center;
  margin-bottom: 20px;
}

.meme-preview-image {
  max-width: 100%;
  border-radius: 5px;
}

.meme-text-overlay {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
   color: var(--text-color);
  font-size: 24px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-weight: bold;
}

.meme-submit-button {
  width: 50%;
  padding: 10px;
  background-color: var(--primary-color);
   color: var(--text-color);
  border: none;
  border-radius: var(--border-radius-full);
  cursor: pointer;
  margin: auto;
  display: block;
  font-weight: 700;
  font-size: 18px;
}


.meme-submit-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.meme-error-message {
  color: red;
  margin-bottom: 20px;
  text-align: center;
}

.meme-styling-controls {
  display: flex;
  gap: 15px;
  padding: 20px;
  border-radius: var(--border-radius-sm);
  background-color: var(--background-color);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  justify-content: center;
  font-family: Arial, sans-serif;
}

.meme-styling-controls label {
  font-size: 14px;
  font-weight: 600;
 color: var(--text-color);;
  display: flex;
  align-items: center;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.meme-styling-controls input[type="number"],
.meme-styling-controls input[type="color"],
.meme-styling-controls select {
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-md);
  font-size: 14px;
  transition: all 0.3s ease;
 color: var(--text-color);;
  background-color: rgba(250, 225, 250, 0.1);
}

.meme-styling-controls input[type="number"]:hover,
.meme-styling-controls input[type="color"]:hover,
.meme-styling-controls select:hover {
  border-color: var(--primary-color);
}

.meme-styling-controls input[type="number"]:focus,
.meme-styling-controls input[type="color"]:focus,
.meme-styling-controls select:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 8px rgba(54, 211, 0, 0.3);
}

.meme-styling-controls select {
  padding-right: 15px;
}

.meme-styling-controls label input[type="color"] {
  padding: 4px;
  width: 50px;
  height: 35px;
  cursor: pointer;
}

/* Meme gallery */
.meme-gallery-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--modal-overlay-background);
  backdrop-filter: var(--modal-overlay-backdrop-filter);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.meme-gallery-modal-content {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.meme-gallery-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.meme-gallery-slideshow {
  display: flex;
  align-items: center;
}

.meme-gallery-nav-button {
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.75);
  font-size: 25px;
  cursor: pointer;
  padding: 10px;
  user-select: none;
}

.meme-gallery-nav-button:hover {
 color: var(--text-color);;
}

.meme-gallery-thumbnail-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 5px;
  overflow-x: auto;
  width: 100%;
}

.meme-gallery-thumbnail-small {
  width: 60px;
  height: 60px;
  cursor: pointer;
  object-fit: cover;
  border-radius: 4px;
  opacity: 0.6;
  transition: opacity 0.2s, transform 0.2s;
}

.meme-gallery-thumbnail-small:hover,
.meme-gallery-thumbnail-small.active {
  opacity: 1;
  transform: scale(1.1);
}

.meme-gallery-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 8px;
  position: relative;
}

.placeholder-image {
  width: 100%;
  height: 100%;
  margin-bottom: 16px;
  opacity: 0.6;
}

.placeholder-text {
  font-size: 18px;
  color: #888;
  position: absolute;
  bottom: 45px;
}



/* About user */

.about-user-container {
  background-color: var(--background-color);
  border-radius: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: solid 1px rgba(250, 250, 250, 0.4);
  padding: 20px;
  margin: 0 auto;
}

.about-user-header {
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e7eb;
}

.about-user-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1f2937;
}

.about-user-subtitle {
  margin-top: 4px;
  font-size: 0.875rem;
  color: #6b7280;
}

.about-user-content {
  padding-top: 20px;
}

.about-user-details {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
}

.about-user-row {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 12px;
}

.about-user-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--primary-color);
}

.about-user-value {
  font-size: 0.875rem;
  color: #4b5563;
}

/* Shop about */

/* Container for the entire shop about section */
.shop-about-container {
  background-color: var(--background-color);
  padding: 20px;
  border-radius: 15px;
  border: 1px solid var(--border-color);
  max-width: 700px;
  margin: 0 auto;
}

/* Header of the about section */
.shop-about-header {
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e7eb;
}

.shop-about-title {
  font-size: 1.125rem;
  font-weight: 600;
  color: #1f2937;
}

.shop-about-subtitle {
  margin-top: 4px;
  font-size: 0.875rem;
  color: #6b7280;
}

/* Content section */
.shop-about-content {
  margin-top: 20px;
}

.shop-about-details {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
  border-top: 1px solid #e5e7eb;
  padding-top: 20px;
}

.shop-about-detail-row {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 16px 0;
  border-bottom: 1px solid #e5e7eb;
}

.shop-about-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
}

.shop-about-value {
  font-size: 0.875rem;
  color: #4b5563;
}

/* Actions section for the shop owner */
.shop-about-actions {
  text-align: right;
  margin-top: 20px;
}

.shop-about-action-button {
  padding: 10px 15px;
  background-color: #3b82f6;
  color: var(--text-color);
  border-radius: 6px;
  text-decoration: none;
  font-size: 0.875rem;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.shop-about-action-button:hover {
  background-color: #2563eb;
}

/*User Banner */
.user-banner-container {
  position: relative;
  width: 100%;
  aspect-ratio: 4 / 1;
  border-bottom: 1px solid rgba(250, 250, 250, 0.4);
  overflow: hidden;
  min-height: 360px;
}

.user-banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover; 
  object-position: center;
}

.user-banner-controls {
  position: absolute;
  right: 15px;
  top: calc(100% - 3rem); 
  display: flex;
  align-items: center;
  gap: 16px;
  z-index: 20;
}

.user-banner-controls-save {
  width: 200px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    right: 20px;
    bottom: 0px;
    z-index: 20;
}

.shop-banner-controls-save {
  width: 200px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    right: 20px;
    bottom: 0px;
    z-index: 20;
}

.user-banner-edit-icon {
  width: 42px;
  height: 42px;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid var(--border-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.user-banner-file-input {
  display: none;
}

.user-banner-camera-icon {
   color: var(--text-color);
  font-size: 1.5rem;
  cursor: pointer;
}

.user-banner-save-button {
  padding: 8px 16px;
  background-color: rgba(0, 0, 0, 0.5);
   color: var(--text-color);
  border: none;
  border-radius: var(--border-radius-full);
  border: 1px solid var(--border-color);
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.user-banner-save-button:hover {
  background-color: #2563eb;
}

.user-avatar-save-button {
  padding: 8px 16px;
  background-color: rgba(0, 0, 0, 0.5);
   color: var(--text-color);
  border: none;
  border-radius: var(--border-radius-full);
  border: 1px solid var(--border-color);
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.user-avatar-save-button:hover {
  background-color: #2563eb;
}

@media (max-width: 1024px) {
  .user-banner-container {
    aspect-ratio: unset; 
    min-height: 360px; 
    height: 360px; 
  }

  .user-banner-image {
    width: 100%;
    height: 100%;
    object-fit: cover; 
    object-position: center; 
  }
}

@media (max-width: 768px) {
  .user-banner-container {
    aspect-ratio: unset; 
    min-height: 320px; 
    height: 320px; 
  }

  .user-banner-image {
    width: 100%;
    height: 100%;
    object-fit: cover; 
    object-position: center; 
  }
}
@media (max-width: 480px) {
  .user-banner-container {
    aspect-ratio: unset; 
    min-height: 280px; 
    height: 280px; 
  }

  .user-banner-image {
    width: 100%;
    height: 100%;
    object-fit: cover; 
    object-position: center; 
  }
}
/* Shop Banner */
.shop-banner-container {
  position: relative;
  width: 100%;
  aspect-ratio: 4 / 1;
  border-bottom: 1px solid rgba(250, 250, 250, 0.3);
  overflow: hidden;
  min-height: 320px;
}

@media (max-width: 768px) {
  .shop-banner-container {
    position: relative;
    width: 100%;
    margin-top: 8vh;
  }
  .user-banner-container {
    position: relative;
    width: 100%;
    margin-top: 8vh;
   
  }
}

.shop-banner-image {
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease; 
}

.shop-banner-controls {
  position: absolute;
  right: 15px;
  top: calc(100% - 3rem); 
  display: flex;
  align-items: center;
  gap: 16px;
  z-index: 5;
  cursor: pointer;
}

.shop-banner-edit-icon {
  width: 42px;
  height: 42px;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid var(--border-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.shop-banner-file-input {
  display: none;
}

.shop-banner-camera-icon {
   color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
}

.shop-banner-save-button {
  padding: 8px 16px;
  background-color: #3b82f6;
   color: var(--text-color);
  border: none;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.shop-banner-save-button:hover {
  background-color: #2563eb;
}


@media (max-width: 768px) {
  .shop-banner-container {
    aspect-ratio: unset; 
    min-height: 280px; 
    height: 280px; 
  }

  .shop-banner-image {
    width: 100%;
    height: 100%;
    object-fit: cover; 
    object-position: center; 
  }
}

/* My room component */

.myroom-carousel-container {
  position: relative;
  overflow: hidden;
  border: 1px solid var(--border-color);
  border-radius: 15px;
  height: 310px;
  width: 100%;
}

.myroom-carousel-content {
  display: flex;
  transition: transform 0.5s ease-in-out;
  height: 100%;
}

.myroom-carousel-item {
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: opacity 0.5s ease-in-out;
}

.myroom-carousel-item.active {
  opacity: 1;
}

.myroom-carousel-image-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.myroom-carousel-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 8px;
  text-align: center;
   color: var(--text-color);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.myroom-carousel-title {
  font-size: 1rem;
  margin-bottom: 5px;
}

.myroom-carousel-description {
  font-size: 0.8rem;
  margin-bottom: 10px;
}

.myroom-carousel-button {
  background-color: #ff5722;
   color: var(--text-color);
  padding: 5px 10px;
  text-decoration: none;
  border-radius: 15px;
  transition: background-color 0.3s ease;
  font-size: 0.8rem;
  width: 50%;
}

.myroom-carousel-button:hover {
  background-color: #e64a19;
}

.myroom-carousel-control {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5rem;
   color: var(--text-color);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  padding: 8px;
  z-index: 1;
  transition: background-color 0.3s ease;
}

.myroom-carousel-control.left {
  left: 5px;
}

.myroom-carousel-control.right {
  right: 5px;
}

.myroom-carousel-control:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.sidebar-badge {
  border-radius: 9999px;
  color: #fff !important;
  font-size: .75rem;
  font-weight: 700;
  padding: .25rem;
  position: absolute;
  right: -.55rem;
  top: -.25rem;
  height: 20px !important;
  width: 20px !important;
}

.main-header-component-categories {
  align-items: center;
  background-color: var(--background-color) !important;
  border: 1px solid rgba(250, 250, 250, 0.5) !important;
  border-bottom: none !important;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  bottom: 0;
  display: flex;
  height: 60px;
  margin-bottom: -30px !important;
  margin-left: 15px !important;
  margin-right: 15px !important;
  margin-top: 10px;
  position: relative;
  width: 270px;
}

.my-images-component-image-grid {
  grid-gap: 10px;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  padding: 0px !important;
}

/*My images profile */

/* MyImagesProfile.css */

/* Container Styling */
.my-images-profile-component {
  margin: 0 auto;
  margin-bottom: 25px;
  padding: 10px;
  border: none ;
  border-radius: 0px;
  background-color: transparent;
  min-height: 200px;
}

/* Header Styling */
.my-images-profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px !important;
}

.my-images-profile-header h3 {
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  margin-top: 0px !important;
}

/* Image Grid Styling */
.my-images-profile-image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2x2 grid */
  gap: 10px;
}

.my-images-profile-thumbnail {
  aspect-ratio: 1;
  cursor: pointer;
  object-fit: cover;
  border-radius: 4px;
  transition: transform 0.2s;
  border: 1px solid var(--border-color);
}

.my-images-profile-thumbnail:hover {
  transform: scale(1.05);
}

/* Modal Overlay Styling */
.my-images-profile-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--modal-overlay-background);
  backdrop-filter: var(--modal-overlay-backdrop-filter);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content Styling */
.my-images-profile-modal-content {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Close Button Styling */
.my-images-profile-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
 color: var(--text-color);;
}

/* Gallery Styling */
.my-images-profile-gallery {
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-images-profile-gallery-image {
  max-width: 100%;
  max-height: 80vh;
  margin: 0 20px;
  border-radius: 4px;
}

/* Navigation Button Styling */
.my-images-profile-nav-button {
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.7);
  font-size: 25px;
  cursor: pointer;
  user-select: none;
  padding: 10px;
  transition: color 0.2s ease;
}

.my-images-profile-nav-button:hover {
 color: var(--text-color);;
}

.my-images-profile-nav-button-left {
  margin-right: 20px;
}

.my-images-profile-nav-button-right {
  margin-left: 20px;
}

/* Thumbnail Navigation Styling */
.my-images-profile-thumbnail-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 20px;
  overflow-x: auto;
  width: 100%;
  position: absolute;
  bottom: 0;
}


.my-images-profile-thumbnail-small {
  width: 60px;
  height: 60px;
  cursor: pointer;
  object-fit: cover;
  border-radius: 4px;
  opacity: 0.6;
  transition: opacity 0.2s, transform 0.2s;
  border: 1px solid var(--border-color);
}

.my-images-profile-thumbnail-small:hover,
.my-images-profile-thumbnail-small.active {
  opacity: 1;
  transform: scale(1.1);
}

/* MyShopImagesProfile.css */

/* Container Styling */
.my-shop-images-profile-component {
  margin: 0 auto;
  margin-bottom: 25px !important;
  padding: 15px !important;
  
  border-radius: var(--border-radius-sm);
  background-color: transparent !important;
  min-height: 200px !important;
}

/* Header Styling */
.my-shop-images-profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px !important;
}

.my-shop-images-profile-header h3 {
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  margin-top: 0px !important;
}

/* Image Grid Styling */
.my-shop-images-profile-image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2x2 grid */
  gap: 10px;
}

.my-shop-images-profile-thumbnail {
  aspect-ratio: 1;
  cursor: pointer;
  object-fit: cover;
  border-radius: 4px;
  transition: transform 0.2s;
  border: 1px solid var(--border-color);
}

.my-shop-images-profile-thumbnail:hover {
  transform: scale(1.05);
}

/* Modal Overlay Styling */
.my-shop-images-profile-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--modal-overlay-background);
  backdrop-filter: var(--modal-overlay-backdrop-filter);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content Styling */
.my-shop-images-profile-modal-content {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Close Button Styling */
.my-shop-images-profile-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
 color: var(--text-color);;
}

/* Gallery Styling */
.my-shop-images-profile-gallery {
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-shop-images-profile-gallery-image {
  max-width: 100%;
  max-height: 80vh;
  margin: 0 20px;
  border-radius: 4px;
}

/* Navigation Button Styling */
.my-shop-images-profile-nav-button {
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.7);
  font-size: 25px;
  cursor: pointer;
  user-select: none;
  padding: 10px;
  transition: color 0.2s ease;
}

.my-shop-images-profile-nav-button:hover {
 color: var(--text-color);;
}

.my-shop-images-profile-nav-button-left {
  margin-right: 20px;
}

.my-shop-images-profile-nav-button-right {
  margin-left: 20px;
}

/* Thumbnail Navigation Styling */
.my-shop-images-profile-thumbnail-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 5px;
  overflow-x: auto;
  width: 100%;
}

.my-shop-images-profile-thumbnail-small {
  width: 60px;
  height: 60px;
  cursor: pointer;
  object-fit: cover;
  border-radius: 4px;
  opacity: 0.6;
  transition: opacity 0.2s, transform 0.2s;
  border: 1px solid var(--border-color);
}

.my-shop-images-profile-thumbnail-small:hover,
.my-shop-images-profile-thumbnail-small.active {
  opacity: 1;
  transform: scale(1.1);
}

/* See All Button Styling */
.see-all-button {
  color: var(--primary-color);
  background: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.user-profile-data-tab-inactive {
  color: #ffffff !important;
}

span.main-header-component-cart-count {
  background-color: var(--secondary-color) !important;
}

.trending-products-carousel-bg {
  background-color: transparent !important;
}

li.trending-products-carousel-item li {
  border-bottom: none !important;
}



.followers-following-panel-panel {
  background-color: transparent !important;
  border: none !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  height: 100%;
  max-width: 100% !important;
  pointer-events: auto;
}

.marketplace-hero-slide-button {
background-color: var(--primary-color) !important;
padding: 15px 25px !important;
border-radius: var(--border-radius-full) !important;
  cursor: pointer !important;
}

.category-section-component-browse-link {
  background-color: rgba(250, 250, 250, 0.2) !important;
  border: 1px solid rgba(250, 250, 250, 0.5) !important;
  border-radius: var(--border-radius-full) !important;
  color: #fff !important;
  display: none;
  font-size: 16px !important;
  font-weight: 600;
  margin-bottom: 25px !important;
  padding: 10px 25px !important;
  transition: color .3s ease;
  cursor: pointer !important;
}

.trending-products-carousel-link-hidden {
  background-color: rgba(250, 250, 250, 0.2) !important;
  border: 1px solid rgba(250, 250, 250, 0.5) !important;
  border-radius: var(--border-radius-full) !important;
  color: #fff !important;
  display: none;
  font-size: 16px !important;
  font-weight: 600;
  margin-bottom: 25px !important;
  padding: 10px 25px !important;
  transition: color .3s ease;
  cursor: pointer !important;
}

.followers-following-panel-close-button {
  background-color: rgba(250, 250, 250, 0.2) !important;
  border: 1px solid rgba(250, 250, 250, 0.5) !important;
  border-radius: var(--border-radius-full) !important;
 color: var(--text-color);;
  position: relative;
  padding: 7px !important;
  cursor: pointer !important;
}

.wishlist-component-close-button {
  display: flex;
  justify-content: flex-end;
  position: absolute !important;
  right: 15px !important;
  top: 15px !important;
  width: 40px !important;
  cursor: pointer !important;
}

.cart-sidebar-close-btn-container {
  background-color: rgba(250, 250, 250, 0.2) !important;
  border: 1px solid rgba(250, 250, 250, 0.5) !important;
  border-radius: var(--border-radius-full) !important;
 color: var(--text-color);;
  position: relative;
  padding: 7px !important;
  cursor: pointer !important;
}


.homepage-hero-slide {
  border: 1px solid rgba(250, 250, 250, 0.5) !important;
  border-radius: 10px !important;
}

h1.newsletter-signup-title {
  margin-bottom: 15px !important;
}

.newsletter-signup-input {
  border: 1px solid rgba(250, 250, 250, 0.5) !important;
  border-radius: var(--border-radius-full) !important;
  color: #2d3748;
  margin-bottom: 0 !important;
  outline: none;
  padding: .625rem;
  padding-left: 15px !important;
  width: 100%;
  background-color: rgba(250, 250, 250, 0.2) !important;
}

/*---- WALLET------*/


.crypto-wallet-action-button.buy {
  background-color: rgb(54, 211, 0);
  color: #ffffff !important;
  cursor: pointer !important;
}

.crypto-wallet-transfer-button {
  background-color: rgb(255, 181, 17) !important;
  color: #ffffff !important;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  border-radius: var(--border-radius-full);
  flex: 1 1 0%;
  margin: 0px 0.25rem;
  padding: 7px 15px !important;
  cursor: pointer !important;
}

.crypto-wallet-offer-accept-button {
  background-color: rgb(255, 181, 17) !important;
  color: #ffffff !important;
  cursor: pointer;
  font-size: 14px;
  margin-top: 0.5rem;
  text-align: center;
  width: 100%;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  border-radius: 15px;
  padding: 4px 20px;
  cursor: pointer !important;
}

.crypto-wallet-action-button.buy {
  background-color: rgb(255, 181, 17) !important;
  color: #ffffff !important;
  cursor: pointer !important;
}

.crypto-wallet-section-title.crypto-wallet-bottom-bar {
  display: none !important;
}

.crypto-wallet-actions {
  padding: 0px !important;
}

.cart-sidebar-checkout-btn {
  background-color: rgb(255, 181, 17) !important;
  cursor: pointer;
  text-decoration: none;
  border-radius: var(--border-radius-full) !important;
  cursor: pointer !important;
}

/*------END WALLET CODE-----*/


.avatar-dropdown-menu-close-btn-container, .cart-sidebar-close-btn-container, .followers-following-panel-close-button, .wishlist-component-close-button {
  border-radius: var(--border-radius-full) !important;
  padding: 7px !important;
  position: absolute !important;
  right: 15px !important;
  top: 15px !important;
  cursor: pointer !important;
}

.follower-list-widget-search {
  background-color: rgba(250, 250, 250, 0.2) !important;
  border: 1px solid rgba(250, 250, 250, 0.5) !important;
  border-radius: var(--border-radius-full) !important;
  box-sizing: border-box;
  margin-bottom: 15px !important;
  padding: 10px 25px !important;
  width: 100%;
}

.following-list-widget-search {
  background-color: rgba(250, 250, 250, 0.2) !important;
  border: 1px solid rgba(250, 250, 250, 0.5) !important;
  border-radius: var(--border-radius-full) !important;
  box-sizing: border-box;
  margin-bottom: 15px !important;
  padding: 10px 25px !important;
  width: 100%;
}


.product-details-component__delivery-badge, .product-details-component__pickup-badge {
  border-radius: var(--border-radius-full) !important;
  box-shadow: 0 4px 6px #0000001a;
 color: var(--text-color);;
  font-size: .875rem;
  font-weight: 700;
  padding: .25rem .75rem;
  background-color: var(--background-color);
  border: 1px solid var(--border-color) !important;
}


.suggested-product-container {
  padding: 0px !important;
  max-width: 1320px !important;
  margin: auto !important;
}

.product-details-component__shop-info {
  align-items: center;
  display: flex;
  padding-top: 25px !important;
  border-top: 1px solid rgba(250, 250, 250, 0.4) !important;
  margin-top: 25px !important;
}

.product-details-component__quantity-value {
  background-color: #edf2f7;
  color: #2d3748;
  font-weight: 500;
  padding: 10px 25px !important;
  border-radius: var(--border-radius-full) !important;
  text-align: center !important;
}

.product-details-component__visit-shop-button {
  background-color: var(--primary-color) !important;
  border-radius: var(--border-radius-full) !important;
  box-shadow: 0 4px 6px #0000001a;
  display: inline-block;
  padding: 15px 25px !important;
  text-align: center;
  transition: background-color .3s ease;
}

.newsletter-signup-input {
  border-radius: var(--border-radius-full) !important;
  color: #ffffff !important;
  margin-bottom: 0 !important;
  outline: none;
  padding: 15px 25px !important;
  width: 100%;
}

svg.product-card-component-cart-icon {
  color: #ffffff !important;
  width: 45px !important;
  padding: 10px !important;
  height: auto !important;
  margin-left: 10px !important;
  background-color: var(--secondary-color)!important;
  border-radius: var(--border-radius-full) !important;
}

.product-card-component-quantity-button {
  border: 1px solid rgba(250, 250, 250, 0.5) !important;
  padding: 0px !important;
  width: 35px !important;
  height: 35px !important;
  border-radius: var(--border-radius-full) !important;
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.product-card-component-quantity {
  margin: 0px 8px;
  height: 35px !important;
  width: 45px !important;
  text-align: center !important;
  color: #000000 !important;
  background-color: #ffffff !important;
  border-radius: var(--border-radius-full) !important;
  padding: 15px !important;
  text-align: center !important;
  padding-top: 5px !important;
}


.product-details-component__thumbnail {
  border-radius: 8px;
  cursor: pointer;
  height: 150px !important;
  margin-bottom: 8px;
  margin-right: 8px;
  object-fit: cover;
  transition: all .3s ease;
  width: auto;
}

.post-widget-reshare-textarea {
  border: 1px solid rgba(250, 250, 250, 0.5) !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  border-radius: 10px !important;
  margin-bottom: 15px !important;
  padding: 15px !important;
  width: 100%;
}

.post-widget-cancel-button {
  background-color: rgba(250, 250, 250, 0.5) !important;
  color: #374151 !important;
  float: left !important;
  margin-top: 10px !important;
}

.post-widget-reshare-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px !important;
}

.post-widget-reshare-buttons-container {
  display: flex;
  justify-content: space-between;
}

.map-page-map-container button {
  background-color: var(--primary-color) !important;
  border-radius: var(--border-radius-full) !important;
  cursor: pointer !important;
}

button.add-friend-button-button.following {
  background-color: rgba(0, 0, 0, 0.7) !important;
  border: 1px solid var(--border-color) !important;
  padding: 5px 15px !important;
}

.notification-sidebar-title {
  font-size: 18px !important;
  font-weight: 700 !important;
}

.notification-sidebar-header {
  padding: 0px 0px !important;
  border-bottom: 1px solid rgba(250, 250, 250, 0.4) !important;
  padding-bottom: 10px !important;
}

/* My events component - find events button */

.find-events-button {
  background-color: var(--primary-color); 
  color: var(--text-color); 
  padding: 10px 20px; 
  border: none; 
  border-radius: 15px; 
  font-size: 16px; 
  cursor: pointer; 
  text-align: center; 
  text-decoration: none;
  display: inline-block; 
  transition: background-color 0.3s ease; 
}

.find-events-button:hover {
  background-color: #3d6000; 
}

.find-events-button:focus {
  outline: none; 
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); 
}


.wishlist-component-item-add-to-cart {
  background-color: var(--secondary-color) !important;
  border-radius: var(--border-radius-full) !important;
  color: #fff !important;
  height: auto !important;
  margin-left: 10px !important;
  padding: 10px !important;
  width: 45px !important;
}

.my-events-title {
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  margin-bottom: 15px !important;
  margin-top: 0px !important;
  text-align: center;
}

.find-events-button {
  background-color: var(--primary-color);
  border: none;
  border-radius: var(--border-radius-full) !important;
 color: var(--text-color);;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  padding: 5px 25px !important;
  text-align: center;
  text-decoration: none;
  transition: background-color .3s ease;
  font-weight: 700 !important;
}

.profile-upload-container label svg {
  cursor: pointer !important;
}



.profile-upload-container label svg {
  cursor: pointer !important;
  text-align: center !important;
  position: absolute;
 color: var(--text-color);;
  font-size: 1.5rem;
}

.post-widget-reshare {
  border-bottom: 1px solid #ccc;
  margin-bottom: 25px;
  padding-bottom: 25px;
}

.individual-shop-products-filter-button {
  align-items: center;
  background: none;
  border: none;
  color: #ffffff !important;
  cursor: pointer;
  display: inline-flex;
  font-size: 14px;
  font-weight: 500;
}

.login-component-submit-button {
  align-items: center;
  background-color: var(--primary-color);
  border: none;
  border-radius: var(--border-radius-full) !important;
 color: var(--text-color);;
  cursor: pointer;
  display: flex;
  font-size: .875rem;
  font-weight: 500;
  height: 2.5rem;
  justify-content: center;
  transition: background-color .3s ease;
  width: 100%;
}

.login-component-input {
  background-color: rgba(250, 250, 250, 0.2) !important;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-full) !important;
  box-shadow: 0 1px 2px rgba(0,0,0,0.5) !important;
  font-size: 16px !important;
}

.profile-upload-container label svg {
  top: 5px !important;
  right: 0 !important;
  left: 5px !important;
  bottom: 0 !important;
}

/* Room creation form */

.room-creation-form-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: var(--background-color);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.room-creation-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.room-creation-form-group {
  margin-bottom: 15px;
}

.room-creation-form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.room-creation-form-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: var(--border-radius-lg);
  background-color: var(--background-color);
}

.room-creation-form-error {
  color: red;
  margin-top: 10px;
  text-align: center;
}

.room-creation-form-button {
  width: 100%;
  padding: 10px;
  background-color: var(--primary-color);
   color: var(--text-color);
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 16px;
}

.room-creation-form-button:disabled {
  background-color: #6c757d;
}

.room-creation-form-banner-preview {
  margin-top: 10px;
}

.room-creation-form-banner-preview img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}


/* Rooms container */

.rooms-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.rooms-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.rooms-list {
  list-style-type: none;
  padding: 0;
}

.rooms-list-item {
  padding: 15px;
  margin-bottom: 10px;
  background-color: var(--background-color);
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.rooms-list-item:hover {
  background-color: rgba(250, 250, 250, 0.3);
}

.room-name {
  font-size: 18px;
  font-weight: bold;
}

.room-description {
  margin-top: 5px;
  color: #6c757d;
}

/* rooms profile */

.rooms-profile-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-md);
}

.rooms-profile-create-room-button {
  background-color: var(--primary-color);
   color: var(--text-color);
  padding: 10px 20px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 20px;
}

.rooms-profile-create-room-button:hover {
  background-color: #0056b3;
}

.rooms-profile-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.rooms-profile-modal-content {
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 15px;
  border: 1px solid var(--border-color);
  max-width: 500px;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  position: relative;
}

.rooms-profile-close-modal-button {
  position: absolute;
  top: 16px;
  right: 24px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #999;
}

.rooms-profile-close-modal-button:hover {
  color: #333;
}

/* Room details */

.room-details-page {
  width: 100%;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.room-banner {
  position: relative;
  width: 100%;
  aspect-ratio: 4/1;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.room-banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
   color: var(--text-color);
  padding: 20px;
  box-sizing: border-box;
}

.room-name {
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 10px;
 color: var(--text-color);;
}

.room-description {
  font-size: 1.2em;
  color: #ddd;
  line-height: 1.6;
}

.room-details-content {
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius-sm);
  justify-items: center;
  align-items: center;
}

@media (max-width: 768px) {
  

  .room-details-content {
    margin: 10px;
    padding: 15px;
  }

  .room-name {
    font-size: 1.5em;
  }

  .room-description {
    font-size: 1em;
  }
}

.room-members {
  margin-top: 10px;
  margin-left: 120px;
  margin-right: 120px;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-sm);
  padding: 20px;
}

.room-members h3 {
  margin-bottom: 20px;
  font-size: 24px;
}

.room-members-container {
  display: flex;
}

.room-members-avatar-display {
  display: flex;
  align-items: center;
  gap: 16px; 
}

.room-member-avatar {
  z-index: 1;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid white;
  margin-left: -8px;
}

.room-member-avatar:first-child {
  margin-left: 0;
}

.room-members-count {
  font-size: 20px;
  font-weight: 600;
  margin-top: 10px;
  background-color: var(--background-color);
  padding: 10px;
  border-radius: 15px;
  border: 1px solid var(--border-color);
  margin-left: 20px;
}


/* Add members form */

.add-member-button {
  margin-top: 15px;
  padding: 10px 20px;
  background-color: var(--primary-color);
   color: var(--text-color);
  border: none;
  border-radius: 15px;
  cursor: pointer;
}

.add-member-button:hover {
  background-color: #218838;
}

.add-member-form-container {
  position: fixed; 
  top: 50%;       
  left: 50%;      
  transform: translate(-50%, -50%); 
  z-index: 1000;  
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-sm);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3); 
  max-width: 90%; 
  width: 500px;
}

.add-member-form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-member-form-title {
  font-size: 1.5em;
  color: #333;
}

.add-member-form-close-button {
  background: none;
  border: none;
  cursor: pointer;
}

.add-member-form-icon {
  width: 20px;
  height: 20px;
}

.add-member-form-tabs-nav {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.add-member-form-tab {
  padding: 10px 20px;
  cursor: pointer;
}

.add-member-form-tab-active {
  border-bottom: 2px solid #000;
}

.add-member-form-tab-inactive {
  color: #999;
}

.add-member-form-user-selection {
  margin-top: 20px;
}

.add-member-form-search {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-sm);
}

.add-member-form-list {
  max-height: 200px;
  overflow-y: auto;
}

.add-member-form-user-item label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.add-member-form-user-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.add-member-form-user-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

.add-member-form-actions {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.add-member-form-submit {
  padding: 10px 20px;
  background-color: #28a745;
   color: var(--text-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.add-member-form-submit:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Custom checkbox styling */
.add-member-form-user-item input[type="checkbox"] {
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #007bff;
  border-radius: 4px;
  background-color: var(--text-light);
  margin-right: 10px;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease-in-out;
}

.add-member-form-user-item input[type="checkbox"]:checked {
  background-color: #007bff;
  border-color: #007bff;
}

.add-member-form-user-item input[type="checkbox"]:checked::before {
  content: '\2713'; /* Checkmark character */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
   color: var(--text-color);
  font-weight: bold;
}

.add-member-form-user-item input[type="checkbox"]:hover {
  border-color: #0056b3;
}

.add-member-form-user-item input[type="checkbox"]:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
}



/* Campaign modal */

.campaign-creator-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--modal-overlay-background);
  backdrop-filter: var(--modal-overlay-backdrop-filter);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.campaign-creator-modal-content {
  background-color: var(--modal-content-background);
  padding: 20px;
  border: 1px solid var(--border-color);
  border-radius: 15px;
  max-width: 1200px;
  width: 100%;
  max-height: 90vh; 
  overflow-y: auto; 
  position: relative;
  scroll-behavior: smooth;
  
}

.campaign-creator-close-button {
  position: absolute;
  display: flex;
    align-items: center;
    justify-content: center;
  top: 10px;
  right: 10px;
  background-color: var(--background-color); 
  border: 1px solid var(--border-color);
  border-radius: 50%;
 color: var(--text-color);;
  width: 30px;
  height: 30px;
  font-size: 16px;
  cursor: pointer;
  padding: 8px;
  transition: background-color 0.3s ease, transform 0.2s ease; 
}

.campaign-creator-close-button:hover {
  background-color: rgba(250, 250, 250, 0.3); 
  transform: scale(1.1); 
}

.campaign-creator-close-button:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(255, 0, 0, 0.7); 
}


.shop-create-advertisement-campaign-inner-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    gap: 20px; 
  }

.sponsored-brands-widget-cover {
    border-bottom: 1px solid rgba(250, 250, 250, 0.4) !important;
    border-top: none !important;
}

.rooms-container h2 {
    margin-bottom: 20px;
    text-align: center;
    font-size: 18px !important;
    font-weight: 700 !important;
}

.rooms-profile-create-room-button:hover {
    background-color: var(--primary-color) !important;
}
.rooms-profile-create-room-button {
    border-radius: var(--border-radius-full) !important;
    font-weight: 700 !important;
}


.sponsored-brands-widget-title {
    color: #ebebeb !important;
    font-size: 10px !important;
}

.css-a4r3a9, .sidebar-badge {
    background-color: red !important;
}

span.main-header-component-cart-count {
    background-color: red !important;
}

.sponsored-brands-widget-name {
    font-size: 17px !important;
    font-weight: 500 !important;
    margin-top: 0px !important;
    margin-bottom: 15px !important;
}

/*.post-widget-comment-submit-button {
    background-color: var(--primary-color) !important;
    border-radius: var(--border-radius-full) !important;
    color: #ffffff !important;
    cursor: pointer !important;
    font-weight: 700;
    padding: 5px 25px !important;
    width: 21% !important;
    font-size: 17px !important;
    transition: background-color .2s ease-in-out;
}*/

span.main-header-component-cart-count {
    border-radius: var(--border-radius-full);
    color: #fff !important;
    font-weight: 700;
    height: 20px !important;
    width: 20px !important;
    padding: .25rem;
    position: absolute;
    align-items: center;
    background-color: red !important;
    display: inline-flex;
    justify-content: center;
    font-size: 12px;
    right: 0;
    top: 0;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.icon.css-vubbuv {
    margin-right: 7px !important;
}

.selected-options-display {
  display: flex;
  gap: 40px;
  margin-bottom: 10px;
}

.add-new-ad-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-color);
  border: 1px solid var(--primary-color);
  border-radius: 30px;
  margin-top: 10px;
  padding: 15px;
  width: 100%;
  transition: box-shadow 0.3s ease, background-color 0.3s ease;
}
.add-new-ad-button:hover {
  background-color: rgba(54, 211, 0, 0.1); 
  box-shadow: 0 0 15px 5px rgba(54, 211, 0, 0.7);
}

button.create-product-component-submit-button {
  border-radius: var(--border-radius-full) !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  margin-top: 0px !important;
}

.create-product-component-submit {
  margin-top: 0px !important;
}

select.create-product-component-select {
  border-radius: var(--border-radius-full) !important;
  margin-top: 25px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
}

h5.create-product-component-title {
  margin-bottom: 0px !important;
}

select.create-product-component-select {
  border-radius: var(--border-radius-full) !important;
  margin-top: 25px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
}

select.tag-dropdown {
  width: 100%;
    border-radius: var(--border-radius-full);
    background-color: rgba(250, 250, 250, 0);
}

.create-product-component-form-input, .create-product-component-form-textarea {
  border-radius: var(--border-radius-full) !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.w-full.flex.flex-col.items-center {
  margin: auto !important;
  margin-right: 25px !important;
}

textarea#description {
  border-radius: 15px !important;
}

.bulk-product-upload-modal {
  background-color: var(--background-color) !important;
  border: 1px solid var(--border-color) !important;
}

input.CSV-upload-component-file-input {
  border-radius: var(--border-radius-full) !important;
  background-color: var(--background-color) !important;
  cursor: pointer !important;
}
.shop-dashboard-sidebar-profile-switch {
  background-color: var(--background-color) !important;
  border-top: 1px solid rgba(250, 250, 250, 0.3) !important;
}

img.switcheroo-shop-component-avatar {
  border: 2px solid rgba(250, 250, 250, 0.7) !important;
}

img.shop-dashboard-header-avatar {
  border: 2px solid rgba(250, 250, 250, 0.7) !important;
}

svg.create-product-component-dropzone-icon {
  text-align: center !important;
  margin: auto !important;
}

/* MarketplacePage.css */

/* Product Grid */
.marketplace-page-product-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {
  .marketplace-page-product-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
  }
}

@media (min-width: 1024px) {
  .marketplace-page-product-grid {
    grid-template-columns: repeat(4, 1fr);
    gap: 25px;
  }
}

@media (min-width: 1280px) {
  .marketplace-page-product-grid {
    grid-template-columns: repeat(5, 1fr);
    gap: 30px;
  }
}

/* No products found text */
.marketplace-page-no-products-text {
  text-align: center;
  width: 100%;
  padding-bottom: 100px;
  font-size: 20px;
}

/* Grid layout for components below ShopBySeller */
.marketplace-page-grid-container {
  display: grid;
  grid-template-columns: 75% 25%; 
  margin-top: 20px;
}

/* Left container (75%) */
.marketplace-page-left-container {
  padding: 10px;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 15px;
  margin-right: 20px;
}

/* Right container (25%) */
.marketplace-page-right-container {
  padding-left: 25px !important;
  border-left: 1px solid var(--border-color); 
  margin-bottom: 0px !important;
}

/* Responsive for smaller screens */
@media (max-width: 1024px) {
  .marketplace-page-grid-container {
    display: block;
    margin-right: 10px;
    margin-left: 10px;
  }
  .marketplace-page-left-container {
    margin-right: 0px;
  }
}

/* Responsive for smaller screens */
@media (max-width: 768px) {
  .marketplace-page-grid-container {
    display: block;
    margin-right: 0px;
    margin-left: 0px;
  }
  .marketplace-page-right-container{
    display: none;
  }
}


.circle-shop-card {
  text-align: center;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 10px;
}

.circle-avatar-container {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  transition: transform 0.3s ease;
  border: 2px solid rgba(250, 250, 250, 0.4);
}

.circle-avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease; /* Added transition for smooth fade */
}

/* Hide the shop name initially */
.circle-shop-name {
  opacity: 0;
  font-size: 16px;
   color: var(--text-color);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none; /* Disable pointer events on the shop name */
  transition: opacity 0.3s ease;
  z-index: 2;
}

/* Show shop name on hover */
.circle-shop-card:hover .circle-shop-name {
  opacity: 1;
}

.circle-shop-card:hover .circle-avatar {
  opacity: 0.2; /* Dim the avatar on hover to make the text more visible */
}

/* Optional: make the avatar grow slightly on hover */
.circle-shop-card:hover .circle-avatar-container {
  transform: scale(1.05);
}

.suggested-connection-list {
  display: flex;

}

/* Dubs update sept 7 */

.shop-dashboard-sidebar-profile-switch {
  background-color: var(--background-color) !important;
  border-top: 1px solid rgba(250, 250, 250, 0.3) !important;
}
img.switcheroo-shop-component-avatar {
  border: 2px solid rgba(250, 250, 250, 0.7) !important;
}

img.shop-dashboard-header-avatar {
  border: 2px solid rgba(250, 250, 250, 0.7) !important;
}

svg.create-product-component-dropzone-icon {
  text-align: center !important;
  margin: auto !important;
}

/* MarketplacePage.css */

/* Product Grid */
.marketplace-page-product-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {
  .marketplace-page-product-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
  }
}

@media (min-width: 1024px) {
  .marketplace-page-product-grid {
    grid-template-columns: repeat(4, 1fr);
    gap: 25px;
  }
}

@media (min-width: 1280px) {
  .marketplace-page-product-grid {
    grid-template-columns: repeat(5, 1fr);
    gap: 30px;
  }
}

/* No products found text */
.marketplace-page-no-products-text {
  text-align: center;
  width: 100%;
  padding-bottom: 100px;
  font-size: 20px;
}


/* Styling for the circular seller cards */
.circle-shop-list {
  display: flex;
  margin: 0px auto auto !important;
  padding: 15px !important;
  overflow-x: auto !important;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  background-color: transparent !important;
  border: none !important;
  border-bottom: 1px solid rgba(250, 250, 250, 0.3) !important;
  border-radius: 0px !important;
  width: 100% !important;
  max-width: 100% !important;
}

/* Medium screens (tablets) */
@media (max-width: 1024px) {
  .circle-shop-list {
    margin-left: 60px;
    
  }
}

/* Small screens (mobile) */
@media (max-width: 768px) {
  .circle-shop-list {
    margin-left: 30px;
   
    padding: 0.75rem;
  }
}

/* Extra small screens (small mobile) */
@media (max-width: 480px) {
  .circle-shop-list {
    margin-left: 10px;
    margin-right: 10px;
    padding: 0.5rem;
  }
}

.circle-shop-card {
  text-align: center;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 10px;
}

.circle-avatar-container {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  transition: transform 0.3s ease;
  border: 2px solid rgba(250, 250, 250, 0.4);
}

.circle-avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease; /* Added transition for smooth fade */
}

/* Hide the shop name initially */
.circle-shop-name {
  opacity: 0;
  font-size: 16px;
   color: var(--text-color);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none; /* Disable pointer events on the shop name */
  transition: opacity 0.3s ease;
  z-index: 2;
}

/* Show shop name on hover */
.circle-shop-card:hover .circle-shop-name {
  opacity: 1;
}

.circle-shop-card:hover .circle-avatar {
  opacity: 0.2; /* Dim the avatar on hover to make the text more visible */
}

/* Optional: make the avatar grow slightly on hover */
.circle-shop-card:hover .circle-avatar-container {
  transform: scale(1.05);
}

.forgot-password-input {
    background-color: rgba(250, 250, 250, 0.2) !important;
    border: 1px solid rgba(250, 250, 250, 0.5) !important;
    border-radius: var(--border-radius-full) !important;
    font-size: 16px !important;
    padding: 15px 25px !important;
    color: #ffffff !important;
}

.forgot-password-button {
    background-color: var(--primary-color) !important;
    border-radius: var(--border-radius-full) !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    padding: 15px 25px !important;
}


.circle-shop-name {
   color: var(--text-color);;
    font-size: 14px;
    font-weight: 700 !important;
    left: 50%;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity .3s ease;
    z-index: 2;
    cursor: pointer !important;
}

.circle-shop-name {
   color: var(--text-color);;
    font-size: 14px;
    font-weight: 700 !important;
    left: 50%;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity .3s ease;
    z-index: 2;
    cursor: pointer !important;
}

.marketplace-page-left-container {
    background-color: transparent !important;
    border: none !important;
    border-radius: 0px !important;
    padding: 0px !important;
}

/*------BACKGROUND CHANGE------*/
.bg-black {
    --tw-bg-opacity: 1;
    background-color: transparent !important;
    border-bottom: none !important;
}

.css-ohwg9z {
    position: relative;
    overflow: hidden;
    border: 1px solid rgba(250, 250, 250, 0.5) !important;
    border-radius: 15px !important;
}

.sponsored-brand-ad-carousel-content {
    border: 1px solid rgba(250, 250, 250, 0.5) !important;
}



.circle-avatar-container {
    width: 100px !important;
    height: 100px !important;
}

.sponsored-dashboard-filter-row {
    padding: 10px !important;
}

.sponsored-dashboard-time-buttons-row {
    padding: 10px !important;
}

.sponsored-dashboard-filter-item span {
    font-size: 12px !important;
    color: #ffffff !important;
}

.sponsored-dashboard-filter-item.active {
    background-color: var(--background-color) !important;
    border: 2px solid rgba(250, 250, 250, 0.5) !important;
    color: #ffffff !important;
}

.sponsored-dashboard-time-filter-item.active {
    background-color: var(--background-color) !important;
    border: 2px solid rgba(250, 250, 250, 0.5) !important;
    color: #FFFFFF !important;
}

.sponsored-dashboard-time-filter-icon {
    color: #BBBBBB !important;
    font-size: 25px !important;
    margin-bottom: 8px;
}

.sponsored-dashboard-time-filter-item span {
    font-size: 11px !important;
    font-weight: 600;
    color: #ffffff !important;
}

.sponsored-dashboard-filter-item span {
    font-size: 11px !important;
    font-weight: 600;
    color: #ffffff !important;
}

.sponsored-dashboard-filter-icon {
    color: #BBBBBB !important;
    font-size: 25px !important;
    margin-bottom: 8px;
}

.sponsored-dashboard-filter-item {
    padding: 10px !important;
    transition: all .3s ease;
    min-width: 80px !important;
    height: 80px !important;
}

.shop-create-advertisement-campaign-sidebar {
    padding: 15px !important;
    gap: 15px !important;
}

.create-ad-next-button {
    border-radius: var(--border-radius-full) !important;
    font-weight: 500 !important;
    display: inline-block;
    width: 200px;
    padding: 10px 20px;
    text-align: center; 
    white-space: nowrap
}

.create-ad-next-button.disabled {
  background-color: var(--background-color);
  cursor: not-allowed;
}

.create-ad-button-group {
    margin-top: 25px !important;
    width: 25% !important;
    float: right !important;
}

.create-ad-steps-content {
    flex-wrap: wrap;
    max-width: 1200px;
    overflow: hidden;
    border-radius: 100px !important;
    border: none !important;
}

.create-ad-steps-content, .create-ad-steps-wrapper {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    width: 100%;
    border: none !important;
    margin-bottom: 15px !important;
}

.shop-dashboard-header-icon {
    color: #CCCCCC !important;
    margin: 0 15px !important;
}

.create-ad-button-group {
    width: 25% !important;
    margin-right: 40px;
    margin-top: 25px !important;
}

.sidebar-nav-item-default:hover {
  background-color: rgba(250, 250, 250, 0.2) !important;
  border: none !important;
}

span.main-header-component-cart-count {
  margin-top: -7px !important;
  margin-right: -5px !important;
}

.main-header-component-cart-count, .main-header-component-wishlist-count {
  margin-top: -7px !important;
  margin-right: -5px !important;
}

/* Modal backdrop */
.notification-post-view-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--modal-overlay-background);
  backdrop-filter: var(--modal-overlay-backdrop-filter);
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

/* Modal content container */
.notification-post-view-modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  border: 1px solid var(--border-color);
  width: 60%;
  max-width: 800px;
  height: 90vh; 
  overflow-y: auto;
  border-radius: 15px;
  z-index: 1000;
  padding: 20px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}
/* Ensure content inside the modal is scrollable */
.notification-post-view-modal-content::-webkit-scrollbar {
  width: 8px;
}

.notification-post-view-modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: var(--border-radius-sm);
}

.notification-post-view-modal-content::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Modal close button */
.notification-post-view-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

/* Post content inside modal */


/* Show modal */
.notification-post-view-modal.open .notification-post-view-backdrop {
  opacity: 1;
  visibility: visible;
}

.notification-post-view-modal.open .notification-post-view-modal-content {
  opacity: 1;
  visibility: visible;
}

.notifcations-view-post-button {
  background-color: var(--primary-color);
  border: none;
  border-radius: var(--border-radius-full);
  color: var(--text-color);
  cursor: pointer;
  font-size: 11px;
  font-weight: 500;
  height: 25px;
  width: 75px;
  min-width: 75px;
  max-width: 100px;
  padding: 0px;
  margin: 10px;
  margin-left: 3px;
  margin-right: 10px;
  align-self: center;
  transition: background-color 0.3s ease;
  float: right;
  display: block;
  position: absolute;
  right: 0;
}




.notifcations-view-post-button:hover {
  background-color: #13881e; 
}

span.main-header-component-wishlist-count {
  align-items: center;
  background-color: var(--primary-color) !important;
  border-radius: var(--border-radius-full);
  color: #fff !important;
  display: inline-flex;
  font-size: 12px;
  font-weight: 700;
  height: 20px !important;
  width: 20px !important;
  justify-content: center;
  padding: .25rem;
  position: absolute;
  right: 0;
  top: 0;
}

.category-products-page__active-filter {
  align-items: center;
  background-color: rgba(250, 250, 250, 0.2) !important;
  border: 1px solid var(--border-color) !important;
  border-radius: 9999px;
  color: #ffffff !important;
  display: inline-flex;
  font-size: 16px !important;
  font-weight: 500;
  padding: 15px 25px !important;
}

.category-products-page__remove-icon {
  height: 15px !important;
  width: 15px !important;
}


.category-products-page__disclosure-title {
  color: #ffffff !important;
  font-weight: 500;
  font-size: 16px !important;
}

.friend-card {
  min-width: 175px !important;
  height: 255px !important;
}

a.friend-card-link {
  height: 100px !important;
}

h4.friend-card-name {
  font-size: 12px !important;
  font-weight: 700 !important;
}

.suggested-connection-list {
  overflow-x: scroll !important;
}


h2.recommended-connections-title {
  font-size: 18px !important;
  font-weight: 700 !important;
  margin-top: 25px !important;
  margin-bottom: 15px !important;
}

.category-products-page__products {
  width: 90% !important;
}

.category-products-page__disclosure-section {
  border-bottom: 1px solid rgba(250, 250, 250, 0.4) !important;
  padding: 1.5rem 0;
  width: 85% !important;
  margin: auto !important;
}

.category-products-page__active-filters-bar {
  margin: 1rem auto;
  max-width: 7xl;
  padding: 0px !important;
  width: 85% !important;
  margin-top: 25px !important;
}

h2#filter-heading {
  color: #fff !important;
  font-weight: 700 !important;
}

.trending-products-carousel-product-name {
  font-weight: 700 !important;
}

.trending-products-carousel-info {
  background-color: var(--background-color) !important;
}

.category-products-page__active-filter {
  padding: 5px 15px !important;
  margin-right: 5px !important;
}

.newsletter-signup-container {
  background-color: var(--background-color) !important;
}

.room-creation-form-button {
  border-radius: var(--border-radius-full) !important;
  font-weight: 700 !important;
}

.post-widget-comment-submit-button {
  border-radius: var(--border-radius-full) !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  padding: 10px 25px !important;
}

.profile-sidebar-component-item.active .profile-sidebar-component-text, .profile-sidebar-component-text.active {
  font-weight: 700 !important;
}

.profile-sidebar-component-button {
  border-radius: var(--border-radius-full) !important;
  font-weight: 700 !important;
}

.profile-content-component-form-input {
  background-color: rgba(250, 250, 250, 0.3) !important;
  border: 1px solid var(--border-color) !important;
  border-radius: var(--border-radius-full) !important;
  padding: 8px 25px !important;
}

.profile-content-component-form-group {
  margin-bottom: 15px !important;
}


.profile-sidebar-component-button {
  background-color: var(--background-color) !important;
  border: 1px solid var(--border-color) !important;
  border-radius: var(--border-radius-full) !important;
  margin-top: 25px !important;
  font-weight: 700 !important;
}

.shop-login-component-form-group label {
  color: #ffffff !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  margin-bottom: 10px !important;
}

.shop-login-component-footer a {
  color: var(--primary-color) !important;
  padding-left: .5rem;
  text-decoration: none;
}

.shop-login-component-button {
  background-color: var(--primary-color) !important;
  border-radius: var(--border-radius-full) !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  padding: 7px 25px !important;
}

.shop-login-component-input {
  border: 1px solid var(--border-color) !important;
  border-radius: var(--border-radius-full) !important;
  font-size: 16px !important;
  padding: 7px 15px !important;
  background-color: rgba(250, 250, 250, 0.3) !important;
}

.shop-login-component-eye-icon {
  cursor: pointer;
  position: absolute;
  right: 15px !important;
  top: 73% !important;
  transform: translateY(-50%);
}

.load-more-comments-button {
  background-color: var(--background-color);
  border-radius: var(--border-radius-full);
  border: 1px solid var(--border-color);
  padding: 8px 16px;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.load-more-comments-button:hover {
  background-color: rgba(255, 255, 255, 0.3); 
  transform: translateY(-2px); 
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2); 
}

.subscription-checkout-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.subscription-checkout-form-container {
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-md);
  padding: 20px;
}

.subscription-checkout-form {
  display: flex;
  flex-direction: column;
}

.subscription-checkout-label {
  margin-bottom: 10px;
 color: var(--text-color);;
}

.subscription-checkout-input {
  padding: 10px;
  background-color: var(--background-color);
  margin-bottom: 20px;
  border-radius: 15px;
  border: 1px solid var(--border-color);
}

.subscription-checkout-button {
  background-color: var(--primary-color);
 color: var(--text-color);;
  padding: 10px 20px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  margin-bottom: 20px;
}
.subscription-checkout-coupon-button {
  background-color: var(--background-color);
 color: var(--text-color);;
  padding: 10px 20px;
  border: 1px solid var(--border-color);
  border-radius: 15px;
  cursor: pointer;
  margin-bottom: 20px;
}

.subscription-checkout-details-container {
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-md);
  padding: 20px;
}

.subscription-checkout-details {
  padding: 10px;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 15px;
}

/**/
.subscription-couponcode-creation-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.subscription-couponcode-creation-form-container {
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-md);
  padding: 20px;
}

.subscription-couponcode-creation-form {
  display: flex;
  flex-direction: column;
}

.subscription-couponcode-creation-label {
  margin-bottom: 10px;
 color: var(--text-color);;
}

.subscription-couponcode-creation-input {
  padding: 10px;
  background-color: var(--background-color);
  margin-bottom: 20px;
  border-radius: 15px;
  border: 1px solid var(--border-color);
}

.subscription-couponcode-creation-button {
  background-color: var(--primary-color);
 color: var(--text-color);;
  padding: 10px 20px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  margin-bottom: 20px;
}

.subscription-couponcode-creation-coupon-button {
  background-color: var(--background-color);
 color: var(--text-color);;
  padding: 10px 20px;
  border: 1px solid var(--border-color);
  border-radius: 15px;
  cursor: pointer;
  margin-bottom: 20px;
}

.subscription-couponcode-creation-details-container {
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-md);
  padding: 20px;
}

.subscription-couponcode-creation-details {
  padding: 10px;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 15px;
}

/* Subscription coupon list */

.subscription-couponcode-list-container {
  padding: 20px;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-md);
  max-width: 1000px;
  margin: 0 auto;
}

.subscription-couponcode-heading {
  font-size: 24px;
  color: #343a40;
  margin-bottom: 20px;
  text-align: center;
}

.subscription-couponcode-edit-container {
  padding: 20px;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-sm);
  margin-bottom: 20px;
}

.subscription-couponcode-form {
  display: flex;
  flex-direction: column;
}

.subscription-couponcode-label {
  font-size: 14px;
  margin-bottom: 8px;
 color: var(--text-color);;
}

.subscription-couponcode-input {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ced4da;
  background-color: var(--background-color);
  border-radius: 15px;
  font-size: 14px;
}

.subscription-couponcode-button-group {
  display: flex;
  justify-content: space-between;
}

.subscription-couponcode-save-btn,
.subscription-couponcode-cancel-btn {
  padding: 10px 15px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
}

.subscription-couponcode-save-btn {
  background-color: #28a745;
 color: var(--text-color);;
  border: none;
}

.subscription-couponcode-cancel-btn {
  background-color: #dc3545;
 color: var(--text-color);;
  border: none;
}

.subscription-couponcode-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.subscription-couponcode-table th,
.subscription-couponcode-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #dee2e6;
}

.subscription-couponcode-table th {
  background-color: var(--background-color);
 color: var(--text-color);;
}


.subscription-couponcode-action-btn {
  padding: 5px 10px;
  background-color: #007bff;
 color: var(--text-color);;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 5px;
}

.subscription-couponcode-action-btn.delete {
  background-color: #dc3545;
}


.switcheroo-swap-icon {
  padding: 0px 3px 20px !important;
}

a.share-button {
  background-color: rgba(250, 250, 250, 0.4) !important;
}

.share-button {
  height: 25px !important;
  width: 25px !important;
  border-radius: var(--border-radius-full) !important;
  font-size: 16px !important;
  padding: 6px !important;
  margin-top: 25px !important;
  margin-bottom: 15px !important;
}



.shop-info-sidebar-top-row {
  padding-bottom: 25px !important;
  padding-top: 25px !important;
  border-bottom: 1px solid rgba(250, 250, 250, 0.3) !important;
}

.shop-info-sidebar-bottom-row {
  gap: 0 !important;
  padding-top: 10px !important;
  border-bottom: 1px solid rgba(250, 250, 250, 0.3) !important;
  margin-bottom: 15px !important;
  margin-top: 5px !important;
}

.shop-settings-component-submit-button {
  background-color: var(--primary-color);
  border: none !important;
  border-radius: var(--border-radius-full) !important;
  box-shadow: 0 1px 2px #0000000d;
  cursor: pointer;
  margin-bottom: 25px !important;
  padding: 10px !important;
  text-align: center;
  width: 30%;
  font-weight: 700 !important;
}
/*---Shop Settings---*/

.shop-settings-component-avatar, .shop-settings-component-banner {
    display: flex !important;
    justify-content: center;
    margin-bottom: 1.25rem;
    position: relative;
    align-items: center;
    text-align: center;
}

.shop-settings-component-avatar img {
    border-radius: 50%;
    height: 200px;
    width: 200px;
    margin: auto !important;
}

.shop-settings-component-camera-icon-container {
    align-items: center;
    background-color: rgba(250, 250, 250, 0.4) !important;
    right: auto !important;
    width: 20px !important;
    height: 20px !important;
}

.shop-settings-component-input {
    background-color: rgba(250, 250, 250, 0.2) !important;
    border: 1px solid var(--border-color) !important;
    border-radius: var(--border-radius-full) !important;
    padding-left: 15px !important;
}

.shop-settings-page-manage-payment-btn {
    background-color: rgba(250, 250, 250, 0.3) !important;
    border: 1px solid var(--border-color) !important;
    border-radius: var(--border-radius-full) !important;
   color: var(--text-color);;
    display: inline-block;
    font-weight: 700;
    padding: 8px 16px;
    text-decoration: none;
}

.css-mh3zap {
    color: #ffffff !important;
}

.css-14349d1 {
    color: #ffffff !important;
}

/* Onboarding steps */
.onboarding-steps {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.onboarding-step {
  display: flex;
  align-items: center;
  position: relative;
}

/* Circle for each step */
.onboarding-step-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  transition: all 0.3s ease;
}

/* Default styling for upcoming steps */
.onboarding-step-circle.upcoming {
  background-color: #e0e0e0;
  color: #999;
  border: 2px solid #e0e0e0;
}

/* Styling for completed steps */
.onboarding-step-circle.completed {
  background-color: var(--primary-color);
   color: var(--text-color);
  border: 2px solid #4caf50;
}

.onboarding-step-circle.completed .onboarding-step-icon {
  font-size: 20px;
   color: var(--text-color);
}

/* Styling for the current step */
.onboarding-step-circle.current {
  background-color: #ffffff;
  color: var(--primary-color);
  border: 2px solid #4caf50;
  box-shadow: 0 0 10px rgba(76, 175, 80, 0.5);
}

/* Step label styling */
.onboarding-step-label {
  margin-left: 10px;
  font-size: 14px;
  transition: color 0.3s ease;
}

/* Default styling for upcoming step labels */
.onboarding-step-label.upcoming {
  color: #999;
}

/* Styling for completed step labels */
.onboarding-step-label.completed {
  color: var(--primary-color);
}

/* Styling for the current step label */
.onboarding-step-label.current {
  color: var(--primary-color);
  font-weight: bold;
}

/* Line between steps */
.onboarding-step-line {
  width: 60px;
  height: 4px;
  margin-left: 14px;
  transition: background-color 0.3s ease;
}

/* Line for completed steps */
.onboarding-step-line.completed-line {
  background-color: var(--primary-color);
}

/* Line for upcoming steps */
.onboarding-step-line.upcoming-line {
  background-color: #e0e0e0;
}


/* Onboarding Steps Mobile */

.onboarding-steps-mobile {
  border-radius: 8px;
  margin: 0 auto;
}

.onboarding-steps-mobile-header {
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 12px;
}

.onboarding-steps-mobile-list {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 15px;
  width: 100%;
}

.onboarding-steps-mobile-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.onboarding-steps-mobile-circle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.onboarding-steps-mobile-circle.completed {
  background-color: var(--primary-color);
   color: var(--text-color);
}

.onboarding-steps-mobile-circle.current {
  background-color: var(--background-color);
  border: 1px solid var(--primary-color);
   color: var(--text-color);
}

.onboarding-steps-mobile-circle.upcoming {
  background-color: #e0e0e0;
  color: #757575;
}

.onboarding-steps-mobile-label {
  display: none;
  font-size: 0.8em;
 color: var(--text-color);;
  margin-top: 4px;
  text-align: center;
}


/* Seller Activation Page Styles */

.seller-activation-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-color);
  padding: 20px;
}

.seller-activation-content {
  width: 100%;
  max-width: 600px;
  text-align: center;
  background-color: var(--background-color);
  padding: 40px;
  border-radius: var(--border-radius-md);
  border: 1px solid var(--border-color);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.seller-activation-error {
  color: red;
  font-size: 1.1rem;
  margin-bottom: 20px;
}

.seller-activation-success p {
  font-size: 1.2rem;
 color: var(--text-color);;
  margin-bottom: 20px;
}

.seller-activation-setup-button {
  padding: 12px 25px;
  font-size: 16px;
  cursor: pointer;
  background-color: var(--primary-color);
   color: var(--text-color);
  border: none;
  border-radius: var(--border-radius-full);
  transition: background-color 0.3s ease;
}

.seller-activation-setup-button:hover {
  background-color: #3d6000;
}

.close-scanner-button {
  position: absolute;
  bottom: 10px;        
  width: 100%;      
  background-color: #f44336; 
   color: var(--text-color);     
  padding: 20px 15px;
  border: none;
  border-radius: var(--border-radius-full);
  font-size: 20px;
  cursor: pointer;
  z-index: 1000;   
}

.close-scanner-button:hover {
  background-color: #d32f2f; 
}

.membership-personal-data-button {
  min-width: 125px !important;
}

.create-ad-button.disabled {
  background-color: var(--background-color);
  cursor: not-allowed;
}

.active-time-button {
  background-color: #3d6300;
   color: var(--text-color);
}

.category-section-component-bg {
  padding-left: 120px !important;
  padding-right: 120px !important;
  padding-bottom: 50px !important;
  padding-top: 0px !important;
}


.category-section-component-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 100% !important;
  padding: 0 !important;
}

.trending-products-carousel-wholesale-bg {
  padding: 0px 120px !important;
}

.trending-products-carousel-wholesale-container {
  max-width: 100% !important;
  padding: 25px 0px !important;
  width: 100% !important;
}

.trending-products-carousel-wholesale-link-hidden {
  color: #ffffff !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  transition: color .3s ease;
  background-color: var(--primary-color) !important;
  padding: 10px 25px !important;
  border-radius: var(--border-radius-full) !important;
}

h2.category-section-component-title {
  margin-bottom: 15px !important;
}

.w-10\/12.mx-auto {
  padding: 55px 120px !important;
}

.newsletter-signup-input {
  border-radius: var(--border-radius-full) !important;
  color: #fff !important;
  margin-bottom: 15px !important;
  margin-top: 15px !important;
  padding: 10px 25px !important;
}

/*----Stripe----*/

a.⚙.rs32.rs31.rs30.rs33.rs31.rs32.rs34.rs35.rs30.rs32.as3z.as310.as311.as37.as3g.as35.as38.as312.as313.as314.as315.as316.as317.as318.as319.as3c.as31a.as31b.as31c.as31d.as31e.as31f.as31g.as31h.as3r.as31i.as31j.as31k.as31l.as31m.as31n.as31o.as31p.as31q.as31r.as31s.as31t.as31u.as31v.as31w.as31x.as31y.as31z.as320.as321.as322.⚙1hz7nuk {
  border-radius: var(--border-radius-full) !important;
  padding: 10px 25px !important;
}

/*----End Stripe----*/


a:hover.sidebar-nav-item-default.sidebar-nav-item-large {
  border-left-width: 4px !important;
  padding-left: 12px !important;
}

button.event-card-component-button {
  font-weight: 700 !important;
}

a.sponsored-product-carousel-button {
  font-weight: 700 !important;
}

h2.dual-product-ad-carousel-title {
  font-weight: 700 !important;
}
a.dual-product-ad-carousel-button {
  font-weight: 700 !important;
}


.quad-product-ad-carousel-button, .sponsored-product-carousel-button {
  font-weight: 700 !important;
}

.product-card-component-quantity-button {
  padding-bottom: 3px !important;
}

svg.product-card-component-cart-icon {
  height: 35px !important;
  padding: 7px !important;
  width: 35px !important;
  padding-left: 5px !important;
}

.newsletter-signup-button {
  font-weight: 700 !important;
}

.product-card-component-product-name {
  font-size: 18px !important;
}

.mobile-button-container {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  padding: 10px 18px;
  border-top: 1px solid rgba(250, 250, 250, 0.4);
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1000;
  text-align: center;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 1024px) {
  .mobile-sidebar {
      display: none;
  }
}

.mobile-sidebar-button {
  padding: 10px;
  background-color: rgba(250, 250, 250, 0.4);
   color: var(--text-color);
  border-radius: var(--border-radius-full);
  border: 1px solid var(--border-color);
  cursor: pointer;
  width: 60px;
}

.mobile-sidebar-modal {
  position: fixed;
  bottom: 80px;
  left: 10px;
  width: 100%;
  border-radius: var(--border-radius-full);
  z-index: 1000;
  padding-top: 70px; 
  padding-bottom: 100px; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
  height: 80vh;
  mask-image: linear-gradient(to bottom, transparent, black 20%, black 80%, transparent);
  -webkit-mask-image: linear-gradient(to bottom, transparent, black 20%, black 80%, transparent);
  -webkit-overflow-scrolling: touch;
}

.mobile-sidebar-modal::-webkit-scrollbar {
  display: none;
}

.mobile-sidebar-modal {
  scrollbar-width: none; 
}

.mobile-sidebar-nav {
  height: 100%;
}
.mobile-sidebar-spacer {
  height: 100px; 
  width: 100%;
}


.mobile-background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.8) 100%); 
  backdrop-filter: var(--modal-overlay-backdrop-filter); 
  z-index: 30; 
}

.mobile-sidebar-item {
  padding: 20px;
  text-align: center;
  display: flex;
  align-items: center;
}

.mobile-sidebar-icon {
  width: 24px;
  height: 24px;
}

.mobile-header-icon {
  width: 24px;
  height: 24px;
}
.header-icon {
  width: 24px;
  height: 24px;
  margin-left: 10px;
}
.mobile-header-icon-container {
  width: 44px;
  height: 44px;
  padding: 10px;
  border-radius: var(--border-radius-full);
  background-color: var(--background-color);
  border: 2px solid rgba(250, 250, 250, 0.9);
  padding-top: 8px;
}


.mobile-sidebar-item-name {
  margin-left: 20px;
 color: var(--text-color);;
}

.w-10\/12.mx-auto {
  padding: 0px !important;
}



/*----UPDATES TO STRUCTURE---*/
.bg-\[\#f5f5f5\] {
  background-color: transparent !important;
  border-left: 1px solid #fafafa66 !important;
  border-right: 1px solid #fafafa66 !important;
}



/*---Profile----*/
.profile-extra-container {
  background-color: var(--background-color) !important;
  border: 1px solid rgba(250, 250, 250, 0.2) !important;
  /*border: 1px solid var(--border-color) !important;*/
  border-radius: 10px !important;
  grid-column: 1 / 2;
  height: 310px !important;
  overflow: hidden;
}



.main-feed-page {
    background-color: transparent !important;
}

/*----END----*/

.user-profile-data-tab-active {
  color: #ffffff !important;
}

img.category-marketplace-section-component-category-image {
    border-radius: 10px !important;
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}

a.category-marketplace-section-component-browse-link {
    border: 1px solid rgba(250, 250, 250, 0.2) !important;
}


aside.main-feed-page-sticky-sidebar.hidden.lg\:block.w-full.lg\:w-1\/4.shrink-0.bg-\[\#f5f5f5\].p-2.rounded-lg.lg\:order-1.main-feed-page-scrollable {
  border: none !important;
  border-right: 1px solid rgba(250, 250, 250, 0.3) !important;
  border-radius: 0px !important;
}
aside.main-feed-page-sticky-sidebar.hidden.lg\:block.w-full.lg\:w-1\/4.shrink-0.bg-\[\#f5f5f5\].p-4.rounded-lg.lg\:order-3.main-feed-page-scrollable {
  border: none !important;
  border-left: 1px solid var(--border-color);
  border-radius: 0px !important;
}

.my-events-container {
  min-height: 250px !important;
  border: 1px solid var(--border-color) !important;
  border-radius: 10px !important;
}

.wishlist-component-empty {
  border-left: 1px solid var(--border-color);
  margin-top: 5px !important;
}

.rooms-profile-container {
  min-height: 200px !important;
  margin-bottom: 25px !important;
}


.my-events-container {
  border-radius: 10px !important;
  border: 1px solid var(--border-color) !important;
  padding: 15px !important;
  min-height: 200px !important;
}

.user-profile-data-content-container {
  margin-bottom: 25px !important;
}

.shop-images-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--modal-overlay-background);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  backdrop-filter: var(--modal-overlay-backdrop-filter);
}

.shop-images-modal {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: var(--border-radius-sm);
  border: 1px solid var(--border-color);
  width: 80%;
  max-width: 80vw;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.shop-images-modal-close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
}

.shop-images-modal h2 {
  margin-top: 0;
  text-align: center;
  font-size: 24px;
}

.shop-images-modal-loading,
.shop-images-modal-error,
.shop-images-modal-no-images {
  text-align: center;
  font-size: 16px;
  color: #555;
}

.shop-images-modal-image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
  margin-top: 20px;
}

.shop-images-modal-image-item {
  border-radius: var(--border-radius-sm);
  overflow: hidden;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.shop-images-modal-image-item:hover {
  border-color: #007bff;
}

.shop-images-modal-image-preview {
  width: 100%;
  height: auto;
  display: block;
}

.category-marketplace-section-component-section-wrapper {
  padding-top: 25px !important;
  max-width: 100% !important;
  padding-bottom: 25px !important;
}


.trending-products-carousel-bg {
  margin: 25px 0px !important;
}

li.trending-products-carousel-item {
  border-bottom: none !important;
}

.trending-products-carousel-info {
  background-color: #fafafa1a !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.trending-products-carousel-image-container {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom: 1px solid rgba(250, 250, 250, 0.3) !important;
}



li.trending-products-carousel-item {
  border-bottom: none !important;
  border-radius: 10px !important;
  border: 1px solid rgba(250, 250, 250, 0.2) !important;
  padding-bottom: 0px !important;
}

.trending-products-carousel-list {
      padding: 0px !important;
}

.footer-component-bg {
  margin-top: 25px !important;
  border-top: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.newsletter-signup-container {
  background-color: rgba(250, 250, 250, 0.07) !important;
}

/* Container and Background */

.featured-shop-container {
  padding-top: 25px; 
  padding-bottom: 25px;
}


/* Image and Layout */
.featured-shop-relative-container {
  position: relative;
  overflow: hidden;
  border-radius: 0.5rem; /* Equivalent to rounded-lg */
  height: 24rem; /* Equivalent to lg:h-96 */
}

.featured-shop-image-wrapper {
  position: absolute;
  inset: 0;
  transition: opacity 0.3s ease;
}

.featured-shop-image-wrapper:hover {
  opacity: 0.75;
}

.featured-shop-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.featured-shop-hidden-lg {
  height: 24rem;
  width: 100%;
  display: block;
}

.featured-shop-hidden-sm {
  height: 8rem;
  width: 100%;
  display: block;
}

/* Absolute Container with Title and Button */
.featured-shop-absolute-container {
  position: absolute;
  bottom: 0;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  background-color: rgba(0, 0, 0, 0.25);
  padding: 1.5rem; /* Equivalent to p-6 */
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

@media (min-width: 1024px) {
  .featured-shop-absolute-container {
    width: 24rem; /* Equivalent to lg:w-96 */
    flex-direction: column;
    align-items: flex-start;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0.5rem;
  }
}

/* Text Styles */
.featured-shop-title {
  font-size: 1.25rem; /* Equivalent to text-xl */
  font-weight: bold;
   color: var(--text-color);
}

.featured-shop-subtitle {
  margin-top: 0.25rem;
  font-size: 0.875rem; /* Equivalent to text-sm */
  color: rgba(255, 255, 255, 0.75); /* Equivalent to text-gray-300 */
}

/* Button Styles */
.featured-shop-button {
  margin-top: 1.5rem; /* Equivalent to mt-6 */
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1rem; /* Equivalent to px-4 py-3 */
  font-size: 1rem; /* Equivalent to text-base */
  font-weight: medium;
   color: var(--text-color);
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 0.375rem; /* Equivalent to rounded-md */
  transition: background-color 0.3s ease;
}

.featured-shop-button:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Equivalent to hover:bg-opacity-10 */
}

@media (min-width: 640px) {
  .featured-shop-button {
    margin-left: 2rem; /* Equivalent to sm:ml-8 */
    margin-top: 0;
  }
}

@media (min-width: 1024px) {
  .featured-shop-button {
    margin-left: 0;
    width: 100%; /* Equivalent to lg:w-full */
  }
}

.marketplace-hero-slide.marketplace-hero-slide-active {
  border-radius: 10px !important;
}


.footer-component-bg {
  background-color: transparent !important;
}

ul.footer-component-column.footer-component-logo-column p {
  font-weight: 700 !important;
  font-size: 18px !important;
}

.dual-product-ad-carousel-content {
  border-radius: 10px !important;
  border: 1px solid var(--border-color) !important;
}



.open-shop-images-modal-button {
  display: flex;
  align-items: center;
  background-color: var(--background-color); 
  color: var(--text-color);
  border: 1px solid var(--primary-color); 
  border-radius: var(--border-radius-full);
  padding: 10px 20px; 
  font-size: 16px; 
  font-weight: 500; 
  cursor: pointer; 
  transition: background-color 0.3s ease, box-shadow 0.3s ease; 
}

.open-shop-images-modal-button:hover {
  background-color: var(--primary-color); 
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); 
}

.open-shop-images-modal-button:active {
  background-color: #004080; /* Even darker shade on click */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Pressed button effect */
}

.open-shop-images-modal-button:focus {
  outline: none; /* Remove outline */
  box-shadow: 0px 0px 0px 4px rgba(0, 123, 255, 0.25); /* Focus outline */
}

.shop-images-modal-tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.shop-images-modal-tab {
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  background-color: var(--background-color);
  font-size: 16px;
  border-radius: var(--border-radius-lg);
  border: 1px solid var(--border-color);
}

.shop-images-modal-tab.active {
  background-color: rgba(250, 250, 250, 0.4);
   color: var(--text-color);
}

/* Sub-tabs Container */
.shop-images-modal-sub-tabs {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid rgba(250, 250, 250, 0.4); 
  margin-bottom: 1rem;
}

/* Sub-tab Items */
.shop-images-modal-sub-tab {
  flex: 1;
  text-align: center;
  padding: 0.75rem 1rem; 
  font-size: 0.875rem; 
  font-weight: 500;
 color: var(--text-color);; 
  cursor: pointer;
  background-color: var(--background-color); 
  transition: background-color 0.3s ease, color 0.3s ease;
  position: relative; 
}

/* Hover and Focus Effects */
.shop-images-modal-sub-tab:hover {
  background-color: rgba(250, 250, 250, 0.3); 
}

.shop-images-modal-sub-tab:focus {
  z-index: 10; 
  outline: none; 
}

/* First tab (rounded left) */
.shop-images-modal-sub-tab:first-child {
  border-top-left-radius: 0.375rem; 
  border-bottom-left-radius: 0.375rem;
}

/* Last tab (rounded right) */
.shop-images-modal-sub-tab:last-child {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

/* Active Tab */
.shop-images-modal-sub-tab.active {
  color: var(--primary-color); 
  background-color: rgba(250, 250, 250, 0.3); 
}

/* Active Tab Indicator */
.shop-images-modal-sub-tab.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0.125rem; 
  background-color: #3d6300; 
}

/* Responsive Dropdown for small screens */
@media (max-width: 768px) {
  .shop-images-modal-sub-tabs {
    display: none;
  }


  .shop-images-modal-sub-tabs-select {
    display: block;
    width: 100%;
    border: 1px solid #d1d5db; 
    padding: 0.5rem; 
    border-radius: 0.375rem;
    font-size: 1rem;
    color: #111827
  }

  .shop-images-modal-sub-tabs-select:focus {
    border-color: #4f46e5; 
    outline: none;
    box-shadow: 0 0 0 3px rgba(99, 102, 241, 0.5); 
  }
}

@media (min-width: 768px) {
  .shop-images-modal-sub-tabs-select {
    display: none;
  }}

.main-feed-page {
  display: flex;
  flex-direction: column;
}

.main-feed-page-container {
  display: flex;
  align-items: start;
  gap: 0px;
  padding: 2rem 1rem;
  padding-left: 0px;
  padding-right: 0px;
  justify-content: center;
  width: 100%;
  
}


@media (max-width: 768px) {
  .main-feed-page-container {
      flex-direction: row;
      padding-left: 0px;
      padding-right: 0px;
      gap: 0px;
  }
}
@media (max-width: 480px) {
  .main-feed-page-container {
      flex-direction: row;
      padding-left: 0px;
      padding-right: 0px;
      gap: 0px;
  }
}



/* Sticky Sidebar for large screens */
.main-feed-page-sticky-sidebar-left {
  display: none;
  width: 100%;
  padding: 0rem 1rem;
  border-radius: 0.5rem;
  order: 1;
}

@media screen and (min-width: 1024px) {
  .main-feed-page-sticky-sidebar-left {
      display: block;
      width: 25%;
      position: sticky;
      top: 160px;
      flex-shrink: 0;
  }
}

.main-feed-page-sticky-sidebar-right {
  display: none;
  width: 100%;
  padding: 0rem 1rem;
  border-radius: 0.5rem;
  order: 3;
}

@media screen and (min-width: 1024px) {
  .main-feed-page-sticky-sidebar-right {
      display: block;
      width: 25%;
     
      flex-shrink: 0;
  }
}

/* Mobile Sidebar */
.main-feed-page-sidebar-mobile {
  display: block;
  width: 100%;
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 0.5rem;
}

@media screen and (min-width: 1024px) {
  .main-feed-page-sidebar-mobile {
      display: none;
  }
}

.css-ohwg9z {
  border: none !important;
}

.sponsored-shops-carousel-content {
  border: 1px solid rgba(250, 250, 250, 0.2) !important;
  border-radius: 15px !important;
}
.circle-shop-name {
  cursor: pointer !important;
  font-size: 10px !important;
  font-weight: 700 !important;
}
.sponsored-shops-widget-name {
  font-size: 90% !important;
  font-weight: 700 !important;
  color: #fff;
}

.community-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
}


.trending-products-carousel-title {
  font-size: 25px !important;
}

.trending-products-carousel-header {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.featured-shop-container {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  border: 1px solid var(--border-color) !important;
  border-radius: 10px !important;
  margin-top: 45px !important;
  margin-bottom: 25px !important;
}

.rooms-list {
  border: 1px solid rgba(250, 250, 250, 0.2) !important;
  border-radius: 10px !important;
  padding-bottom: 0px !important;
}

.rooms-list-item {
  margin-bottom: 0px !important;
  min-height: 200px !important;
}

.room-description {
  font-size: 15px !important;
  font-weight: 500 !important;
}

.room-name {
  font-size: 20px !important;
  font-weight: 700;
  margin-bottom: 10px;
  padding-top: 50% !important;
}

.find-events-button {
  font-weight: 700 !important;
  padding: 10px 25px !important;
}

.room-description {
  margin-top: 0px !important;
}

.room-name {
  margin-bottom: 0px !important;
}

.meme-create-button {
  background-color: var(--primary-color);
  border: none;
  border-radius: var(--border-radius-full);
 color: var(--text-color);;
  cursor: pointer;
  padding: 10px 20px;
  transition: background-color .3s ease;
  font-weight: 700 !important;
}

/*---SUBSCRIPTION----*/

.subscription-pricing-container {
  background-color: transparent !important;
}

button.subscription-pricing-subscribe-btn {
  background-color: var(--primary-color) !important;
  border-radius: var(--border-radius-full) !important;
  color: #fff !important;
  font-weight: 700 !important;
}

.subscription-pricing-tier {
  background-color: rgba(250, 225, 250, 0.1) !important;
}

span.subscription-pricing-feature-check {
  color: var(--primary-color) !important;
}

.subscription-pricing-tier.most-popular {
  border: 1px solid var(--primary-color) !important;
}

p.subscription-pricing-tier-popular {
  background-color: var(--primary-color) !important;
}

.subscription-pricing-frequency-option.active {
  background-color: var(--primary-color) !important;
}

.rooms-container {
  margin-bottom: 25px !important;
  padding: 0px !important;
  width: 100% !important;
  max-width: 800px !important;
}

.rooms-list-item {
  margin-bottom: 0 !important;
  min-height: 250px !important;
}

.room-name {
  font-size: 20px !important;
  padding-top: 70% !important;
}

.rooms-profile-container {
  border-radius: 10px !important;
}

.rooms-list {
  border-radius: 10px !important;
  padding-bottom: 0 !important;
  width: 100% !important;
  height: 250px !important;
  overflow: scroll !important;
}

.post-widget-image {
  border-radius: 10px !important;
  margin-top: 15px !important;
  margin-bottom: 10px !important;
}

.room-name {
  margin-top: 0 !important;
}

/* General Container */
.mobile-bar-banner-ad-container {
  background-color: transparent !important;
    border-bottom: none !important;
    box-sizing: border-box;
    width: 100%;
    position: relative;
    margin-left: 30px;
    margin-right: 30px;
}

/* Content styling */
.mobile-bar-banner-ad-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.75); 
  padding: 0px 15px;
  border-radius: var(--border-radius-full);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.5); 
  position: relative;
  overflow: hidden; 
  background-blend-mode: darken;
  aspect-ratio: 6/1;
  width: 100%;
}

/* Image Container */
.mobile-bar-banner-ad-image-container {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  aspect-ratio: 6/1;
}

/* Image Styling */
.mobile-bar-banner-ad-image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1; 
}

/* Text Wrapper */
.mobile-bar-banner-ad-text {
  z-index: 2; 
   color: var(--text-color);
  display: flex;
  flex-direction: column;
  position: relative;
}

/* Text Link Styling */
.mobile-bar-banner-ad-link {
   color: var(--text-color);
  text-decoration: none;
  font-size: 14px;
  line-height: 1.5;
  flex-grow: 1;
}

/* Text Link Bold */
.mobile-bar-banner-ad-link strong {
  font-weight: bold;
}

/* Dismiss Button Styling */
.mobile-bar-banner-ad-dismiss {
  background: none;
  border: none;
  padding: 12px;
  cursor: pointer;
  z-index: 2; 
}

/* Dismiss Icon Styling */
.mobile-bar-banner-ad-icon {
  height: 20px;
  width: 20px;
   color: var(--text-color);
}

.mobile-bar-banner-ad-buttons {
  position: absolute;
  width: 100%;
  justify-content: space-between;
}

/* Mobile Bar Banner Ad Container */

/* Mobile Banner Content */
.ad-preview-banner-mobile-container {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: var(--border-radius-full);
  display: flex;
  justify-content: space-between;
  align-items: center;
  aspect-ratio: 6/1;
  position: relative;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  margin-top: 600px;
}

/* Image Container */
.ad-preview-banner-image-container {
  position: absolute;
  width: 100%;
  height: 100%;
  aspect-ratio: 6/1;
}

/* Image Styling */
.ad-preview-mobile-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

/* Banner Text */
.ad-preview-banner-content {
  z-index: 2;
   color: var(--text-color);
  display: flex;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

/* Banner Link */
.ad-preview-banner-link {
  font-size: 14px;
   color: var(--text-color);
  font-weight: bold;
}

/* Dismiss Button */
.ad-preview-banner-dismiss {
  background: none;
  border: none;
  cursor: pointer;
  z-index: 2;
   color: var(--text-color);
}

/* Dismiss Icon */
.ad-preview-banner-icon {
  height: 20px;
  width: 20px;
   color: var(--text-color);
}


.carousel-nav-button {
  z-index: 50;
}

.shop-profile-container {
  position: relative;
  width: 100%;
}

.shop-avatar-container-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
}


.shop-avatar-profile-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

@media (max-width: 480px) {
  .shop-avatar-container-overlay {
    align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        left: 0;
        padding: 0;
        top: 1;
        width: 100%;
        height: 330px;
  }
  .shop-avatar-profile-wrapper {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 20px;
  }
}

svg:not(:root).svg-inline--fa, svg:not(:host).svg-inline--fa {
  overflow: visible;
  box-sizing: content-box;
  cursor: pointer !important;
}

.post-widget-comment-input-container {
  margin-top: 25px !important;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
  padding-top: 15px !important;
  border-top: 1px solid rgba(250, 250, 250, 0.2) !important;
}

.sponsored-products-component-product-name {
  text-transform: capitalize !important;
}

input[type=text]:focus {
border: none !important;
}

.mobile-sidebar-button {
  background-color: var(--background-color) !important;
  border: 2px solid rgba(250, 250, 250, 0.5) !important;
  border-radius: 100% !important;
  padding: 10px !important;
  width: auto !important;
  height: auto !important;
  max-height: 45px !important;
  max-width: 45px !important;
  min-height: 45px !important;
  min-width: 45px !important;
}

.community-title {
  font-size: 25px !important;
  margin-bottom: 0px !important;
  margin-top: 35px !important;
}

.friend-name {
  font-size: 18px !important;
  font-weight: 700 !important;
}

.notification-sidebar-body {
  padding: 0px !important;
  padding-bottom: 100px !important;
}

h2.community-title {
  font-size: 25px !important;
  font-weight: 700;
  margin-bottom: 0px !important;
  margin-top: 35px !important;
}

.category-marketplace-section-component-header-wrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.css-1l7c0cy {
  height: 402px !important;
  position: absolute;
  height: 100%;
  width: 100%;
  border: 1px solid rgba(250, 250, 250, 0.2) !important;
  border-radius: 15px !important;
}


.friend-name {
  font-size: 18px !important;
  font-weight: 700 !important;
}

.sponsored-products-component-product-name {
  text-transform: capitalize !important;
  font-weight: 700 !important;
}

.onboarding-step-circle.completed {
  background-color: var(--primary-color) !important;
  border: var(--primary-color) !important;
}

.onboarding-step-label.current {
  color: var(--primary-color) !important;
}

.onboarding-step-label.completed {
  color: var(--primary-color) !important;
}

.as31j {
  background-color: var(--primary-color) !important;
  padding: 15px 25px !important;
  border: none !important;
  border-radius: var(--border-radius-full) !important;
}

.onboarding-step-circle.current {
  border: 3px solid var(--primary-color) !important;
  color: var(--primary-color) !important;
}

.as31r:hover:not(:active):not([aria-disabled="true"]) {
  border-color: none !important;
  border: none !important;
}

.onboarding-step-line.completed-line {
  background-color: var(--primary-color) !important;
}

.ad-format-view-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 10px;
}

.view-toggle-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0 10px;
  padding: 10px;
  transition: transform 0.2s ease;
  display: flex;
  align-items: center;
}

.view-toggle-icon {
  width: 24px;
  height: 24px;
  color: #4a5568; 
}

.view-toggle-btn.active .view-toggle-icon {
  color: var(--primary-color); 
  transform: scale(1.2);
}
.view-toggle-btn.active {
  border-radius: var(--border-radius-full);
  box-shadow: 0 0 10px rgba(54, 211, 0, 0.7), 0 0 20px rgba(54, 211, 0, 0.5);
}

.view-toggle-btn:hover .view-toggle-icon {
  transform: scale(1.1);
}

.view-toggle-btn:focus {
  outline: none;
}

.shop-list .shop-card h3 {
  font-weight: 600 !important;
  font-size: 12px !important;
  margin-bottom: 10px !important;
  margin-top: 10px !important;
}

.sponsored-feed-products-container {
  background-color: transparent !important;
}

.sponsored-feed-products-card {
  border: 1px solid var(--border-color) !important;
}

.css-1l7c0cy {
  border: none !important;
  border-radius: 15px !important;
  height: 402px !important;
}

/* Main container for the event carousel */
.events-carousel-container {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}

/* Styling for the loading state */
.events-carousel-loading {
  font-size: 18px;
  text-align: center;
  margin: 50px 0;
}

/* Each slide in the carousel */
.events-carousel-slide {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

/* Link styling for the event cards */
.events-carousel-link {
  text-decoration: none;
  margin: 0 10px;
}

span.main-header-component-cart-count {
  background-color: #ff9f0a !important;
}

.shop-banner-save-button {
  background-color: var(--primary-color) !important;
  border-radius: var(--border-radius-full) !important;
  padding: 10px 25px !important;
}

.MuiBox-root.css-dejdd>img {
  height: 56.5px !important;
  width: 56.5px !important;
}

/* Image Cropper Styles */
.image-cropper__file-input {
  display: block;
  margin-bottom: 1rem;
}
.image-cropper__file-input-inner {
  width: 100%;
  font-size: 0.875rem;
  padding: 0.5rem;
}

.image-cropper__error-container {
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  max-width: 400px;
  border: 1px solid #fbd38d;
}

.image-cropper__error-icon {
  flex-shrink: 0;
}

.image-cropper__error-icon-img {
  height: 20px;
  width: 20px;
  fill: #f6ad55;
}

.image-cropper__error-content {
  margin-left: 12px;
  color: black;
}

.image-cropper__error-heading {
  font-size: 16px;
  font-weight: 600;
  color: #b45309;
  margin: 0;
}

.image-cropper__error-message {
  font-size: 14px;
  color: #975a16;
  margin-top: 4px;
}

.image-cropper__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.image-cropper__image {
  max-height: 70vh;
  border-radius: 0% !important;
}
.image-cropper__button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #38bdf8;
   color: var(--text-color);
  font-size: 0.875rem;
  border-radius: 9999px;
  cursor: pointer;
}
.image-cropper__button:hover {
  background-color: #0ea5e9;
}
.image-cropper__canvas {
  margin-top: 1rem;
  border: 1px solid black;
  width: 150px;
  height: 150px;
}

/* Modal Styles */
.image-cropper-modal {
  position: fixed;
  inset: 0;
  z-index: 1000;
}
.image-cropper-modal__overlay {
  position: fixed;
  inset: 0;
  background: var(--modal-overlay-background);
  backdrop-filter: var(--modal-overlay-backdrop-filter);
}
.image-cropper-modal__content-wrapper {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-cropper-modal__content {
  width: 90%;
  max-width: 800px;
  min-height: 60vh;
  max-height: 90vh;
  background-color: rgba(0, 0, 0, 0.9);
  color: #f3f4f6;
  border-radius: var(--border-radius-sm);
  border: 1px solid var(--border-color);
  padding: 36px;
  position: relative;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  overflow-y: auto;
}

@media (max-width: 480px) {
  .image-cropper-modal__content {
    width: 100%;
    max-width: 800px;
    min-height: 60vh;
    max-height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    color: #f3f4f6;
    border-radius: var(--border-radius-sm);
    border: 1px solid var(--border-color);
    padding: 10px;
    position: relative;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
    overflow-y: auto;
    margin-bottom: 50px;
  }
}
.image-cropper-modal__header {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.image-cropper-modal__close-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #9ca3af;
  padding: 0.5rem;
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
}
.image-cropper-modal__close-button:hover {
  color: #d1d5db;
}

.shop-images-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.shop-images-pagination button {
  background-color: var(--primary-color);
   color: var(--text-color);
  border: none;
  padding: 10px 15px;
  border-radius: var(--border-radius-full);
  width: 200px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.shop-images-pagination button:hover {
  background-color: #2ba600;
}

.shop-images-pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.shop-images-pagination span {
  font-size: 16px;
  color: #555;
}

.bento-container {
  display: flex;
  height: 60vh;
  gap: 10px;
  border-radius: var(--border-radius-full);
  overflow: hidden;
}

.bento-column {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-sm);
}

.bento-column-left {
  background-color: var(--background-color);
  flex-direction: column;
  flex: 3 1;
}

.bento-column-right {
  flex-direction: column;
  gap: 10px;
}

.bento-middle {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: var(--border-radius-sm);
  overflow: hidden;
}

.bento-box {
  flex: 1;
  background-color: var(--background-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.bento-box-top {
  background-color: var(--background-color);
  width: 100%;
}

.bento-box-bottom {
  background-color: var(--background-color);
  width: 100%;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .bento-container {
    flex-direction: column;
    height: auto;
  }

  .bento-column, .bento-middle {
    flex: none;
    width: 100%;
    height: 100%;
  }

  .bento-middle {
    height: 100%;
  }
}
.bento-title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
  margin-top: 20px;
}
/* Container */
.page-not-found-container {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
   color: var(--text-color);
  text-align: center;
}

.page-not-found-header {
z-index: 100;
}

.page-not-found-logo {
  height: 240px;
}

.page-not-found-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/* Main Content */
.page-not-found-main {
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.page-not-found-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  filter: brightness(0.5) saturate(0.3);
}

.page-not-found-content {
  max-width: 500px;
  z-index: 2;
  text-align: center;
}

.page-not-found-status {
  font-size: 2rem;
  font-weight: bold;
}

.page-not-found-title {
  margin-top: 10px;
  font-size: 3rem;
  font-weight: bold;
}

.page-not-found-description {
  margin-top: 20px;
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 0.8);
}

.page-not-found-back-link {
  margin-top: 30px;
}

.page-not-found-back-link a {
  font-size: 1.25rem;
  font-weight: bold;
 color: var(--text-color);;
  text-decoration: none;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .page-not-found-content {
    padding: 20px;
  }

  .page-not-found-title {
    font-size: 2rem;
  }

  .page-not-found-description {
    font-size: 1rem;
  }

  .page-not-found-status {
    font-size: 1.5rem;
  }
}


.profile-follow-overview-container {
  display: flex;
  width: 100%;
}

.top-banner-ad-container {
    border: 1px solid var(--border-color);
}

/* LocationTracker.css */
.location-locator-container {
  padding: 20px;
  text-align: center;
}

.location-locator-info {
  background-color: #f0f0f0;
  padding: 15px;
  border-radius: 8px;
  display: inline-block;
}

.location-locator-error-container {
  background-color: #fff3f3;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #ffcccc;
  display: inline-block;
}

.location-locator-loading-text {
  font-size: 16px;
  color: #666;
}

.location-locator-error-text {
  font-size: 14px;
  color: #d9534f;
  margin: 10px 0;
}

.location-locator-retry-button {
  background-color: #007bff;
 color: var(--text-color);;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.location-locator-retry-button:hover {
  background-color: #0056b3;
}

.location-locator-retry-button:focus {
  outline: none;
  box-shadow: 0 0 0 4px rgba(0, 123, 255, 0.5);
}

.events-parent-container {
  max-width: 800px;
  margin: 0 auto;
  border-radius: 8px;
  padding-top: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}



.events-parent-container-tabs-nav {
  display: flex;
  gap: 2rem; 
  margin-bottom: -1px;
  justify-content: center;
}

.events-parent-container-tabs-button {
 color: var(--text-color);; 
  padding: 1rem 0.25rem;
  font-size: 0.875rem;
  font-weight: 500; 
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.events-parent-container-tabs-button:hover {
  color: #374151;
}

.events-parent-container-tabs-button-active {
  border-bottom-color: var(--primary-color); 
  color: var(--primary-color); 
  padding: 1rem 0.25rem; 
  font-size: 0.875rem; 
  font-weight: 500;
}


.events-parent-tab-button {
  padding: 10px 20px;
  background-color: #3d6300;
  border: none;
  border-radius: var(--border-radius-full);
  cursor: pointer;
  transition: background-color 0.3s;
}

.events-parent-tab-button.active {
  background-color: #007bff;
 color: var(--text-color);;
  font-weight: bold;
}

.events-parent-tab-button:hover {
  background-color: #d0d0d0;
}

.events-parent-tab-content {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.shop-avatar-wrapper {
  width: 100%;
  height: 100%;
}

.sponsored-ads-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.shop-create-notification-container {
  border-radius: 8px;
  background-color: var(--background-color);
  padding: 16px;
  display: flex;
  align-items: center;
  margin: 16px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.shop-create-notification-success {
  border: 1px solid var(--primary-color);
}

.shop-create-notification-content {
  display: flex;
  width: 100%;
  align-items: center;
}

.shop-create-notification-icon {
  flex-shrink: 0;
  color: var(--primary-color);
  margin-right: 12px;
}

.shop-create-notification-check-icon {
  height: 24px;
  width: 24px;
}

.shop-create-notification-message {
  flex-grow: 1;
  color: #2e7d32;
  font-size: 14px;
  font-weight: 500;
}

.shop-create-notification-close {
  background: transparent;
  border: none;
  padding: 8px;
  color: var(--primary-color);
  cursor: pointer;
  outline: none;
}

.shop-create-notification-close-icon {
  height: 20px;
  width: 20px;
}

.shop-create-notification-close:hover {
  background-color: #d9f2e1;
} 

.user-profile-data-tab-navigation {
  border: none !important;
  border-bottom: 1px solid rgba(250, 250, 250, 0.3) !important;
  border-radius: 0 !important;
  padding-bottom: 20px !important;
}

span.main-header-component-cart-count {
  background-color: var(--secondary-color) !important;
}

svg.product-card-component-cart-icon {
    background-color: var(--secondary-color) !important;
}

.sponsored-brand-ad-carousel-button {
    background-color: var(--secondary-color) !important;
}

.dual-product-ad-carousel-button {
    background-color: var(--secondary-color) !important;
}

.marketplace-hero-slide-button {
    background-color: var(--secondary-color) !important;
}

.carousel-4-slides-component-button {
    background-color: var(--secondary-color) !important;
    padding: 10px 25px !important;
}

.sponsored-feed-products-card {
    aspect-ratio: 1 / 1;
    border: 1px solid var(--border-color);
    width: 93%;
}

.shop-profile-data-tab-navigation {
    border: none;
    border-bottom: 1px solid var(--border-color);
    border-radius: 0px;
    padding: 25px;
    padding-top: 0px;
}


.shop-profile-sidebar-right {
    border-left: none !important;
}

.connections-widget-container {
    background-color: initial !important;
    border: none !important;
    border-radius: 0px;
    box-shadow: none;
    padding: 25px !important;
}

.connections-widget-tabs {
    border-bottom: 1px solid rgba(250, 250, 250, 0.3);
    margin-bottom: 15px;
    margin-top: 15px;
}

.connections-widget-header h1 {
    display: none;
}

.connections-widget-tabs {
    border-bottom: 1px solid #e2e8f0;
    margin-bottom: 0px;
    margin-top: 0px;
}

.following-list-widget-wrapper {
    background-color: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
    padding: 25px;
}

.following-list-widget-wrapper {
    background-color: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
    padding: 25px 0px;
}

.following-list-widget-title {
    display: none;
}

.follower-list-widget-title {
    display: none;
}

.follower-list-widget-search, .following-list-widget-search {
    background-color: var(--background-color) !important;
    border: 1px solid var(--border-color) !important;
    border-radius: var(--border-radius-full) !important;
    box-sizing: border-box;
    margin-bottom: 35px !important;
    margin-top: 25px;
    padding: 10px 25px !important;
    width: 100%;
}

.connections-widget-tab.active {
    color: var(--text-color);
    font-size: 16px;
    font-weight: 700;
}

.user-profile-data-tab-active {
    color: #fff !important;
    font-size: 16px;
}

.sponsored-ads-list {
  display: flex;
  flex-direction: column;
  gap: 25px !important;
}

.shop-create-product-page-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.shop-create-product-page-main {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.shop-create-product-page-sidebar {
  width: 80px;
}

@media (max-width: 800px) {
  .shop-create-product-page-sidebar {
    width: 0px;
  }
}

.shop-create-product-page-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shop-create-product-page-header {
  border-bottom: 1px solid #e5e7eb; 
  padding: 16px; 
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.shop-create-product-page-title {
  font-size: 1.125rem; 
  font-weight: bold;
  line-height: 1.75rem; 
  color: var(--text-color);
    margin-bottom: 10px;
    text-transform: uppercase;
    padding-left: 10px;
    border-left: 4px solid var(--primary-color);
}

.shop-create-product-page-upload {
  margin-top: 12px; 
  margin-left: 16px;
  display: flex;
  gap: 20px;
}

@media (min-width: 640px) {
  .shop-create-product-page-upload {
    margin-top: 0; 
  }
}

.create-product-component-variants-section {
  margin-top: 20px;
  padding: 20px;
  margin: 5px;
  border-radius: var(--border-radius-md);
  background-color: var(--background-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.create-product-component-variants-title {
  text-align: center;
  font-size: 24px;
}

.create-product-component-accordion-header {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid var(--border-color);
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: var(--border-radius-md);
}

.create-product-component-accordion-header.open {
  background-color: rgba(255, 255, 255, 0.1);
}

.create-product-component-accordion-title {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 20px;
}

.create-product-component-accordion-toggle {
  background-color: transparent;
  border: none;
  font-size: 14px;
  cursor: pointer;
  color: #007bff;
  padding: 0;
  text-decoration: underline;
}

.create-product-component-accordion-toggle:hover {
  text-decoration: none;
}

.create-product-component-accordion-content {
  padding: 15px;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-md);
  border-top: none;
}

.create-product-component-accordion-content img {
  max-width: 150px;
  margin-top: 10px;
}



.create-product-component-add-variant-button {
  display: inline-block;
  background-color: rgba(54, 211, 0, 0.3);
  border: 1px solid var(--primary-color);
   color: var(--text-color);
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 20px;
  transition: background-color 0.3s ease;
  border-radius: var(--border-radius-full);
  width: 100%;
}
.create-product-component-remove-variant {
  display: inline-block;
  background-color: rgba(200, 35, 51, 0.5);
  border: 1px solid #c82333;
   color: var(--text-color);
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 15px;
  transition: background-color 0.3s ease;
  border-radius: var(--border-radius-full);
}

.create-product-component-remove-variant:hover {
  background-color: #c82333;
}

.create-product-component-add-variant-button:hover {
  background-color: var(--primary-color);
}

.create-product-component-measurement-section {
  display: flex;
  gap: 15px;
}

.create-product-component-measurement-section .create-product-component-form-section {
  flex: 1;
}

.create-product-component-weight-dimensions {
  padding: 10px 16px;
  border: 1px solid rgba(250, 250, 250, 0.1);
  border-radius: var(--border-radius-lg);
  background-color: var(--background-color);
  display: flex;
  gap: 8px;
  font-family: 'Arial', sans-serif;
  width: fit-content;
  margin-top: 5px;
  margin-bottom: 5px;
}

.create-product-component-weight,
.create-product-component-dimensions {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.create-product-component-weight-label,
.create-product-component-dimensions-label {
  font-weight: bold;
  margin-right: 8px;
  color: #999;
}

.create-product-component-weight-value,
.create-product-component-dimensions-value {
 color: var(--text-color);;
}


.create-product-variant-form-group {
  background-color: var(--background-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: var(--border-radius-md);
  padding: 10px;
  overflow-y: auto;
  width: fit-content;
  margin-bottom: 10px;
}

.create-product-variant-dropdown-menu {
  display: flex;
}

.color-swatch-picker {
  display: flex;
  align-items: center;
  gap: 1rem; 
  margin-bottom: 1rem; 
  width: 100%;
}

.color-swatch-picker input[type="text"] {
  padding: 0.5rem 1rem;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 1rem;
  width: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease;
}

.color-swatch-picker input[type="text"]:focus {
  outline: none;
  border-color: #007bff; 
}

.color-swatch-picker input[type="color"] {
  width: 40px;
  height: 40px;
  border: none;
  cursor: pointer;
  padding: 20px;
  border-radius: 50%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); 
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.color-swatch-picker input[type="color"]:hover {
  transform: scale(1.1); 
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.color-swatch-picker input[type="color"]:focus {
  outline: none;
  border: 2px solid #007bff; 
}

.color-swatch-picker-button {
  width: 25%;
  background-color: var(--background-color);
 color: var(--text-color);;
  border: 1px solid var(--primary-color);
  border-radius: var(--border-radius-full);
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.color-swatch-picker-button:hover {
  background-color: var(--primary-color);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); 
}

.color-swatch-picker-button:focus {
  outline: none;
  background-color: #0056b3;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3); 
}

.color-swatch-picker-button:active {
  background-color: #004080; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transform: scale(0.98); 
}




/* Dimension Fields Section */
.create-product-component-measurement-section {
  display: flex;
  gap: 15px;
}

.create-product-component-measurement-section .create-product-component-form-section {
  flex: 1;
}

.create-product-component-variant-image-preview {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.create-product-component-variant-image {
  max-width: 100px;
  max-height: 100px;
  margin-right: 10px;
}

.create-product-component-remove-variant-image {
  background-color: #dc3545;
   color: var(--text-color);
  border: none;
  padding: 5px 10px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.create-product-component-remove-variant-image:hover {
  background-color: #c82333;
}

/* Styling for the dropdown container */
.create-product-variant-select-multiple {
  display: inline-block;
  position: relative;
  width: 100%;
}

/* Styling for the select box */
.create-product-variant-select {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: var(--text-light);
  appearance: none;
  cursor: pointer;
  height: auto;
  overflow: hidden;
  line-height: 1.5;
}

/* Add arrow to the select box */
.create-product-variant-select-multiple::after {
  content: '▾';
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  pointer-events: none;
}

/* Allow for multiple selections */
.create-product-variant-select[multiple] {
  height: auto;
  padding-right: 30px;
}

/* Styling for selected options */
.create-product-variant-select option:checked {
  background-color: #007bff;
   color: var(--text-color);
}

/* Improved hover styles */
.create-product-variant-select:hover {
  border-color: #007bff;
}

/* Focus styles */
.create-product-variant-select:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}



/* Brand Selection */
.brand-selection-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  padding: 24px;
  justify-items: center; 
}

@media (min-width: 1024px) {
  .brand-selection-container {
    grid-template-columns: repeat(3, 1fr); 
  }
  .post-widget-wrapper {
    padding: 25px 25px;
}
.category-scroller-container {
    padding-left: 0px;
}

.profile-sidebar-left {
    display: inline-block;
    padding: 0 !important;
    width: 25%;
    float: left;
    order: 1;
}
.profile-main-area {
        order: 2;
        width: 40%;
    }
.profile-sidebar-right {
        order: 3;
        width: 25%;
        display: inline-block;
        float: right;
    }
}

@media (min-width: 768px) {
  .brand-selection-container {
    grid-template-columns: repeat(2, 1fr); 
  }
}

/* Card styling */
.brand-selection-card {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 16px; /* Larger rounded corners */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15); /* More prominent shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.brand-selection-card:hover {
  transform: scale(1.05); /* Slight zoom on hover */
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
}

/* Image styling */
.brand-selection-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease; /* Smooth transition on hover */
}

.brand-selection-card:hover .brand-selection-image {
  transform: scale(1.1); /* Zoom image slightly on hover */
}

/* Overlay styling */
.brand-selection-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent); /* Darker gradient */
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 16px; /* Increased padding */
}

/* Brand name styling */
.brand-selection-name {
   color: var(--text-color);
  font-size: 1.5rem; /* Larger font size */
  font-weight: 700; /* Bolder font */
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.6); /* Text shadow for better readability */
}

/* Message styling */
.brand-selection-message {
  font-size: 1.2rem;
  color: #888;
  text-align: center;
  padding: 20px;
}


/* Map Component Styles */
.map-component-container {
  text-align: center;
  margin: 2px 0;
  padding: 10px 0;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.map-component-title {
  margin: 0 0 10px 0;
  color: #333;
}

.map-component-button {
  background-color: var(--primary-color);
   color: var(--text-color);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.map-component-button:hover {
  background-color: #45a049;
}

.map-component {
  height: 80vh;
}

.tag-selector-label {
  display: flex;
  font-size: 0.875rem;
  font-weight: 500;
 color: var(--text-color);;
  padding-bottom: 5px;
}

.shop-dashboard-hero-header-chart-controls {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.shop-dashboard-hero-header-chart-controls button {
  background-color: rgba(250, 250, 250, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  border-radius: var(--border-radius-full);
  transition: background-color 0.3s, color 0.3s;
}

.shop-dashboard-hero-header-chart-controls button.active-filter {
  background-color: rgba(75, 192, 192, 1);
 color: var(--text-color);;
  border-color: rgba(75, 192, 192, 1);
}

.shop-dashboard-hero-header-chart-controls button:hover {
  background-color: rgba(75, 192, 192, 0.2);
}

.shop-dashboard-hero-header-chart {
  width: 100%;
  height: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--border-radius-sm);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
}

.css-1usjvyn {
  border: 1px solid var(--border-color);
}

.shop-all-orders-page-container {
  width: 100%;
}

.shop-all-orders-page-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}


.shop-all-orders-page-main {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-left: 50px;
}

.ad-type-distribution-container {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  border-radius: var(--border-radius-sm);
  background-color: var(--background-color);
  padding: 10px;
}

.ad-type-distribution-title {
  text-align: center;
  color: #00f7ff;
  font-family: 'Orbitron', sans-serif;
}

@media (max-width: 480px) {
  .ad-type-distribution-container {
    display: none;
  }
}

.all-products-search-box {
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
}

.all-products-search-input {
  width: auto;
  height: 40px;
  padding: 0 60px;
  border: 1px solid var(--border-color) !important;
  border-radius: var(--border-radius-full) !important;
  background-color: var(--background-color);
   color: var(--text-color);
}

@media (max-width: 768px) {
  .all-products-search-input {
    padding: 0 30px;
    width: 100%;
  }
}

.all-products-search-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.all-products-search-clear-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
.all-products-component-clear-filters-button {
  padding: 8px 12px;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  width: 100%;
  cursor: pointer;
  border-radius: var(--border-radius-full);
  margin-left: 8px;
}

.all-products-component-clear-filters-button:hover {
  background-color: rgba(250, 250, 250, 0.3);
}

.shop-subscription-page__content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.shop-subscription-page__sidebar {
  width: 330px;
}

.shop-subscription-page__main {
  display: flex;
  justify-content: center;
  width: 100%;
}

/* Create Product Prompt */

.create-product-prompt {
  text-align: center;
}

.create-product-prompt__icon {
  margin: 0 auto;
  height: 48px;
  width: 48px;
  color: #9ca3af; 
}
.create-meme-prompt__icon {
  margin: 0 auto;
  height: 50%;
  width: 50%;
  color: #9ca3af; 
}

.create-product-prompt__title {
  margin-top: 8px;
  font-size: 0.875rem; 
  font-weight: 600; 
  color: #111827;
}

.create-product-prompt__text {
  margin-top: 4px;
  font-size: 0.875rem; 
  color: #6b7280;
}

.create-product-prompt__button-container {
  margin-top: 24px;
}

.create-product-prompt__button {
  display: inline-flex;
  align-items: center;
  border-radius: var(--border-radius-full);
  background-color: var(--background-color);
  border: 1px solid var(--primary-color);
  padding: 0.5rem 1rem;
  font-size: 0.875rem; 
  font-weight: 600; 
   color: var(--text-color);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  transition: background-color 0.2s ease;
}

.create-product-prompt__button:hover {
  background-color: rgba(250, 250, 250, 0.3); 
}

.create-product-prompt__button-icon {
  margin-left: -0.125rem;
  margin-right: 0.375rem;
  height: 20px;
  width: 20px;
}

.create-product-prompt__button:focus-visible {
  outline: 2px solid transparent;
  outline-offset: 2px;
  outline-color: var(--primary-color); 
}

/* Create Brand Prompt */

.create-brand-prompt {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 90vw;
  height: 40vw;
  align-items: center;
  justify-content: center;
}

.create-brand-prompt__icon {
  margin: 0 auto;
  height: 48px;
  width: 48px;
  color: #9ca3af; 
}

.create-brand-prompt__title {
  margin-top: 8px;
  font-size: 0.875rem; 
  font-weight: 600;  
}

.create-brand-prompt__text {
  margin-top: 4px;
  font-size: 0.875rem; 
  color: #6b7280;
}

.create-brand-prompt__button-container {
  margin-top: 24px;
}

.create-brand-prompt__button {
  display: inline-flex;
  align-items: center;
  border-radius: var(--border-radius-full);
  background-color: var(--background-color);
  border: 1px solid var(--primary-color);
  padding: 0.5rem 1rem;
  font-size: 0.875rem; 
  font-weight: 600; 
   color: var(--text-color);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  transition: background-color 0.2s ease;
}

.create-brand-prompt__button:hover {
  background-color: rgba(250, 250, 250, 0.3); 
}

.create-brand-prompt__button-icon {
  margin-left: -0.125rem;
  margin-right: 0.375rem;
  height: 20px;
  width: 20px;
}

.create-brand-prompt__button:focus-visible {
  outline: 2px solid transparent;
  outline-offset: 2px;
  outline-color: var(--primary-color); 
}

.mobile-navbar-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-top: 1px solid rgba(250, 250, 250, 0.3);
  margin-top: 10px;
}

.mobile-navbar-profile-section {
  display: flex;
  flex-direction: column;
}

.mobile-navbar-profile-item {
  padding: 10px;
}

.mobile-navbar-link {
 color: var(--text-color);;
  text-decoration: none;
  padding-bottom: 30px;
  font-weight: 500;
  padding-left: 6px;
  cursor: pointer;
  font-size: 24px;
}

.mobile-navbar-active {
  color: #17dd1f;
}

.mobile-navbar-link-cursor {
  cursor: pointer;
}

.mobile-navbar-auth-links {
  display: flex;
  flex-direction: column;
}

/* Mobile categories grid */

/* Grid container for mobile view only */
.category-grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding: 10px;
}

/* Style for each grid item */
.category-grid-item {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  aspect-ratio: 3/2;
}

/* Image inside each grid item */
.category-grid-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}
.category-grid-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1));
  transition: background-color 0.3s ease-in-out;
}

/* Hover effect for image */
.category-grid-item:hover .category-grid-image {
  transform: scale(1.05);
}

/* Title for each category */
.category-grid-title {
  position: absolute;
  bottom: 10px;
  left: 10px;
   color: var(--text-color);
  font-size: 16px;
  font-weight: bold;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.7);
}

/* Media query to show the grid only on mobile screens */
@media only screen and (max-width: 768px) {
  .category-grid-container {
    display: grid;
  }
}

@media only screen and (min-width: 769px) {
  .category-grid-container {
    display: none;
  }
  .mobile-navbar-wrapper {
    display: none;
  }
}

/* CSS for SignupCTA component */
.signup-cta-container {
  margin-top: 20px;
  position: relative;
  background: radial-gradient(circle, rgba(250, 250, 250, 0.1), #631563, #130f10);
  border: 1px solid rgba(250, 250, 250, 0.1);
  border-radius: var(--border-radius-md);
  max-height: 400px;
  overflow: hidden;
}

.signup-cta-inner {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.signup-cta-content {
  width: 50%;
  padding: 2rem;
  max-height: 400px;
  overflow: auto;
}

.signup-cta-logo-wrapper {
  max-width: 640px;
  margin: 0 auto;
}

.signup-cta-logo {
  height: 2.75rem;
}

.signup-cta-info {
  display: none;
  margin-top: 4rem;
}

.signup-cta-info.signup-cta-sm-flex {
  display: flex;
  margin-top: 1rem;
}

.signup-cta-promo {
  position: relative;
  border-radius: 9999px;
  padding: 0.75rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #6b7280;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.signup-cta-read-more {
  font-weight: 600;
  color: #16a34a;
}

.signup-cta-title {
  margin-top: 2rem;
  font-size: 2.5rem;
  font-weight: 700;
  color: #1f2937;
  line-height: 1.25;
}

.signup-cta-description {
  margin-top: 1.5rem;
  font-size: 1.125rem;
  line-height: 2;
  color: #4b5563;
}

.signup-cta-actions {
  margin-top: 2.5rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.signup-cta-get-started {
  border-radius: var(--border-radius-full);
  background-color: var(--background-color);
  border: 1px solid var(--primary-color);
  padding: 0.625rem 1.75rem;
  font-size: 0.875rem;
  font-weight: 600;
   color: var(--text-color);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  transition: background-color 0.2s;
}

.signup-cta-get-started:hover {
  background-color: #15803d;
}

.signup-cta-learn-more {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5;
 color: var(--text-color);;
}

.signup-cta-image-wrapper {
  width: 50%;
  position: relative;
  max-height: 400px;
  overflow: hidden;
}

.signup-cta-image {
  width: 100%;
  background-color: #f9fafb;
  object-fit: cover;
  height: 100%;
}

/*Image editor and cropper */

.image-editor__actions {
  display: flex;
  justify-content: center;
  background-color: var(--background-color);
  padding: 10px;
  gap: 20px;
}

.image-cropper__save-button {
  background-color: var(--background-color);
  border: 1px solid var(--primary-color);
  border-radius: var(--border-radius-full);
  padding: 10px;
}

.image-cropper__crop-button {
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-full);
  padding: 10px;
}

.image-editor__cropper {
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}

.react-advanced-cropper-canvas {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; 
}

.banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); 
  z-index: 1;
}

p.notification-feed-empty-message {
  margin-top: 25px;
  color: #7f7f7f !important;
}

.post-widget-popover {
  float: none;
  position: relative;
}

.color-swatches, .size-selection {
  margin-top: 10px;
}

.color-swatches h4, .size-selection h4 {
  font-size: 1rem;
  color: #333;
  font-weight: 600;
}

.color-swatches .swatch, .size-selection .size-btn {
  cursor: pointer;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.color-swatches {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  align-items: center;
}

.color-swatches-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.swatch {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid rgba(250, 250, 250, 0.3);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.swatch:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.swatch.active {
  border: 2px solid rgba(250, 250, 250, 1);
  transform: scale(1.2);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

.size-selection {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.size-selection-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.size-btn {
  padding: 8px 12px;
  font-size: 0.9rem;
  border: 1px solid var(--border-color);
  ;
  background-color: var(--background-color);
  transition: all 0.2s ease-in-out;
}

.size-btn:hover {
  background-color: rgba(250, 250, 250, 0.3);
  transform: translateY(-2px);
}

.size-btn.active {
  background-color: rgba(250, 250, 250, 0.3);
 color: var(--text-color);;
  transform: scale(1.1); 
  transition: transform 0.3s ease;
}



.all-products-mobile-variants {
  margin-top: 20px;
}

.all-products-mobile-variants-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.all-products-mobile-variant-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.all-products-mobile-variant-image {
  margin-right: 10px;
  border-radius: 5px;
}

.all-products-mobile-variant-details {
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.all-products-mobile-variant-label {
  font-weight: bold;
}

.all-products-mobile-variant-value {
  margin-left: 5px;
}

.user-profile-dashboard-container {
  width: 100%;
}

.user-profile-dashboard-main {
  display: flex;
  width: 100%;
  gap: 2rem; 
  padding: 2.5rem 1rem;
  
  margin: 0 auto;
}

.user-profile-dashboard-sidebar {
  position: sticky;
  top: 0;
  margin-top: 18%; 
}

@media (min-width: 800px) {
  .user-profile-dashboard-sidebar {
    width: 335px;
    margin-top: 0;
    margin-left: 50px;
  }
}
@media (max-width: 480px) {
  .user-profile-dashboard-main {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0rem; 
    padding: 1.5rem 0.5rem;
    max-width: 1200px;
    margin: 0 auto;
  }
}

.user-profile-dashboard-content {
  flex: 1;
}

.user-profile-dashboard-content-container {
  padding: 0px 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-md);
  background-color: var(--background-color);
}

.logout-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: var(--modal-overlay-backdrop-filter);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.logout-modal-content {
  background-color: var(--background-color);
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
}

.logout-modal-buttons {
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
}

.logout-modal-button-yes,
.logout-modal-button-no {
  background: rgba(0, 0, 0, 0.1);
   color: var(--text-color);
  border: 1px solid var(--primary-color);
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: var(--border-radius-full);
}

.logout-modal-button-no {
  border: 1px solid red;
}

.logout-modal-button-yes:hover,
.logout-modal-button-no:hover {
  background: rgba(0, 0, 0, 0.3);
}

/* Main Container */
.user-order-history-container {
  background-color: var(--background-color);
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  border-radius: var(--border-radius-md);
}

/* Header */
.user-order-history-header {
  padding-bottom: 2rem;
}
.user-order-history-title {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
}
.user-order-history-description {
  color: #666;
  font-size: 0.875rem;
}

/* Order List */
.user-order-history-orders-list {
  margin-top: 2rem;
}
.user-order-history-order-card {
  background-color: var(--background-color);
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* Order Details */
.user-order-history-order-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user-order-history-order-info {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
}
.user-order-history-info-item {
  display: flex;
  flex-direction: column;
}
.user-order-history-info-label {
  font-weight: bold;
 color: var(--text-color);;
}
.user-order-history-info-value {
  color: #555;
  margin-top: 0.25rem;
}

.user-order-history-status-info-container {
  font-weight: bold;
  display: flex;
  justify-content: end;
}
.user-order-history-status-info-value {
  color: rgba(250, 250, 250, 0.7);
  font-size: 24px;
}

/* Order Actions */
.user-order-history-order-actions {
  display: flex;
  gap: 1rem;
}
.user-order-history-order-action-button {
  background: var(--primary-color);
   color: var(--text-color);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius-full);
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: background 0.3s ease;
}
.user-order-history-order-action-button:hover {
  background: #0056b3;
}
.user-order-history-order-icon {
  margin-left: 0.5rem;
}

/* Product List */
.user-order-history-products-list {
  margin-top: 1.5rem;
}
.user-order-history-product-item {
  display: flex;
  align-items: center;
  padding: 1rem 0;
  border-top: 1px solid #e5e7eb;
}
.user-order-history-product-image-container {
  flex-shrink: 0;
  margin-right: 1.5rem;
}
.user-order-history-product-image {
  height: 160px;
  width: 160px;
  object-fit: cover;
  border-radius: 4px;
}
.user-order-history-product-details {
  display: grid;
  grid-template-columns: 1fr 1fr; 
  gap: 1rem; 
  align-items: start;
  width: 100%;
}


.user-order-history-product-info {
  display: flex;
  flex-direction: column;
}

.user-order-history-product-name {
  font-weight: bold;
  color: #333;
}
.user-order-history-product-description {
  color: #666;
  margin-top: 0.5rem;
}
.user-order-history-product-price {
  font-weight: bold;
  color: #333;
  margin-top: 0.5rem;
}
.user-order-history-product-actions {
  margin-top: 20px;
  display: flex;
  gap: 20px;
}
.user-order-history-product-link {
  color: var(--primary-color);
  text-decoration: none;
  transition: color 0.3s ease;
  border: 1px solid var(--primary-color);
  border-radius: var(--border-radius-lg);
  background-color: var(--background-color);
  margin-top: 20px;
  padding: 8px 12px;
}
.user-order-history-buy-again {
  margin-left: 10px;
}

/* No Orders Message */
.user-order-history-no-orders {
  color: #777;
  text-align: center;
  margin-top: 2rem;
}
@media (max-width: 768px) {
  .user-order-history-product-description {
   display: none;
  }
  .user-order-history-order-info {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1.5rem;
}
.user-order-history-order-details {
  display: block;
}
.user-order-history-order-actions {
  margin-top: 20px;
}
.user-order-history-product-image {
  height: 60px;
  width: 60px;
  object-fit: cover;
  border-radius: 4px;
}
.user-order-history-container {
  padding: 10px;
}
.user-order-history-product-details {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  align-items: start;
  width: 100%;
}
.user-order-history-status-info-container {
  justify-content: flex-start;
}
.user-order-history-buy-again {
  display: none;
}
.user-order-history-product-actions {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
}

.user-inbox-component-message-input {
  color: #777777 !important;
}

/* Container for the entire custom value selection */
.custom-value-selection-container {
  margin-top: 20px;
  padding: 10px;
}

/* Title of the custom value selection */
.custom-value-selection-container h4 {
  margin-bottom: 10px;
  font-size: 1.2em;
  font-weight: 600;
  color: #333;
}

/* Container for custom value buttons */
.custom-value-selection {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

/* Custom value button styling */
.custom-value-btn {
  padding: 8px 16px;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-full);
  background-color: var(--background-color);
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

/* Button hover effect */
.custom-value-btn:hover {
  background-color: var(--primary-color);
  border-color: #aaa;
}

/* Active button styling */
.custom-value-btn.active {
  background-color: var(--primary-color);
 color: var(--text-color);;
  border-color: var(--primary-color);
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .custom-value-selection {
    gap: 5px;
  }
  .custom-value-btn {
    padding: 6px 12px;
    font-size: 0.9em;
  }
} 

.location-retry-button {
  background-color: var(--background-color);
   color: var(--text-color);
  padding: 12px 24px;
  font-size: 16px; 
  font-weight: bold;
  border: 1px solid var(--primary-color); 
  border-radius: var(--border-radius-full); 
  cursor: pointer; 
  transition: background-color 0.3s ease, transform 0.2s ease; 
}

.location-retry-button:hover {
  background-color: var(--primary-color); 
}

.location-retry-button:active {
  transform: scale(0.98); 
  background-color: #388e3c; 
}

.location-retry-button:focus {
  outline: none; 
  box-shadow: 0 0 4px 2px rgba(0, 150, 0, 0.4); 
}


/* Container Styles */
.top-level-dropdown {
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
  background-color: var(--discover-background);
  backdrop-filter: var(--modal-overlay-backdrop-filter);
  width: 69vw;
  min-height: 69vh;
  max-width: 1000px;
  margin: 0 auto;
  overflow: hidden;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 60px;
  overflow-y: scroll;
}

@media (max-width: 480px) {
  .top-level-dropdown {
    width: 100vw;
    height: 100vh;
  }
}

/* Navigation Styles */
.top-level-dropdown-nav {
  display: flex;
  justify-content: space-around;
  padding: 1rem;
  background-color: var(--background-color);
 color: var(--text-color);;
  overflow-x: scroll;
  gap: 10px;
  border-bottom: 1px solid var(--border-color);
}

.top-category-item {
  cursor: pointer;
  font-weight: 500;
  color: var(--text-color);
  transition: color 0.3s ease, transform 0.2s ease;
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius-lg);
}

.top-category-item:hover {
  color:  var(--primary-color);
  transform: scale(1.05);
}

.top-category-item.active {
  color: var(--primary-color);
  font-weight: bold;
  background-color: rgba(54, 211, 0, 0.1);
}

/* Dropdown Content Styles */
.top-level-dropdown-content {
  border-top: 1px solid #e0e0e0;
  display: flex;
  justify-content: center;
  animation: fadeIn 0.3s ease;
}


.marketplace-dropdown-container {
  z-index: 50;
  top: 3.5rem;
  width: 100%;
  height: 100%;
  border-top: 2px solid rgba(250, 250, 250, 0.3);
}

.marketplace-dropdown-inner {
  display: flex;
  padding: 1rem;
}

.marketplace-dropdown-section {
  flex: 1;
  
}

@media (max-width: 480px) {
  .marketplace-dropdown-section {
    flex: 1;
    margin-right: 0rem;
  }
  .marketplace-dropdown-inner {
    display: flex;
    padding: 1rem;
    flex-direction: column;
    margin-bottom: 50px;
  }
} 

.marketplace-dropdown-title {
  font-size: 1.25rem;
 color: var(--text-color);;
  margin-bottom: 0.75rem;
}
/* Shared styles for dropdown items and products */
.marketplace-dropdown-item,
.marketplace-dropdown-product {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  cursor: pointer;
  transition: background 0.3s ease;
  border-radius: var(--border-radius-md);
}

/* Product container */
.marketplace-dropdown-product {
  padding: 5px;
  width: 50%;
}

/* Hover effect */
.marketplace-dropdown-item:hover,
.marketplace-dropdown-product:hover {
  background-color: var(--background-color);
}

/* Images */
.marketplace-dropdown-item-image,
.marketplace-dropdown-product-image {
  width: 100px; /* Default size */
  height: 100px;
  border-radius: 0.375rem;
  margin-right: 0.5rem;
}

/* Text */
.marketplace-dropdown-item-text,
.marketplace-dropdown-product-title {
  color: var(--text-color);
  font-weight: bold;
  font-size: 1rem; 
}

/* Responsive Styles */
@media (max-width: 768px) {
  .marketplace-dropdown-item,
  .marketplace-dropdown-product {
    flex-direction: column; 
    align-items: flex-start; 
    padding: 0.5rem;
  }

  /* Full width for mobile */
  .marketplace-dropdown-product {
    width: 100%;
  }

  /* Adjust image size */
  .marketplace-dropdown-item-image,
  .marketplace-dropdown-product-image {
    width: 100%;
    height: 100%;
    margin-right: 0; 
    margin-bottom: 0.5rem; 
  }

  /* Adjust text size and alignment */
  .marketplace-dropdown-item-text,
  .marketplace-dropdown-product-title {
    font-size: 0.875rem;
    text-align: left;
  }
}

@media (max-width: 480px) {

  .marketplace-dropdown-item-text,
  .marketplace-dropdown-product-title {
    font-size: 0.75rem;
  }
}


.marketplace-dropdown-featured-shop {
  margin-top: 1rem;
  overflow: hidden;
  border-radius: var(--border-radius-md); 
}

.marketplace-dropdown-featured-shop-inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.marketplace-dropdown-featured-shop-inner:hover {
  transform: scale(1.05); 
}

.marketplace-dropdown-featured-shop-image {
  width: 100%;
  border-radius: var(--border-radius-md);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.4s ease; 
}

.marketplace-dropdown-featured-shop-overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-md);
  transition: background-color 0.3s ease;
}

.marketplace-dropdown-featured-shop-inner:hover .marketplace-dropdown-featured-shop-overlay {
  background-color: rgba(0, 0, 0, 0.5); 
}

.marketplace-dropdown-featured-shop-text {
 color: var(--text-color);;
  font-size: 1.2rem;
  font-weight: bold;
  opacity: 1;
  transition: transform 0.3s ease, opacity 0.3s ease;
  transform: translateY(0); 
}

.marketplace-dropdown-featured-shop-inner:hover .marketplace-dropdown-featured-shop-text {
  transform: translateY(-5px); 
}

.marketplace-dropdown-feature-products {
  display: flex !important;
}


/* MagazineDropdown.css */

.magazine-dropdown-container {
  z-index: 50;
  width: 100%;
  padding: 1rem;
  border-top: 2px solid rgba(250, 250, 250, 0.3);
 color: var(--text-color);;
  font-family: Arial, sans-serif;
}

.magazine-dropdown-inner {
  display: flex;
  flex-direction: column;
}

.magazine-dropdown-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.magazine-dropdown-content {
  display: flex;
  gap: 2rem;
}

.magazine-dropdown-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.magazine-dropdown-section {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 1rem;
  border-radius: 0.375rem;
  transition: background-color 0.3s ease;
}

.magazine-dropdown-section:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.magazine-dropdown-section-title {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.magazine-dropdown-section-description {
  font-size: 1rem;
  color: #ccc;
}

.magazine-dropdown-right {
  flex: 1;
}

.magazine-dropdown-mock-magazine {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}

.magazine-dropdown-article {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0.375rem;
  overflow: hidden;
  transition: transform 0.2s;
}

.magazine-dropdown-article:hover {
  transform: translateY(-5px);
}

.magazine-dropdown-article-image-container {
  height: 200px;
  aspect-ratio: 16/9;
  object-fit: cover;
  object-position: center;
}

.magazine-dropdown-article-image {
  width: 100%;
  height: 100%;
}

.magazine-dropdown-article-title {
  font-size: 1rem;
  font-weight: bold;
  margin: 0.5rem;
}

.magazine-dropdown-article-author {
  font-size: 0.875rem;
  color: #ccc;
  margin: 0 0.5rem 0.5rem;
}

/* StreamingLaunchpad.css */

.streaming-dropdown-container {
  width: 100%;
  padding: 1rem;
  color: var(--text-color);
  font-family: Arial, sans-serif;
}

.streaming-dropdown-featured-stream {
  margin-bottom: 2rem;
}

.streaming-dropdown-video-player img {
  width: auto;
  max-height: 300px;
  border-radius: var(--border-radius-md);
}

.streaming-dropdown-stream-info h2 {
  font-size: 2rem;
  margin: 0.5rem 0;
}

.streaming-dropdown-stream-info p {
  margin: 0.25rem 0;
  color: #b3b3b3;
}

.streaming-dropdown-section {
  margin-bottom: 2rem;
}

.streaming-dropdown-section h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.streaming-dropdown-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1rem;
}

.streaming-dropdown-card {
  background-color: #27272a;
  border-radius: 0.5rem;
  overflow: hidden;
  transition: transform 0.2s;
}

.streaming-dropdown-card:hover {
  transform: translateY(-5px);
}

.streaming-dropdown-card img {
  width: 100%;
  height: auto;
}

.streaming-dropdown-card-info {
  padding: 0.5rem;
}

.streaming-dropdown-name {
  font-size: 1rem;
  font-weight: bold;
}

.streaming-dropdown-details {
  font-size: 0.875rem;
  color: #a1a1aa;
}

/* Music Dropdown */

/* MusicDropdown.css */

.music-dropdown-container {
  z-index: 50;
  width: 100%;
  padding: 1.5rem;
  border-top: 1px solid #282828;
  color: #b3b3b3;
  font-family: 'Arial', sans-serif;
}

.music-dropdown-header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-bottom: 1px solid #282828;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.music-dropdown-title {
  font-size: 2rem;
  color: #1db954; /* Spotify green */
  font-weight: 700;
}

.music-player {
  display: flex;
  align-items: center;
  background: linear-gradient(135deg, rgba(255, 102, 178, 0.7) 20%, rgba(153, 102, 255, 0.7) 80%);
  backdrop-filter: var(--modal-overlay-backdrop-filter);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.6);
  transition: background 0.4s ease, transform 0.2s ease;
}

.music-player:hover {
  background: linear-gradient(135deg, rgba(255, 102, 178, 0.8) 10%, rgba(153, 102, 255, 0.8) 90%);
  transform: scale(1.02); /* Slight scale effect for subtle emphasis */
}



.music-player-cover {
  width: 120px;
  height: 120px;
  flex-shrink: 0;
  margin-right: 1.5rem;
  border-radius: var(--border-radius-sm);
  overflow: hidden;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
}

.music-player-cover-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.music-player-info {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.music-player-title {
  font-size: 1.1rem;
  font-weight: 600;
 color: var(--text-color);;
  margin-bottom: 0.5rem;
}

.music-progress-bar {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-top: 0.5rem;
  color: #b3b3b3;
  font-size: 0.9rem;
}

.current-time,
.duration {
  min-width: 40px;
  color: #b3b3b3;
}

.progress-slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px; /* Height of the progress bar */
  background-color: #404040;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  background: linear-gradient(to right, #1db954 0%, #1db954 var(--progress), #404040 var(--progress), #404040 100%);
  transition: background 0.3s ease, height 0.2s ease;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4); /* Soft shadow for depth */
}

/* Track height grows slightly on hover */
.progress-slider:hover {
  height: 10px;
}

/* Thumb styling */
.progress-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px; /* Size of the thumb */
  height: 16px;
  background-color: #1db954;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #fff;
  margin-top: -4px; /* Centers the thumb vertically */
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3); /* Soft shadow for depth */
}

/* Thumb grows slightly on hover */
.progress-slider:hover::-webkit-slider-thumb {
  background-color: #1ed760; /* Lighter green on hover */
  transform: scale(1.1); /* Enlarged slightly for feedback */
}

/* Mozilla Firefox styling */
.progress-slider::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background-color: #1db954;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #fff;
  margin-top: -4px; /* Centers the thumb vertically for Firefox */
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Hover effects for Firefox */
.progress-slider:hover::-moz-range-thumb {
  background-color: #1ed760;
  transform: scale(1.1);
}

/* Progress bar styling */
.progress-slider::-webkit-slider-runnable-track {
  height: 8px;
  border-radius: 5px;
  background: #404040;
}


.music-player-controls {
  display: flex;
  gap: 1rem;
 color: var(--text-color);;
  margin-left: 1.5rem;
  align-items: center;
}

.player-icon {
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.2s ease;
}

.play-pause-icon {
  font-size: 2rem;
}

.player-icon:hover {
  color: #1db954;
}

.music-dropdown-sections {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
}

.music-dropdown-section {
  background-color: #181818;
  padding: 1rem;
  border-radius: 0.75rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
}

.music-dropdown-section:hover {
  background-color: #282828;
  transform: translateY(-2px);
}

.music-dropdown-section-title {
  font-size: 1.2rem;
 color: var(--text-color);;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.music-dropdown-section-description {
  font-size: 0.9rem;
  color: #b3b3b3;
}

@media (max-width: 768px) {
  .music-player {
    flex-direction: column;
    align-items: center;
    padding: 15px;
  }

  .music-player-cover {
    width: 100px;
    height: 100px;
    margin: 0 0 10px 0;
  }

  .music-player-info {
    text-align: center;
  }

  .music-player-title {
    font-size: 1rem;
  }

  .music-progress-bar {
    gap: 8px;
    font-size: 0.8rem;
  }

  .music-player-controls {
    margin: 10px 0 0 0;
    gap: 0.5rem;
  }

  .player-icon {
    font-size: 1.25rem;
  }

  .play-pause-icon {
    font-size: 1.75rem;
  }

  .music-dropdown-title {
    font-size: 1.5rem;
  }

  .music-dropdown-sections {
    grid-template-columns: 1fr; 
    gap: 1rem;
  }

  .music-dropdown-section {
    padding: 0.75rem;
  }

  .music-dropdown-section-title {
    font-size: 1rem;
  }

  .music-dropdown-section-description {
    font-size: 0.8rem;
  }
}


/* StudiosDropdown.css */

.studios-dropdown-container {
  z-index: 50;
  width: 100%;
  padding: 1rem;
 color: var(--text-color);;
  border-top: 2px solid rgba(250, 250, 250, 0.3);
}

.studios-dropdown-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Image Collage Scroller */
.studios-dropdown-image-scroller {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  margin-bottom: 1rem;
}

.studios-dropdown-image-scroller::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.studios-dropdown-image-item {
  flex: 0 0 auto;
  margin-right: 1rem;
  scroll-snap-align: start;
}

.studios-dropdown-image-item img {
  height: 240px;
  width: 240px;
  object-fit: cover;
  border-radius: 0.5rem;
}

.studios-dropdown-title {
  font-size: 2rem;
  margin: 1.5rem 0;
  text-align: center;
}

/* Studio Sections */
.studios-dropdown-sections {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.studios-dropdown-section {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  width: 250px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
}

.studios-dropdown-section:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transform: translateY(-5px);
}

.studios-dropdown-section-icon {
  font-size: 2rem;
  margin-right: 0.75rem;
}

.studios-dropdown-section-title {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.studios-dropdown-section-description {
  font-size: 0.95rem;
  color: #ccc;
}


.community-dropdown-container {
  z-index: 50;
  width: 100%;
  max-width: 1000px;
  padding: 1rem;
  border-top: 2px solid rgba(250, 250, 250, 0.3);
}

.community-dropdown-inner {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.community-dropdown-title {
  font-size: 1.5rem;
 color: var(--text-color);;
  margin-bottom: 1rem;
}

.community-dropdown-sections {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.community-dropdown-section {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0.75rem;
  border-radius: 0.375rem;
  transition: background-color 0.3s ease;
}

.community-dropdown-section:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.community-dropdown-section-title {
  font-size: 1.1rem;
 color: var(--text-color);;
  margin-bottom: 0.5rem;
}

.community-dropdown-section-description {
  font-size: 0.9rem;
  color: #ccc;
}

.trending-post-container {
  overflow: auto;
  width: 50%;
}
.marketplace-product-carousel-container {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 20px 50px; /* Increased padding to allow space for buttons */
}

.marketplace-product-carousel-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.marketplace-product-carousel-slide {
  display: flex;
  gap: 20px;
  transition: transform 0.5s ease-in-out;
}

.marketplace-product-carousel-slide > * {
  flex: 1;
}

.marketplace-product-carousel-nav-button {
  background-color: rgba(0, 0, 0, 0.8);
  border: none;
  border-radius: var(--border-radius-full);
  font-size: 2rem;
  cursor: pointer;
  font-size: 20px;
 color: var(--text-color);;
  transition: color 0.3s ease;
  position: absolute;
  top: 50%;
  width: 32px;
  height: 32px;
  transform: translateY(-50%);
  z-index: 1;
}

.marketplace-product-carousel-nav-button:hover {
  color: var(--primary-color);
}

.marketplace-product-carousel-nav-button.prev {
  left: 0px; /* Previously was -50px, now inside container padding */
}

.marketplace-product-carousel-nav-button.next {
  right: 0px; /* Previously was -50px, now inside container padding */
}

.marketplace-product-carousel-no-products-text {
  text-align: center;
  font-size: 1.5rem;
  color: #555;
  margin: 2rem 0;
}

/* Responsive Styling */
@media (max-width: 1023px) {
  .marketplace-product-carousel-container {
    padding: 20px 40px;
  }
  .marketplace-product-carousel-nav-button.prev {
    left: 10px;
  }
  .marketplace-product-carousel-nav-button.next {
    right: 10px;
  }
}

@media (max-width: 768px) {
  /* Adjust if needed */
}

@media (max-width: 600px) {
  .marketplace-product-carousel-slide {
    flex-direction: column;
    align-items: center;
  }

  .marketplace-product-carousel-slide > * {
    width: 100%;
    max-width: 300px;
  }

  /* For mobile, you may opt to keep the buttons at sides or reposition them differently: */
  .marketplace-product-carousel-nav-button.prev,
  .marketplace-product-carousel-nav-button.next {
    top: auto;
    bottom: -50px;
    transform: none;
  }

  .marketplace-product-carousel-nav-button.prev {
    left: 30%;
  }

  .marketplace-product-carousel-nav-button.next {
    right: 30%;
  }
}


/* AuthenticationPrompt.module.css */
.authentification-prompt-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
  text-align: center;
  padding: 20px;
  background-color: #0c1117; 
  box-shadow: 6px 4px 6px #000000;
  border-radius: var(--border-radius-sm);
  border: 3px solid #777777; 
  z-index: 50; 
  position: relative; 
}


.authentification-prompt-title {
  font-size: 2em;
 color: var(--text-color);;
  margin-bottom: 10px;
}

.authentification-prompt-message {
  font-size: 1.2em;
 color: var(--text-color);;
  margin-bottom: 30px;
}

.authentification-prompt-buttonContainer {
  display: flex;
  gap: 20px;
}

.authentification-prompt-button,
.authentification-prompt-buttonOutline {
  padding: 12px 24px;
  font-size: 1em;
  border-radius: var(--border-radius-full);
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.authentification-prompt-button {
  background-color: var(--primary-color);
 color: var(--text-color);;
}

.authentification-prompt-button:hover {
  background-color: var(--primary-color);
}

.authentification-prompt-buttonOutline {
  background-color: transparent;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
}

.authentification-prompt-buttonOutline:hover {
  background-color: var(--primary-color);
 color: var(--text-color);;
}

/* Category scroller */

.category-scroller-container {
  overflow-x: auto;
  padding: 20px;
  padding-top: 25px;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: var(--background-color);
  border-bottom: 1px solid rgba(250, 250, 250, 0.3);
  border-top: none;
  margin-top: 0;
}


.category-scroller {
  display: flex;
  gap: 10%;
  width: 100%;
  justify-content: center;
}

.category-item {
  flex: 0 0 auto;
  text-align: center;
  cursor: pointer;
}

.category-image-wrapper {
  width: 90px;
  height: 90px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.category-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.category-name {
  margin-top: 10px;
  font-size: 1em;
}

.category-item:hover .category-image-wrapper {
  transform: scale(1.05);
}

.shop-profile-data-dashboard-link {
  width: 90%;
  margin: auto !important;
}

.image-editor__aspect-ratio-selector {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background-color: var(--background-color);
}

.image-editor__aspect-ratio-selector p {
  margin: 0;
}

.image-editor__aspect-ratio-selector button {
  padding: 0.5rem 1rem;
  border: 1px solid var(--border-color);
  background-color: var(--background-color);
  cursor: pointer;
  border-radius: var(--border-radius-full);
 color: var(--text-color);;
  width: 100px;
}

.image-editor__aspect-ratio-selector button.active {
  background-color: var(--primary-color);
}

.advanced-cropper {
  height: 100%;
}

.meme-creator h2, .meme-upload-section {
  font-weight: 700;
  font-size: 20px;
}

.music-player-cover {
    height: 100px;
    margin-right: 15px;
    width: 100px;
}

.music-player {
    border-radius: 15px;
    padding: 15px 15px;
}

.product-card-component-image {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.product-card-component-container {
    padding-bottom: 25px;
}

.product-card-component-product-name {
    font-size: 18px !important;
    font-weight: 700;
    text-transform: capitalize;
}

.follower-list-widget-content {
    padding-bottom: 15px;
}



.followers-avatar-display-container {
    gap: 10px;
}

span.marketplace-dropdown-item-text {
    padding: 5px;
    margin: 5px;
}

.my-post-widget-flex-between {
    gap: 10px;
}



.css-1ald77x {
    color: #CCCCCC !important;
}

.css-15kq27i {
    color: var(--text-color);
}

label#\:rc\:-label {
    color: #FFFFFF !important;
}

label#\:rb\:-label {
    color: #ffffff !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.css-1bp1ao6 {
    color: #ffffff !important;
}

.selected-options-display {
    gap: 40px;
    margin-bottom: 25px;
}

span.ad-preview-map-product-slider-button {
    background-color: var(--secondary-color) !important;
    border-radius: var(--border-radius-full) !important;
    padding: 10px;
}

.ad-preview-quad-product-carousel-button {
    background-color: var(--secondary-color) !important;
}

.ad-preview-dual-product-carousel-button {
    background-color: var(--secondary-color) !important;
}

.product-details-component__icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.product-details-component__icon--delivery {
  color: var(--primary-color); 
}

.product-details-component__icon--pickup {
  color: #FF9800;
}

.switcheroo-modal-item-image {
  border: 1px solid #ffffff;
}

.product-card-component-quantity-selector {
  padding: 10px;
}

.about-user-container {
  width: 90%;
  margin-top: 20px;
}

img.fallback-avatar-image {
  border-radius: var(--border-radius-full);
}

/* Documentation */

/* CreateProductDoc.css */
.doc-container {
  margin: 0 auto;
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.08); 
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  width: 100%;
  transition: box-shadow 0.3s ease;
}

.doc-container:hover {
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.3);
}

.doc-title {
  font-size: 2.4rem;
  font-weight: 700;
  color: var(--text-color);
  margin-bottom: 1.5rem;
  letter-spacing: -0.5px;
  transition: color 0.3s ease;
}

.doc-title:hover {
  color: #00aaff;
}

.doc-intro {
  font-size: 1.2rem;
  color: #cccccc;
  margin-bottom: 2rem;
  line-height: 1.75;
}

.doc-steps {
  list-style-type: decimal;
  margin-left: 2rem;
  color: var(--primary-color);
}

.doc-step {
  margin-bottom: 2.5rem;
  padding-left: 0.5rem;
  border-left: 3px solid var(--primary-color); 
  transition: border-color 0.3s ease;
}

.doc-step:hover {
  border-color: var(--primary-color);
}

.doc-step h2 {
  font-size: 1.75rem;
  color: var(--primary-color);
  margin-bottom: 0.75rem;
  font-weight: 600;
}

.doc-step p {
  font-size: 1.05rem;
  color: #f5f5f5;
  line-height: 1.8;
}

.doc-step ul {
  list-style-type: disc;
  margin-left: 1.8rem;
  color: #f5f5f5;
}

.doc-step ul li {
  margin-bottom: 0.6rem;
  font-size: 1rem;
  transition: color 0.3s ease;
}


.doc-additional {
  margin-top: 2.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}

.doc-additional h2 {
  font-size: 1.75rem;
  color: #00aaff;
  margin-bottom: 0.75rem;
  font-weight: 600;
}

.doc-additional ul {
  list-style-type: disc;
  margin-left: 1.8rem;
  color: #f5f5f5;
}

.doc-additional ul li {
  margin-bottom: 0.6rem;
  font-size: 1.05rem;
  transition: color 0.3s ease;
}

.doc-additional ul li:hover {
  color: #00c1ff;
}


/* DocumentationSidebar.css */

.documentation-sidebar-container {
  width: 250px;
  height: 100vh;
  top: 0px;
  position: sticky;
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgba(250, 250, 250, 0.3);
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.documentation-sidebar-header {
  display: flex;
  align-items: center;
  height: 60px;
  margin-bottom: 20px;
}

.documentation-sidebar-logo {
  width: auto;
}

.documentation-sidebar-nav {
  flex: 1;
  overflow-y: auto;
}

.documentation-sidebar-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.documentation-sidebar-list-item {
  margin-bottom: 10px;
}

.documentation-sidebar-link {
  display: block;
  padding: 10px 15px;
  border-radius: 4px;
 color: var(--text-color);;
  text-decoration: none;
  font-weight: 600;
  background-color: transparent;
  transition: background-color 0.2s;
}

/* Add active style for main section */
.documentation-sidebar-link.current {
  background-color: rgba(250, 250, 250, 0.5); 
  color: var(--primary-color); 
}

.documentation-sidebar-link:hover {
  background-color: rgba(250, 250, 250, 0.3);
}

/* Sublist link styles */
.documentation-sidebar-sublink {
  display: block;
  padding: 8px 15px;
  border-radius: 4px;
 color: var(--text-color);;
  text-decoration: none;
  background-color: transparent;
  transition: background-color 0.2s;
}

.documentation-sidebar-sublink.current {
  background-color: rgba(250, 250, 250, 0.5);
  color: var(--primary-color); 
}

.documentation-sidebar-sublink:hover {
  background-color: rgba(250, 250, 250, 0.3);
}


.documentation-sidebar-disclosure {
  margin-bottom: 10px;
}

.documentation-sidebar-disclosure-button {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  width: 100%;
  border-radius: 4px;
 color: var(--text-color);;
  text-decoration: none;
  font-weight: 600;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.2s;
}

.documentation-sidebar-disclosure-button:hover {
  background-color: rgba(250, 250, 250, 0.3);
}

.documentation-sidebar-chevron-icon {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  transition: transform 0.3s;
}

.documentation-sidebar-chevron-icon.open {
  transform: rotate(90deg);
}

.documentation-sidebar-sublist {
  list-style: none;
  padding: 5px 0 0 25px;
  margin: 0;
}

.documentation-sidebar-sublist-item {
  margin-bottom: 8px;
}

.documentation-sidebar-sublink {
  display: block;
  padding: 8px 15px;
  border-radius: 4px;
 color: var(--text-color);;
  text-decoration: none;
  background-color: transparent;
  transition: background-color 0.2s;
}

.documentation-sidebar-sublink:hover {
  background-color: rgba(250, 250, 250, 0.3);
}

.documentation-sidebar-profile {
  margin-top: auto;
  padding-top: 20px;
  border-top: 1px solid #e2e8f0;
}

.documentation-sidebar-profile-link {
  display: flex;
  align-items: center;
  text-decoration: none;
 color: var(--text-color);;
  font-weight: 600;
  padding: 10px 0;
}

.documentation-sidebar-profile-img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 15px;
}

.documentation-sidebar-profile-name {
  font-size: 1rem;
}

.create-product-doc-page {
  display: flex;
}

/* HelpContactForm.css */

.help-contact-form-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  gap: 20px; 
  background-color: var(--background-color);
  background-image: url('https://images.unsplash.com/photo-1542281286-9e0a16bb7366?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'); /* Add your background image URL here */
  background-size: cover; 
  background-position: center; 
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 100%;
}



.help-contact-form-info {
  flex: 1;
  padding: 20px;
  border-bottom: 1px solid #e2e8f0;
}

.help-contact-form-title {
  font-size: 2rem;
  font-weight: bold;
 color: var(--text-color);;
  margin-bottom: 1rem;
}

.help-contact-form-description {
  font-size: 1rem;
  color: #555;
  margin-bottom: 1.5rem;
}

.help-contact-form-contact {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.help-contact-form-contact-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.help-contact-form-icon {
  font-size: 1.5rem;
  color: #555;
}

.help-contact-form-link {
  color: #0070f3;
  text-decoration: none;
  font-weight: 600;
}

.help-contact-form-link:hover {
  color: #005bb5;
}

.help-contact-form-form {
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(3px);
  border-radius: var(--border-radius-md);
}

.help-contact-form-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.help-contact-form-field {
  display: flex;
  flex-direction: column;
}

.help-contact-form-field-full {
  grid-column: span 2;
  display: flex;
  flex-direction: column;
}

.help-contact-form-label {
  font-weight: bold;
 color: var(--text-color);;
  margin-bottom: 5px;
}

.help-contact-form-input,
.help-contact-form-textarea {
  padding: 10px;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-lg);
  font-size: 1rem;
 color: var(--text-color);;
}

.help-contact-form-input:focus,
.help-contact-form-textarea:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 5px rgba(0, 112, 243, 0.2);
}

.help-contact-form-textarea {
  resize: vertical;
}

.help-contact-form-submit {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.help-contact-form-button {
  background-color: var(--primary-color);
  color: var(--text-color);
  border: none;
  padding: 10px 20px;
  border-radius: var(--border-radius-full);
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s;
}

.help-contact-form-button:hover {
  background-color: #005bb5;
}


.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: black;
}

.lightning {
  fill: yellow;
  filter: drop-shadow(0 0 8px yellow);
  animation: flicker 0.2s infinite alternate, shake 0.1s infinite;
}


.following-list-widget-wrapper {
  padding-top: 0px !important;
}

.shop-info-sidebar-action-button {
  font-size: 12px !important;
  font-weight: 700 !important;
}

.notification-feed-avatar {
  border: 1px solid #efefef !important;
}

.best-deals-homepage-container {
  padding: 0 !important;
  width: 100%;
  overflow: scroll !important;
}

.product-details-component__send-message-text {
  font-size: 12px !important;
}

message-button {
  min-width: 165px !important;
}

.size-btn.active {
  font-size: 12px !important;
  font-weight: 700 !important;
}

.product-details-component__shop-ratings {
  font-size: 12pxv;
  font-weight: 600 !important;
}

.color-swatches-container {
  font-size: 12px !important;
}

.size-selection-container {
  font-size: 12px !important;
}

.size-btn {
  font-size: 12px !important;
}

p.my-post-widget-typography {
  font-size: 12px !important;
  padding-left: 7px;
  font-weight: 600 !important;
}

.shop-info-sidebar-actions {
  font-size: 12px !important;
  font-weight: 600 !important;
}

.following-list-widget-wrapper {
  padding-top: 0px !important;
}

.tag-scroller-container {
  display: flex;
  overflow-x: auto;
  padding: 10px;
}

.tag-menu-item {
  flex: 0 0 auto;
  margin-right: 10px;
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-md);
  cursor: pointer;
}

.tag-menu-item:hover {
  background-color: var(--background-color);
}

.checkout-component-button {
  border-radius: 100px !important;
}

.checkout-component-input {
  border-radius: var(--border-radius-full) !important;
}

.meme-create-button {
  background-color: var(--background-color);
  margin-bottom: -25px !important;
}

.rooms-profile-create-room-button {
  padding: 5px 15px;
}

.find-events-button {
  padding: 5px 25px !important;
}

.follower-list-widget-search, .following-list-widget-search {
  margin-top: 0px;
}

.shop-info-sidebar-avatar-label {
  cursor: pointer;
}

.room-members {
  width: 87%;
}

.loader-component-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 1);
  z-index: 9999; 
}

.loader-component-overlay .lottie-container {
  width: 300px;
  height: 300px;
}

.my-post-widget-slideshow-container {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.my-post-widget-slideshow {
  position: relative;
}

.my-post-widget-prev-button,
.my-post-widget-next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
   color: var(--text-color);
  border: none;
  font-size: 24px;
  padding: 8px 16px;
  cursor: pointer;
}

.my-post-widget-prev-button {
  left: 0;
}

.my-post-widget-next-button {
  right: 0;
}

.my-post-widget-slideshow-image {
  width: 100%;
  height: auto;
}

.my-post-widget-thumbnails-container {
  display: flex;
  overflow-x: auto;
  margin-top: 10px;
}

.my-post-widget-thumbnail {
  position: relative;
  margin-right: 5px;
  cursor: pointer;
}

.my-post-widget-thumbnail.active {
  border: 2px solid blue;
}

.my-post-widget-thumbnail-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
}

.my-post-widget-thumbnail-delete-button {
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.7);
  border: none;
  font-size: 16px;
  cursor: pointer;
}

/* Slideshow Container */
.post-widget-slideshow-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

/* Slideshow Navigation */
.post-widget-slideshow {
  position: relative;
  display: flex;
  justify-content: center;
}

.post-widget-prev-button,
.post-widget-next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
   color: var(--text-color);
  border: none;
  font-size: 24px;
  padding: 8px 16px;
  cursor: pointer;
  z-index: 1;
}

.post-widget-prev-button {
  left: 0;
}

.post-widget-next-button {
  right: 0;
}

.post-widget-slideshow-image {
  width: 100%;
  height: auto;
  display: block;
}

/* Thumbnails */
.post-widget-thumbnails-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  gap: 10px;
}

.post-widget-thumbnail-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin: 0 5px;
  cursor: pointer;
  opacity: 0.6;
  border-radius: var(--border-radius-sm);
}

.post-widget-thumbnail-image.active {
  border: 2px solid rgba(250, 250, 250, 0.3); /* Customize the border color */
  opacity: 1;
}

.mini-loader {
  width: 50px;
  height: 50px;
}

.mini-loader svg {
  width: 100%;
  height: 100%;
}

.mini-loader-container-profile {
  width: 100%;
  height: 100px; 
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Container for categories and subcategories */
.individual-shop-category-container {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.individual-shop-category-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
}
.individual-shop-category-row{
  display: flex;
  width: 100%;
  gap: 10px;
  border-bottom: 1px solid rgba(250, 250, 250, 0.3);
}


.individual-shop-subcategory-row {
  display: flex;
  gap: 10px;
  overflow-x: scroll; 
}

.individual-shop-category-button {
  padding: 8px 24px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-full);
  background-color: var(--background-color);
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  margin-bottom: 5px;
}

.individual-shop-subcategory-button {
  padding: 4px 8px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-full);
  background-color: var(--background-color);
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  white-space: nowrap;
  display: inline-block;
  margin-top: 5px;
}

.individual-shop-category-button:hover,
.individual-shop-subcategory-button:hover {
  background-color: rgba(250, 250, 250, 0.3);
}

.individual-shop-category-button.active,
.individual-shop-subcategory-button.active {
  background-color: var(--primary-color);
 color: var(--text-color);;
}

.individual-shop-subcategories-container {
  display: flex;
  gap: 8px;
  margin-top: 5px;
}

@media (max-width: 768px) {
  .individual-shop-category-button,
  .individual-shop-subcategory-button {
    padding: 6px 12px;
    font-size: 13px;
  }
}

.MuiTypography-root.MuiTypography-h6.link-post-preview-title.css-1anx036 {
  font-size: 10px !important;
}

h6.my-post-widget-link-post-preview-title {
  font-size: 10px !important;
  font-weight: 600 !important;
}


/* client/src/components/StartStream.css */

.start-stream-container {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: var(--background-color);
}

.start-stream-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.start-stream-container button {
  display: block;
  width: 100%;
  padding: 12px;
  background-color: #3498db;
 color: var(--text-color);;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.start-stream-container button:disabled {
  background-color: #95a5a6;
  cursor: not-allowed;
}

.stream-details {
  margin-top: 20px;
}

.stream-details h3 {
  margin-bottom: 10px;
}

.stream-details p {
  margin: 5px 0;
}

.stream-details a {
  color: #3498db;
  text-decoration: none;
}

.stream-details a:hover {
  text-decoration: underline;
}

.error {
  color: red;
  text-align: center;
  margin-top: 10px;
}

/* client/src/components/WatchStream.css */

.watch-stream-container {
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
}

.stream-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.stream-avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 20px;
}

.stream-info h2 {
  margin: 0;
  font-size: 28px;
}

.stream-role {
  display: block;
  font-size: 16px;
  color: #555;
}

.stream-player {
  position: relative;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  margin-bottom: 20px;
}

.stream-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 8px;
}

.stream-description {
  margin-bottom: 20px;
}

.stream-description h3 {
  margin-bottom: 10px;
}

.stream-chat {
  margin-bottom: 20px;
}

.stream-chat h3 {
  margin-bottom: 10px;
}

.error-message {
  text-align: center;
  color: red;
  font-size: 18px;
  margin-top: 50px;
}

/* client/src/components/ActiveStreams.css */

.active-streams-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.active-streams-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.streams-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.stream-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  background-color: var(--background-color);
}

.stream-card h3 {
  margin-bottom: 10px;
}

.stream-iframe {
  width: 100%;
  height: 200px;
  border: none;
  border-radius: 4px;
}

.watch-button {
  display: inline-block;
  margin-top: 10px;
  padding: 8px 12px;
  background-color: #3498db;
 color: var(--text-color);;
  text-decoration: none;
  border-radius: 4px;
}

.watch-button:hover {
  background-color: #2980b9;
}

.error-message {
  text-align: center;
  color: red;
  font-size: 18px;
  margin-top: 50px;
}

.stream-method-selection {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.stream-method-selection label {
  margin-bottom: 10px;
  cursor: pointer;
  color: var(--text-color);
}

.stream-method-selection input {
  margin-right: 10px;
}

/* Stream Controls */
.stream-controls {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.stream-controls button {
  padding: 10px 20px;
  background-color: rgba(250, 250, 250, 0.3);
  color: var(--text-color);
  border: none;
  border-radius: var(--border-radius-full);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.stream-controls button:hover {
  background-color: rgba(250, 250, 250, 0.5);
}

/* Optional: Additional styles for better UI */
.start-stream-container h2 {
  text-align: center;
  margin-bottom: 30px;
}

.stream-details h3 {
  margin-bottom: 20px;
}

.stream-details p {
  margin: 10px 0;
}

.stream-details a {
  color: #1e90ff;
}

.stream-details a:hover {
  color: rgba(250, 250, 250, 0.5);
}

.error {
  color: #ff4d4f;
  background-color: rgba(255, 77, 79, 0.1);
  padding: 10px 20px;
  border-radius: var(--border-radius-md);
  margin-top: 20px;
}

.stream-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.stream-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 20px;
  object-fit: cover;
}

.stream-info h2 {
  margin: 0;
  font-size: 24px;
}

.stream-info .stream-role {
  color: #1e90ff;
  font-size: 14px;
}

/* Stream Player Styles */
.stream-player {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
  border-radius: var(--border-radius-md);
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
}

.stream-player video,
.stream-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: var(--border-radius-md);
}

/* Stream Description Styles */
.stream-description {
  background-color: rgba(250, 250, 250, 0.05);
  padding: 15px;
  border-radius: var(--border-radius-md);
  margin-bottom: 20px;
}

.stream-description h3 {
  margin-bottom: 10px;
  color: var(--text-color);
}

.stream-description p {
  color: #cccccc;
}

/* Stream Chat Styles */
.stream-chat {
  background-color: rgba(250, 250, 250, 0.05);
  padding: 15px;
  border-radius: var(--border-radius-md);
}

.stream-chat h3 {
  margin-bottom: 10px;
  color: var(--text-color);
}

.stream-chat p {
  color: #cccccc;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .stream-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .stream-avatar {
    margin-bottom: 10px;
  }

  .stream-player {
    padding-bottom: 75%; /* Adjust aspect ratio for smaller screens */
  }
}

/* chat.css */

.chat-container {
  background-color: rgba(250, 250, 250, 0.05);
  padding: 15px;
  border-radius: var(--border-radius-md);
  height: 300px;
  display: flex;
  flex-direction: column;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 10px;
}

.chat-message {
  margin-bottom: 10px;
  color: #cccccc;
}

.chat-message.own-message {
  text-align: right;
  color: #1e90ff;
}

.chat-input {
  display: flex;
}

.chat-input input {
  flex: 1;
  padding: 10px;
  border-radius: 20px 0 0 20px;
  border: none;
  outline: none;
}

.chat-input button {
  padding: 10px 20px;
  border-radius: 0 20px 20px 0;
  border: none;
  background-color: rgba(250, 250, 250, 0.3);
  color: var(--text-color);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.chat-input button:hover {
  background-color: rgba(250, 250, 250, 0.5);
}

.MuiTypography-root.MuiTypography-h6.link-post-preview-title.css-1anx036 {
  font-size: 10px !important;
  display: none !important;
}

.help-icon-link {
  margin-left: 4px;
  display: inline-flex;
  align-items: center;
  position: relative;
  text-decoration: none;
}

.help-icon {
  width: 16px;
  height: 16px;
 color: var(--text-color);; 
  cursor: pointer;
  transition: color 0.3s; 
}

.help-icon-link:hover .help-icon {
  color: var(--primary-color); 
}

.help-text {
  display: none; 
  margin-left: 6px;
  color: var(--primary-color);
  font-size: 14px;
  text-decoration: underline;
  transition: opacity 0.3s;
}

.help-icon-link:hover .help-text {
  display: inline;
  opacity: 1;
}

/* LifeEvents.css */

.life-events-component {
  position: relative;
  display: inline-block;
  margin: 20px 0;
 color: var(--text-color);;
}

.create-life-event-button {
  background-color: var(--primary-color);
 color: var(--text-color);;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: var(--border-radius-full);
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.create-life-event-button:hover {
  background-color: #1ed760;
}

.arrow {
  margin-left: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  padding: 3px;
  display: inline-block;
  transition: transform 0.3s ease;
}

.down {
  transform: rotate(45deg);
}

.up {
  transform: rotate(-135deg);
}

.life-events-dropdown {
  position: absolute;
  top: 45px;
  left: 0;
  background-color: #282828;
  border: 1px solid #404040;
  border-radius: 8px;
  width: 200px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  list-style: none;
  padding: 0;
  margin: 0;
}

.life-events-dropdown-item {
  padding: 10px 15px;
 color: var(--text-color);;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.life-events-dropdown-item:hover {
  background-color: #1db954;
}

.selected-event {
  margin-top: 10px;
  font-size: 14px;
 color: var(--text-color);;
}

/* Modal Overlay Styling */
.life-events-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--modal-overlay-background);
  backdrop-filter: var(--modal-overlay-backdrop-filter);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content Styling */
.life-events-modal-content {
  background: #282828;
  padding: 20px 30px;
  border-radius: var(--border-radius-sm);
  width: 90%;
  max-width: 400px;
  position: relative;
 color: var(--text-color);;
}

.life-events-close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
 color: var(--text-color);;
}

.life-events-modal-content h2 {
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
}

.life-events-form {
  display: flex;
  flex-direction: column;
}

.life-events-form label {
  margin-bottom: 15px;
  font-size: 14px;
}

.life-events-form input[type="date"],
.life-events-form textarea {
  width: 100%;
  padding: 8px 10px;
  margin-top: 5px;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-lg);
  background-color: var(--background-color);
 color: var(--text-color);;
}

.life-events-form textarea {
  resize: vertical;
  height: 80px;
}

.life-events-submit-button {
  background-color: var(--primary-color);
 color: var(--text-color);;
  padding: 10px;
  border: none;
  border-radius: var(--border-radius-full);
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.life-events-submit-button:hover {
  background-color: #1ed760;
}

.life-events-form-error {
  color: #ff4d4f;
  font-size: 12px;
  margin-top: 5px;
}

/* Life Events List */
.life-events-list {
  margin-top: 20px;
}

.life-events-loading,
.life-events-error,
.life-events-no-events {
  text-align: center;
  margin: 10px 0;
  font-size: 14px;
}

.life-events-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.life-event-item {
  background-color: #3a3a3a;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.life-event-item h4 {
  margin: 0 0 5px 0;
  font-size: 16px;
}

.life-event-item p {
  margin: 2px 0;
  font-size: 14px;
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
  .life-events-component {
    margin: 10px 0;
  }

  .create-life-event-button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .arrow {
    margin-left: 8px;
    padding: 2px;
  }

  .life-events-dropdown {
    width: 150px;
    top: 40px;
  }

  .life-events-dropdown-item {
    padding: 8px 12px;
    font-size: 14px;
  }

  .life-events-modal-content {
    padding: 15px 20px;
    max-width: 90%;
  }

  .life-events-form label {
    font-size: 13px;
  }

  .life-events-form input[type="date"],
  .life-events-form textarea {
    font-size: 14px;
  }

  .life-events-submit-button {
    font-size: 14px;
    padding: 8px;
  }

  .life-event-item h4 {
    font-size: 15px;
  }

  .life-event-item p {
    font-size: 13px;
  }
}

.product-details-component__product-title {
  text-transform: capitalize;
}

.user-inbox-component-message-item.active {
  background-color: var(--background-color) !important;
}

button.create-product-component-image-preview-remove {
  border-radius: var(--border-radius-full) !important;
  width: 25px !important;
  height: 25px !important;
  padding-bottom: 5px !important;
  padding-top: 0px !important;
}

.user-profile-data-tab-content {
  margin-top: 0 !important;
  margin: 15px !important;
}


/* shopify import */
.shopify-import-container {
  background-color: var(--background-color);
  border-radius: var(--border-radius-md);
  padding: 40px;
  max-width: 500px;
  margin: 50px auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.shopify-import-title {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
  font-size: 1.8em;
}

.shopify-import-form {
  display: flex;
  flex-direction: column;
}

.shopify-import-form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.shopify-import-label {
  margin-bottom: 8px;
  font-weight: 600;
  color: #555;
}

.shopify-import-input {
  padding: 12px 15px;
  border: 1px solid #ccc;
  border-radius: var(--border-radius-md);
  font-size: 1em;
  transition: border-color 0.3s ease;
  background-color: var(--background-color);
}

.shopify-import-input:focus {
  border-color: #007bff;
  outline: none;
}

.shopify-import-button {
  padding: 12px 20px;
  background-color: var(--primary-color);
 color: var(--text-color);;
  border: none;
  border-radius: var(--border-radius-full);
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease; 
  margin-top: 10px;
}

.shopify-import-button:hover {
  background-color: #28a200; 
  transform: scale(1.05); 
}

.shopify-import-button:disabled {
  background-color: #86cc6e;
  cursor: not-allowed;
}

.shopify-import-message {
  margin-top: 20px;
  padding: 10px;
  background-color: #d4edda;
  color: #155724;
  border-left: 5px solid #28a745;
  border-radius: 5px;
}

.shopify-import-errors {
  margin-top: 20px;
  padding: 10px;
  background-color: #f8d7da;
  color: #721c24;
  border-left: 5px solid #dc3545;
  border-radius: 5px;
}

.shopify-import-error-title {
  margin-bottom: 10px;
  font-size: 1.2em;
}

.shopify-import-error-list {
  list-style-type: disc;
  padding-left: 20px;
}

.shopify-import-error-item {
  margin-bottom: 5px;
}
.shopify-incomplete-products-list {
  height: 600px; 
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
}

/* Incomplete product editor */

.incomplete-product-editor-container {
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-sm);
  padding: 15px;
  margin: 10px 0;
  background-color: var(--background-color);
  transition: background-color 0.3s ease;
}

.updated-background {
  background-color: rgba(40, 167, 69, 0.2);
}

.incomplete-product-editor-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.incomplete-product-editor-title {
  font-size: 18px;
  margin: 0;
}

.collapse-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.icon {
  width: 20px;
  height: 20px;
 color: var(--text-color);;
}

.incomplete-product-editor-body {
  margin-top: 10px;
}

.incomplete-product-editor-form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.incomplete-product-editor-label {
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-bottom: 5px;
}

.create-product-component-form-required {
  color: #dc3545; 
  margin-left: 2px;
}

.help-icon-link {
  display: flex;
  align-items: center;
  margin-left: 5px;
  color: #007bff;
  text-decoration: none;
}

.help-icon-link:hover .help-icon {
  color: #0056b3;
}

.help-icon {
  width: 16px;
  height: 16px;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.incomplete-product-editor-input,
.incomplete-product-editor-select {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: var(--border-radius-sm);
  font-size: 14px;
  background-color: var(--background-color);
  transition: border-color 0.2s ease;
}

.incomplete-product-editor-input:focus,
.incomplete-product-editor-select:focus {
  border-color: #007bff;
  outline: none;
}

.incomplete-product-editor-button {
  background-color: var(--primary-color);
 color: var(--text-color);;
  border: none;
  padding: 10px 15px;
  border-radius: var(--border-radius-full);
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s ease;
}

.incomplete-product-editor-button:hover:not(:disabled) {
  background-color: #0056b3;
}

.incomplete-product-editor-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.incomplete-product-editor-message {
  margin-top: 10px;
  color: #28a745; 
  font-size: 14px;
}

.error-message {
  color: #dc3545;
}


/* ImportShopifyProductsModal */

.import-shopify-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--modal-overlay-background); 
  backdrop-filter: var(--modal-overlay-backdrop-filter);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
}

.import-shopify-modal-content {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 8px;
  position: relative;
  max-height: 80%;
  overflow-y: auto;
  max-width: 600px;
  width: 90%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.import-shopify-modal-close-button {
  position: absolute;
  top: 12px;
  right: 16px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.import-shopify-modal-open-button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  background-color: var(--primary-color); 
  color: #fff;
  border: none;
  border-radius: var(--border-radius-full);
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease; 
}

.import-shopify-modal-open-button:hover {
  background-color: #28a200; 
  transform: scale(1.05); 
}

.import-shopify-modal-open-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(54, 211, 0, 0.5); 
}


/* src/components/Header/SearchBar.css */

.search-bar-container {
  margin: 15px;
  display: flex;
  position: relative;
  width: 42%;
  justify-content: center;
}

.search-bar-input {
  width: 100%;
  height: 40px;
  padding: 0 60px 0 40px;
  border: 1px solid var(--border-color) !important;
  border-radius: var(--border-radius-full) !important;
  background-color: var(--background-color);
   color: var(--text-color);
  outline: none;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.search-bar-input:focus {
  background-color: rgba(250, 250, 250, 0.2);
  border-color: #007bff;
}

@media (max-width: 768px) {
  .search-bar-input {
    padding: 0 30px 0 20px;
    width: 100%;
  }
}

.search-bar-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #555;
  cursor: pointer;
}

.search-bar-clear-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #555;
  cursor: pointer;
}

.search-bar-dropdown {
  position: absolute;
  top: 50px; 
  width: 100%;
  max-height: 600px; 
  background-color: var(--discover-background);
  backdrop-filter: var(--modal-overlay-backdrop-filter);
  padding: 15px;
  z-index: 10;
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
  overflow-y: auto;
  display: flex;
  gap: 20px; 
}

@media (max-width: 1024px) {
  .search-bar-dropdown {
    width: 600px; 
  }
}

@media (max-width: 768px) {
  .search-bar-dropdown {
    width: 100%; 
    flex-direction: column;
  }
}


@media (max-width: 480px) {
  .search-bar-container {
    margin: 0px;
    display: flex;
    position: relative;
    width: 100%;
    justify-content: center;
  }
  .search-bar-dropdown {
    width: 100%;
    flex-direction: column;
    max-height: none;
  }
}

.search-bar-left-column,
.search-bar-right-column {
  flex: 1;
}

.search-bar-section {
  margin-bottom: 20px;
}

.search-bar-section-title {
  font-size: 14px;
  color: var(--text-color);
  margin-bottom: 10px;
  text-transform: uppercase;
  padding-left: 10px;
  border-left: 4px solid var(--primary-color);
}

.search-bar-item {
  display: flex;
  align-items: center;
  border-radius: var(--border-radius-md);
  padding: 8px 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-bar-item:hover {
  background-color: var(--background-color);
}

.search-bar-item-img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 10px;
  object-fit: cover;
}

.search-bar-item-info {
  display: flex;
  flex-direction: column;
}

.search-bar-item-title {
  font-size: 16px;
   color: var(--text-color);
  font-weight: 600;
  margin: 0;
}

.search-bar-item-details {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.search-bar-item-brand,
.search-bar-item-shop {
  font-size: 12px;
  font-weight: 600;
   color: var(--text-color);
  padding: 2px 6px;
  border-radius: 10px;
  margin-right: 5px;
}

.search-bar-item-brand {
  background-color: var(--background-color);
}


.search-bar-no-results {
  padding: 10px;
  color: #ccc;
  text-align: center;
  font-size: 14px;
}



.mobile-search-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: var(--modal-overlay-backdrop-filter);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.mobile-search-modal-content {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 500px;
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
}

.close-mobile-search-modal {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  z-index: 10;
}

.close-mobile-search-modal:hover {
  color: #555;
}

.mobile-search-modal-content .search-bar-container {
  margin-top: 40px;
}

.mobile-search-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.mobile-search-header {
  display: flex;
  align-items: center;
  padding: 10px;
  width: 80%;
}

.mobile-search-close {
 color: var(--text-color);;
  margin-right: 10px;
  cursor: pointer;
}

.mobile-search-input-wrapper {
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
}

.mobile-header-search-icon {
  align-items: center;
  display: flex;
  justify-content: center;
  color: rgba(250, 250, 250, 0.8);
  height: 32px;
  width: 32px;
  opacity: .8;
}

.mobile-search-icon {
  position: absolute;
  left: 10px;
  color: #777;
}

.mobile-search-input {
  width: 100%;
  padding: 8px 40px 8px 35px;
  border: none;
  border-radius: var(--border-radius-md);
  background: #2a2a2a;
 color: var(--text-color);;
  outline: none;
}

.mobile-search-input:focus {
  background: #333;
}

.mobile-search-clear-icon {
  position: absolute;
  right: 10px;
  color: #777;
  cursor: pointer;
}

.mobile-search-results {
  flex: 1;
  overflow-y: auto;
  padding: 15px;
}

.mobile-search-section {
  margin-bottom: 20px;
}

.mobile-search-section-title {
  font-size: 14px;
  color: #aaa;
  margin-bottom: 10px;
  text-transform: uppercase;
  padding-left: 10px;
  border-left: 4px solid var(--primary-color);
}

.mobile-search-item {
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.mobile-search-item:hover {
  background-color: var(--background-color);
}

.mobile-search-item-img {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  margin-right: 10px;
  object-fit: cover;
}

.mobile-search-item-info {
  display: flex;
  flex-direction: column;
}

.mobile-search-item-title {
  font-size: 16px;
   color: var(--text-color);
  font-weight: 600;
  margin: 0;
}

.mobile-search-item-details {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.mobile-search-item-brand,
.mobile-search-item-shop {
  font-size: 12px;
  font-weight: 600;
   color: var(--text-color);
  background-color: #007bff;
  border-radius: 5px;
  padding: 2px 6px;
  margin-right: 5px;
}

.mobile-search-item-shop {
  background-color: #28a745;
}

.mobile-search-no-results {
  padding: 10px;
  color: #ccc;
  text-align: center;
  font-size: 14px;
}


.top-level-dropdown {
  border-top-right-radius: 0px ;
  border-top-left-radius: 0px;
}

.meme-slide-button-container {
  bottom: 45px;
  padding: 0px 0;
}

/* src/components/Products/ProductInfiniteMosaic.css */

.mosaic-product-mosaic-container {
  width: 100%;
  margin: 0 auto;
}

.mosaic-control-panel {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.mosaic-control-select {
  padding: 8px;
  background-color: var(--background-color);
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: var(--border-radius-lg);
}

.mosaic-product-mosaic {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
}

.mosaic-mosaic-item {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: var(--border-radius-sm);
  background-color: var(--text-light);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  aspect-ratio: 1;
}

.mosaic-mosaic-item:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.mosaic-mosaic-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.mosaic-favorite-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  cursor: pointer;
  font-size: 1.5em;
  color: var(--primary-color);
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.favorite-icon {
  color: #fff;
}

.favorite-icon-active {
  color: var(--primary-color);
}

.mosaic-mosaic-overlay {
  position: absolute;
  bottom: 0;
  background: var(--overlay-color);
 color: var(--text-color);;
  width: 100%;
  padding: 10px;
  opacity: 0;
  transition: opacity 0.4s ease;
  box-sizing: border-box;
}

.mosaic-mosaic-item:hover .mosaic-mosaic-overlay {
  opacity: 1;
}

.mosaic-product-name {
  margin: 0;
  font-size: 1em;
  font-weight: bold;
}

.mosaic-product-price {
  margin: 5px 0 0;
  font-size: 0.9em;
}

.mosaic-rating {
  display: flex;
  margin-top: 5px;
}

.mosaic-star {
  color: #e4e5e9;
  font-size: 0.9em;
  margin-right: 2px;
}

.mosaic-star.filled {
  color: #ffc107;
}

/* Modal Overlay */
.mosaic-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--modal-overlay-background);
  backdrop-filter: var(--modal-overlay-backdrop-filter);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal Content */
.mosaic-modal-content {
  background-color: var(--discover-background);
  color: var(--text-color);
  padding: 20px;
  position: relative;
  width: 90%;
  max-width: 800px;
  border-radius: var(--border-radius-sm);
  border: 1px solid var(--border-color);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  max-height: 90vh;
  overflow-y: auto;
}

.mosaic-modal-close {
  position: absolute;
  top: 10px;
  right: 20px;
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
 color: var(--text-color);;
}

.mosaic-modal-row {
  display: flex;
}

.mosaic-modal-inner {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .mosaic-modal-inner {
    flex-direction: row;
    gap: 20px;
  }
}

.mosaic-modal-image-section {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mosaic-modal-image-section img {
  width: 100%;
  max-width: 400px;
  height: auto;
  border-radius: 5px;
}

.mosaic-modal-details-section {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.mosaic-modal-product-name {
  margin-top: 0;
  font-size: 1.5em;
}

.mosaic-modal-price {
  font-size: 1.2em;
  font-weight: bold;
  margin: 10px 0;
}

.mosaic-modal-rating {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.mosaic-modal-rating-value {
  margin-right: 5px;
}

.mosaic-modal-stars {
  display: flex;
}

.mosaic-modal-star-filled {
  color: #ffc107;
}

.mosaic-modal-star-empty {
  color: #e4e5e9;
}

.mosaic-modal-review-count {
  margin-left: 10px;
  font-size: 0.9em;
  color: #555;
}

.mosaic-modal-description {
  flex-grow: 1;
  margin-bottom: 20px;
}

.mosaic-modal-section {
  margin-bottom: 20px;
}

.mosaic-modal-section-title {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 10px;
}

.mosaic-modal-options {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.mosaic-modal-option {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.mosaic-modal-option-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.mosaic-modal-option-selected {
  outline: 2px solid #3b82f6;
  border-radius: 4px;
}

.mosaic-modal-option-input {
  display: none;
}

.mosaic-modal-option-color {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 5px;
  border: 1px solid #ccc;
}

.mosaic-modal-option-name {
  font-size: 0.9em;
 color: var(--text-color);
}

.mosaic-modal-add-to-bag-button {
  display: flex;
  padding: 10px 20px;
  background-color: var(--primary-color);
 color: var(--text-color);;
  border: 2px solid var(--primary-color);
  border-radius: var(--border-radius-full);
  cursor: pointer;
  font-size: 1em;
  font-weight: 600;
  transition: background-color 0.3s ease;
  margin-right: 15px;
  align-items: center;
}


.mosaic-modal-add-to-bag-button:hover {
  background-color: rgba(250, 250, 250, 0.3);
}

.mosaic-modal-buttons {
  display: flex;
}

.mosaic-modal-full-details {
  margin-top: 0px;
}

.mosaic-modal-view-details-button {
  padding: 10px 20px;
  background-color: transparent;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: var(--border-radius-full);
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.mosaic-modal-view-details-button:hover {
  background-color: rgba(250, 250, 250, 0.3);
 color: var(--text-color);;
}

/* Reviews Section */
.mosaic-modal-reviews-section {
  margin-top: 20px;
}

.mosaic-modal-reviews-section h3 {
  margin-bottom: 10px;
  font-size: 1.2em;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}

.mosaic-modal-review {
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}

.mosaic-modal-review:last-child {
  border-bottom: none;
}

.mosaic-modal-reviewer {
  margin: 0;
  font-weight: bold;
}

.mosaic-modal-review-comment {
  margin: 5px 0 0;
  font-size: 0.9em;
}

/* Loading Indicator */
.mosaic-loading-indicator {
  text-align: center;
  padding: 20px;
  font-size: 1.2em;
  color: #555;
}

/* Responsive Design */
@media (max-width: 600px) {
  .mosaic-product-mosaic {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }

  .mosaic-control-panel {
    flex-direction: column;
    gap: 10px;
  }

  .mosaic-modal-inner {
    flex-direction: column;
    padding-top: 40px;
  }

  .mosaic-modal-image-section img {
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .mosaic-product-mosaic {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}


/* ThreeTileGrid.css */

/* Container Styles */
.three-tile-container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 64px 0px;
}

.three-tile-header-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
}

.three-tile-heading {
  font-size: 2rem;
  font-weight: bold;
 color: var(--text-color);;
}

.three-tile-browse-link,
.three-tile-browse-link-mobile {
  font-size: 1rem;
  font-weight: 600;
  color: var(--primary-color);
  text-decoration: none;
  transition: transform 0.3s;
}

.three-tile-browse-link:hover,
.three-tile-browse-link-mobile:hover {
  transform: scale(1.05);
}

/* Carousel Styles */
.three-tile-carousel {
  margin-top: 24px;
  position: relative; /* For positioning carousel buttons */
}

/* Grid Layout */
.three-tile-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  position: relative;
}

.three-tile-item.large {
  grid-column: 1 / 2;
  grid-row: 1 / 3; 
  position: relative;
  aspect-ratio: 1 / 2; 
  
}

.small-tiles-container {
  grid-column: 2 / 4; 
  grid-row: 1 / 3;   
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
  margin-left: 10px;
  position: relative;
}

.three-tile-item.small {
  aspect-ratio: 1 / 1; /* Square aspect ratio */
 
}

/* Remove fixed heights */
.three-tile-item.large,
.three-tile-item.small {
  height: auto;
}

/* Carousel Buttons for Large Tile */
.three-tile-item.large .three-tile-carousel-button.prev,
.three-tile-item.large .three-tile-carousel-button.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
 color: var(--text-color);;
  border: none;
  font-size: 16px;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 50%;
  z-index: 1;
}

.three-tile-item.large .three-tile-carousel-button.prev {
  left: 16px;
}

.three-tile-item.large .three-tile-carousel-button.next {
  right: 16px;
}

/* Carousel Buttons for Small Tiles */
.small-tiles-container .three-tile-carousel-button.product-prev,
.small-tiles-container .three-tile-carousel-button.product-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
 color: var(--text-color);;
  border: none;
  font-size: 16px;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 50%;
  z-index: 1;
}

.small-tiles-container .three-tile-carousel-button.product-prev {
  left: 0px; /* Adjust as needed */
}

.small-tiles-container .three-tile-carousel-button.product-next {
  right: 0px; /* Adjust as needed */
}

/* Tile Styles */
.three-tile-item {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.three-tile-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.three-tile-overlay {
  position: absolute;
  inset: 0;
  background: linear-gradient(to bottom, transparent, black);
  opacity: 0.5;
}

.three-tile-text {
  position: absolute;
  bottom: 16px;
  left: 16px;
 color: var(--text-color);;
}

.three-tile-text h3 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
}

.three-tile-text p {
  margin-top: 8px;
  font-size: 1rem;
}

.three-tile-browse-mobile {
  margin-top: 24px;
  text-align: center;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .three-tile-grid {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }

  .three-tile-item.large,
  .small-tiles-container,
  .three-tile-item.small {
    grid-column: 1 / -1;
    grid-row: auto;
  }

  .three-tile-item.large {
    aspect-ratio: auto;
    height: auto;
  }

  .small-tiles-container {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: 20px;
    margin-left: 0px;
  }

  .three-tile-item.small {
    aspect-ratio: 1 / 1;
  }
}

.shop-dashboard-hero-header-chart {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.switcheroo-shop-component-swap-icon.css-vubbuv {
  background-color: var(--primary-color) !important;
}

.shop-dashboard-hero-header-chart-controls button.active-filter {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
 color: var(--text-color);;
}

.shop-dashboard-hero-header-card {
  margin-bottom: 0px;
  padding: 10px;
  width: 100%;
}

.post-widget-popover-button {
  color: var(--text-color);
}



/*----RADIATE BOLT----*/
img.post-widget-like-icon {
cursor: pointer;
position: relative;
}

img.post-widget-like-icon::after {
content: '';
position: absolute;
top: 0; left: 0; right: 0; bottom: 0;
filter: none !important;
}

img.post-widget-like-icon:hover::after {
animation: radiate .5s;
}

/*img.post-widget-like-icon, */
img.post-widget-like-icon::after {
background: #ffcc33 !important;
border-radius: var(--border-radius-full);
}

/*-----END RADIATE-----*/
/* src/components/Marketplace/ExploreBentoGrid.css */
/* Updated Masonry Grid Styles */
.my-masonry-grid {
  display: flex;
  margin-left: -20px; /* gutter size offset */
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 20px; /* gutter size */
  background-clip: padding-box;
}

/* Style for individual grid items */
.my-masonry-grid_column > .explore-bento-grid-item {
  margin-bottom: 20px;
}

/* Remove grid-template-columns as Masonry handles columns */
.explore-bento-section {
  padding: 10px;
  zoom: normal;
}

.explore-bento-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.explore-bento-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #333;
  text-align: center;
  margin-bottom: 40px;
}



/* Grid Item Styles */
.explore-bento-grid-item {
  position: relative;
  overflow: hidden;
  background-color: var(--background-color);
  border-radius: var(--border-radius-md);
  display: flex;
  flex-direction: column;
  border: 0.5px solid rgba(250, 250, 250, 0.3);

  /* Initial Animation State */
  opacity: 0;
  transform: translateY(20px);
  animation-fill-mode: forwards;
  /* Remove existing transition to prevent conflicts */
  transition: none;

  /* Ensure items take full width */
  width: 100%;
}

/* Apply Animations to Grid Items */
.explore-bento-grid-item:nth-child(4n+1) {
  animation: fadeInLeft 0.6s ease-out forwards;
  animation-delay: 0.2s;
}

.explore-bento-grid-item:nth-child(4n+2) {
  animation: fadeInRight 0.6s ease-out forwards;
  animation-delay: 0.3s;
}

.explore-bento-grid-item:nth-child(4n+3) {
  animation: fadeInUp 0.6s ease-out forwards;
  animation-delay: 0.4s;
}

.explore-bento-grid-item:nth-child(4n) {
  animation: fadeInDown 0.6s ease-out forwards;
  animation-delay: 0.5s;
}

/* Optional: Slight Scale-Up for Modern Touch */
.explore-bento-grid-item {
  transform: scale(0.95);
}

.explore-bento-grid-item:nth-child(4n+1),
.explore-bento-grid-item:nth-child(4n+2),
.explore-bento-grid-item:nth-child(4n+3),
.explore-bento-grid-item:nth-child(4n) {
  transform: scale(1);
}

/* Hover Effect */
.explore-bento-grid-item:hover {
  transform: scale(1.02); /* Slight scale-up on hover */
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
}

/* Link Styling */
.explore-bento-card-link {
  text-decoration: none;
  color: inherit;
  height: 100%; /* Ensure the link covers the entire grid item */
  display: flex;
  flex-direction: column;
}

/* Card Styling */
.explore-bento-card {
  flex: 1; /* Allows the card to expand and fill the grid item */
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

/* Product Card Specific Styles */
.product-bento-item {
  aspect-ratio: 1 / 1; /* Ensures square shape */
}

.product-aspect-ratio-box {
  position: relative;
  width: 100%;
  padding-top: 100%; 
  overflow: hidden;
}

.product-aspect-ratio-box__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* Shop Card Styles */
.explore-bento-shop-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  aspect-ratio: 16/9;
  position: relative;
}

.explore-bento-shop-banner {
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.explore-bento-shop-banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.explore-bento-shop-info {
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  gap: 10px; /* Space between avatar and name */
  background: rgba(0, 0, 0, 0.6); /* Optional background for better visibility */
  padding: 5px 10px;
  border-radius: var(--border-radius-lg);
}

.explore-bento-shop-avatar {
  width: 40px;
  height: 40px;
  border: 2px solid #fff;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0; 
}

.explore-bento-shop-avatar-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.explore-bento-shop-name {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  white-space: nowrap; 
  text-overflow: ellipsis;
  overflow: hidden;
}


.explore-bento-card-content {
  position: absolute;
  bottom: 0; 
  left: 0;
  width: 100%; 
 color: var(--text-color);;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.explore-bento-card-title {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
  line-height: 1.4;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  white-space: nowrap;
  color: #fff;
}


.explore-bento-card-price-container {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.explore-bento-card-price {
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
}

/* Post Item Specific Styles */
.post-item {
  /* Define specific aspect ratios */
}

.post-item.aspect-1 {
  aspect-ratio: 1 / 1;
}

.post-item.aspect-4-5 {
  aspect-ratio: 4 / 5;
}

.post-item.aspect-16-9 {
  aspect-ratio: 16 / 9;
}

/* PlaceholderCard Specific Styles */
.explore-bento-placeholder-card {
  background-color: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
}

.explore-bento-placeholder-card .explore-bento-card-content {
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: var(--border-radius-sm);
}

.explore-bento-placeholder-card .explore-bento-card-title {
 color: var(--text-color);;
  font-size: 1.2rem;
}

/* Loader Styles */
.loader {
  text-align: center;
  padding: 20px;
  font-size: 1.2rem;
  color: #555;
}

/* Responsive Adjustments */

/* Medium Screens */
@media (max-width: 1024px) {
  .explore-bento-container {
    padding: 0 10px;
  }

  /* Adjust Shop Avatar Position for smaller screens */
  .explore-bento-shop-avatar {
    width: 50px;
    height: 50px;
  }

  .explore-bento-card-content {
    padding: 8px;
  }
}

/* Small Screens */
@media (max-width: 768px) {
  .explore-bento-container {
    padding: 0 5px;
  }

  /* Adjust Shop Avatar Position for smaller screens */
  .explore-bento-shop-avatar {
    width: 40px;
    height: 40px;
    bottom: 15px;
    left: 15px;
  }

  .explore-bento-card-content {
    padding: 6px;
  }

  /* Adjust aspect ratios for posts if needed */
}

/* Extra Small Screens (Mobile) */
@media (max-width: 480px) {
  .my-masonry-grid {
    margin-left: -10px; 
    margin-top: 0px;
  }

  .my-masonry-grid_column {
    padding-left: 10px;
  }

  .explore-bento-grid-item {
    margin-bottom: 10px;
  }

  .explore-bento-shop-avatar {
    width: 35px;
    height: 35px;
    bottom: 30px;
    left: 10px;
  }

  .explore-bento-card-content {
    padding: 5px;
    padding-top: 20px;
  }
}

/* PostCard Component CSS */
.post-card {
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 0.75rem;
 color: var(--text-color);;
  display: flex;
  flex-direction: column;
  height: 100%; 
  position: relative; 
}

/* Aspect Ratios */
.post-card.aspect-1 {
  aspect-ratio: 1 / 1;
}

.post-card.aspect-4-5 {
  aspect-ratio: 4 / 5;
}

.post-card.aspect-16-9 {
  aspect-ratio: 16 / 9;
}

@media (max-width: 768px) {
  .post-card.aspect-16-9 {
    aspect-ratio: 1 / 1;
  }
}
/* Media Container */
.post-card-media-container {
  position: relative;
  width: 100%;
  border-radius: 0.75rem;
  overflow: hidden;
}

/* Overlay Styling */
.post-card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.7) 0%,    
      rgba(0, 0, 0, 0) 50%,     
      rgba(0, 0, 0, 0.7) 100%   
  );
 color: var(--text-color);;
  opacity: 1;
  transition: opacity 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  box-sizing: border-box;
}


/* Show overlay on hover */
.post-card-media-container:hover .post-card-overlay {
  opacity: 1;
}

/* Overlay Header */
.post-card-overlay-header {
  display: flex;
  align-items: center;
}

/* Avatar Styling */
.post-card-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 0.75rem;
  object-fit: cover;
}

/* Owner Info */
.post-card-owner-info {
  display: flex;
  flex-direction: column;
}

.post-card-owner-name {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
  color: #fff;
}

.post-card-date {
  font-size: 0.875rem;
  color: #bbb;
  margin: 0;
}

/* Overlay Description */
.post-card-overlay-description {
  margin-top: 0.5rem;
  font-size: 0.9rem;
  line-height: 1.4;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limit to 3 lines */
  -webkit-box-orient: vertical;
}

/* Overlay Actions */
.post-card-overlay-actions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #fff
}

/* Like Icon Styling */
.post-card-like-icon {
  width: 24px;
  height: 24px;
}

/* Media (Image and Video) Styling */
.post-card-image,
.post-card-video {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
  border-radius: 0.75rem;
}

/* Post Description Styling (for posts without media) */
.post-card-description {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
}

.post-card-description span {
  color: green;
  cursor: pointer;
}

/* Link Preview Styling */
.post-card-link {
  text-decoration: none;
  color: inherit;
  margin-bottom: 1rem;
}

.post-card-link-preview {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 0.75rem;
  overflow: hidden;
}

.post-card-link-image {
  width: 30%;
  object-fit: cover;
}

.post-card-link-content {
  padding: 0.75rem;
  flex: 1;
}

.post-card-link-title {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.post-card-link-description {
  margin-bottom: 0.5rem;
}

.post-card-link-url {
  color: var(--primary-color);
}

/* Actions when there's no media */
.post-card-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.post-card-action-buttons {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* Responsive Adjustments for PostCard */

/* Medium Screens */
@media (max-width: 1024px) {
  .post-card-avatar {
    width: 35px;
    height: 35px;
    margin-right: 0.5rem;
  }

  .post-card-owner-name {
    font-size: 0.95rem;
  }

  .post-card-date {
    font-size: 0.8rem;
  }

  .post-card-overlay-description {
    font-size: 0.85rem;
  }

  .post-card-overlay-actions {
    gap: 0.4rem;
  }
}

/* Small Screens */
@media (max-width: 768px) {
  .post-card-avatar {
    width: 30px;
    height: 30px;
    margin-right: 0.5rem;
  }

  .post-card-owner-name {
    font-size: 0.85rem;
  }

  .post-card-date {
    font-size: 0.75rem;
  }

  .post-card-overlay-description {
    font-size: 0.8rem;
  }

  .post-card-overlay-actions {
    gap: 0.3rem;
  }
}

/* Extra Small Screens (Mobile) */
@media (max-width: 480px) {
  .post-card-avatar {
    width: 25px;
    height: 25px;
    margin-right: 0.4rem;
  }

  .post-card-owner-name {
    font-size: 0.8rem;
  }

  .post-card-date {
    font-size: 0.7rem;
  }

  .post-card-overlay-description {
    font-size: 0.75rem;
  }

  .post-card-overlay-actions {
    background-color: var(--background-color);
    border-radius: var(--border-radius-lg);
    gap: 0.2rem;
  }
}

/* Modal Styling */
.post-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000; 
  background: var(--modal-overlay-background);
  backdrop-filter: var(--modal-overlay-backdrop-filter);
}

.post-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5); 
  cursor: pointer;
}

.post-modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--discover-background);
  border: 1px solid var(--border-color);
  padding: 20px;
  border-radius: var(--border-radius-md);
  max-height: 90vh;
  overflow-y: auto;
  width: 600px; 
 color: var(--text-color);; 
}

.post-modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 30px;
 color: var(--text-color);;
  cursor: pointer;
  z-index: 1;
}

.explore-bento-placeholder-card .explore-bento-card {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


/* Ensure PostCard stretches to fill the grid item */
.post-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Ensure images and videos within PostCard fit their containers */
.post-card-image,
.post-card-video {
  width: 100%;
  height: auto;
  border-radius: 0.75rem;
}

@media (max-width: 480px) {
  .post-card-image,
.post-card-video {
  width: 100%;
  height: auto;
  aspect-ratio: 1;
}
.post-modal-content {
  width: 100%;
}
}

/* Ensure PlaceholderCards stretch appropriately */
.explore-bento-placeholder-card .explore-bento-card {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* SingleLargeProductGrid.css */

/* Container Styles */
.product-large-grid-container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 10px 0px;
}

.product-large-grid-header-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
}

.product-large-grid-heading {
  font-size: 2rem;
  font-weight: bold;
 color: var(--text-color);;
}

.product-large-grid-browse-link,
.product-large-grid-browse-link-mobile {
  font-size: 1rem;
  font-weight: 600;
  color: var(--primary-color);
  text-decoration: none;
  transition: transform 0.3s;
}

.product-large-grid-browse-link:hover,
.product-large-grid-browse-link-mobile:hover {
  transform: scale(1.05);
}

/* Carousel Styles */
.product-large-grid-carousel {
  margin-top: 24px;
  position: relative; /* For positioning carousel buttons */
}

/* Grid Layout */
.product-large-grid-grid {
  display: grid;
  grid-template-columns: 2fr 1fr; /* Adjusted for one large and multiple small tiles */
  position: relative;
}

.product-large-grid-item.large {
  position: relative;
  aspect-ratio: 1 / 1; /* Square aspect ratio */
  overflow: hidden;
  border-radius: 8px;
  margin-right: 5px;
}

.product-large-grid-small-tiles-container {
  display: grid;
  grid-gap: 10px;
  position: relative;
  margin-left: 8px;
}

.product-large-grid-item.small {
  position: relative;
  aspect-ratio: 1 / 1; /* Square aspect ratio */
  overflow: hidden;
  border-radius: 8px;
}

/* Remove fixed heights */
.product-large-grid-item.large,
.product-large-grid-item.small {
  height: auto;
}

/* Carousel Buttons for Large Tile */
.product-large-grid-item.large .product-large-grid-carousel-button.prev,
.product-large-grid-item.large .product-large-grid-carousel-button.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
 color: var(--text-color);;
  border: none;
  font-size: 16px;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 50%;
  z-index: 1;
}

.product-large-grid-item.large .product-large-grid-carousel-button.prev {
  left: 16px;
}

.product-large-grid-item.large .product-large-grid-carousel-button.next {
  right: 16px;
}

/* Carousel Buttons for Small Tiles */
.product-large-grid-small-tiles-container .product-large-grid-carousel-button.product-prev,
.product-large-grid-small-tiles-container .product-large-grid-carousel-button.product-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
 color: var(--text-color);;
  border: none;
  font-size: 16px;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 50%;
  z-index: 1;
}

.product-large-grid-small-tiles-container .product-large-grid-carousel-button.product-prev {
  left: 0px; /* Adjust as needed */
}

.product-large-grid-small-tiles-container .product-large-grid-carousel-button.product-next {
  right: 0px; /* Adjust as needed */
}

/* Tile Styles */
.product-large-grid-item {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.product-large-grid-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.product-large-grid-overlay {
  position: absolute;
  inset: 0;
  background: linear-gradient(to bottom, transparent, black);
  opacity: 0.5;
}

.product-large-grid-text {
  position: absolute;
  bottom: 16px;
  left: 16px;
 color: var(--text-color);;
}

.product-large-grid-text h3 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
}

.product-large-grid-text p {
  margin-top: 8px;
  font-size: 1rem;
}

.product-large-grid-browse-mobile {
  margin-top: 24px;
  text-align: center;
}

.product-large-grid-error {
  text-align: center;
  color: #ff4d4f;
  font-size: 1.2rem;
  margin: 20px 0;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .product-large-grid-grid {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }

  .product-large-grid-item.large,
  .product-large-grid-small-tiles-container,
  .product-large-grid-item.small {
    grid-column: 1 / -1;
    grid-row: auto;
  }

  .product-large-grid-item.large {
    aspect-ratio: auto;
    height: auto;
  }

  .product-large-grid-small-tiles-container {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: 20px;
    margin-left: 0px;
  }

  .product-large-grid-item.small {
    aspect-ratio: 1 / 1;
  }
}

/* src/components/Marketplace/ShopGrid.css */
/* Container Styles */
.shop-grid-container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 64px 0px;
}

.shop-grid-header-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
}

.shop-grid-heading {
  font-size: 2rem;
  font-weight: bold;
 color: var(--text-color);;
}

.shop-grid-browse-link,
.shop-grid-browse-link-mobile {
  font-size: 1rem;
  font-weight: 600;
  color: var(--primary-color);
  text-decoration: none;
  transition: transform 0.3s;
}

.shop-grid-browse-link:hover,
.shop-grid-browse-link-mobile:hover {
  transform: scale(1.05);
}

/* Carousel Styles */
.shop-grid-carousel {
  margin-top: 24px;
  position: relative; /* For positioning carousel buttons */
}

/* Grid Layout */
.shop-grid-grid {
  display: grid;
  grid-template-columns: 2fr 1fr; 
  position: relative;
  gap: 10px;
}

.shop-grid-item.large {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.shop-grid-small-tiles-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(200px, auto);
  position: relative;
  gap: 10px;
}

.shop-grid-item.small {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  height: 200px;
}

/* Ensure background image covers the full item */
.shop-grid-item {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* Carousel Buttons for Large Shop */
.shop-grid-item.large .shop-grid-carousel-button.prev,
.shop-grid-item.large .shop-grid-carousel-button.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
 color: var(--text-color);;
  border: none;
  font-size: 16px;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 50%;
  z-index: 1;
}

.shop-grid-item.large .shop-grid-carousel-button.prev {
  left: 16px;
}

.shop-grid-item.large .shop-grid-carousel-button.next {
  right: 16px;
}

/* Carousel Buttons for Small Shops */
.shop-grid-small-tiles-container .shop-grid-carousel-button.shop-prev,
.shop-grid-small-tiles-container .shop-grid-carousel-button.shop-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
 color: var(--text-color);;
  border: none;
  font-size: 16px;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 50%;
  z-index: 1;
}

.shop-grid-small-tiles-container .shop-grid-carousel-button.shop-prev {
  left: 0px; /* Adjust as needed */
}

.shop-grid-small-tiles-container .shop-grid-carousel-button.shop-next {
  right: 0px; /* Adjust as needed */
}

/* Tile Styles */
.shop-grid-item {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.shop-grid-item img {
  width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
}

.shop-grid-placeholder {
  width: 100%;
  height: 100%;
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #555;
  font-size: 1rem;
}

.shop-grid-overlay {
  position: absolute;
  inset: 0;
  background: linear-gradient(to bottom, transparent, black);
  opacity: 0.5;
}

.shop-grid-browse-mobile {
  margin-top: 24px;
  text-align: center;
}

.shop-grid-error {
  text-align: center;
  color: #ff4d4f;
  font-size: 1.2rem;
  margin: 20px 0;
}

/* Centered Content (Avatar and Shop Name) */
.shop-grid-center-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

/* Avatar Styles */
.shop-grid-avatar,
.shop-grid-avatar-placeholder {
  width: 90px !important;
  height: 90px !important;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #fff; /* Optional: Add a border for better visibility */
  margin: 0 auto;
}

/* Shop Name Styles */
.shop-grid-shop-name {
  margin-top: 10px;
  font-size: 1.2rem;
  font-weight: bold;
 color: var(--text-color);;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .shop-grid-grid {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 20px;
  }

  .shop-grid-item.large,
  .shop-grid-small-tiles-container,
  .shop-grid-item.small {
    grid-column: 1 / -1;
    grid-row: auto;
  }

  .shop-grid-item.large {
    height: auto;
  }

  .shop-grid-small-tiles-container {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 20px;
  }

  .shop-grid-item.small {
    height: auto;
  }
}



/* MainPage Styles */

/* General Container Styling */
.main-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  justify-content: center;
}

.main-page-tab-content-container {
  display: flex;
  justify-content: center;
  width: 100%;
  position: fixed;
  top: 12vh;
  z-index: 40; 
}

/* Tab Navigation Styling */
.main-page-tab-navigation {
  display: block;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius-full);
  padding: 10px 0;
  background: rgba(0, 0, 0, 0.4); 
  backdrop-filter: var(--modal-overlay-backdrop-filter); 
  z-index: 40; 
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

/* Hidden State */
.main-page-tab-navigation.hidden {
  transform: translateY(-100%); 
  opacity: 0;
}

/* Visible State */
.main-page-tab-navigation.visible {
  transform: translateY(0);
  opacity: 1;
}

.main-page-tab-button {
  padding: 10px 20px;
  margin: 0 5px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: transparent;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
 color: var(--text-color);;
  border-radius: var(--border-radius-full);
}

.main-page-tab-button:hover {
  background-color: rgba(255, 255, 255, 0.3);
 color: var(--text-color);;
}

.main-page-active-tab {
  background-color: var(--primary-color); 
  color: var(--text-color); 
  border-radius: var(--border-radius-lg);
}

/* Content Area Styling */
.main-page-tab-content {
  flex: 1;
  padding: 80px 20px 20px; 
  
}

/* Responsive Adjustments */

/* Medium Screens */
@media (max-width: 1024px) {
  .main-page-tab-navigation {
    flex-direction: column;
    align-items: center;
  }

  .main-page-tab-button {
    margin: 5px 0;
  }
}

/* Small Screens */
@media (max-width: 768px) {
  .main-page-tab-navigation {
    top: 8vh;
    flex-direction: row;
    padding: 5px 10px;
  }

}

/* Extra Small Screens (Mobile) */
@media (max-width: 480px) {
  .main-page-tab-content {
    padding: 80px 10px 10px; /* Adjusted padding for mobile */
  }
  .explore-bento-section {
    padding: 0px;
  }
  .explore-bento-title {
    display: none;
  }
}


/* ItemLoader.css */

.item-loader {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: var(--border-radius-md);
 
  animation: pulse 1.5s infinite;
}

.skeleton {
  
  border-radius: 4px;
  margin-bottom: 10px;
}

.skeleton-image {
  width: 100%;
  height: 150px;
}

.skeleton-text {
  width: 80%;
  height: 20px;
}

.skeleton-text.short {
  width: 60%;
  height: 20px;
}

.product-card-component-color-swatch {
  border-radius: var(--border-radius-full);
  height: 25px;
  width: 25px;
}
.signup-cta-container {
  margin-top: 25px;
}

.post-modal-close-button {
  right: 25px;
}



.main-page-tab-navigation {
  background-color: var(--background-color);
  border-radius: var(--border-radius-full);
  padding: 10px 10px;
}

.post-modal-close-button {
  right: 15px;
}

.category-products-page__category-scroller {
  display: flex;
  justify-content: center;
}

.category-products-page__subcategories {
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
}

.category-products-page__subcategories h3 {
  font-size: 1.5rem;
  color: var(--text-color);
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.subcategory-list {
  display: flex;
  overflow-x: scroll;
  gap: 20px;
  padding: 20px;
}

.subcategory-button {
  flex: 0 0 auto;
  padding: 8px 15px;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-full);
  color: var(--text-color);
  font-size: 0.9rem;
  font-weight: 500;
  text-transform: capitalize;
  cursor: pointer;
  transition: all 0.3s ease;
}


.subcategory-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
  border-color: var(--border-color);
  transform: translateY(-2px);
}

/* Active Subcategory Button Styling */
.subcategory-button.active-subcategory {
  transform: scale(1.1);
  box-shadow: 0 0 10px 2px rgba(0, 123, 255, 0.7) !important; 
  transition: transform 0.3s, box-shadow 0.3s;
}

.subcategory-button:active {
  transform: translateY(1px);
  background-color: rgba(0, 0, 0, 0.9);
  border-color: rgba(250, 250, 250, 0.8);
}

.subcategory-list p {
  color: #d3d3d3;
  font-size: 1rem;
  margin: 10px 0;
}

/* Assuming you're using a CSS file like src/styles/CategoryProductsPage.css */

/* Active Category Styling */
.active-category {
  transform: scale(1.1);
  box-shadow: 0 0 10px 2px rgba(255, 215, 0, 0.7); /* Gold glow */
  transition: transform 0.3s, box-shadow 0.3s;
}

/* Transition for Category Items */
.category-item {
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
}

/* Hover Effect for Category Items */
.category-item:hover {
  transform: scale(1.05);
}


/* Hover Effect for Subcategory Buttons */
.subcategory-button:hover {
  transform: scale(1.1);
  box-shadow: 0 0 5px 1px rgba(0, 123, 255, 0.5);
}

.active-category-image-wrapper {
  transform: scale(1.1);
  box-shadow: 0 0 15px 3px rgba(255, 215, 0, 0.7); /* Gold glow */
}

.post-widget-reshare-avatar {
  border: 1px solid #ffffff;
}

/* src/components/Shop/ShopBySellerProds.css */

/* Container for the shop list */
.shop-prods-list-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1400px;
  padding: 40px 20px;
}

/* Loading and Error States */
.shop-prods-loading,
.shop-prods-error {
  text-align: center;
  font-size: 1.5rem;
  color: #555;
  padding: 50px 0;
}

.shop-prods-error {
  color: #e74c3c;
}

/* Each shop grid */
.shop-prods-grid {
  display: flex;
  margin-bottom: 60px;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-md);
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Adjust flex direction for shops with no products */
.shop-prods-grid.no-products {
  flex-direction: column;
}

/* Shop banner styling */
.shop-prods-banner {
  width: 50%;
  position: relative;
}

.shop-prods-grid.no-products .shop-prods-banner {
  width: 100%;
}

.shop-prods-banner a {
  display: block;
  height: 100%;
}

.shop-prods-banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.shop-prods-banner-image:hover {
  transform: scale(1.05);
}

/* Overlay over shop banner */
.shop-prods-banner-overlay {
  position: absolute;
  bottom: 20px;
  left: 20px;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  padding: 10px 15px;
  border-radius: var(--border-radius-md);
 color: var(--text-color);;
}

.shop-prods-avatar-container {
  margin-right: 10px;
}

.shop-prods-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff;
}

.shop-prods-avatar-placeholder {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  color: #555;
}

.shop-prods-shop-name {
  margin: 0;
  font-size: 1.5rem;
  font-weight: bold;
}

/* Products carousel styling */
.shop-prods-products-carousel {
  width: 50%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.shop-prods-grid.no-products .shop-prods-products-carousel {
  display: none;
}

/* Carousel track */
.shop-prods-carousel-track {
  display: flex;
  transition: transform 0.5s ease-in-out;
  height: 100%;
  /* Prevent wrapping */
  flex-wrap: nowrap;
}

/* Each carousel slide */
.shop-prods-carousel-slide {
  display: flex;
  width: 100%;
  justify-content: center;
  /* Prevent shrinking */
  flex-shrink: 0;
}

/* Product card styling */
.shop-prods-product-card {
  width: 45%; /* Slight gap between products */
  margin: 0 2.5%;
  text-decoration: none;
  color: inherit;
  position: relative;
  display: block;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: var(--text-light);
  transition: transform 0.3s ease;
}

.shop-prods-product-card:hover {
  transform: translateY(-5px);
}

.shop-prods-product-image-container {
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  position: relative;
  background-color: #f0f0f0;
}

.shop-prods-product-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease;
}

.shop-prods-product-card:hover .shop-prods-product-image {
  opacity: 0.8;
}

/* Overlay styling */
.shop-prods-product-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
 color: var(--text-color);;
  padding: 10px;
  box-sizing: border-box;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.shop-prods-product-card:hover .shop-prods-product-overlay {
  opacity: 1;
}

.shop-prods-product-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shop-prods-product-name {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 600;
}

.shop-prods-product-price {
  margin: 5px 0 0 0;
  font-size: 1rem;
  color: #ffd700; /* Gold color for price */
}

/* Carousel buttons */
.shop-prods-carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  border: none;
  font-size: 2rem;
  padding: 0px 15px;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  z-index: 2;
}

.shop-prods-prev {
  left: 10px;
}

.shop-prods-next {
  right: 10px;
}

.shop-prods-carousel-button:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .shop-prods-grid {
    flex-direction: column;
  }

  .shop-prods-banner,
  .shop-prods-products-carousel {
    width: 100%;
  }

  .shop-prods-carousel-button.prev {
    left: 5px;
  }

  .shop-prods-carousel-button.next {
    right: 5px;
  }
}

@media (max-width: 768px) {
  .shop-prods-list-container {
    padding: 20px 10px;
  }

  .shop-prods-carousel-button {
    font-size: 1.5rem;
    padding: 0px 12px;
  }

  .shop-prods-product-card {
    width: 90%;
    margin: 10px 5%;
  }

  .shop-prods-product-name {
    font-size: 1rem;
  }

  .shop-prods-product-price {
    font-size: 0.9rem;
  }

  .shop-prods-banner-overlay {
    bottom: 10px;
    left: 10px;
    padding: 8px 12px;
  }

  .shop-prods-avatar {
    width: 50px;
    height: 50px;
  }

  .shop-prods-shop-name {
    font-size: 1.2rem;
  }
}


.explore-bento-shop-info {
  padding-left: 6px;
}

.events-parent-container {
  background-color: initial;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-sm);
  margin: 0 auto 25px;
  min-height: 200px;
  padding: 15px;
}

.product-card-component-size-selection {
  display: flex;
  align-items: center;
  justify-content: center;
}

.size-buttons-container {
  display: flex;
    padding: 10px;
    gap: 5%;
    overflow: scroll;
    width: 100%;
    justify-content: center;
}

/* ComingSoon.css */

.coming-soon-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  backdrop-filter: blur(8px); 
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5; 
}

.coming-soon-content h1 {
  color: var(--text-color);
  font-size: 3rem;
  font-family: 'Roboto';
  text-align: center;
  animation: fadeIn 2s ease-in-out;
}

/* Responsive Design */
@media (max-width: 768px) {
  .coming-soon-content h1 {
    font-size: 2rem;
  }
  .size-buttons-container {
    display: flex;
      padding: 10px;
      gap: 5%;
      overflow: scroll;
      width: 100%;
      justify-content: start;
  }
}

@media (max-width: 480px) {
  .coming-soon-content h1 {
    font-size: 1.5rem;
  }
}

.size-btn.active {
  font-size: 10px !important;
  font-weight: 700 !important;
}
.size-btn {
  border-radius: var(--border-radius-full);
  font-size: 10px;
  padding: 5px 10px;
}

.my-images-component-modal-content {
  background: rgba(12, 17, 23, 0.7);
  border-radius: 15px;
  border: 1px solid var(--border-color);
}

.my-images-component-thumbnail-small.active, .my-images-component-thumbnail-small:active {
  opacity: 1;
  transform: scale(1.1);
}

.shop-prods-banner-overlay {
  border-radius: var(--border-radius-full);
  padding: 10px 15px;
}

.category-marketplace-section-component-bg-black {
  margin-bottom: 20px;
}

.three-tile-container {
  padding: 25px 0;
}

.terms-conditions-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
 color: var(--text-color);;
}

.terms-conditions-header {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
  color: #555;
}

.terms-conditions-intro {
  margin-bottom: 20px;
  font-size: 1rem;
  text-align: center;
}

.terms-conditions-section-container {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
}

.terms-conditions-section {
  border-bottom: 1px solid #ddd;
}

.terms-conditions-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 15px;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  background-color: var(--background-color);
  text-align: left;
  transition: background-color 0.3s ease;
}

.terms-conditions-section-header:hover {
  background-color: rgba(250, 250, 250, 0.2);
}

.terms-conditions-section-header-open {
  background-color: var(--background-color);
}

.terms-conditions-section-content {
  padding: 10px 15px;
  background-color: var(--background-color);
  font-size: 0.95rem;
}


/* Add this CSS to your stylesheet */

.vendor-terms-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
}

.vendor-terms-container h1 {
  text-align: center;
  margin-bottom: 40px;
}

.vendor-terms-container h2 {
  margin-top: 30px;
  margin-bottom: 10px;
  color: #2c3e50;
}

.vendor-terms-container ul {
  list-style-type: disc;
  margin-left: 20px;
}

.vendor-terms-container p {
  margin-top: 30px;
  font-weight: bold;
}


.marketplace-dropdown-container {
  z-index: 50;
  top: 3.5rem;
  width: 100%;
  height: 100%;
  border-top: 2px solid rgba(250, 250, 250, 0.3);
}

.marketplace-dropdown-subcategories {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 0.5rem 1rem;
}

.marketplace-dropdown-subcategory {
  margin-right: 0.5rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  white-space: nowrap;
  transition: background-color 0.3s ease;
  background-color: var(--background-color);
  border-radius: var(--border-radius-lg);
  margin-top: 5px;
}

.marketplace-dropdown-subcategory:hover {
  background-color: #444;
}

.marketplace-dropdown-subcategory-text {
  color: var(--text-color);
  font-weight: 500;
}

.marketplace-dropdown-inner {
  display: flex;
  padding: 1rem;
}

/* Existing CSS ... */

@media (max-width: 480px) {
  .marketplace-dropdown-section {
    flex: 1;
    margin-right: 0rem;
  }
  .marketplace-dropdown-inner {
    flex-direction: column;
    margin-bottom: 50px;
  }
}

.community-dropdown-container {
  border-top: none;
  border-bottom: 1px solid rgba(250, 250, 250, 0.4);
}

.circle-shop-list {
  -webkit-overflow-scrolling: touch;
  background-color: initial !important;
  border: none !important;
  padding-bottom: 0px !important;
}

.community-dropdown-container {
  border-top: 1px solid rgba(250, 250, 250, 0.4);
  border-bottom: 1px solid rgba(250, 250, 250, 0.4);
  padding: 15px;
  background-color: var(--background-color);
}

.category-image-wrapper {
  border: 1px solid rgba(250, 250, 250, 0.5);
}

.my-images-component-gallery-image {
  border: 1px solid var(--border-color);
}

.my-images-component-modal-content {
  min-height: 90%;
  min-width: 90%;
  align-content: center;
}

.my-images-component-gallery-image {
  margin: 25px;
  max-height: 80vh;
  max-width: 91%;
  border: 1px solid var(--border-color);
}

.my-images-component-thumbnail-small {
  border: 1px solid rgba(250, 250, 250, 0.5);
}

.my-images-component-thumbnail-navigation {
  gap: 10px;
}

.category-image-wrapper:active {
  height: 97px !important;
  width: 97px !important;
}

.circle-shop-list {
  padding: 0px !important;
}

.community-dropdown-title {
  margin-bottom: 0px;
}

.top-category-item.active {
    background-color: var(--background-color);
}

.top-category-item {
    background-color: var(--background-color);
}

.main-header-component-categories {
    width: 200px;
}

.subcategory-button {
    background-color: var(--background-color);
}

/* Event Details  */
.event-details-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  
}

.event-details-card {
  background-color: var(--background-color);
  border-radius: 16px;
  overflow: hidden;
  max-width: 800px;
  width: 100%;
  backdrop-filter: var(--modal-overlay-backdrop-filter);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.37);
  animation: fadeIn 0.5s ease-in-out;
}

/* Image Container */
.event-details-image-container {
  position: relative;
  height: 300px;
  overflow: hidden;
}

.event-details-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.event-details-image-container:hover .event-details-image {
  transform: scale(1.05);
}

.event-details-title {
  position: absolute;
  bottom: 16px;
  left: 16px;
  color: var(--text-color);
  font-size: 32px;
  font-weight: bold;
  text-shadow: 0 4px 6px rgba(0, 0, 0, 0.6);
}

/* Content */
.event-details-content {
  padding: 24px;
}

.event-details-info {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
}

.event-details-row {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #f0f0f0;
}

.event-details-icon {
  width: 24px;
  height: 24px;
  color: var(--primary-color);
  margin-right: 8px;
}

.event-details-link {
  color: #81d4fa;
  text-decoration: none;
  transition: color 0.3s;
}

.event-details-link:hover {
  color: #29b6f6;
}

/* Actions */
.event-details-actions {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
}

.event-details-button {
  background-color: var(--primary-color);
  color: var(--button-text-color); /* Use the button text color variable */
  border: none;
  padding: 14px;
  border-radius: var(--border-radius-full);
  font-size: 18px;
  cursor: pointer;
  transition: var(--transition-default); /* Use the default transition variable */
}


.event-details-button:hover {
  background-color: #43a047;
  transform: translateY(-2px);
}

.event-details-calendar-buttons {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  flex-wrap: wrap;
}

/* Sections */
.event-details-section {
  margin-bottom: 32px;
}

.event-details-section h3 {
  font-size: 24px;
  color: #f0f0f0;
  margin-bottom: 16px;
}

/* Host */
.event-details-host {
  display: flex;
  align-items: center;
  gap: 16px;
}

.event-details-host-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid rgba(250, 250, 250, 0.1);
}

/* Attendees */
.event-details-attendees {
  display: flex;
  align-items: center;
  gap: 8px;
}

.event-details-attendee-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid rgba(250, 250, 250, 0.1);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s;
}

.event-details-attendee-avatar:hover {
  transform: translateY(-4px);
}

.event-details-more-attendees {
  font-size: 18px;
  color: #f0f0f0;
  background-color: var(--background-color);
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Description */
.event-details-content p {
  font-size: 18px;
  color: #d0d0d0;
  line-height: 1.6;
}

/* Responsive Design */
@media (max-width: 768px) {
  .event-details-content {
      padding: 16px;
  }

  .event-details-title {
      font-size: 24px;
      bottom: 8px;
      left: 8px;
  }

  .event-details-section h3 {
      font-size: 20px;
  }

  .event-details-button {
      font-size: 16px;
      padding: 12px;
  }

  .event-details-host-avatar,
  .event-details-attendee-avatar {
      width: 48px;
      height: 48px;
  }

  .event-details-more-attendees {
      width: 40px;
      height: 40px;
      font-size: 16px;
  }
  
  .event-details-image-container {
    position: relative;
    height: 150px;
    overflow: hidden;
  }
  .event-details-container {
    margin-top: 12vh;
}
}

/* Page Container */
.event-details-page {
  position: relative;
  padding: 0;
  background-color: #1a1a1a;
}

/* Back to Events Navigation */
.back-to-events {
  position: fixed;
  top: 12vh;
  left: 20px;
  z-index: 1000;
}

.back-to-events-link {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 10px 16px;
  border-radius: 12px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
}

.back-to-events-link:hover {
  background-color: rgba(250, 250, 250, 0.2);
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.back-to-events-icon {
  width: 20px;
  height: 20px;
  color: var(--primary-color);
  transition: color 0.3s;
}

.back-to-events-link:hover .back-to-events-icon {
  color: #81d4fa;
}

/* Responsive Design */
@media (max-width: 768px) {
  .back-to-events {
      position: fixed;
      top: 8vh;
      left: 16px;
  }

  .back-to-events-link {
      width: 48px;
      height: 48px;
      padding: 0;
      border-radius: 50%;
      background-color: rgba(250, 250, 250, 0.15);
      justify-content: center;
      align-items: center;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }

  .back-to-events-link:hover {
      background-color: rgba(250, 250, 250, 0.25);
      transform: scale(1.05);
  }

  .back-to-events-icon {
      width: 24px;
      height: 24px;
  }

  .back-to-events-text {
      display: none;
  }
}

.marketplace-dropdown-container {
  border-top: rgba(250, 250, 250, 0.3);
}

.top-level-dropdown-content {
  border-top: none;
}


.my-event-card-component-info {
  top: 0px;
  margin-top: 0px;
  padding: 15px !important;
}

.my-event-card-component-datetime p {
  font-size: 12px;
}

.my-event-card-component-date, .my-event-card-component-location, .my-event-card-component-time {
  font-size: 12px;
}

.my-event-card-component-button, .my-event-card-component-button a:hover {
  cursor: pointer;
  font-size: 14px;
  padding: 4px 15px !important;
  font-weight: 700;
}

.my-event-card-component-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
}

.post-card-avatar {
  border: 1px solid rgba(250, 250, 250, 1.5);
}

.explore-bento-product-card {
  position: relative;
}

/* Shop Info Container */
.explore-bento-product-card-shop-info {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.5); 
  border-radius: var(--border-radius-md); 
  padding: 5px 10px;
  z-index: 1;
  max-width: 80%; 
  flex-wrap: nowrap;
  overflow: hidden;
}

/* Shop Avatar */
.explore-bento-product-card-shop-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%; 
  object-fit: cover;
  flex-shrink: 0; 
}

/* Shop Name */
.explore-bento-product-card-shop-name {
  color: #fff;
  margin-left: 8px;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis; 
  overflow: hidden;
}

/* Responsive Design Adjustments */
@media (max-width: 768px) {
  .explore-bento-product-card-shop-info {
    top: 8px;
    left: 8px;
    padding: 4px 8px;
    border-radius: 15px;
    max-width: 90%; 
  }

  .explore-bento-product-card-shop-avatar {
    width: 25px;
    height: 25px;
  }

  .explore-bento-product-card-shop-name {
    font-size: 12px;
    margin-left: 6px;
  }
}

@media (max-width: 480px) {
  .explore-bento-product-card-shop-info {
    top: 5px;
    left: 5px;
    padding: 3px 6px;
  }

  .explore-bento-product-card-shop-avatar {
    width: 20px;
    height: 20px;
  }

  .explore-bento-product-card-shop-name {
    font-size: 10px;
    margin-left: 4px;
  }
}

.shop-dashboard-sidebar-category-header {
 color: var(--text-color);;
  font-size: 16px;
  font-weight: 700;
}

.create-ad-complete-button {
  background-color: var(--primary-color);
  border-radius: var(--border-radius-full);
 color: var(--text-color);;
  font-size: 16px;
  font-weight: 700;
}

.create-product-component-form-input {
  text-transform: capitalize;
}

/* Ship Engine */
.shipengine-calculator-container {
  background-color: var(--background-color);
  padding: 20px;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-md);
  width: 50%;
  margin: 0 auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.shipengine-calculator-title {
  font-size: 16px;
  color: var(--text-color);
    margin-bottom: 10px;
    text-transform: uppercase;
    padding-left: 10px;
    border-left: 4px solid var(--primary-color);
}

.shipengine-calculator-label {
  font-size: 12px;
  color: var(--text-color);
    text-transform: uppercase;
    padding-left: 10px;
    border-left: 2px solid var(--primary-color);
    margin-left: 10px;
    display: flex;
  align-items: center;
}

/* Form Layout: 2 Columns for 'Ship To' and 'Package Details' */
.shipengine-calculator-form-row {
  display: flex;
  gap: 20px;
  flex-wrap: wrap; 
}

.shipengine-calculator-form-column {
  flex: 1 1 0;
  min-width: 250px; 
}

.shipengine-calculator-field-group {
  display: flex;
  position: relative;
}

/* Section Titles */
.shipengine-calculator-section-title {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Input Groups */
.shipengine-calculator-input-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Inputs */
.shipengine-calculator-input {
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-sm);
  background-color: var(--background-color);
}

/* Calculate Button */
.shipengine-calculator-button {
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 10px 20px;
  border: 1px solid  var(--primary-color);
  border-radius: var(--border-radius-full); 
  cursor: pointer;
  margin-top: 20px;
  font-size: 16px;
  transition: background-color ease 0.3s, opacity 0.3s;
}

.shipengine-calculator-button:hover {
  background-color: var(--primary-color);
}

/* Disabled button state */
.shipengine-calculator-button:disabled {
  background: #ccc;
  cursor: not-allowed;
  opacity: 0.6;
}

/* Error message */
.shipengine-calculator-error {
  color: red;
  margin-top: 10px;
}

/* Rates Title */
.shipengine-calculator-rates-title {
  font-size: 20px;
  margin-top: 20px;
}

/* Rates Container & Items */
.shipengine-calculator-rates {
  margin-top: 10px;
}

.shipengine-calculator-rate {
  background-color: var(--background-color);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Responsive: stack columns on smaller devices */
@media (max-width: 768px) {
  .shipengine-calculator-form-row {
    flex-direction: column;
  }
}

.active-category-image-wrapper {
  box-shadow: 0 0 15px 3px var(--primary-color)b3;
  transform: scale(1.1);
}

.subcategory-button.active-subcategory {
  box-shadow: 0 0 10px 2px var(--primary-color)b3 !important;
}

.subcategory-button:hover {
  box-shadow: 0 0 10px 2px var(--primary-color)b3 !important;
}

.followers-avatar-display-image {
  border: 1px solid #fff;
}

.sponsored-products-component-visit-store {
  font-size: 10px !important;
  padding: 5px 5px !important;
  width: 100px !important;
  margin-bottom: -55px;
}

.sponsored-products-component-info div {
  width: 100% !important;
  text-align: center;
  float: left;
  display: block;
  position: relative;
}

.sponsored-products-component-info {
  width: 100%;
  min-height: 100px;
}

main.events-page-main-content {
  background-color: transparent !important;
}

.events-page-container {
  background-color: transparent !important;
}

.events-page-aside {
  background-color: transparent !important;
  border-left: 1px solid var(--border-color);;
}


.my-events-container {
  border: 1px solid var(--border-color) !important;
  width: 100%;
}

.events-page-create-event-button {
  background-color: rgba(0, 0, 0, 0.1) !important;
  border: 1.5px solid var(--primary-color) !important;
}

button.events-page-create-event-button:hover {
  background-color: var(--primary-color) !important;
}

button.event-card-component-button:hover {
  background-color: transparent !important;
  border: 2px solid var(--primary-color) !important;
}

.event-card-component-button {
  background-color: var(--primary-color);
  border: 2px solid var(--primary-color) !important;
  padding: 5px 20px !important;
}

.event-card-component-description {
  font-size: 17px;
  font-weight: 600;
}

.event-card-component-info {
  justify-content: end;
}

.event-card-component-date, .event-card-component-location, .event-card-component-time {
  text-transform: capitalize;
}

.explore-bento-product-card-shop-avatar {
  border: 1px solid #fff;
}

.post-card-avatar {
  border: 1.5px solid #fff;
}

.sponsored-product-carousel, .sponsored-product-mobile-carousel {
  border-radius: var(--border-radius-sm);
  margin: 0px 0;
  min-height: 200px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.sponsored-product-carousel-paper, .sponsored-product-mobile-carousel-paper {
  align-items: center;
  background-position: 50%;
  background-size: cover;
  border-radius: var(--border-radius-sm);
 color: var(--text-color);;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0 !important;
  overflow: hidden;
  padding-bottom: 0 !important;
  position: relative;
  width: 100%;
  min-height: inherit;
}

.post-card-date {
  font-size: 10px;
}

.sponsored-product-carousel-title {
  font-size: 18px !important;
  font-weight: 700 !important;
  letter-spacing: 1.5px !important;
  margin: 0;
  text-overflow: ellipsis !important;
}


.mosaic-modal-buttons {
  margin: auto;
}

.mosaic-modal-view-details-button {
  background-color: initial;
  border: 2px solid var(--primary-color);
  border-radius: var(--border-radius-full);
  color: var(--primary-color);
  cursor: pointer;
  font-size: 1em;
  font-weight: 600;
  padding: 10px 20px;
  transition: background-color .3s ease, color .3s ease;
}

.product-details-component__add-to-cart-button {
  background-color: var(--secondary-color) !important;
  border: 2px solid #fd8309 !important;
}

/* src/components/CarrierSelection.css */

.carrier-selection-container {
  max-width: 600px;
  margin: 40px auto;
  padding: 30px;
  background-color: var(--background-color);
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.carrier-selection-title {
  text-align: center;
  margin-bottom: 25px;
  font-size: 24px;
  color: #333;
}

.carrier-selection-error {
  color: #ff4d4f;
  text-align: center;
  margin-bottom: 15px;
}

.carrier-selection-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.carrier-dropdown {
  position: relative;
  width: 100%;
  margin-bottom: 25px;
}

.carrier-dropdown-toggle {
  width: 100%;
  padding: 12px 20px;
  background-color: var(--background-color);
  border: 1px solid #ddd;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  font-size: 18px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.3s;
}

.carrier-dropdown-toggle:hover {
  background-color: #f0f0f0;
}

.carrier-dropdown-toggle::after {
  content: '▼';
  font-size: 14px;
  margin-left: 10px;
}

.carrier-dropdown-menu {
  position: absolute;
  top: 110%;
  left: 0;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur (10px);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-sm);
  z-index: 1000;
  padding: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.carrier-option {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.carrier-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
}

.carrier-logo {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  object-fit: contain;
}

.carrier-name {
  font-size: 16px;
 color: var(--text-color);;
}

.carrier-save-button {
  padding: 12px 25px;
  background-color: var(--primary-color);
   color: var(--text-color);
  border: none;
  border-radius: var(--border-radius-full);
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s;
}

.carrier-save-button:hover {
  background-color: #45a049;
}

/* Responsive Design */
@media (max-width: 600px) {
  .carrier-selection-container {
    padding: 20px;
  }

  .carrier-logo {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .carrier-dropdown-toggle {
    font-size: 16px;
    padding: 10px 15px;
  }

  .carrier-name {
    font-size: 14px;
  }

  .carrier-save-button {
    font-size: 16px;
    padding: 10px 20px;
  }
}

/* Carriers onboarding */
.carriers-onboarding-container {
  padding: 20px;
  font-family: sans-serif;
}

.carriers-onboarding-title {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.carriers-onboarding-error {
  color: red;
  margin-bottom: 1rem;
}

.carriers-onboarding-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.carriers-onboarding-fieldset {
  border: none;
  padding: 0;
}

.carriers-onboarding-legend {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.carriers-onboarding-checkbox-group {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.carriers-onboarding-checkbox-option {
  position: relative;
  background: var(--background-color); 
  border-radius: 8px;
  padding: 10px; 
  cursor: pointer; 
  transition: border-color 0.3s, background 0.3s; 
  border: 2px solid transparent; 
  display: flex; 
  align-items: center; 
  gap: 10px; 
}

.carriers-onboarding-checkbox-option:hover {
  border-color: var(--border-color); 
}

.carriers-onboarding-checkbox-input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.carriers-onboarding-checkbox-option img {
  width: 40px;
  height: 40px;
  object-fit: contain; /* Ensures logos scale properly */
}

.carriers-onboarding-checkbox-label {
  font-size: 1rem;
  position: relative;
  padding-right: 25px; 
 color: var(--text-color);; 
}

.carriers-onboarding-checkbox-label::after {
  content: '✔'; /* Unicode checkmark */
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  color: var(--primary-color); /* Indigo color for the checkmark */
  opacity: 0; /* Hidden by default */
  transition: opacity 0.3s; 
}

.carriers-onboarding-checkbox-option input[type="checkbox"]:checked ~ .carriers-onboarding-checkbox-label::after {
  opacity: 1; 
}

.carriers-onboarding-checkbox-option input[type="checkbox"]:checked ~ img {
  border: 2px solid var(--primary-color); 
  border-radius: 4px; 
}

.carriers-onboarding-checkbox-option input[type="checkbox"]:checked ~ .carriers-onboarding-checkbox-label {
  color: var(--primary-color); 
} 

.carriers-onboarding-checkbox-option input[type="checkbox"]:checked ~ .carriers-onboarding-checkbox-label {
  border-color: var(--primary-color); 
} 


.carriers-onboarding-save-button {
  padding: 0.5rem 1rem;
  background: var(--primary-color); 
 color: var(--text-color);;
  border: none;
  border-radius: var(--border-radius-full);
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
}

/* Add a hover effect with subtle scaling and shadow */
.carriers-onboarding-save-button:hover {
  transform: scale(1.05); /* Slightly increase size on hover */
  background-color: #2bb000; /* Darker green for hover */
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2); /* Add a shadow effect */
}

/* Active state with pressed-down effect */
.carriers-onboarding-save-button:active {
  transform: scale(0.95); /* Slightly shrink on click */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); /* Reduce shadow for pressed effect */
}



/* Style the container when the checkbox is selected */
.carriers-onboarding-checkbox-input:checked + .carriers-onboarding-checkbox-option {
  border-color: var(--primary-color); 
  background: rgba(79, 70, 229, 0.1); 
}


/* Button Styling */
.shipping-checkout-button {
  background-color: var(--primary-color);
 color: var(--text-color);;
  border: none;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  border-radius: var(--border-radius-full);
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  text-align: center;
}

.shipping-checkout-button:hover {
  background-color: #3d6300;
}

.shipping-checkout-button:disabled {
  background-color: var(--background-color);
  cursor: not-allowed;
}

/* Shipping Options Container */
.shipping-checkout-options {
  margin-top: 20px;
  padding: 16px;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-md);
  background-color: var(--background-color);
}

.shipping-checkout-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
  color: #333;
}

/* Radio Group */
.shipping-checkout-options-group {
  display: flex;
  overflow: scroll;
  gap: 12px;
}

/* Individual Option */
.shipping-checkout-option {
  display: flex;
  align-items: flex-start;
  padding: 12px;
  width: 200px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  background-color: var(--background-color);
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
  cursor: pointer;
}

.shipping-checkout-option:hover {
  border-color: var(--primary-color);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.shipping-checkout-radio {
  margin-right: 16px;
  transform: scale(1.2);
  cursor: pointer;
}

/* Option Details */
.shipping-checkout-option-details {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.shipping-checkout-option-carrier {
  font-size: 16px;
  font-weight: 600;
 color: var(--text-color);;
}

.shipping-checkout-option-service {
  font-size: 14px;
  color: #999;
}

.shipping-checkout-option-price {
  font-size: 14px;
  font-weight: 600;
  color: var(--primary-color);
}

.shipping-checkout-option-delivery {
  font-size: 12px;
  color: #888;
}

.all-products-component td, .all-products-component th {
  text-transform: capitalize !important;
}

.product-details-component__info-container {
  max-width: 1320px;
  margin: auto;
}

.product-details-component__active-indicator {
    background-color: transparent !important;
    bottom: 0 !important;
    height: 4px;
    left: 0;
    position: absolute;
    right: 0;
    border: 3px solid var(--primary-color);
    height: 100%;
    border-radius: var(--border-radius-full);
}

.product-details-component__product-description-text {
    font-size: 16px;
    line-height: 2rem;
    padding-bottom: 25px;
    padding-top: 25px;
    white-space: pre-line;
}

/* Container styling */
.shipping-tracker-order-tracking-container {
  max-width: 600px;
  margin: 2rem auto;
  padding: 1.5rem;
  border-radius: var(--border-radius-md);
  background-color: var(--background-color);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

/* Header styling */
.shipping-tracker-order-tracking-container h3 {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1rem;
 color: var(--text-color);;
  text-align: center;
  border-bottom: 2px solid #007bff;
  display: inline-block;
  padding-bottom: 0.5rem;
}

.shipping-tracker-order-tracking-container h4 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 2rem;
  color: #999;
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.5rem;
}

/* Paragraph styling */
.shipping-tracker-order-tracking-container p {
  font-size: 1rem;
  line-height: 1.5;
 color: var(--text-color);;
  margin: 0.5rem 0;
}

.shipping-tracker-order-tracking-container p strong {
  color: #999;
}

/* Tracking history list */
.shipping-tracker-order-tracking-history ul {
  list-style: none;
  padding: 0;
  margin-top: 1rem;
}

.shipping-tracker-order-tracking-history ul li {
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 1rem;
  background: #fff;
  transition: box-shadow 0.3s ease;
}

.shipping-tracker-order-tracking-history ul li:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.shipping-tracker-order-tracking-history ul li p {
  margin: 0.2rem 0;
}

.shipping-tracker-order-tracking-history ul li p strong {
  font-weight: 600;
}

/* Tracking link styling */
.shipping-tracker-tracking-link {
  display: inline-block;
  margin-top: 1.5rem;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
 color: var(--text-color);;
  background: #007bff;
  border-radius: 6px;
  text-decoration: none;
  text-align: center;
  transition: background 0.3s ease;
}

.shipping-tracker-tracking-link:hover {
  background: #0056b3;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .shipping-tracker-order-tracking-container {
    padding: 1rem;
  }

  .shipping-tracker-order-tracking-container h3 {
    font-size: 1.5rem;
  }

  .shipping-tracker-order-tracking-container h4 {
    font-size: 1.3rem;
  }

  .shipping-tracker-tracking-link {
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
  }
}

/* PrivacyPolicy.css */

.legal-docs-container {
  background-color: var(--background-color); 
  color: var(--text-color); 
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.6;
}

.legal-docs-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
  border-bottom: 2px solid #444;
  padding-bottom: 10px;
}

.legal-docs-subtitle {
  font-size: 1.8rem;
  margin-top: 30px;
  margin-bottom: 15px;
  color: #dddddd;
}

.legal-docs-subsubtitle {
  font-size: 1.5rem;
  margin-top: 25px;
  margin-bottom: 10px;
  color: #eeeeee;
}

.legal-docs-text {
  font-size: 1rem;
  margin-bottom: 15px;
}

.legal-docs-list {
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 20px;
}

.legal-docs-list li {
  margin-bottom: 10px;
}

.legal-docs-link {
  color: var(--primary-color);
  text-decoration: none;
}

.legal-docs-link:hover {
  text-decoration: underline;
}

.legal-docs-table-container {
  overflow-x: auto;
  margin-bottom: 20px;
}

.legal-docs-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.legal-docs-table th,
.legal-docs-table td {
  border: 1px solid #444;
  padding: 12px;
  text-align: left;
}

.legal-docs-table th {
  background-color: #3a3a3a;
  color: var(--text-color);
}

.legal-docs-table tr:nth-child(even) {
  background-color: #3a3a3a;
}

.legal-docs-table tr:hover {
  background-color: #505050;
}
.shop-profile-data-tab-navigation {
    padding: 0px 25px 25px;
}

@media (min-width: 769px) and (max-width: 1024px) {
  .explore-bento-card-title {
    font-size: 14px;
    font-weight: 700;
  }
  .main-feed-page-main-content {
    margin-bottom: 100px;
}
  .main-page-tab-content {
        padding: 80px 10px 10px;
        padding-top: 50px;
}
  .product-card-component-shop {
    background-color: rgba(0, 0, 0, 0.7);
    border: 1px solid #ffffff;
    border-radius: var(--border-radius-lg);
    padding: 4px 5px;
}
.explore-bento-shop-avatar {
        height: 24px;
        width: 25px;
}
  .explore-bento-shop-info {
    border-radius: var(--border-radius-lg);
    bottom: 10px;
    gap: 5px;
    left: 5px;
    padding: 5px;
}
  .shop-info-sidebar-follow-section {
        margin-top: 0px;
}
.shop-profile-data-tab-navigation {
    padding: 15px 15px 15px;
}
  .shop-info-sidebar-name {
        font-size: 20px;
    }
.switcheroo-modal-box {
    top: 420px;
}
.shop-info-sidebar-follow-section {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 1;
}
.shop-profile-data-tab-navigation {
    border: none;
    border-bottom: 1px solid #fafafa4d;
    border-radius: 0;
    display: inline-block;
    /* margin-bottom: 15px; */
    /* margin: 15px; */
    overflow: scroll;
    padding: 0px 25px 25px;
    width: 100%;
}
}

@media(max-width:1023px){
.profile-sidebar-left {
    display: none;
}
.profile-sidebar-right, .shop-profile-sidebar-right {
    display: none;
}
.shop-info-sidebar-follow-section {
        margin-top: 0px;
    }
.shop-info-sidebar-follow-section {
    gap: 5px;
}
.profile-main-area {
    padding-bottom: 100px;
}
.profile-main-content {
  padding-top: 15px;
}
}

/* Responsive Design */
@media (max-width: 768px) {
  .legal-docs-container {
    padding: 20px;
  }

  .legal-docs-title {
    font-size: 2rem;
  }

  .legal-docs-subtitle {
    font-size: 1.5rem;
  }

  .legal-docs-subsubtitle {
    font-size: 1.3rem;
  }

  .legal-docs-text {
    font-size: 0.95rem;
  }

  .legal-docs-table th,
  .legal-docs-table td {
    padding: 8px;
  }
  .explore-bento-card-title {
    font-size: 12px;
    font-weight: 700;
  }
  .explore-bento-shop-name {
   color: var(--text-color);;
    font-size: 12px;
    font-weight: 700;
  }
  .my-post-widget-wrapper {
    margin-bottom: 0px;
}
  .main-feed-page-container {
    padding: 0px;
  }
  .main-feed-page-main-content {
    margin-bottom: 100px;
}
  .main-page-tab-content {
        padding: 80px 10px 10px;
        padding-top: 50px;
    }
  .product-card-component-shop {
    
    border: 1px solid #ffffff;
    border-radius: var(--border-radius-lg);
    padding: 4px 5px;
}
  .explore-bento-shop-avatar {
        height: 24px;
        width: 25px;
}
  .explore-bento-shop-info {
    border-radius: var(--border-radius-lg);
    bottom: 10px;
    gap: 5px;
    left: 5px;
    padding: 5px;
}
    .shop-info-sidebar-name {
        margin-top: 0px;
}
  .shop-info-sidebar-follow-section {
        margin-top: 55px;
}
  .shop-profile-data-tab-navigation {
    padding: 15px 15px 15px;
}
  .shop-info-sidebar-name {
        font-size: 18px;
    }
  .switcheroo-modal-box {
    top: 420px;
}
  .checkout-component-left, .checkout-component-right {
    width: 100%;
}
  .switcheroo-swap-icon {
    background-color: var(--primary-color);
    border: 2px solid #000000 !important;
    border-radius: var(--border-radius-full);
    height: 20px !important;
    width: 23px !important;
}
.all-products-component-create-product-button {
    border-radius: var(--border-radius-full);
    font-size: 12px;
    right: 15px;
    top: 100px;
}
.shop-create-product-page-main {
    padding-bottom: 75px;
}

.explore-bento-container {
    padding: 10px;
    }
.shop-info-sidebar-follow-section {
        margin-top: 0px;
    }
.shop-banner-container, .user-banner-container {
        margin-top: 7.0vh;
        position: relative;
        width: 100%;
    }
/*.user-banner-container {
    height: 357px;
}*/
.category-image-wrapper {
  width: 75px;
  height: 75px;
}
.category-scroller {
  display: flex;
  gap: 5%;
  width: 100%;
  justify-content: center;
}
.top-level-dropdown {
    width: 100%;
}
}
@media(min-width:481px) and (max-width:1080px){

}
@media (max-width: 480px) {
    .main-feed-page-container {
    padding: 0px;
    }
    .main-feed-page-main-content {
    margin-bottom: 100px;
}
    .main-page-tab-content {
        padding: 80px 10px 10px;
        padding-top: 50px;
    }
  .product-card-component-shop {
    border: 1px solid #ffffff;
    border-radius: var(--border-radius-lg);
    padding: 4px 5px;
}
  .explore-bento-shop-avatar {
        height: 24px;
        width: 25px;
    }
  .explore-bento-shop-info {
    border-radius: var(--border-radius-lg);
    bottom: 10px;
    gap: 5px;
    left: 5px;
    padding: 5px;
}
  .shop-info-sidebar-name {
        margin-top: 0px;
    }
  .shop-profile-data-tab-navigation {
    padding: 15px 15px 15px;
}
  .shop-info-sidebar-name {
        font-size: 18px;
    }
.switcheroo-modal-box {
    top: 420px;
}
  .checkout-component-left, .checkout-component-right {
    width: 100%;
}
  .switcheroo-swap-icon {
    background-color: var(--primary-color);
    border: 2px solid #000000 !important;
    border-radius: var(--border-radius-full);
    height: 20px !important;
    width: 23px !important;
}
  .mobile-navbar-wrapper {
    display: block;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
  }

.switcheroo-modal-box {
    top: 420px;
}
  .checkout-component-left, .checkout-component-right {
    width: 100%;
}
.switcheroo-swap-icon {
    background-color: var(--primary-color);
    border: 2px solid #000000 !important;
    border-radius: var(--border-radius-full);
    height: 20px !important;
    width: 23px !important;
}
.all-products-component-create-product-button {
    border-radius: var(--border-radius-full);
    font-size: 12px;
    right: 15px;
    top: 100px;
}
.shop-create-product-page-main {
    padding-bottom: 75px;
}

/*.shop-profile-container {
    position: relative;
    width: 100%;
    margin-top: 15px;
    top: 10px;
}*/

.user-profile-data-tab-content {
    margin: 0px !important;
}
.category-scroller {
    gap: 10px;
    width: 100%;
}
.widget-wrapper {
        padding: 10px ;
    }
.category-products-page__category-scroller {
    margin-top: 65px;
}
.category-image-wrapper {
    width: 75px;
}
.category-name {
    font-size: 15px;
    margin-top: 10px;
    font-weight: 700;
}
.category-products-page__subcategories {
    border-radius: var(--border-radius-full);
    padding: 0px;
}
.category-products-page__products {
    margin: 0px;
    max-width: 100%;
    padding: 20px;
    width: 100% !important;
}
.trending-products-carousel-bg {
    margin: 25px 0 !important;
    width: 100%;
}
.top-level-dropdown {
    margin: 0 auto;
    max-width: 1000px;
    min-height: 69vh;
    overflow: hidden;
    overflow-y: scroll;
    position: absolute;
    top: 60px;
    width: 100vw;
}
.category-image-wrapper {
        width: 75px;
        height: 75px;
}
.main-page-tab-button {
    border-radius: var(--border-radius-full);
    font-size: 12px;
    margin: 0 5px;
    padding: 5px 10px;
}
.main-page-tab-navigation {
    border-radius: 100px;
    padding: 10px 15px;
}
.mobile-nav-link svg {
    height: 27px;
    width: 27px;
}
.category-image-wrapper {
  height: 55px;
  width: 55px;
}
.mobile-nav-link svg {
  height: 25px;
  width: 25px;
}
}

.explore-bento-title {
    display: none;
}

.mobile-bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: var(--modal-overlay-backdrop-filter);
  border-top: 1px solid rgba(250, 250, 250, 0.3);
  z-index: 1000;
}

.mobile-nav-icons {
  display: flex;
  justify-content: space-around;
  padding: 20px 0;
}

.mobile-nav-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: var(--text-color); 
  font-size: 12px;
  height: 27px;
  width: 27px;
}

.mobile-nav-item-active {
  color: var(--primary-color); 
}

.mobile-nav-link svg {
  width: 32px;
  height: 32px;
}



.main-header-component-mobile-header-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;
}

.main-header-component-mobile-discover {
  display: flex;
  flex: 0 0 auto;
  width: 25%;
  gap: 15px;
  align-items: center;
}

.main-header-component-mobile-logo-container {
  flex: 1;
  text-align: center;
  width: 50%;
  display: flex;
  justify-content: center;
}


.main-header-component-mobile-actions {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  gap: 15px;
  width: 25%;
  justify-content: end;
}

.main-header-component-mobile-cart {
  position: relative;
  cursor: pointer;
}

.main-header-component-mobile-cart-count {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #f00;
 color: var(--text-color);;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
  padding: 2px 5px;
}

.sidebar-profile-toolbar-avatar {
  cursor: pointer;
}

.main-header-component-sidebar-title {
  font-size: 20px;
  font-weight: 600;
  margin-right: auto; /* Push other elements to the right */
 color: var(--text-color);;
}

/* Sold Out Overlay */
.product-card-component-sold-out-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-sm);
  z-index: 10;
}

.product-card-component-sold-out-text {
 color: var(--text-color);;
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.switcheroo-modal-box h2 {
    font-size: 25px !important;
    font-weight: 600;
}


.individual-shop-category-button.active, .individual-shop-subcategory-button.active {
    background-color: var(--background-color);
   color: var(--text-color);;
    border: 3px solid var(--primary-color);
}

.main-page-tab-content-container {
    margin: 15px auto;
}

.notification-feed-item {
    padding-bottom: .5rem;
    padding-top: .5rem;
}

.notification-sidebar-body {
    padding: 0 0 !important;
}

.post-widget-popover-panel {
    background-color: var(--background-color);
    border: 1px solid rgba(250, 250, 250, 0.5);
    border-radius: 7px;
    margin-top: 0px;
    min-width: 100px;
}

textarea.edit-description-textarea {
    margin-top: 15px;
}

button.inline-flex.items-center.justify-center.rounded-md.bg-green-600.px-4.py-2.text-sm.font-semibold.text-white.hover\:bg-green-700.focus\:outline-none.focus\:ring-2.focus\:ring-green-500.focus\:ring-offset-2.transition.duration-200.ease-in-out {
    border-radius: var(--border-radius-full);
    background-color: var(--background-color);
    border: 3px solid var(--primary-color);
}



.post-widget-slideshow-container {
    margin: 0 auto;
    position: relative;
    width: 100%;
    margin-top: 25px;
}

.post-widget-slideshow-image {
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius-sm);
}

.post-widget-thumbnails-container {
    margin-top: 15px;
}

.post-widget-thumbnail-image {
    border: 1px solid var(--border-color);
}

.all-products-component .actions {
    border-left: 1px solid #2d3748;
}

.individual-shop-category-button, .individual-shop-subcategory-button {
    border-radius: var(--border-radius-full);
}

.notification-sidebar-header {
    padding: 15px !important;
    margin-bottom: 15px;
}

.my-images-component-image-grid {
    overflow: visible;
}

.top-level-dropdown {
    padding-bottom: 100px;
}

.notification-feed-text {
    max-width: 70%;
    margin-right: 5px;
}

.notification-sidebar-panel-content {
    padding-bottom: 50px;
}

.followers-following-panel-inner {
    padding-bottom: 50px;
}


/*IMPORTANT*/
.user-profile-data-tab-content {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.main-feed-page, div#root {
    width: 100% !important;
    min-width: 270px !important;
}

@media(max-width: 320px){

.widget-wrapper {
        padding: 10px;
    }
}

.event-card-component-owner-avatar {
    border: 1px solid rgba(250, 250, 250, 0.8);
}

.main-page-tab-navigation {
    margin-top: -10px !important;
}



input.follower-list-widget-search, input.following-list-widget-search {
    margin-top: 10px;
    margin-bottom: 15px;
}

.trending-products-carousel-info {
    background-color: transparent !important;
}
li.trending-products-carousel-item {
    background-color: var(--background-color);
}

.trending-products-carousel-category {
    color: #d1d5db;
    font-size: 10px;
    background-color: var(--background-color);
    width: 25%;
    margin: 0px auto;
    border-radius: var(--border-radius-full);
}

.wishlist-component-item-remove {
    cursor: pointer;
    margin-bottom: 0px;
    margin-left: 0;
    height: 15px;
    width: 15px;
}
.wishlist-component-item-add-to-cart {
    height: auto !important;
    margin-left: 10px !important;
    padding: 7px !important;
    width: 40px !important;
}
.wishlist-component-item-add-to-cart {
    background-color: var(--secondary-color) !important;
    border: solid 5px #fd8309 !important;
    border-radius: var(--border-radius-full) !important;
    color: #fff !important;
    height: 25px;
    margin-left: 10px !important;
    padding: 0px !important;
    width: 40px !important;
}

.form-error-message {
  display: flex;
  align-items: center;
  font-size: 0.875rem; 
  margin-top: 0.25rem; 
  font-weight: 500;
  line-height: 1.25;
  padding: 10px;
  border-radius: var(--border-radius-lg);
  background-color: rgba(244, 67, 54, 0.7);
  width: fit-content;
}

.form-error-message:before {
  content: '⚠️ '; 
  font-size: 1rem;
  vertical-align: middle;
  padding-right: 5px;
}

.product-card-component-eye-icon, .product-card-component-wishlist-icon {
  background-color: var(--primary-color);
  border-radius: var(--border-radius-full);
  padding: 4px;
  position: absolute;
  right: 8px;
  height: 30px;
  width: 30px;
}



input.follower-list-widget-search, input.following-list-widget-search {
    margin-top: 10px !important;
    margin-bottom: 15px !important;
}

.wishlist-component-items-container {
    border-top: 1px solid rgba(250, 250, 250, 0.4);
    width: 100%;
}
.wishlist-component-item {
    border-bottom: 1px solid rgba(250, 250, 250, 0.4);
    padding: 16px;
}
.three-tile-text h3 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
}

.explore-icon {
  height: 24px;
  width: 24px;
}

.explore-icon svg {
  fill: var(--icon-color); 
  stroke: var(--icon--color); 
}

.explore-title {
  display: none;
}

@media (min-width: 768px){
  .main-header-component-wrapper {
      align-items: center;
      height: 50px;
      justify-content: space-between;
      margin-left: 0px;
  }
  }

@media (max-width: 800px) {
  .main-header-component-mobile-actions {
      width: auto;
  }
  .profile-main-content {
    display: block;
  }
  }

@media (max-width: 768px) {
  .explore-title {
    display: block; 
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: left;
    color: #aaa;
    margin-top: 10vh;
    padding-left: 10px;
    border-left: 4px solid var(--primary-color);
    text-transform: uppercase;
    margin-left: 10px;
  }
  .shop-profile-data-tab-navigation {
    padding: 15px;
}
  .shop-profile-data-tab-navigation {
    border-radius: 0;
    padding: 25px 25px 25px;
   /* margin-bottom: 15px;
    display: inline-block;
    overflow: scroll;
    width: 100%;*/
}
}

.explore-title {
  margin-top: 10vh;
}

.main-header-component-wrapper {
  display: flex;
  }

.explore-bento-section {
  max-width: 1120px;
  margin: auto;
  overflow: scroll;
}

.profile-sidebar-left {
  padding: 0 !important;
  
}
.main-header-component-mobile-discover {
cursor: pointer;
}

.header-icon {
  cursor: pointer;
}

.individual-shop-products-container {
  padding-top: 0px;
}
.bento-column-left {
    padding: 25px;
}

.product-card-component-shop-name {
    text-overflow: ellipsis;
}

.events-parent-container {
    display: none;
}

.explore-bento-section {
    zoom: normal;
    padding: 10px;
    padding-top: 25px;
}

/* The main container covering the full viewport */
.verification-start-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.verification-start-card {
  background-color: var(--background-color); 
  border-radius: var(--border-radius-lg);    
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); 
  max-width: 480px;     
  width: 100%;
  padding: 2rem;
  text-align: center;
}

/* Heading style */
.verification-start-heading {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #333;
}

/* Paragraph text style */
.verification-start-text {
  font-size: 1rem;
  color: #666;
  line-height: 1.6;
  margin-bottom: 2rem;
}

/* Primary button style */
.verification-start-button {
  background-color: #1A73E8;  
 color: var(--text-color);;
  border: none;
  border-radius: var(--border-radius-full);
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Button hover effect */
.verification-start-button:hover {
  background-color: #1669c1; 
}

main.profile-main-area {
    max-width: 600px;
    margin-left: 0px;
    margin-right: 0px;
}


/* The backdrop that darkens the page */
.auth-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--modal-overlay-background);
  backdrop-filter: var(--modal-overlay-backdrop-filter);
  z-index: 9999; 
  display: flex;
  align-items: center;
  justify-content: center;
}

/* The modal content box */
.auth-modal-content {
  position: relative;
  background: #333;
  width: 400px; /* or your preference */
  max-width: 95%; /* so it’s responsive on small screens */
  border-radius: 8px;
   color: var(--text-color);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

/* Close button (X) in the corner */
.auth-modal-close {
  position: absolute;
  top: 8px;
  right: 10px;
  background: transparent;
 color: var(--text-color);;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.auth-modal-close:hover {
  color: #ccc;
}

.streaming-coming-soon-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
   color: var(--text-color);
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
  z-index: 2;
  pointer-events: none;
  flex-direction: column;
  text-align: center;
}


/* Admin Pages */

/* Admin Dashboard Main Container */
.admin-dashboard-container {
  width: 100%;
}

/* Dashboard Content Wrapper */
.admin-dashboard-content {
  display: flex;
  width: 100%;
}

/* Dashboard Main Wrapper */
.admin-dashboard-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
}

/* Sidebar */
.admin-dashboard-sidebar {
  width: 80px;
}

/* Responsive Sidebar for 800px screens */
@media (min-width: 800px) {
  .admin-dashboard-sidebar {
    width: 330px;
  }
}

/* Admin Sidebar */

/* Admin Sidebar Container */
.admin-sidebar-container {
  height: 95vh;
  width: 15vw;
  background-color: var(--sidebar-background);
  backdrop-filter: var(--modal-overlay-backdrop-filter);
  border-right: 1px solid var(--border-color);
  overflow-y: auto;
  position: fixed;
  top: 30;
  left: 0;
}

/* Sidebar Menu */
.admin-sidebar-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* Sidebar Item */
.admin-sidebar-item {
  width: 100%;
  padding: 15px;
  display: flex;
  align-items: center;
}

.admin-sidebar-link {
  display: flex;
  align-items: center;
  width: 100%;
  text-decoration: none;
  color: #555;
  transition: color 0.3s ease;
}

.admin-sidebar-item:hover .admin-sidebar-link {
  color: var(--primary-color);
}

.admin-sidebar-active .admin-sidebar-link {
  color: var(--primary-color);
}

/* Sidebar Text */
.admin-sidebar-text {
  display: none;
  padding-left: 10px;
  font-size: 18px;
  font-weight: 400;
  color: var(--text-color);
}

/* Show Sidebar Text for larger screens */
@media (min-width: 800px) {
  .admin-sidebar-text {
    display: block;
  }
}


/* Admin theme manager */

.admin-theme-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 80vw;
  background-color: var(--background-color);
  color: var(--text-color);
  backdrop-filter: blur(8px); 
  padding: 24px;
  border-radius: 8px;
  font-family: "Arial", sans-serif;
  position: relative;
}

/* Loading text */
.admin-theme-loading {
  margin: 24px;
  color: var(--text-color);
  text-align: center;
  font-size: 1.2rem;
}

/* Title */
.admin-theme-title {
  text-align: center;
  font-size: 1.8rem;
  margin-bottom: 24px;
  font-weight: 600;
  color: var(--text-color);
}

/* The form that holds all color fields in 2 columns */
.admin-theme-form {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns */
  gap: 20px;
}

/* Single field container */
.admin-theme-field {
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  padding: 16px;
  border-radius: var(--border-radius-md);
  border: 1px solid var(--border-color);
}

/* Label for each field */
.admin-theme-field-label {
  font-weight: 500;
  margin-bottom: 8px;
}

/* Wrapper for the color preview, text input, and pencil icon */
.admin-theme-field-input-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Color Preview Box */
.admin-theme-color-preview {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  flex-shrink: 0;
}

/* The text input for color strings (#FFFFFF, rgba(…), etc.) */
.admin-theme-text-input {
  flex: 1;
  padding: 8px;
  border-radius: var(--border-radius-md);
  border: 1px solid var(--border-color);
  background-color: var(--background-color);
  color: var(--text-color);
  font-size: 0.9rem;
}

.admin-theme-text-input:focus {
  outline: none;
  border-color: var(--border-color);
}

/* Pencil icon button */
.admin-theme-color-button {
  border: none;
  background-color: var(--background-color);
  color: var(--text-color);
  font-size: 1rem;
  padding: 8px 12px;
  border-radius: var(--border-radius-full);
  border: 1px solid var(--primary-color);
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
}

.admin-theme-color-button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.admin-theme-submit-btn {
  grid-column: 1 / -1; 
  margin-top: 16px;
  justify-self: end;
  padding: 10px 24px;
  font-size: 1rem;
  cursor: pointer;
  background-color: var(--primary-color);
  color: var(--text-color);
  border: none;
  border-radius: var(--border-radius-full);
  transition: background-color 0.3s ease;
  font-weight: 500;
}

.admin-theme-submit-btn:hover {
  background-color: var(--primary-color-hover);
}

.admin-theme-submit-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Modal Overlay */
.admin-theme-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(10px);
  z-index: 9999;  
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Modal Container */
.admin-theme-modal {
  background-color: #222;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  box-shadow: 0 2px 10px rgba(0,0,0,0.3);
}

/* Buttons within the modal */
.admin-theme-modal-buttons {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.admin-theme-modal-btn {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  font-size: 0.9rem;
}

.admin-theme-modal-btn.confirm {
  background-color: #36d300;
  color: var(--text-color);
}

.admin-theme-modal-btn.confirm:hover {
  background-color: #2c4600;
}

.admin-theme-modal-btn.cancel {
  background-color: #444;
  color: var(--text-color);
}

.admin-theme-modal-btn.cancel:hover {
  background-color: #666;
}



/* A wrapper for multiple sections */
.admin-theme-form-groupings {
  display: flex;
  flex-direction: column;
  gap: 40px; 
}

/* Each distinct theme section (Light, Dark, General) */
.admin-theme-section {
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-md);
  padding: 16px;
}

/* Title for each theme section */
.admin-theme-section-title {
  margin: 0 0 16px;
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--text-color);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 8px;
}

.two-column-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.admin-theme-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

/* Base container for the message */
.admin-theme-message {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  max-width: 400px;
  margin: 10px 0;
  position: relative;
}

/* Success message styles */
.admin-theme-message--success {
  background-color: #ecfdf5;
  border: 1px solid #34d399;
  color: #065f46;
}

/* Error message styles */
.admin-theme-message--error {
  background-color: #fef2f2;
  border: 1px solid #f87171;
  color: #7f1d1d;
}

/* Icon styling */
.admin-theme-message__icon {
  margin-right: 10px;
  font-size: 16px;
}

/* Message text */
.admin-theme-message__text {
  flex-grow: 1;
}

/* Close button */
.admin-theme-message__close {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: inherit;
  padding: 0 6px;
}
.admin-theme-message__close:hover {
  opacity: 0.7;
}


/* Admin header */

/* Admin Header Container */
.admin-header-container {
  width: 100%;
  height: 80px;
  background-color: var(--header-background);
  border-bottom: 1px solid var(--border-color);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  left: 0;
  z-index: 30;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

/* Site Logo */
.admin-header-site-logo {
  display: block;
  height: auto;
  max-height: 60px;
  cursor: pointer;
  background-image: var(--site-logo-url);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}


/* Navigation Icons */
.admin-header-nav {
  display: flex;
  align-items: center;
  gap: 20px;
}

.admin-header-nav-item {
  color: #555;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.admin-header-nav-item:hover {
  color: #000;
}

/* User Avatar */
.admin-header-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

/* Theme Mode Toggle Container */
.theme-mode-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

/* Theme Toggle Button */
.theme-toggle-btn {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  color: var(--text-color);
  background-color: var(--background-color);
  border: 2px solid var(--text-color);
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  outline: none;
  position: relative;
  overflow: hidden;
}

/* Inside toggle container */
.theme-toggle-btn span {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
}

/* Highlight active mode */
.theme-toggle-btn .active-mode {
  background-color: var(--background-color);
  color: var(--text-color);
  font-weight: bold;
}

/* Dim inactive mode */
.theme-toggle-btn .inactive-mode {
  opacity: 0.5;
}


/* AllSellers.css */

/* Container for the entire component */
.admin-all-sellers-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  box-sizing: border-box;
}

/* CHART SECTION */
.admin-all-sellers-chart-section {
  width: 100%;
  padding: 16px;
  margin-bottom: 20px;
}

.admin-all-sellers-chart-heading {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
}

.admin-all-sellers-chart-button-container {
  margin-bottom: 10px;
  display: flex;
  gap: 8px;
}

.admin-all-sellers-chart-button {
  background-color: var(--primary-color);
  color: var(--text-color);
  padding: 10px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.admin-all-sellers-chart-button--active {
  background-color: #008CBA;
}

.admin-all-sellers-chart-wrapper {
  width: 100%;
  background-color: var(--background-color);
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  min-height: 300px;
}

/* DATAGRID SECTION */
.admin-all-sellers-datagrid-section {
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
}

.admin-all-sellers-datagrid-heading {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 12px;
}

.admin-all-sellers-datagrid-wrapper {
  width: 100%;
  background-color: var(--background-color);
  border-radius: 8px;
  box-sizing: border-box;
}

/* MODALS - Overlay */
.admin-all-sellers-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: var(--modal-overlay-background);
  backdrop-filter: var(--modal-overlay-backdrop-filter);
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* MODALS - Container */
.admin-all-sellers-modal {
  background: var(--background-color);
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 600px;
  position: relative;
  box-sizing: border-box;
}

.admin-all-sellers-modal--scrollable {
  max-height: 80vh;
  overflow-y: auto;
}

/* MODAL close button */
.admin-all-sellers-modal-close {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  margin-bottom: 10px;
}

/* Modal Title */
.admin-all-sellers-modal-title {
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
}

/* Modal Buttons */
.admin-all-sellers-modal-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.admin-all-sellers-modal-button {
  padding: 8px 16px;
  background-color: #146e10;
  color: var(--text-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* EDIT FORM */
.admin-all-sellers-edit-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.admin-all-sellers-form-group,
.admin-all-sellers-form-group-wide,
.admin-all-sellers-form-group-switch {
  display: flex;
  flex-direction: column;
}

.admin-all-sellers-form-group-switch {
  align-items: flex-start;
}

.admin-all-sellers-form-group > label,
.admin-all-sellers-form-group-wide > label {
  font-weight: 500;
  margin-bottom: 5px;
}

.admin-all-sellers-form-group > input,
.admin-all-sellers-form-group-wide > textarea {
  background: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 10px;
  padding: 8px;
  font-size: 14px;
}

.admin-all-sellers-form-group-wide textarea {
  min-height: 80px;
  resize: vertical;
}

.admin-all-sellers-save-button {
  grid-column: span 2;
  padding: 10px 20px;
  background-color: var(--primary-color);
  border: none;
  border-radius: 50px;
  color: var(--text-color);
  cursor: pointer;
}

/* SWITCH (HeadlessUI) */
.admin-all-sellers-switch {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 24px;
  width: 40px;
  border-radius: 9999px;
  transition: background-color 0.2s ease-in-out;
}

.admin-all-sellers-switch-toggle {
  inline-size: 18px;
  block-size: 18px;
  background-color: #ffffff;
  border-radius: 50%;
  transform: translateX(2px);
  transition: transform 0.2s ease-in-out;
}

.admin-all-sellers-switch-toggle--active {
  transform: translateX(18px);
}







.followers-following-panel-tabs {
  border-bottom: 1px solid rgba(250, 250, 250, 0.4);
  margin-bottom: 1rem;
}



.tag-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.tag-input {
  padding: 8px 12px;
  border: 1px solid var(--border-color);
  background-color: rgba(0,0,0,0.5);
  border-radius: 4px;
}

.tag-custom-input {
flex: 1;
padding: 8px 12px; 
box-sizing: border-box; 
border: 1px solid var(--border-color); 
border-radius: var(--border-radius-lg); 
background-color: var(--background-color); 
font-size: 16px;
color: var(--text-light); 
}

.tag-custom-input:focus {
border-color: var(--primary-color); 
outline: none; 
box-shadow: 0 0 8px rgba(54, 211, 0, 0.533); 
}

.tagbtn {
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
}

.tagbtn-add-tag {
  background-color: var(--primary-color);
  color: white;
  padding: 8px 16px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.2s;
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tagbtn-add-tag:hover {
  background-color: var(--primary-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

.tagbtn-add-tag:active {
  background-color: #004085;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transform: translateY(0);
}

.tagbtn-add-tag:focus {
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}


.tagbtn-approve {
  background-color: #28a745;
  color: white;
}

.tagbtn-delete {
  background-color: #dc3545;
  color: white;
}

.loading-text {
  text-align: center;
}

.tag-list {
  list-style-type: none;
  padding: 0;
  background-color: rgba(0,0,0,0.5)
}

.tag-item {
  background-color: rgba(0,0,0,0.5);
  padding: 6px;
  border-radius: 4px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tag-name {
  display: inline-flex;
  align-items: center;
  gap: 2px; 
  border-radius: 0.375rem; 
  background-color: #38a169; 
  padding: 8px;
  font-size: 0.75rem; 
  font-weight: 500;
  color: white;
  box-shadow: inset 0 0 0 1px rgba(107, 114, 128, 0.1);
}


.tag-info {
  flex-grow: 1;
}

.tag-selector .tag {
  margin: 5px;
  padding: 5px 10px;
  border: 1px solid var(--border-color);
  background-color: #f9f9f9;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
}

.selected-tags-list {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.tag-badge {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--background-color);
  color: white;
  padding-left: 10px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 999px; 
  font-size: 0.875rem; 
  line-height: 16px; 
  cursor: default;
  border: 1px solid var(--border-color)
}

.tag-badge button {
  background: transparent;
  border: none;
  color: white;
  cursor: pointer;
}

.tag-badge button:hover {
  color: #ccc;
}

.custom-tag-container {
  display: flex;
  width: 100%;
  margin-top: 10px;
  gap: 10px;
}

.tag-selector {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 10px;
  border:  1px solid rgba(250, 250, 250, 0.3);
  border-radius: var(--border-radius-lg);
}

.tag-dropdown-container {
  width: 100%;
  padding: 0.5rem;
  background-color: var(--background-color);
  border:  1px solid rgba(250, 250, 250, 0.3);
  border-radius: var(--border-radius-lg);
}

.tag-selector-button {
  padding: 0.5rem;
  width: 100%;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: var(--border-radius-full);
  cursor: pointer;
  max-width: 200px;
}

.tag-selector-button:hover {
  background-color: #218838;
}

.tag-badge.approved {
  border-color: var(--primary-color);
}

.tag-badge.unapproved {
  border-color: #FFA500;
}

.remove-tag-button {
  padding: 0.5rem;
  width: 100%;
  background-color: var(--background-color);
  color: white;
  border: none;
  border-radius: var(--border-radius-full);
  cursor: pointer;
  max-width: 200px;
}

.remove-tag-button:hover {
  color: red; 
}

.tag-custom-input {
  width: 100%; 
  padding: 8px 12px; 
  box-sizing: border-box; 
  border: 1px solid var(--border-color); 
  border-radius: var(--border-radius-lg); 
  background-color: var(--background-color); 
  font-size: 16px;
  color: var(--text-light); 
}

.tag-custom-input:focus {
  border-color: green; 
  outline: none; 
  box-shadow: 0 0 8px rgba(44, 204, 0, 0.533); 
}


/* dashboard-shipping-settings.css */

.dashboard-shipping-settings {
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-md);
  padding: 20px;
  width: 50%;
  font-family: Arial, sans-serif;
}

.dashboard-shipping-settings__title {
  font-size: 16px;
  margin-bottom: 1rem;
  color: var(--text-color);
  margin-bottom: 10px;
  text-transform: uppercase;
  padding-left: 10px;
  border-left: 4px solid var(--primary-color);
}

.dashboard-shipping-settings__error {
  background-color: #ffe2e2;
  color: #c0392b;
  padding: 0.75rem;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.dashboard-shipping-settings__success {
  background-color: #e2ffe2;
  color: #27ae60;
  padding: 0.75rem;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.dashboard-shipping-settings__form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.dashboard-shipping-settings__fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

.dashboard-shipping-settings__legend {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.dashboard-shipping-settings__carriers-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.dashboard-shipping-settings__section {
padding: 20px;
border: 1px solid var(--border-color);
background-color: var(--background-color);
border-radius: var(--border-radius-md);
margin-bottom: 10px;
}

.dashboard-shipping-settings__section h3{
  margin-bottom: 10px;
  font-weight: 700;
}

.dashboard-shipping-settings__carrier-option {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  padding: 0.5rem;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-md);
  transition: background-color ease 0.3s;
  background-color: var(--background-color);
}

.dashboard-shipping-settings__carrier-option:hover {
  background-color: var(--primary-color);
}

.dashboard-shipping-settings__carrier-option input[type="checkbox"] {
  transform: scale(1.2);
}

.carrier-option__logo-container {
  width: 40px;
  height: 40px;
  display: flex;
}

.carrier-option__logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.carrier-option__label {
  font-weight: 500;
}

.dashboard-shipping-settings__submit-btn {
  align-self: flex-start;
  background-color: var(--background-color);
  color: #ffffff;
  padding: 0.75rem 1.5rem;
  border: 1px solid var(--primary-color);
  border-radius: var(--border-radius-full);
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s, opacity 0.2s;
}

.dashboard-shipping-settings__submit-btn:hover {
  background-color: #165bbf;
}

.dashboard-shipping-settings__submit-btn:active {
  opacity: 0.9;
}

/*----WALLET LIGHT THEME-----*/

.crypto-wallet-input {
    background-color: rgba(250, 250, 250, 0.1);
    border: 0px solid #ddd;
    border-radius: 1.5rem;
    height: 28px;
    outline: none;
    padding: 15px;
    width: 100%;
}

/*------END------*/
