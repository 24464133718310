.share-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  .share-button {
    text-decoration: none;
    padding: 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    font-size: 14px;
    transition: background-color 0.3s ease;
  }
  
  .share-button i {
    margin-right: 8px;
  }
  
  .share-button.facebook {
    background-color: #1877f2;
     color: var(--text-color);
  }
  
  .share-button.facebook:hover {
    background-color: #145db2;
  }
  
  .share-button.twitter {
    background-color: #1da1f2;
     color: var(--text-color);
  }
  
  .share-button.twitter:hover {
    background-color: #1997db;
  }
  
  .share-button.linkedin {
    background-color: #0a66c2;
     color: var(--text-color);
  }
  
  .share-button.linkedin:hover {
    background-color: #085394;
  }
  